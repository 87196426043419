import React, {useEffect, useMemo, useRef} from 'react';
import {CurrencyType, PackageName, Pricing, State} from "../../store/types";
import {useViewport} from "../../hooks/responsive";
import {
  formatDuration,
  formatDurationRange,
  formatPriceBasedOnValue,
  getPackageAssignedDuration,
  getPackageDurations,
} from "../../../../common/utils/formats";
import {selectCurrentPackage, selectVenue} from "../../reducers/venues";
import {selectReservation} from "../../reducers/reservation";
import {selectUIConfig} from "../../reducers/ui-reducer";
import TimeIcon from "../../../../common/assets/package-time-icon.svg";
import GuestsIcon from "../../../../common/assets/package-guests-icon.svg";
import {connect, ConnectedProps} from "react-redux";
import classNames from "classnames";
import './packageCard.scss'
import downIcon from "../../../assets/down-icon.svg"
import defaultImage from "../../../assets/default-image.jpg"
import {SelectWithIcon} from "../../../../common/components/SelectWithIcon/SelectWithIcon";
import HTMLRenderer from "../../../../common/components/HTMLRenderer/HTMLRenderer";

const mapStateToProps = (state: State) => ({
  reservation: selectReservation(state),
  uiConfig: selectUIConfig(state),
  currentPackage: selectCurrentPackage(state),
  activeVenue: selectVenue(state),
});

const connector = connect(mapStateToProps);

type Props = {
  item: PackageName;
  onChangeCurrentPackage: (item: PackageName) => void;
  setDuration: (item: PackageName, duration: number) => void;
  onContactUs: (e: React.FormEvent) => void;
} & ConnectedProps<typeof connector>


const PackageCard = (
  {
    item,
    currentPackage,
    uiConfig,
    reservation,
    onChangeCurrentPackage,
    activeVenue,
    setDuration,
    onContactUs
  }: Props
) => {
  const accordionContentHeight = useRef({ height: 0 });
  const accordionDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (accordionDivRef.current) {
      accordionContentHeight.current.height = accordionDivRef.current.scrollHeight;
    }
  }, [])

  useEffect(() => {
    if (packageIsActive) {
      const {duration, guests} = reservation;
      if (
        (!item?.enableDurationChoice && item.duration === duration) ||
        (item?.enableDurationChoice && durationList.includes(duration)) ||
        (item?.enableAssignDuration && getPackageAssignedDuration(item, guests) === duration)
      ) {
        setDuration(item, duration);
      }
    }
  }, []);
  const [showMoreInfo, setShowMoreInfo] = React.useState(false);
  const toggleShowMoreInfo = () => setShowMoreInfo(!showMoreInfo);

  const packageIsActive = currentPackage?.id === item.id;
  const closed = activeVenue?.closed;
  const contactUsButtonText = activeVenue?.contactUsButtonText;
  const currency = uiConfig?.currency || CurrencyType.USD;

  const {isMobile, isMiddleScreen} = useViewport();

  let durationList: number[] = [];
  if (item.enableDurationChoice) {
    durationList = getPackageDurations(item);
  }
  let durationSlots = durationList.map((d) => {
    const textButton = formatDuration(d, activeVenue?.timeSlotDuration);
    return {key: d, text: textButton};
  });

  const renderPrice = () => {
    const minPrice = item.price ? formatPriceBasedOnValue(+item.price, currency) : '';
    const actionText = uiConfig?.actionText?.toLocaleLowerCase();

    let priceMethod;

    switch (item.method) {
      case Pricing.perPerson:
        priceMethod = "/person";
        break;

      case Pricing.perLane:
        priceMethod = `/hour`;
        break;

      case Pricing.flatRate:
        priceMethod = "";
        break;

      case Pricing.flatRatePerLane:
        priceMethod = `/${actionText || "lane"}`;
        break;

      case Pricing.flatRatePerHour:
        priceMethod = `/hour`;
        break;

      default:
        priceMethod = "/person";
    }

    return !item.contactOnly && (
      <p className="package-card__price">
        <span className="package-card__price-value">{minPrice}</span>
        <span className="package-card__price-method">{priceMethod}</span>
      </p>
    )
  }

  const renderGuests = () => {
    return (
      <p className='package-card__guests'>
        <img src={GuestsIcon} alt="Guests"/>
        <span>
          {item.minGuests} - {item.maxGuests} Guests
        </span>
      </p>
    )
  }

  const renderDuration = () => {
    if(item?.hidePackageDuration) {
      return <></>
    }
    let packageDuration = item.duration;

    if (item?.enableAssignDuration) {
      const {guests} = reservation;
      packageDuration = getPackageAssignedDuration(item, guests)
    }

    let content = (
      <span>
        {formatDuration(packageDuration, activeVenue?.timeSlotDuration)}
      </span>
    )

    if(item.enableDurationChoice){
      const minimumDuration = Math.min(...durationList);
      const maximumDuration = Math.max(...durationList);

      content = (
        <span>
          {formatDurationRange([minimumDuration, maximumDuration], activeVenue?.timeSlotDuration)}
        </span>
      )
    }

    return (
      <p className='package-card__guests'>
        <img src={TimeIcon} alt="Duration"/>
        {content}
      </p>
    )
  }

  const renderMoreInfo = () => {
    return (
      <div
        ref={accordionDivRef}
        style={{height: showMoreInfo ? `${accordionContentHeight.current.height}px` : 0}}
        className='package-card__info-content-wrapper'
      >
        <br/>
        <HTMLRenderer className='package-card__info-content' html={item.description}/>
      </div>
    )
  }

  const durationValue = useMemo(() => {
    if(durationSlots.some(slot => slot.key === reservation.duration)){
      return reservation.duration
    }

    return durationSlots[0]?.key
  }, [reservation.duration, durationSlots[0]])

  const renderButton = () => {
    if(item.contactOnly){
      return (
        <button
          className="package-card__select-button is-contact-us"
          onClick={onContactUs}
        >
          {contactUsButtonText}
        </button>
      )
    }

    return (
      <button
        className={
          classNames(
            'package-card__select-button',
            {
              'is-selected': packageIsActive
            }
          )
        }
        onClick={() => onChangeCurrentPackage(item)}
      >
        {packageIsActive ? 'Selected' : 'Select'}
      </button>
    )
  }

  const renderDesktopView = () => {
    return (
      <>
        <div className='package-card__image-container'>
          <img src={item.image || defaultImage} alt={item.name} className='package-card__image'/>
          {/*<span className='package-card__tag'>NEW</span>*/}
        </div>
        <div className='package-card__content'>
          <h1 className='package-card__title'>
            {item.name}
          </h1>
          <div className='package-card__details'>
            {renderPrice()}
            {renderGuests()}
            {renderDuration()}
          </div>
          <p className='package-card__short-description'>{item.shortDescription}</p>
          {renderMoreInfo()}
          <div className={`package-card__actions ${durationSlots.length > 0 && !item.hidePackageDuration ? "" : 'package-card__without-selector'}`}>
            <button
              className={
                classNames('package-card__info-button', {
                  'is-active': showMoreInfo,
                })
              }
              onClick={toggleShowMoreInfo}
            >
              <span>
                {showMoreInfo ? 'Show less' : 'More Info'}
              </span>
              <img src={downIcon} alt="Down"/>
            </button>
            {(durationSlots.length > 0 && !item.contactOnly && !item.hidePackageDuration) && (
              <div className='package-card__select-wrapper'>
                <label htmlFor="select-duration" className='package-card__select-label'>
                  Duration
                </label>
                <SelectWithIcon
                  placeholder=''
                  options={durationSlots}
                  id='select-duration'
                  wrapperClassName={classNames(
                    'package-card__select',
                    {
                      'is-mobile': isMobile,
                    }
                  )}
                  contentClass='content'
                  disabled={closed || item?.enableAssignDuration}
                  value={durationValue}
                  onChange={(duration) => {
                    setDuration(item, parseInt(String(duration)));
                  }}
                />
              </div>
            )}
            {renderButton()}
          </div>
        </div>
      </>
    )
  }

  return (
    <article
      className={
        classNames(
          'package-card',
          {
            'is-active': packageIsActive,
            'is-mobile': isMiddleScreen,
          }
        )
      }
    >
      {renderDesktopView()}
    </article>
  )
}

export default connector(PackageCard);