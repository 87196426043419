import { Box, Typography } from "@material-ui/core";
import { AccountCircle, LocationOn } from "@material-ui/icons";
import { goBack } from "connected-react-router";
import dayjs from "dayjs";
import { IDropdownOption, mergeStyleSets } from "office-ui-fabric-react";
import React, { ChangeEvent, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import { DATE_FORMAT } from "../../../../common/constants/timedate";
import { pushUrlParamsAction, updateReservationAction } from "../../actions/reservation-actions";
import { changeVenuePackageAction, toPartyWithVenueAction } from "../../actions/venue-actions";
import { useViewport } from "../../hooks/responsive";
import { selectUIConfig } from "../../reducers/ui-reducer";
import { selectVenues } from "../../reducers/venues";
import { State, VenueName } from "../../store/types";
import { getVenueList } from "../../utils/formats";
import ButtonMain from "../common/ButtonMain/ButtonMain";
import Header2 from "../common/Header/Header2";
import SelectWithIcon2 from "../common/SelectWithIcon/SelectWithIcon2";
import "./home.scss";

const MORE_THAN_11 = 100;

const mapDispatchToProps = {
    changeVenuePackage: changeVenuePackageAction,
    toPartyWithVenue: toPartyWithVenueAction,
    updateReservation: updateReservationAction,
    goBack,
    pushUrlParams: pushUrlParamsAction,
};
const mapStateToProps = (state: State) => ({
    venues: selectVenues(state),
    uiConfig: selectUIConfig(state),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const getMenuPropsGuests: (count: number) => IDropdownOption[] = (
    count: number
) =>
    Array(count + 1)
        .fill(null)
        .map((_, index) => ({
            key: index === count ? MORE_THAN_11 : index + 1,
            text:
                index === 0
                    ? `${index + 1} Guest`
                    : `${index + 1}${index === count ? "+" : ""} Guests`,
        }));

const Home2 = ({
    venues,
    uiConfig,
    changeVenuePackage,
    updateReservation,
    toPartyWithVenue,
    goBack,
    pushUrlParams,
}: Props) => {
    const { isMobile } = useViewport();

    const contentStyles = mergeStyleSets({
        body: {
            flex: 1,
            background: `no-repeat center url(${uiConfig?.homeBackgroundImage})`,
            backgroundSize: "cover",
        },
    });

    const menuPropsLocations = getVenueList(venues, '');
    const [venue, setVenue] = useState<VenueName>();
    const [guests, setGuests] = useState(2);
    const [errorVenue, setErrorVenue] = useState<string>("");

    const menuPropsGuests = getMenuPropsGuests(venue ? venue.maxGuests : 10);
    const date = dayjs().format(DATE_FORMAT);

    const selectVenueOption = (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
        const newVenueId = menuPropsLocations?.find(location => location.text === event.target.value)?.key || ''
        const openedVenues = venues.filter(v => !v.isComingSoon);
        const currentVenue = venues.find((venue) => venue.id === newVenueId) || openedVenues[0];
        if (newVenueId && venue?.id !== newVenueId && currentVenue) {
            setErrorVenue("");
            setVenue(currentVenue);
            if (guests > currentVenue.maxGuests) {
                setGuests(MORE_THAN_11);
            }
            changeVenuePackage({
                updatePackage: undefined,
                venueId: currentVenue.id.toString(),
                date,
                guests,
                changeDateOrVenue: true,
                changeVenueWithoutPackage: true
            });
        }
    };
    const selectGuestsOption = (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
        const value = menuPropsGuests?.find(guest => guest.text === event.target.value)?.key || ''

        if (!value) {
            return;
        }
        const guests = Number(value);
        setGuests(guests);
    };

    const onContinue = () => {
        if (!venue) {
            setErrorVenue("Please choose a location to continue");
            return;
        }
        if (guests === MORE_THAN_11) {
            toPartyWithVenue(dayjs().format(DATE_FORMAT));
            return;
        }

        updateReservation({ guests, date, });
        pushUrlParams();
    };

    return (
        <Box className="home-container">
            <Header2 handleBackButton={goBack} />
            <Box className={contentStyles.body}>
                <Box className="container">
                    <Box className="home-content main-content">
                        <Box className={`selectors ${isMobile ? "mobile" : ""}`}>
                            <Typography className="heading-h3-home">{uiConfig?.homeTitle}</Typography>
                            {errorVenue && <Box className="home-selector">
                                <Box className="error">{errorVenue}</Box>
                            </Box>}
                            <Box display={'flex'} flexDirection={'column'} style={{ gap: '16px' }}>

                                <SelectWithIcon2 icon={<LocationOn />} withIcon options={menuPropsLocations.map(menu => menu.text)} onChange={selectVenueOption} label="Select Location"
                                    value={venue?.name} />
                                <SelectWithIcon2 icon={<AccountCircle />} withIcon options={menuPropsGuests.map(menu => menu.text)} onChange={selectGuestsOption} label="Number of Guests"
                                    value={menuPropsGuests.find(menu => menu.key === guests)!.text} />
                                <ButtonMain onClick={() => onContinue()} name="continue">Continue</ButtonMain>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default connector(Home2);
