import React from "react";
import dayjs from "dayjs";
import { Text, DefaultButton } from "office-ui-fabric-react";
import CalendarIcon from "../../../../assets/calendar-icon.svgr";
import { DATE_FORMAT } from "../../../../../common/constants/timedate";
import { Availability } from "../../../store/types";
import "./weekDaysButton.scss";

interface WeekDaysButtonProps {
  date: string;
  selectDate: (date: string) => void;
  showCalendar: (isCalendar: boolean) => void;
  isMobile: boolean;
  isMiddleScreen: boolean;
  isMaxScreen: boolean;
  availability: Availability;
}

const isCalendar = false;

export const WeekDaysButton = ({
  date,
  selectDate,
  showCalendar,
  isMobile,
  isMiddleScreen,
  isMaxScreen,
  availability
}: WeekDaysButtonProps) => {
  const daysCount = isMiddleScreen
    ? isMobile
      ? 6
      : 7
    : isMaxScreen
      ? 11
      : 9;
  const weekDays = Array.apply(null, Array(daysCount)).map((_, index) => {
    const date = dayjs().add(index, "d");
    return {
      date: date.format(DATE_FORMAT),
      weekDay: date.format("dd"),
      day: date.format("D"),
      isAvailable: availability ? availability[date.format(DATE_FORMAT)] : true
    };
  });

  const dayDetermination = (day: any) => {
    if (day.date === date) {
      return (
        <DefaultButton 
          className={`dayButtonSelected ${
            isMaxScreen
              ? "maxSize"
              : isMiddleScreen
                ? isMobile
                  ? "mobileSize"
                  : "middleSize"
                : ""
          } focusableRoundButton`} 
          role="button"
          tabIndex={0}
          onKeyPress={() => (day.isAvailable ? selectDate(day.date) : null)}
          disabled={!day.isAvailable}
        >
          <Text variant={"large"}>{day.day}</Text>
        </DefaultButton>
      );
    } else {
      return (
        <DefaultButton
          className={`dayButtons ${
            isMaxScreen
              ? "maxSize"
              : isMiddleScreen
                ? isMobile
                  ? "mobileSize"
                  : "middleSize"
                : ""
          } ${
            day.isAvailable ? "" : "unavailable"
          } focusableRoundButton`}
          role="button"
          tabIndex={0}
          onKeyPress={() => (day.isAvailable ? selectDate(day.date) : null)}
          disabled={!day.isAvailable}
        >
          <Text variant={"large"}>{day.day}</Text>
        </DefaultButton>
      );
    }
  };

  return (
    <div className="weekDays"> 
      {weekDays.map((day) => (
        <div
          className={`weekDaySection ${
            isMaxScreen
              ? "maxSizeMargin"
              : isMiddleScreen
                ? isMobile
                  ? "mobileSizeMargin"
                  : "middleSizeMargin"
                : ""
          }`}
          key={day.day}
          onClick={() => (day.isAvailable ? selectDate(day.date) : null)}
        >
          <div className="weekDay">{day.weekDay}</div>
          {dayDetermination(day)}
        </div>
      ))}
      <DefaultButton
        className={`${isMaxScreen || (isMiddleScreen && !isMobile) ? "calendarIcon maxSize" : isMobile ? "calendarIcon mobileSize" : "calendarIcon"} focusableRoundButton`}
        onClick={() => showCalendar(!isCalendar)}
        role="button"
        tabIndex={0}
        onKeyPress={() => showCalendar(!isCalendar)}
      >
        <CalendarIcon viewBox="0 0 14 15" />
      </DefaultButton>
    </div>
  );
};
