import { Box, BoxProps, Typography } from '@material-ui/core'
import React from 'react'
import LockIcon from '../../../../assets/payment/lock.svgr'

const InformationSecureAlert2 = ({ ...props }: BoxProps) => {
    return (
        <Box display={'flex'} mx={'20px'} mt={'10px'} mb={'26px'} style={{ gap: '8px' }} {...props}>
            <LockIcon style={{ marginTop: '4px' }} />
            <Typography className='root'>All your information is secure and encrypted. We don't take security lightly so we always use the latest security protocols.</Typography>
        </Box>
    )
}

export default InformationSecureAlert2