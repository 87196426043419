import {
    CardElement,
    PaymentRequestButtonElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import { Stripe, StripeCardElement, StripePaymentRequestButtonElementOptions } from "@stripe/stripe-js";
import { mergeStyleSets } from "office-ui-fabric-react";
import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";
import React, { useEffect, useRef, useState } from "react";
import { InfoAlert } from "../../../../../common/components/Alert/Alert";
import GiftCard from "../../../../../common/components/GiftCard";
import { calculateDeposit, rem } from "../../../../../common/utils/formats";
import {
    GiftCardBalance,
    GiftCardBalanceRequest,
    GiftCardPaymentRequest,
    PMethod,
    Reservation,
    Venue,
} from "../../../store/types";
import CouponCodes2 from "../../OrderSummary2/CouponCodes2";
import ExpressCheckout2 from "../../ReservationInfo/Payment/ExpressCheckout2";
import PaymentContainer2 from "../../ReservationInfo/Payment/PaymentContainer2";
import PaymentDeilimiter from "../../ReservationInfo/Payment/PaymentDeilimiter";
import ButtonMain from "../ButtonMain/ButtonMain";
import './stripeForm.scss';
import AfterPayIcon from '../../../../assets/payment/afterpay-logo.svgr';
import { Button } from "@material-ui/core";

interface Props {
    venue: Venue | undefined;
    reservation: Reservation;
    selected: boolean;
    isMobile: boolean;
    showGiftCard: boolean;
    orderSummary?: ((error?: string, block?: boolean) => JSX.Element) | null;
    uiConfig: any;
    requestError?: string;
    makeStripeReservation: (stripe: Stripe, elements?: StripeCardElement, isAfterpay?: boolean) => void;
    paymentMethodStripe: (stripe: Stripe, ev: any) => void;
    giftBalance?: GiftCardBalance;
    getGiftCardBalance: (cardParams: GiftCardBalanceRequest) => void;
    giftBalanceError?: string;
    addGiftCard: (cardParams: GiftCardPaymentRequest) => void;
    giftCardAmount: number;
    showDiscounts?: boolean;
    applyCouponCode?: (couponCodes: string[]) => void;
    createEmptyPayment: (paymentType: PMethod) => void;
    isUpdateReservation: boolean;
    isUpdateWithVenueChange: boolean;
    oldReservation?: Reservation;
    customStripeHandler?: (stripe: Stripe, elements: StripeCardElement) => void;
}

const checkboxStyle = {
    root: {
        margin: "0 0 30px",
    },
    checkbox: {
        width: rem(20),
        height: rem(20),
    },
    text: {
        fontSize: rem(13),
        lineHeight: "1.5",
    },
    checkmark: {
        fontSize: rem(13),
    },
};

const StripeForm2 = ({
    venue,
    reservation,
    selected,
    isMobile,
    orderSummary,
    uiConfig,
    requestError,
    showGiftCard,
    makeStripeReservation,
    paymentMethodStripe,
    customStripeHandler,
    giftBalance,
    getGiftCardBalance,
    giftBalanceError,
    addGiftCard,
    giftCardAmount,
    showDiscounts,
    applyCouponCode,
    createEmptyPayment,
    isUpdateReservation,
    isUpdateWithVenueChange,
    oldReservation,
}: Props) => {
    const [succeeded, setSucceeded] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [processing, setProcessing] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [isSelected, setIsSelected] = useState<boolean>(selected);
    const [paymentRequest, setPaymentRequest] = useState<any>(null);

    const stripe = useStripe();
    const elements = useElements();
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (stripe) {
            const pr = stripe.paymentRequest({
                country: 'US',
                currency: 'usd',
                total: {
                    label: 'reservation',
                    amount: Math.round(deposit * 100),
                },
                requestPayerEmail: true,
            });
            pr.on('paymentmethod', (ev) => paymentMethodStripe(stripe, ev));

            // Check the availability of the Payment Request API.
            pr.canMakePayment().then(result => {
                if (result) {
                    setPaymentRequest(pr);
                }
            });
        }
    }, [stripe]);

    const subtitle = mergeStyleSets({
        backgroundColor: {
            background: 'white',
            height: "22px",
            padding: "0 10px",
            marginBottom: "12px",
        },
    });

    const confirmButtonText = isUpdateReservation ? "MODIFY RESERVATION" : "COMPLETE YOUR RESERVATION";

    const paymentButtonOptions: StripePaymentRequestButtonElementOptions = {
        paymentRequest,
        style: {
            paymentRequestButton: {
                type: 'default',
                theme: 'dark',
                height: '58px',
            }
        }
    }

    if (!stripe || !elements) {
        return <></>;
    }

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    const handleChange = async (event: any) => {
        setDisabled(event.empty);
        setSucceeded(event.empty);
        setError("");
    };

    const handleSubmit = async (ev: any) => {
        ev.preventDefault();
        setProcessing(true);
        try {
            const cardMethod = elements.getElement(CardElement);
            if (cardMethod !== null) {
                if (customStripeHandler) {
                    customStripeHandler(stripe, cardMethod)
                } else {
                    makeStripeReservation(stripe, cardMethod);
                }
                setSucceeded(true);
                setProcessing(false);
            }
        } catch (e) {
            console.log(e)
            setError(`Sorry, we cannot complete payment. Please try again later.`);
            setDisabled(true);
            setProcessing(false);
        }
    };

    const handleAfterPaySubmit = () => {
        setProcessing(true);
        try {
            makeStripeReservation(stripe, undefined, true);
            setSucceeded(true);
            setProcessing(false);

        } catch (e) {
            console.log(e)
            setError(`Sorry, we cannot complete payment. Please try again later.`);
            setDisabled(true);
            setProcessing(false);
        }
    }


    let deposit = calculateDeposit({
        reservation,
        giftCardAmount,
        isUpdateReservation,
        isUpdateWithVenueChange,
        oldReservation,
    })
    let isSkipPayment = deposit <= 0;
    let isRefundPayment = deposit < 0;
    let isShowRefundPaymentInfo = isUpdateReservation && isSkipPayment && isRefundPayment;
    let isShowSkipPaymentInfo = isUpdateReservation && isSkipPayment && !isRefundPayment;
    let isShowPaymentInfo = isUpdateReservation && !isSkipPayment;

    const isExpressCheckoutDisplaying = (venue?.isAfterPayEnable || venue?.isStripeV2ApplePayEnable) && !!paymentRequest

    return (
        <>
            <form id="payment-form" onSubmit={handleSubmit}>

                {
                    isExpressCheckoutDisplaying &&
                    <>
                        {<ExpressCheckout2>
                            <PaymentRequestButtonElement options={paymentButtonOptions} />
                            {venue?.isAfterPayEnable ? <Button
                                color="default"
                                variant="contained"
                                style={{
                                    width: '100%',
                                    height: '58px',
                                    marginTop: '16px',
                                    textTransform: 'none',
                                    fontSize: '16px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',

                                }}
                                onClick={handleAfterPaySubmit}
                            >
                                <AfterPayIcon width={'150px'} />
                            </Button> : null}
                        </ExpressCheckout2>}

                        <PaymentDeilimiter />
                    </>
                }

                <PaymentContainer2>
                    {!isSkipPayment && (
                        <>
                            <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
                        </>
                    )}
                    {showGiftCard && isMobile && (
                        <GiftCard
                            isMobile={!!isMobile}
                            deposit={deposit}
                            giftBalance={giftBalance}
                            getGiftCardBalance={getGiftCardBalance}
                            giftBalanceError={giftBalanceError}
                            addGiftCard={addGiftCard}
                            giftCardAmount={giftCardAmount}
                            isSkipPayment={isSkipPayment}
                        />
                    )}
                    {showDiscounts && isMobile && (
                        <CouponCodes2
                            reservation={reservation}
                            applyCouponCode={applyCouponCode}
                            deposit={deposit}
                            isSkipPayment={isSkipPayment} />
                    )}
                    {(isMobile && orderSummary) ? orderSummary(error, (processing || disabled || succeeded || !!error)) : (
                        <>
                            {venue?.showPolicy && (
                                <>
                                    <div className="venue-policy">
                                        <div className="heading">VENUE POLICIES</div>
                                        <div
                                            className="venue-policy-value"
                                            dangerouslySetInnerHTML={{
                                                __html: venue?.venueInfo || "",
                                            }}
                                        ></div>
                                    </div>
                                    <Checkbox
                                        styles={checkboxStyle}
                                        label="I have read and agree to the venue policies."
                                        checked={isSelected}
                                        onChange={(_: any, isChecked?: boolean) =>
                                            setIsSelected(!!isChecked)
                                        }
                                    />
                                </>
                            )}
                            {(isShowRefundPaymentInfo) && (
                                <InfoAlert
                                    text="You will receive a refund for the price difference"
                                />
                            )}
                            {(isShowSkipPaymentInfo) && (
                                <InfoAlert
                                    text="There is no price difference for your new reservation. No additional payment is needed"
                                />
                            )}
                            {isShowPaymentInfo && (
                                <InfoAlert
                                    text="Your new reservation requires an additional payment"
                                />
                            )}
                            {(requestError || error) && (
                                <div className="card-error" role="alert">
                                    {error ? error : requestError}
                                </div>
                            )}
                            {isSkipPayment && (
                                <ButtonMain
                                    role="button"
                                    onClick={() => createEmptyPayment(PMethod.stripe)}
                                    disabled={!isSelected}
                                >
                                    {confirmButtonText}
                                </ButtonMain>
                            )}
                            {!isSkipPayment && (
                                <ButtonMain
                                    id="submit"
                                    disabled={processing || disabled || succeeded || !!error || !isSelected}
                                    role="button"
                                    aria-label="submit form"
                                    type="submit"
                                >
                                    {confirmButtonText}
                                </ButtonMain>
                            )}
                        </>
                    )}
                </PaymentContainer2>

            </form>
        </>
    );
};

export default StripeForm2;
