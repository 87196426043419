import DayjsUtils from '@date-io/dayjs';
import { createTheme } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from "@material-ui/styles";
import dayjs from "dayjs";
import { Checkbox } from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import { FormField } from "../../../../../common/components/FormField/FormField";
import HTMLRenderer from "../../../../../common/components/HTMLRenderer/HTMLRenderer";
import { InputField } from "../../../../../common/components/InputField/InputField";
import { PhoneInput } from "../../../../../common/components/PhoneInput/PhoneInput";
import { PrimaryButton } from "../../../../../common/components/PrimaryButton/PrimaryButton";
import { SelectWithIcon } from "../../../../../common/components/SelectWithIcon/SelectWithIcon";
import { color } from "../../../../../common/constants/styles";
import { DATE_FORMAT } from "../../../../../common/constants/timedate";
import { activeCustomFieldsToUIModel, formatOccasionsAndGuestDetails, rem } from "../../../../../common/utils/formats";
import { isValidPhone } from "../../../../../common/utils/validate";
import { DiningOptions, GameType, GameTypeForStadium, OccasionOptions } from "../../../../../server/src/entities/enums";
import { cleanIsShowVerificationCodeModalAction, confirmVerificationCodeAction, getVerificationCodeMessageAction } from "../../../actions/auth-actions";
import {
  confirmReservationAction,
  updateConfirmationAction,
} from "../../../actions/reservation-actions";
import { backToReservationAction } from "../../../actions/venue-actions";
import { useViewport } from "../../../hooks/responsive";
import OrderSummaryWrapper from "../../../providers/OrderSummaryWrapper";
import { selectAuthError, selectClient, selectGuest, selectIsShowVerificationCodeModal, selectVerified } from "../../../reducers/auth-reducer";
import {
  selectGiftCardAmount,
  selectIsRequestInProgress,
  selectIsUpdateReservation,
  selectOldReservation,
  selectRequestError,
  selectReservation,
  selectReservationAddons,
  selectReservationConfirmation,
} from "../../../reducers/reservation";
import { selectUIConfig } from "../../../reducers/ui-reducer";
import { selectCurrentPackage, selectVenue } from "../../../reducers/venues";
import { CustomFieldsType, PizzaType, PlayerDetailsForSteltronicInfo, State, customFields } from "../../../store/types";
import MobileFixedButton from "../../MobileFixedButton/MobileFixedButton";
import VerificationCodeModal from "../VerificationCodeModal/VerificationCodeModal";
import "./reservation.scss";

const customNotesFieldStyle = {
  root: {
    width: "100%",
    padding: "0px",
  },
  fieldGroup: {
    height: "auto",
    background: color.grayBackground,
    color: color.grayText2,
    border: `1px solid ${color.grayBorder2}`,
    borderRadius: 2,
    outline: 0,
  },
  errorMessage: {
    position: "absolute",
  },
  field: {
    paddingLeft: 18,
    fontSize: rem(16),
  },
};

const pizzaOptions = [
  {
    text: PizzaType.cheesePizza,
    key: PizzaType.cheesePizza,
  },
  {
    text: PizzaType.pepperoniPizza,
    key: PizzaType.pepperoniPizza,
  },
  {
    text: PizzaType.cheeseAndPepperoniPizza,
    key: PizzaType.cheeseAndPepperoniPizza,
  },
];

const gameOptions = (Object.keys(GameType) as Array<keyof typeof GameType>).map(key => ({
  text: GameType[key],
  key,
}));

const gameOptionsForStadium = (Object.keys(GameTypeForStadium) as Array<keyof typeof GameTypeForStadium>).map(key => ({
  text: GameTypeForStadium[key],
  key,
}));

const diningOptions = (Object.keys(DiningOptions) as Array<keyof typeof DiningOptions>).map(key => ({
  text: DiningOptions[key],
  key,
}));

const stringToObjectValue = (s: string, packageKeys: string[]): { [key: string]: string | null } => {
  if (!s) {
    return packageKeys.reduce((acc, key) => ({ ...acc, [key]: null }), {})
  }
  return s.split(';').map(pair => {
    let [key, v] = pair.split(/:(.*)/s)
    if (customFields[key].type === CustomFieldsType.Date && !v) {
      return { [key]: null }
    } else if (customFields[key].type === CustomFieldsType.String && !v) {
      return { [key]: '' }
    } else if (customFields[key].type === CustomFieldsType.Number && !v) {
      return { [key]: null }
    }
    return { [key]: v }
  }).reduce((acc: { [key: string]: string | null }, item) => ({ ...acc, ...item }), {})
}

const objectValueToString = (o: { [key: string]: string | null }): string => {
  return Object.keys(o).map(key => `${key}:${o[key] ? o[key] : ''}`).join(';')
}

const mapDispatchToProps = {
  backToReservation: backToReservationAction,
  confirmReservation: confirmReservationAction,
  updateConfirmation: updateConfirmationAction,
  cleanIsShowVerificationCodeModal: cleanIsShowVerificationCodeModalAction,
  getVerificationCodeMessage: getVerificationCodeMessageAction,
  confirmVerificationCode: confirmVerificationCodeAction,
};

const mapStateToProps = (state: State) => ({
  reservation: selectReservation(state),
  client: selectClient(state),
  guest: selectGuest(state),
  venue: selectVenue(state),
  confirmation: selectReservationConfirmation(state),
  uiConfig: selectUIConfig(state),
  giftCardAmount: selectGiftCardAmount(state),
  currentPackage: selectCurrentPackage(state),
  isUpdateReservation: selectIsUpdateReservation(state),
  oldReservation: selectOldReservation(state),
  reservationAddons: selectReservationAddons(state),
  isRequestInProgress: selectIsRequestInProgress(state),
  requestError: selectRequestError(state),
  validationError: selectAuthError(state),
  isShowVerificationCodeModal: selectIsShowVerificationCodeModal(state),
  verified: selectVerified(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const Reservation = ({
  client,
  guest,
  reservation,
  confirmReservation,
  venue,
  backToReservation,
  updateConfirmation,
  confirmation,
  uiConfig,
  giftCardAmount,
  currentPackage,
  isUpdateReservation,
  oldReservation,
  reservationAddons,
  isRequestInProgress,
  requestError,
  validationError,
  isShowVerificationCodeModal,
  verified,
  cleanIsShowVerificationCodeModal,
  getVerificationCodeMessage,
  confirmVerificationCode,
}: Props) => {
  useEffect(() => {
    cleanIsShowVerificationCodeModal();
  }, []);
  const datePickerTheme = createTheme({
    palette: {
      primary: {
        main: uiConfig?.mainButtonColor ? uiConfig?.mainButtonColor : 'rbg(0,0,0)',
      },
      secondary: {
        main: uiConfig?.mainButtonTextColor ? uiConfig?.mainButtonTextColor : 'rbg(255,255,255)',
      }
    },
  });
  const isHideDuration = uiConfig?.hideDuration || currentPackage?.hidePackageDuration;
  const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;
  const { isMobile } = useViewport();
  const [errorFirstName, setErrorFirstName] = useState<string>("");
  const [errorLastName, setErrorLastName] = useState<string>("");
  const [errorPhone, setErrorPhone] = useState<string>("");
  const [errorCustomNotes, setErrorCustomNotes] = useState<string>("");
  const { firstName, lastName, phone, occasion, customNotes, guestDetail, customFieldsInformation } = confirmation;
  const occasionTitle = uiConfig?.occasionTitle;
  const occasions = formatOccasionsAndGuestDetails(uiConfig?.occasions);
  const isShowGuestDetails = uiConfig?.showGuestDetails;
  const guestDetailsTitle = uiConfig?.guestDetailsTitle;
  const guestDetails = formatOccasionsAndGuestDetails(uiConfig?.guestDetails);
  const occasionOptions = formatOccasionsAndGuestDetails(uiConfig?.occasions);
  const [isOpenVerificationModal, setIsOpenVerificationModal] = useState<boolean>(false);
  useEffect(() => {
    if (!firstName || !lastName || !phone || !customNotes) {
      updateConfirmation({
        firstName: firstName || client?.firstName,
        lastName: lastName || client?.lastName,
        phone: phone || client?.phone,
        customNotes: customNotes || venue?.customNotesPrefilledText,
      });
    }
  }, [client]);
  useEffect(() => {
    if (isShowVerificationCodeModal) {
      setIsOpenVerificationModal(true);
    }
  }, [isShowVerificationCodeModal]);

  const changeFirstName = (firstName?: string) => {
    updateConfirmation({ firstName });
  };
  const changeLastName = (lastName?: string) => {
    updateConfirmation({ lastName });
  };
  const changePhone = (phone?: string) => {
    updateConfirmation({ phone });
  };
  const changeReservationOccasion = (occasion?: string | number) => {
    updateConfirmation({ occasion: occasion?.toString() });
  };
  const changeCustomNotes = (customNotes?: string) => {
    updateConfirmation({ customNotes });
  };
  const changeReservationGuestDetail = (detailsArray: string[], indexGuest: number, newGuestDetail?: string) => {
    detailsArray[indexGuest] = newGuestDetail ? newGuestDetail : '';
    const detailsString = detailsArray.join(';');
    updateConfirmation({ guestDetail: detailsString });
  };
  const activeCustomFields = !currentPackage && venue?.activeCustomFields
    ? activeCustomFieldsToUIModel(venue.activeCustomFields)
    : currentPackage && currentPackage?.activeCustomFields
      ? activeCustomFieldsToUIModel(currentPackage?.activeCustomFields)
      : [];
  const mandatoryCustomFields = !currentPackage && venue?.mandatoryCustomFields
    ? activeCustomFieldsToUIModel(venue.mandatoryCustomFields)
    : currentPackage && currentPackage?.mandatoryCustomFields
      ? activeCustomFieldsToUIModel(currentPackage?.mandatoryCustomFields)
      : [];
  const [customFieldsObject, setCustomFieldObject] = useState(stringToObjectValue(customFieldsInformation || '', activeCustomFields));
  const [errorCustomFields, setErrorCustomFields] = useState<string[]>(new Array(activeCustomFields.length).fill(''));
  useEffect(() => {
    if (customFieldsObject["playerDetailsForSteltronic"] && JSON.parse(customFieldsObject["playerDetailsForSteltronic"]).length !== reservation.guests) {
      console.log('-init ', customFieldsObject["playerDetailsForSteltronic"], reservation.guests)
      // const detailsArray = JSON.parse(customFieldsObject['playerDetailsForSteltronic']);
      // const newPlayerDetailsJSON = JSON.stringify(detailsArray);
      // changeReservationCustomFieldsInformation("playerDetailsForSteltronic", newPlayerDetailsJSON)
    }
    if (customFieldsObject["playerDetailsForTripleSeat"] && customFieldsObject["playerDetailsForTripleSeat"].split(',').length !== reservation.guests) {
      console.log('-playerDetailsForTripleSeat ', customFieldsObject["playerDetailsForTripleSeat"], reservation.guests)
      // const newPlayerDetailsJSON = playerDetailsArray.join(',');
      // changeReservationCustomFieldsInformation("playerDetailsForTripleSeat", newPlayerDetailsJSON)
    }

  }, [reservation.guests]);
  const changeReservationCustomFieldsInformation = (fieldKey: string, newInformation: string) => {
    const clearError = errorCustomFields.fill('');
    setErrorCustomFields(clearError)
    const newCustomFieldsObject = { ...customFieldsObject, [fieldKey]: newInformation }
    setCustomFieldObject(newCustomFieldsObject)
    updateConfirmation({ customFieldsInformation: objectValueToString(newCustomFieldsObject) });
  };

  const changeReservationPlayersInformation = ({ playerDetails, index, newName, newShoeSize, isNewBumper, customFieldType }: { playerDetails: PlayerDetailsForSteltronicInfo[], index: number, newName?: string, newShoeSize?: string, isNewBumper?: boolean, customFieldType: CustomFieldsType }) => {
    let newPlayerDetails = playerDetails;
    if (newName !== undefined) {
      newPlayerDetails[index].name = newName;
    } else if (newShoeSize !== undefined) {
      newPlayerDetails[index].shoeSize = newShoeSize;
    } else {
      newPlayerDetails[index].bumper = !!isNewBumper;
    }
    const newPlayerDetailsJSON = JSON.stringify(newPlayerDetails);
    changeReservationCustomFieldsInformation(customFieldType, newPlayerDetailsJSON);
  };

  const changeReservationPlayerDetailsInformationForTs = ({ playerDetailsForTs, index, newDetails }: { playerDetailsForTs: string[], index: number, newDetails?: string }) => {
    let newPlayerDetails = playerDetailsForTs;
    if (newDetails !== undefined) {
      newPlayerDetails[index] = newDetails;
    }
    const newPlayerDetailsJSON = newPlayerDetails.join(',');
    changeReservationCustomFieldsInformation("playerDetailsForTripleSeat", newPlayerDetailsJSON);
  };

  const changeReservationGuestNamesInformation = ({ guestNames, index, newName }: { guestNames: string[], index: number, newName?: string }) => {
    const newGuestNames = guestNames;
    if (newName !== undefined) {
      newGuestNames[index] = newName;
    }
    const newGuestNamesJSON = newGuestNames.join(',');
    changeReservationCustomFieldsInformation("guestNames", newGuestNamesJSON);
  };

  const isUserValidationRequired = !!venue?.enableTwilioPhoneVerification && (!!guest || (!!client && (!client.verified || client.phone !== phone)));
  const isShowVerification = isUserValidationRequired && !verified;

  const onVerificationCodeGetting = (phone: string) => {
    setErrorPhone("");
    if (!phone || !isValidPhone(phone)) {
      setErrorPhone("Please enter a valid phone number.");
      return;
    }
    getVerificationCodeMessage(phone, venue?.id);
  };

  const hideVerificationCodeModal = () => {
    cleanIsShowVerificationCodeModal();
    setIsOpenVerificationModal(false);
  }

  const onConfirmValidationCode = (code: string) => {
    if (phone) {
      cleanIsShowVerificationCodeModal();
      setIsOpenVerificationModal(false);
      const isUpdateClient = !!client && client.phone === phone;
      confirmVerificationCode(phone, code, isUpdateClient, venue?.id);
    }
  }

  const onContinue = () => {
    if (!firstName) {
      setErrorFirstName("This field is required.");
    }
    if (!lastName) {
      setErrorLastName("This field is required.");
    }
    if (!phone || !isValidPhone(phone)) {
      setErrorPhone("Please enter a valid phone number.");
    }
    if (venue?.enableCustomNotes && venue?.enableRequiredCustomNotes && !customNotes) {
      setErrorCustomNotes("This field is required.")
    }
    for (let mandatoryCustomField of mandatoryCustomFields) {
      const isActiveCustomField = activeCustomFields.some(field => field === mandatoryCustomField);
      if (mandatoryCustomField === 'playerDetailsForSteltronic') {
        if (!customFieldsObject['playerDetailsForSteltronic'] && isActiveCustomField) {
          const errorText = "All field is required."
          const errorIndex = activeCustomFields.indexOf(mandatoryCustomField);
          const newError = errorCustomFields.map((error, index) => errorIndex === index ? errorText : error)
          setErrorCustomFields(newError)
          return;
        }
        if (customFieldsObject['playerDetailsForSteltronic']) {
          let isError = false;
          const detailsArray = JSON.parse(customFieldsObject['playerDetailsForSteltronic']);
          detailsArray.forEach((item: PlayerDetailsForSteltronicInfo) => {
            if (!item.name) {
              isError = true;
            }
          });
          if ((isError || detailsArray.length !== reservation.guests) && isActiveCustomField) {
            const errorText = "All field is required."
            const errorIndex = activeCustomFields.indexOf(mandatoryCustomField);
            const newError = errorCustomFields.map((error, index) => errorIndex === index ? errorText : error)
            setErrorCustomFields(newError)
            return;
          }
        }
      } else if (mandatoryCustomField === 'playerDetailsNoShoesForSteltronic') {
        if (!customFieldsObject['playerDetailsNoShoesForSteltronic'] && isActiveCustomField) {
          const errorText = "All field is required."
          const errorIndex = activeCustomFields.indexOf(mandatoryCustomField);
          const newError = errorCustomFields.map((error, index) => errorIndex === index ? errorText : error)
          setErrorCustomFields(newError)
          return;
        }
        if (customFieldsObject['playerDetailsNoShoesForSteltronic']) {
          let isError = false;
          const detailsArray = JSON.parse(customFieldsObject['playerDetailsNoShoesForSteltronic']);
          detailsArray.forEach((item: PlayerDetailsForSteltronicInfo) => {
            if (!item.name) {
              isError = true;
            }
          });
          if ((isError || detailsArray.length !== reservation.guests) && isActiveCustomField) {
            const errorText = "All field is required."
            const errorIndex = activeCustomFields.indexOf(mandatoryCustomField);
            const newError = errorCustomFields.map((error, index) => errorIndex === index ? errorText : error)
            setErrorCustomFields(newError)
            return;
          }
        }
      } else if (mandatoryCustomField === 'playerDetailsForTripleSeat') {
        if (!customFieldsObject['playerDetailsForTripleSeat'] && isActiveCustomField) {
          const errorText = "All field is required."
          const errorIndex = activeCustomFields.indexOf(mandatoryCustomField);
          const newError = errorCustomFields.map((error, index) => errorIndex === index ? errorText : error)
          setErrorCustomFields(newError)
          return;
        }
        if (customFieldsObject['playerDetailsForTripleSeat']) {
          let isError = false;
          const detailsArray = customFieldsObject['playerDetailsForTripleSeat'].split(',');
          detailsArray.forEach((item: string) => {
            if (!item) {
              isError = true;
            }
          });
          if ((isError || detailsArray.length !== reservation.guests) && isActiveCustomField) {
            const errorText = "All field is required."
            const errorIndex = activeCustomFields.indexOf(mandatoryCustomField);
            const newError = errorCustomFields.map((error, index) => errorIndex === index ? errorText : error)
            setErrorCustomFields(newError)
            return;
          }
        }
      } else if (mandatoryCustomField === 'guestNames') {
        if (!customFieldsObject['guestNames'] && isActiveCustomField) {
          const errorText = "All field is required."
          const errorIndex = activeCustomFields.indexOf(mandatoryCustomField);
          const newError = errorCustomFields.map((error, index) => errorIndex === index ? errorText : error);
          setErrorCustomFields(newError);
          return;
        }
        if (customFieldsObject['guestNames']) {
          let isError = false;
          const namesArray = customFieldsObject['guestNames'].split(',');
          namesArray.forEach((item: string) => {
            if (!item) {
              isError = true;
            }
          });
          if ((isError || namesArray.length !== reservation.guests) && isActiveCustomField) {
            const errorText = "All field is required.";
            const errorIndex = activeCustomFields.indexOf(mandatoryCustomField);
            const newError = errorCustomFields.map((error, index) => errorIndex === index ? errorText : error);
            setErrorCustomFields(newError);
            return;
          }
        }
      } else {
        if (!customFieldsObject[mandatoryCustomField] && isActiveCustomField) {
          const errorText = "This field is required."
          const errorIndex = activeCustomFields.indexOf(mandatoryCustomField);
          const newError = errorCustomFields.map((error, index) => errorIndex === index ? errorText : error)
          setErrorCustomFields(newError)
          return;
        }
      }
    }
    if (!!customFieldsObject["guestOfHonorBirthdate"] && !dayjs(customFieldsObject["guestOfHonorBirthdate"], DATE_FORMAT).isValid()) {
      const errorText = "Invalid date."
      const errorIndex = activeCustomFields.indexOf("guestOfHonorBirthdate");
      const newError = errorCustomFields.map((error, index) => errorIndex === index ? errorText : error)
      setErrorCustomFields(newError)
      return;
    }
    if (firstName &&
      lastName &&
      phone &&
      isValidPhone(phone) &&
      ((venue?.enableRequiredCustomNotes && customNotes) ||
        !venue?.enableRequiredCustomNotes ||
        !venue?.enableCustomNotes
      )
    ) {
      const email = client ? client.email : guest?.email;
      if (email !== confirmation.email || !confirmation.email) {
        updateConfirmation({ email });
      }
      if (isShowVerification) {
        onVerificationCodeGetting(phone);
      } else {
        confirmReservation(true);
      }
      return;
    }

  }
  const onFirstNameChange = (_: any, text?: string) => {
    setErrorFirstName("");
    changeFirstName(text);
  };
  const onLastNameChange = (_: any, text?: string) => {
    setErrorLastName("");
    changeLastName(text);
  };
  const onPhoneChange = (text?: string) => {
    setErrorPhone("");
    changePhone(text);
  };
  const onCustomNotesChange = (_: any, text?: string) => {
    setErrorCustomNotes("");
    changeCustomNotes(text);
  };

  if (!reservation) {
    return null; //it should not happen
  }

  const guestDetailsList = Array.apply(null, Array(reservation.guests)).map((_, indexGuest) => {
    const detailsArray = guestDetail ? guestDetail.split(';') : new Array(reservation.guests);
    return isMobile ? (
      <FormField label={`Guest ${indexGuest + 1}`} key={indexGuest}>
        <SelectWithIcon
          placeholder="Please select"
          contentClass="dropdown"
          options={guestDetails}
          onChange={(text) => changeReservationGuestDetail(detailsArray, indexGuest, text?.toString())}
          value={detailsArray[indexGuest]}
        />
      </FormField>
    ) : (
      <div className="guest-row" key={indexGuest}>
        <FormField label={`Guest ${indexGuest + 1}`}>
          <SelectWithIcon
            placeholder="Please select"
            contentClass="dropdown"
            options={guestDetails}
            onChange={(text) => changeReservationGuestDetail(detailsArray, indexGuest, text?.toString())}
            value={detailsArray[indexGuest]}
          />
        </FormField>
      </div>
    )
  });


  const isActiveCustomFields = activeCustomFields.length > 0;
  const activeCustomFieldsList = activeCustomFields.map((customField, index) => {
    const customFieldValue = customFieldsObject[customField];
    const customFieldType = customFields[customField].type;
    const customFieldLabel = customFields[customField].text;
    const customFieldName = customFields[customField].fieldsName;

    const detailsArray = Array.apply(null, Array(reservation.guests)).map((_, indexGuest) => {
      const playerDetails = customFieldsObject["playerDetailsForSteltronic"]
      if (playerDetails) {
        const playerDetailsForSteltronicInformation: PlayerDetailsForSteltronicInfo[] = JSON.parse(playerDetails);
        return playerDetailsForSteltronicInformation[indexGuest] || {
          index: indexGuest + 1,
          name: '',
          shoeSize: '',
          bumper: false,
        };
      } else {
        return {
          index: indexGuest + 1,
          name: '',
          shoeSize: '',
          bumper: false,
        }
      }
    })
    const detailsNoShoesArray = Array.apply(null, Array(reservation.guests)).map((_, indexGuest) => {
      const playerDetails = customFieldsObject["playerDetailsNoShoesForSteltronic"]
      if (playerDetails) {
        const playerDetailsForSteltronicInformation: PlayerDetailsForSteltronicInfo[] = JSON.parse(playerDetails);
        return playerDetailsForSteltronicInformation[indexGuest] || {
          index: indexGuest + 1,
          name: '',
          shoeSize: '',
          bumper: false,
        };
      } else {
        return {
          index: indexGuest + 1,
          name: '',
          shoeSize: '',
          bumper: false,
        }
      }
    })

    let playerDetailsList;
    if (customFieldType === CustomFieldsType.PlayerDetailsForSteltronic ||
      customFieldType === CustomFieldsType.PlayerDetailsNoShoesForSteltronic) {
      const playerDetails = customFieldType === CustomFieldsType.PlayerDetailsForSteltronic ? detailsArray : detailsNoShoesArray
      playerDetailsList = Array.apply(null, Array(reservation.guests)).map((_, indexGuest) => {
        return isMobile ? (
          <>
            <InputField
              label={`Player ${playerDetails[indexGuest].index} Name`}
              key={`${customField} playerName ${indexGuest}`}
              name={`playerName ${indexGuest}`}
              value={playerDetails[indexGuest].name || ""}
              onChange={(_: any, text?: string) => changeReservationPlayersInformation({ playerDetails, index: indexGuest, newName: text || "", customFieldType })}
              autoComplete={"off"}
              errorMessage={errorCustomFields[index]}
            />
            {customFieldType === CustomFieldsType.PlayerDetailsForSteltronic && <InputField
              key={`${customField} shoeSize ${indexGuest}`}
              label='Shoe Size'
              name={`shoeSize ${indexGuest}`}
              type='number'
              value={playerDetails[indexGuest].shoeSize || ""}
              onChange={(_: any, text?: string) => changeReservationPlayersInformation({ playerDetails, index: indexGuest, newShoeSize: text || "", customFieldType })}
              autoComplete={"off"}
            />}
            <Checkbox
              key={`addBumper ${indexGuest}`}
              className='checkbox'
              label='Add Bumper'
              checked={playerDetails[indexGuest].bumper}
              onChange={(_: any, checked?: boolean) => changeReservationPlayersInformation({ playerDetails, index: indexGuest, isNewBumper: !!checked, customFieldType })}
            />
          </>
        ) : (
          <div className="custom-field-row-container" key={`${customField} ${indexGuest}`}>
            <div className="custom-field-row" >
              <InputField
                label={`Player ${playerDetails[indexGuest].index} Name`}
                name={`playerName ${indexGuest}`}
                value={playerDetails[indexGuest].name || ""}
                onChange={(_: any, text?: string) => changeReservationPlayersInformation({ playerDetails, index: indexGuest, newName: text || "", customFieldType })}
                autoComplete={"off"}
                errorMessage={errorCustomFields[index]}
              />
            </div>
            {customFieldType === CustomFieldsType.PlayerDetailsForSteltronic && <div className="custom-field-row" >
              <InputField
                label='Shoe Size'
                name={`shoeSize ${indexGuest}`}
                type='number'
                value={playerDetails[indexGuest].shoeSize || ""}
                onChange={(_: any, text?: string) => changeReservationPlayersInformation({ playerDetails, index: indexGuest, newShoeSize: text || "", customFieldType })}
                autoComplete={"off"}
              />
            </div>}
            <div className="custom-field-row" >
              <Checkbox
                label='Add Bumper'
                className='checkbox'
                checked={playerDetails[indexGuest].bumper}
                onChange={(_: any, checked?: boolean) => changeReservationPlayersInformation({ playerDetails, index: indexGuest, isNewBumper: !!checked, customFieldType })}
              />
            </div>
          </div>
        )
      });
    }


    const playerDetailsArray = Array.apply(null, Array(reservation.guests)).map((_, indexGuest) => {
      if (customFieldsObject["playerDetailsForTripleSeat"]) {
        const playerDetailsForTripleSeatInformation: string[] = customFieldsObject["playerDetailsForTripleSeat"].split(',');
        if (playerDetailsForTripleSeatInformation.length !== reservation.guests) {
          return playerDetailsForTripleSeatInformation[indexGuest] || ''
        }
        return playerDetailsForTripleSeatInformation[indexGuest];
      } else {
        return '';
      }
    });

    let playerDetailsForTsList;
    if (customFieldType === CustomFieldsType.PlayerDetailsForTripleSeat) {
      playerDetailsForTsList = Array.apply(null, Array(reservation.guests)).map((_, indexGuest) => {
        return isMobile ? (
          <InputField
            label={`Player ${indexGuest + 1} Details`}
            key={`${customField} playerDetails ${indexGuest}`}
            name={`playerDetails ${indexGuest}`}
            value={playerDetailsArray[indexGuest] || ""}
            onChange={(_: any, text?: string) => changeReservationPlayerDetailsInformationForTs({ playerDetailsForTs: playerDetailsArray, index: indexGuest, newDetails: text || "" })}
            autoComplete={"off"}
            errorMessage={errorCustomFields[index]}
          />
        ) : (
          <div className="custom-field-row-container" key={`${customField} ${indexGuest}`}>
            <InputField
              label={`Player ${indexGuest + 1} Details`}
              name={`playerDetails ${indexGuest}`}
              value={playerDetailsArray[indexGuest] || ""}
              onChange={(_: any, text?: string) => changeReservationPlayerDetailsInformationForTs({ playerDetailsForTs: playerDetailsArray, index: indexGuest, newDetails: text || "" })}
              autoComplete={"off"}
              errorMessage={errorCustomFields[index]}
            />
          </div>
        )
      });
    }

    const guestNamesArray = Array.apply(null, Array(reservation.guests)).map((_, indexGuest) => {
      if (customFieldsObject["guestNames"]) {
        const guestNamesInformation: string[] = customFieldsObject["guestNames"].split(',');
        if (guestNamesInformation.length !== reservation.guests) {
          return guestNamesInformation[indexGuest] || '';
        }
        return guestNamesInformation[indexGuest];
      } else {
        return '';
      }
    });
    if (customFieldsObject["guestNames"] && customFieldsObject["guestNames"].split(',').length !== reservation.guests) {
      const newGuestNamesJSON = guestNamesArray.join(',');
      changeReservationCustomFieldsInformation("guestNames", newGuestNamesJSON);
    }

    let guestNamesList;
    if (customFieldType === CustomFieldsType.GuestNames) {
      guestNamesList = Array.apply(null, Array(reservation.guests)).map((_, indexGuest) => {
        return isMobile ? (
          <InputField
            label={`Guest ${indexGuest + 1} Full Name`}
            key={`${customField} guestNames ${indexGuest}`}
            name={`guestNames ${indexGuest}`}
            value={guestNamesArray[indexGuest] || ""}
            onChange={(_: any, text?: string) => changeReservationGuestNamesInformation({ guestNames: guestNamesArray, index: indexGuest, newName: text || "" })}
            autoComplete={"off"}
            errorMessage={errorCustomFields[index]}
          />
        ) : (
          <div className="custom-field-row-container" key={`${customField} ${indexGuest}`}>
            <InputField
              label={`Guest ${indexGuest + 1} Full Name`}
              name={`guestNames ${indexGuest}`}
              value={guestNamesArray[indexGuest] || ""}
              onChange={(_: any, text?: string) => changeReservationGuestNamesInformation({ guestNames: guestNamesArray, index: indexGuest, newName: text || "" })}
              autoComplete={"off"}
              errorMessage={errorCustomFields[index]}
            />
          </div>
        )
      });
    }
    return (
      <>
        <div className={`custom-field ${isMobile ? "mobile" : ""}`} key={customField}>
          {customFieldType === CustomFieldsType.Date && (
            <MuiPickersUtilsProvider utils={DayjsUtils}>
              <ThemeProvider theme={datePickerTheme}>
                <div className="subtitle2 label">{customFieldLabel}</div>
                <KeyboardDatePicker
                  autoOk
                  value={customFieldValue}
                  variant="inline"
                  inputVariant="outlined"
                  placeholder={uiConfig?.dateFormat || "MM/DD/YYYY"}
                  onChange={data => changeReservationCustomFieldsInformation(customField, data ? dayjs(data).format(DATE_FORMAT) : "")}
                  format={uiConfig?.dateFormat || "MM/DD/YYYY"}
                  className="datePicker"
                  helperText={errorCustomFields[index]}
                  error={!!errorCustomFields[index]}
                />
              </ThemeProvider>

            </MuiPickersUtilsProvider>
          )}
          {customFieldType === CustomFieldsType.String && (
            <InputField
              label={customFieldLabel}
              name={customFieldName}
              value={customFieldValue || ""}
              onChange={(_: any, text?: string) => changeReservationCustomFieldsInformation(customField, text || "")}
              autoComplete={"off"}
              errorMessage={errorCustomFields[index]}
            />
          )}
          {customFieldType === CustomFieldsType.Selector && (
            <FormField label={customFieldLabel}>
              <SelectWithIcon
                placeholder=''
                contentClass="dropdown"
                options={pizzaOptions}
                onChange={(key) => changeReservationCustomFieldsInformation(customField, key as PizzaType)}
                value={customFieldValue || ''}
              />
              {errorCustomFields[index] && <div className="customSelectorError">{errorCustomFields[index]}</div>}
            </FormField>
          )}
          {customFieldType === CustomFieldsType.Number && (
            <InputField
              label={customFieldLabel}
              name={customFieldName}
              value={customFieldValue?.toString() || ""}
              type='number'
              min='0'
              onChange={(_: any, number?: number) => changeReservationCustomFieldsInformation(customField, number?.toString() || "")}
              autoComplete={"off"}
              errorMessage={errorCustomFields[index]}
            />
          )}
        </div>
        {(customFieldType === CustomFieldsType.PlayerDetailsForSteltronic || customFieldType === CustomFieldsType.PlayerDetailsNoShoesForSteltronic) && !!playerDetailsList && playerDetailsList}
        {(customFieldType === CustomFieldsType.PlayerDetailsForTripleSeat && !!playerDetailsForTsList) && playerDetailsForTsList}
        {(customFieldType === CustomFieldsType.GuestNames && !!guestNamesList) && guestNamesList}
        {customFieldType === CustomFieldsType.GameSelection && (
          <FormField label={customFieldLabel}>
            <SelectWithIcon
              placeholder="Please select"
              contentClass="dropdown"
              options={gameOptions}
              onChange={(key) => changeReservationCustomFieldsInformation(customField, key as GameType)}
              value={customFieldValue || ''}
            />
            {errorCustomFields[index] && <div className="customSelectorError">{errorCustomFields[index]}</div>}
          </FormField>
        )}
        {customFieldType === CustomFieldsType.GameSelectionForStadium && (
          <FormField label={customFieldLabel}>
            <SelectWithIcon
              placeholder="Please select"
              contentClass="dropdown"
              options={gameOptionsForStadium}
              onChange={(key) => changeReservationCustomFieldsInformation(customField, key as GameTypeForStadium)}
              value={customFieldValue || ''}
            />
            {errorCustomFields[index] && <div className="customSelectorError">{errorCustomFields[index]}</div>}
          </FormField>
        )}
        {customFieldType === CustomFieldsType.DiningSelection && (
          <FormField label={customFieldLabel}>
            <SelectWithIcon
              placeholder="Please select"
              contentClass="dropdown"
              options={diningOptions}
              onChange={(key) => changeReservationCustomFieldsInformation(customField, key as DiningOptions)}
              value={customFieldValue || ''}
            />
            {errorCustomFields[index] && <div className="customSelectorError">{errorCustomFields[index]}</div>}
          </FormField>
        )}
        {customFieldType === CustomFieldsType.Occasion && (
          <FormField label={customFieldLabel}>
            <SelectWithIcon
              placeholder="Please select"
              contentClass="dropdown"
              options={occasionOptions}
              onChange={(key) => changeReservationCustomFieldsInformation(customField, key as OccasionOptions)}
              value={customFieldValue || ''}
            />
            {errorCustomFields[index] && <div className="customSelectorError">{errorCustomFields[index]}</div>}
          </FormField>
        )}
      </>
    )
  });
  return (
    <>
      {isOpenVerificationModal && (
        <VerificationCodeModal
          onConfirmValidationCode={onConfirmValidationCode}
          phone={phone}
          onClose={hideVerificationCodeModal}
          resendCode={onVerificationCodeGetting}
        />
      )
      }
      <div className="reservation-container">
        <div className="reservation-inner-container main-content">
          <div
            className={`reservation-reservationInfo ${isMobile ? "mobile" : ""}`}
          >
            <div className="title">Reservation Info</div>
            <div className="row">
              <InputField
                label="First name"
                name="firstName"
                value={firstName}
                errorMessage={errorFirstName}
                onChange={onFirstNameChange}
                autoComplete={"off"}
              />
              <div className="row-delimiter"></div>
              <InputField
                label="Last name"
                name="lastName"
                value={lastName}
                errorMessage={errorLastName}
                onChange={onLastNameChange}
                autoComplete={"off"}
              />
            </div>
            <div className={isMobile ? "column" : "row"}>
              <FormField label="Phone" error={errorPhone}>
                <PhoneInput
                  placeholder="Phone number"
                  defaultCountry="US"
                  value={phone || ""}
                  onChange={onPhoneChange}
                  limitMaxLength={true}
                />
              </FormField>
              {!isMobile && <div className="row-delimiter"></div>}
              <FormField label={occasionTitle || "What's the Occasion?"}>
                <SelectWithIcon
                  placeholder="Please select"
                  contentClass="dropdown"
                  options={occasions}
                  onChange={changeReservationOccasion}
                  value={occasion}
                />
              </FormField>
            </div>
            {venue?.enableCustomNotes && (
              <div className="custom-notes-field">
                <div className="custom-notes-title">
                  {venue?.customNotesTitle}
                </div>
                {venue?.customNotesExplanationText && (
                  <HTMLRenderer html={venue?.customNotesExplanationText} className="custom-notes-details-text" />
                )}
                <InputField
                  styles={customNotesFieldStyle}
                  multiline
                  resizable={false}
                  rows={7}
                  value={customNotes}
                  errorMessage={errorCustomNotes}
                  onChange={onCustomNotesChange}
                  autoComplete="off"
                />
              </div>
            )}
            {isShowGuestDetails && (
              <div className="guest-details-container">
                <div className="guest-details-title">
                  {guestDetailsTitle}
                </div>
                <div className="guest-details-fields">
                  {guestDetailsList}
                </div>
              </div>
            )}
            <div className={`custom-fields-container ${isMobile ? "mobile" : ""}`}>
              {activeCustomFieldsList}
            </div>
            {(requestError || validationError) && (
              <div className="error">{requestError || validationError}</div>
            )}
            {(isMobile && uiConfig?.newDesign) ? <MobileFixedButton onClick={onContinue} name="continue" uiConfig={uiConfig} disabled={isRequestInProgress}>
              CONTINUE
            </MobileFixedButton> :
              (isMobile && !uiConfig?.newDesign) ? (
                <OrderSummaryWrapper
                  reservation={reservation}
                  venue={venue}
                  onEdit={backToReservation}
                  actionText={uiConfig?.actionText}
                  isMobile={isMobile}
                  isHideDuration={isHideDuration}
                  currentPackage={currentPackage}
                  giftCardAmount={giftCardAmount}
                  isUpdateReservation={isUpdateReservation}
                  oldDeposit={reservation?.payed}
                  reservationAddons={reservationAddons}
                  twelveHourClockFormat={twelveHourClockFormat}
                  onContinue2={onContinue}
                >
                  <MobileFixedButton onClick={onContinue} name="continue" uiConfig={uiConfig} disabled={isRequestInProgress}>
                    CONTINUE
                  </MobileFixedButton>
                </OrderSummaryWrapper>
              ) : (
                <PrimaryButton onClick={onContinue} name="continue" uiConfig={uiConfig} disabled={isRequestInProgress}>
                  CONTINUE
                </PrimaryButton>
              )}
          </div>
          {!isMobile && (
            <>
              <div className="column-delimiter"></div>
              <div className="reservation-summary">
                <OrderSummaryWrapper
                  reservation={reservation}
                  venue={venue}
                  onEdit={backToReservation}
                  actionText={uiConfig?.actionText}
                  isMobile={isMobile}
                  isHideDuration={isHideDuration}
                  currentPackage={currentPackage}
                  giftCardAmount={giftCardAmount}
                  isUpdateReservation={isUpdateReservation}
                  oldDeposit={reservation?.payed}
                  reservationAddons={reservationAddons}
                  twelveHourClockFormat={twelveHourClockFormat}
                  onContinue2={onContinue}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default connector(Reservation);
