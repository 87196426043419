import React, { useState, useEffect } from "react";
import { ProfileDto, UpdateClient } from "../../../store/types";
import { PrimaryButton } from "../../../../../common/components/PrimaryButton/PrimaryButton";
import { useViewport } from "../../../hooks/responsive";
import { InputField } from "../../../../../common/components/InputField/InputField";
import { PhoneInput } from "../../../../../common/components/PhoneInput/PhoneInput";
import { isValidPhone } from "../../../../../common/utils/validate";
import { FormField } from "../../../../../common/components/FormField/FormField";
import "./editProfile.scss";

interface Prop {
  updateProfile: (updatedClient: UpdateClient) => void;
  isInProgress: boolean;
  client?: ProfileDto;
  error?: string;
  cleanError: () => void;
  uiConfig: any;
}

export const EditProfile = ({
  error,
  isInProgress,
  updateProfile,
  client,
  cleanError,
  uiConfig,
}: Prop) => {
  const { isMobile } = useViewport();
  useEffect(() => {
    if (!client) {
      return;
    }
    const { firstName, lastName, email, phone } = client;
    setFirstName(firstName);
    setLastName(lastName);
    setPhone(phone);
    setEmail(email);
  }, [client]);
  useEffect(() => {
    
      setErrorText(error);
    
  }, [error]);
  useEffect(() => {
    if (!isInProgress && lastProgress) {
      setConfirmText("Profile is updated.");
      setLastProgress(false);
    }
  }, [isInProgress]);
  const [lastProgress, setLastProgress] = useState(false);
  const [firstName, setFirstName] = useState<string | undefined>(
    client?.firstName
  );
  const [lastName, setLastName] = useState<string | undefined>(
    client?.lastName
  );
  const [phone, setPhone] = useState<string | undefined>("");
  const [email, setEmail] = useState<string | undefined>("");
  const [errorText, setErrorText] = useState<string | undefined>("");
  const [confirmText, setConfirmText] = useState<string | undefined>("");

  const [errorFirstName, setErrorFirstName] = useState<string>("");
  const [errorLastName, setErrorLastName] = useState<string>("");
  const [errorPhone, setErrorPhone] = useState<string>("");
  useEffect(() => () => cleanError(), [cleanError]);

  const onUpdateProfile = () => {
    if (firstName && lastName && email && phone && isValidPhone(phone)) {
      setErrorText("");
      setConfirmText("");
      setLastProgress(true);
      updateProfile({ firstName, lastName, email, phone });
      return;
    }
    if (!firstName) {
      setErrorFirstName("This field is required.");
      setErrorText("All fields are mandatory. Please fill in all fields.");
    }
    if (!lastName) {
      setErrorLastName("This field is required.");
      setErrorText("All fields are mandatory. Please fill in all fields.");
    }
    if (!phone || !isValidPhone(phone)) {
      setErrorPhone("Please enter a valid phone number.");
      setErrorText("All fields are mandatory. Please fill in all fields.");
    }
  };

  const onFirstNameChange = (_: any, text?: string) => {
    setErrorFirstName("");
    setFirstName(text);
  };
  const onLastNameChange = (_: any, text?: string) => {
    setErrorLastName("");
    setLastName(text);
  };
  const onPhoneChange = (text?: string) => {
    setErrorPhone("");
    setPhone(text);
  };

  return (
    <div className="edit-profile">
      <div className="editParameters">
        <div className={isMobile ? "column" : "row"}>
          <InputField
            label="First Name"
            name="firstName"
            value={firstName}
            errorMessage={errorFirstName}
            onChange={onFirstNameChange}
          />
          {!isMobile && <div className="row-delimiter"></div>}
          <InputField
            label="Last Name"
            name="lastName"
            value={lastName}
            errorMessage={errorLastName}
            onChange={onLastNameChange}
          />
        </div>
        <div className={isMobile ? "column" : "row"}>
          <InputField
            type="email"
            name="email"
            label="Email"
            value={email}
            onChange={setEmail}
            disabled={true}
          />
          {!isMobile && <div className="row-delimiter"></div>}
          <FormField label="Phone" error={errorPhone}>
            <PhoneInput
              placeholder="Phone"
              defaultCountry="US"
              value={phone || ""}
              onChange={onPhoneChange}
              limitMaxLength={true}
            />
          </FormField>
        </div>
        {errorText && <div className="error">{errorText}</div>}
        {confirmText && !errorText && (
          <div className="confirm">{confirmText}</div>
        )}
        <PrimaryButton name="save" onClick={onUpdateProfile} uiConfig={uiConfig}>
          SAVE
        </PrimaryButton>
      </div>
    </div>
  );
};
