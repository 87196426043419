import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { getSearch, goBack } from "connected-react-router"
import dayjs from 'dayjs'
import { isEqual } from "lodash"
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ConnectedProps, connect, useSelector } from 'react-redux'
import HTMLRenderer from '../../../../common/components/HTMLRenderer/HTMLRenderer'
import { DATE_FORMAT } from '../../../../common/constants/timedate'
import { composePartyTimeSlots, formatOccasionsAndGuestDetails, formatUSPhone, getPartyDurationList } from '../../../../common/utils/formats'
import { isValidEmail, isValidPhone } from '../../../../common/utils/validate'
import { replaceAppUrlAction, setIsPartySubmittedAction, setReservationErrorAction, submitPartyAction } from '../../actions/reservation-actions'
import { getVenueAction, toPackagesWithVenueAction, toPartyWithVenueAction, toReservationWithVenueAction } from '../../actions/venue-actions'
import { useViewport } from '../../hooks/responsive'
import { selectIsPartySubmitted, selectIsRequestInProgress, selectRequestError } from '../../reducers/reservation'
import { selectUIConfig } from '../../reducers/ui-reducer'
import { selectIsLoadingVenue, selectIsLoadingVenues, selectVenue, selectVenues } from '../../reducers/venues'
import { State } from '../../store/types'
import { getVenueList } from '../../utils/formats'
import { parseUrlQuery } from '../../utils/urlSearchQuery'
import { isValidNumber } from '../MakePartyReservation/MakePartyReservation'
import ButtonMain from '../common/ButtonMain/ButtonMain'
import { Calendar2 } from '../common/Calendar/Calendar2'
import Input2 from '../common/Input2/Input2'
import PhoneInput2 from '../common/Input2/PhoneInput2'
import SelectWithIcon2 from '../common/SelectWithIcon/SelectWithIcon2'
import { formatDate, getCurrentDateAsString } from './utils'


const mapDispatchToProps = {
    submitParty: submitPartyAction,
    setIsPartySubmitted: setIsPartySubmittedAction,
    getVenue: getVenueAction,
    toReservationWithVenue: toReservationWithVenueAction,
    toPackagesWithVenue: toPackagesWithVenueAction,
    setReservationError: setReservationErrorAction,
    replaceAppUrl: replaceAppUrlAction,
    toPartyWithVenue: toPartyWithVenueAction,
    goBack,
};

const mapStateToProps = (state: State) => ({
    isLoadingVenues: selectIsLoadingVenues(state),
    isLoadingVenue: selectIsLoadingVenue(state),
    isRequestInProgress: selectIsRequestInProgress(state),
    isPartySubmitted: selectIsPartySubmitted(state),
    error: selectRequestError(state),
    activeVenue: selectVenue(state),
    venues: selectVenues(state),
    searchParams: getSearch(state),
    uiConfig: selectUIConfig(state),
});


const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;
const ContactUs = ({ activeVenue, venues, searchParams, setIsPartySubmitted, uiConfig, getVenue, replaceAppUrl, isLoadingVenue, toPackagesWithVenue, toReservationWithVenue, submitParty }: Props) => {
    const [isCalendar, setIsCalendar] = useState(false);
    const { isMobile, isMiddleScreen } = useViewport();
    const { venues: { availability } } = useSelector((state: State) => state);
    const [isCheck, setIsCheck] = useState<boolean>(true);
    const [venueId, setVenueId] = useState<string>("");
    const [targetDate, setTargetDate] = useState<string>(getCurrentDateAsString());
    const [timeSlot, setTimeSlot] = useState<number | undefined>(undefined);
    const [duration, setDuration] = useState<number | undefined>(undefined);
    const [durationText, setDurationText] = useState<string | undefined>(undefined);
    const [guestCount, setGuestCount] = useState<string>("");
    const [errorGuestCount, setErrorGuestCount] = useState<string>("");
    const [errorFirstName, setErrorFirstName] = useState<string>("");
    const [errorLastName, setErrorLastName] = useState<string>("");
    const [errorPhone, setErrorPhone] = useState<string>("");
    const [errorEmail, setErrorEmail] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;
    const setDate = (newDate: string) => {
        setTargetDate(newDate);
        const { venue, date } = parseUrlQuery(searchParams);
        const params = {
            venue: activeVenue?.name,
            date: newDate,
        };
        if (!activeVenue?.name || isEqual(params, { venue, date })) {
            return;
        }
        replaceAppUrl(params);
    };
    const selectDate = (date: string) => {
        setIsCalendar(!isCalendar);
        setDate(date);
        setTimeSlot(undefined);
    };
    useEffect(() => {
        setIsPartySubmitted(false);
        if (activeVenue) {
            setVenueId(activeVenue.id);
            return;
        }
    }, [activeVenue]);
    const [phase, setPhase] = useState(0);
    const selectVenueOption = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
        const value = menuPropsLocations.find(loc => loc.text === e.target.value)!.key

        if (value && venueId !== value) {
            const id = value.toString();
            setVenueId(id);
            getVenue(id);
            setErrorGuestCount("");
            setTimeSlot(undefined);
        }
    };
    const isDisabled = () => {
        if (phase === 0) {
            return (
                !activeVenue ||
                !targetDate ||
                timeSlot === undefined ||
                !occasion ||
                !guestCount ||
                duration === undefined
            );
        }
        return false;
    };

    const menuPropsLocations = getVenueList(venues, searchParams);
    const occasions = formatOccasionsAndGuestDetails(uiConfig?.occasions);

    const timeSlots = composePartyTimeSlots(
        targetDate,
        twelveHourClockFormat,
        activeVenue
    );
    const durationList = getPartyDurationList();

    const [occasion, setOccasion] = useState<string>(occasions[0].key || '');
    const [occasionText, setOccasionText] = useState<string>(occasions[0].key || '');

    const onGuestCountChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
        setErrorGuestCount("");
        setGuestCount(e.target.value as string);
    };
    const [timeSlotText, setTimeSlotText] = useState<string>(timeSlots?.map(timeSlot => timeSlot.text)[0] as string);

    useEffect(() => {
        if (timeSlotText) setTimeSlot(timeSlots.find(timeSlot => timeSlot.text === timeSlotText)?.key)
    }, [timeSlotText])
    useEffect(() => {
        if (!timeSlot) setTimeSlotText("")
    }, [timeSlot])

    useEffect(() => {
        if (durationText) setDuration(durationList.find(duration => duration.text === durationText)?.key)
    }, [durationText])
    useEffect(() => {
        if (!duration) setDurationText("")
    }, [duration])


    useEffect(() => {
        if (occasionText) setOccasion(occasions.find(occ => occ.text === occasionText)?.key as string)
    }, [occasionText])
    useEffect(() => {
        if (!occasion) setOccasion("")
    }, [occasion])
    const { date, guests } = parseUrlQuery(searchParams);

    useEffect(() => {
        if (guests) {
            setGuestCount(guests)
        }
    }, [guests])
    const init = useCallback(() => {
        const { venue, date } = parseUrlQuery(searchParams);
        const parsedDate =
            date && date !== "undefined" ? date : dayjs().format(DATE_FORMAT);
        if (parsedDate !== targetDate) {
            setDate(parsedDate);
        }

        if (venues && venues.length > 0) {
            const openedVenues = venues.filter((v) => !v.isComingSoon);
            if (!venue) {
                setVenueId(openedVenues[0].id);
                getVenue(openedVenues[0].id);
            } else if (activeVenue?.name !== venue) {
                const v = venues.find((item) => item.name === venue);
                if (v) {
                    setVenueId(v.id);
                    getVenue(v.id);
                } else {
                    setVenueId(openedVenues[0].id);
                    getVenue(openedVenues[0].id);
                }
            }
            return;
        }
        if (!isLoadingVenue && venues.length > 0) {
            console.log("loading...");
        }
    }, [venues]);
    useEffect(() => {
        init();
    }, []);
    useEffect(() => {
        const { venue, date } = parseUrlQuery(searchParams);
        const params = {
            venue: activeVenue?.name,
            date: targetDate,
        };
        if (!activeVenue?.name || isEqual(params, { venue, date })) {
            return;
        }
        replaceAppUrl(params);
        if (activeVenue?.partyUrl) {
            if (uiConfig?.isPackageReservationMode) {
                toPackagesWithVenue();
            } else {
                toReservationWithVenue(params.date);
            }
        }
    }, [replaceAppUrl, activeVenue, targetDate]);
    useEffect(() => {
        const { venue, date } = parseUrlQuery(searchParams);
        const params = {
            venue: activeVenue?.name,
            date: targetDate,
        };
        if (!activeVenue?.name || isEqual(params, { venue, date })) {
            return;
        }
        replaceAppUrl(params);
        if (activeVenue?.partyUrl) {
            if (uiConfig?.isPackageReservationMode) {
                toPackagesWithVenue();
            } else {
                toReservationWithVenue(params.date);
            }
        }
    }, [replaceAppUrl, activeVenue, targetDate]);


    const top = useRef<HTMLDivElement>(null);


    useEffect(() => {
        if (top?.current) {
            window.scrollTo(0, top.current.offsetTop);
        }
    }, [top]);

    useEffect(() => {
        setIsPartySubmitted(false);
        if (activeVenue) {
            setVenueId(activeVenue.id);
            return;
        }
    }, [activeVenue]);

    const onContinue = (e: any) => {
        e.preventDefault();
        if (
            !activeVenue ||
            !targetDate ||
            timeSlot === undefined ||
            duration === undefined ||
            !occasion ||
            !isValidNumber(guestCount)
        ) {
            if (!isValidNumber(guestCount)) {
                setErrorGuestCount("Please enter a number");
            }
            return;
        }
        if (
            activeVenue.maxGuestsForInquiry > 0 &&
            +guestCount > activeVenue.maxGuestsForInquiry
        ) {
            setErrorGuestCount(
                `Guest number should be less than ${activeVenue.maxGuestsForInquiry}`
            );
            return;
        }
        if (
            activeVenue.minGuestsForInquiry > 0 &&
            +guestCount < activeVenue.minGuestsForInquiry
        ) {
            setErrorGuestCount(
                `Guest number should be more than ${activeVenue.minGuestsForInquiry}`
            );
            return;
        }

        if (phase === 0) {
            return setPhase(1);
        }
    };
    const onSubmit = (e: any) => {
        e.preventDefault()

        if (!firstName) {
            setErrorFirstName("This field is required.");
        }
        if (!lastName) {
            setErrorLastName("This field is required.");
        }
        if (!phone || !isValidPhone(phone)) {
            setErrorPhone("Please enter a valid phone number.");
        }

        if (!email || !isValidEmail(email)) {
            setErrorEmail("Please enter a valid email.");
        }

        if (
            timeSlot !== undefined &&
            duration !== undefined &&
            firstName &&
            lastName &&
            phone &&
            occasion &&
            isValidPhone(phone) &&
            email &&
            isValidEmail(email)
        ) {
            const GA_Medium__c = (
                document.getElementById("GA_Medium__c") as HTMLInputElement
            )?.value;
            const GA_Source__c = (
                document.getElementById("GA_Source__c") as HTMLInputElement
            )?.value;
            const GA_Campaign__c = (
                document.getElementById("GA_Campaign__c") as HTMLInputElement
            )?.value;
            const GA_Term__c = (
                document.getElementById("GA_Term__c") as HTMLInputElement
            )?.value;
            const GA_AdGroup__c = (
                document.getElementById("GA_AdGroup__c") as HTMLInputElement
            )?.value;
            const GCLIDGCLID__c = (
                document.getElementById("GCLIDGCLID__c") as HTMLInputElement
            )?.value;
            const zuevsoftware__gaconnector_Google_Analytics_Client_ID__c = (
                document.getElementById(
                    "zuevsoftware__gaconnector_Google_Analytics_Client_ID__c"
                ) as HTMLInputElement
            )?.value;
            const gaconnector_Google_Analytics_Client_ID__c = (
                document.getElementById(
                    "gaconnector_Google_Analytics_Client_ID__c"
                ) as HTMLInputElement
            )?.value;
            const lead_source = (
                document.getElementById("lead_source") as HTMLInputElement
            )?.value;
            const device = (document.getElementById("device") as HTMLInputElement)
                ?.value;
            const startTime = timeSlotText;
            const endTime = dayjs(timeSlotText,'h:mm A').add(duration * 30, "minutes").format('h:mm A');

            submitParty({
                venueId,
                date: targetDate,
                startTime,
                endTime,
                timeSlot,
                duration,
                firstName,
                lastName,
                phone: formatUSPhone(phone),
                email,
                occasion,
                guestCount,
                subscribe: isCheck,
                device,
                GA_Medium__c,
                GA_Source__c,
                GA_Campaign__c,
                GA_Term__c,
                GA_AdGroup__c,
                GCLIDGCLID__c,
                zuevsoftware__gaconnector_Google_Analytics_Client_ID__c,
                gaconnector_Google_Analytics_Client_ID__c,
                lead_source,
            });
            return;
        }

    };

    const onFirstNameChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
        setErrorFirstName("");
        setFirstName(e.target.value as string);
    };
    const onLastNameChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
        setErrorLastName("");
        setLastName(e.target.value as string);
    };
    const onPhoneChange = (text?: string) => {
        setErrorPhone("");
        setPhone(text || "");
    };
    const onEmailChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
        setErrorEmail("");
        setEmail(e.target.value as string);
    };


    return (
        <form onSubmit={phase === 0 ? onContinue : onSubmit}>

            <Box display={'flex'} flexDirection={'column'} style={{ gap: '16px' }}>
                {phase === 0 ? <>
                    <Box display={'flex'} flexDirection={isMobile ? 'column' : 'row'} style={{ gap: '16px' }}>
                        <SelectWithIcon2 width={isMobile ? '100%' : '50%'} label="Location" options={menuPropsLocations.map(loc => loc.text)} value={menuPropsLocations.find(loc => loc.key === venueId)?.text || ''} onChange={selectVenueOption} />
                        <SelectWithIcon2 width={isMobile ? '100%' : '50%'} label="What’s the Occasion?" options={occasions.map(occ => occ.text)} value={occasionText} onChange={(e) => {
                            setOccasionText(e.target.value ? (e.target.value as string) : "")
                        }
                        } />
                    </Box>
                    <Box>

                        <SelectWithIcon2 width='100%' label="Select Date" options={[formatDate(targetDate, isMobile) || formatDate(date, isMobile)]} onFormClick={() => setIsCalendar(!isCalendar)} onClick={() => { }} />
                        {isCalendar ? (
                            <Calendar2
                                date={targetDate}
                                selectDate={selectDate}
                                availability={availability}
                                isMiddleScreen={isMiddleScreen}
                                isInsideContainer
                            />
                        ) : null}
                    </Box>
                    <Box display={'flex'} flexDirection={isMobile ? 'column' : 'row'} style={{ gap: '16px' }}>
                        <SelectWithIcon2 width={isMobile ? '100%' : '50%'} label="Event Start Time" options={timeSlots.map(timeSlot => timeSlot.text)} value={timeSlotText} onChange={(e) => { setTimeSlotText(e.target.value as string) }} />
                        <SelectWithIcon2 width={isMobile ? '100%' : '50%'} label="Event Duration" options={durationList.map(duration => duration.text)} value={durationText} onChange={(e) => { setDurationText(e.target.value as string) }} />
                    </Box>
                    <Input2 errorInput={errorGuestCount} type='number' label="Number of Guests" placeholder='Estimated guests' value={guestCount} onChange={onGuestCountChange} fullWidth />
                    <Box height={'16px'} />
                </> : <>
                    <Box display={'flex'} flexDirection={isMobile ? 'column' : 'row'} style={{ gap: '16px' }}>
                        <Input2 errorInput={errorFirstName} label="First Name" placeholder='Enter your first name' value={firstName} onChange={onFirstNameChange} fullWidth />
                        <Input2 errorInput={errorLastName} label="Last Name" placeholder='Enter your last name' value={lastName} onChange={onLastNameChange} fullWidth />
                    </Box>
                    <Box display={'flex'} flexDirection={isMobile ? 'column' : 'row'} style={{ gap: '16px' }}>
                        <Input2 type="email" errorInput={errorEmail} label="Email" placeholder='Enter your email' value={email} onChange={onEmailChange} fullWidth />
                        <PhoneInput2 errorInput={errorPhone} label="Phone" placeholder='Enter your phone' value={phone} onChange={onPhoneChange} fullWidth />

                    </Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isCheck}
                                onChange={(e) => setIsCheck(!isCheck)}
                                name="checked"
                                color="default"
                                style={{
                                    color: "#2E3A48",
                                }}
                            />
                        }
                        label={<Typography className='body-small'>Keep me updated on exclusive offers and events</Typography>}
                    />
                    {activeVenue?.makePartyNoteText ? <HTMLRenderer
                        className="info"
                        html={activeVenue?.makePartyNoteText}
                    /> : null}
                </>}

                <ButtonMain style={{ textTransform: 'capitalize' }} disabled={isDisabled()} type='submit'>Next</ButtonMain>
            </Box>
        </form>

    )
}

export default connector(ContactUs)

