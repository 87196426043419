import React, { useState } from "react";
import dayjs from "dayjs";
import { IconButton } from "office-ui-fabric-react";
import { PartyMonth } from "../PartyMonth/PartyMonth";
import { MONTH_NAME_FORMAT } from "../../../../../common/constants/timedate";
import { useViewport } from "../../../hooks/responsive";
import "./partyCalendar.scss";

interface PartyCalendarProps {
  date: string;
  selectDate: (date: string) => void;
}

const buttonStyle = {
  root: {
    width: "46px",
    margin: 0,
    height: "46px",
    minHeight: "46px",
    padding: "0px",
    background: "white",
    color: "black",
    borderRadius: "50%",
    border: "1px solid black",
  },
  rootHovered: {
    background: "white",
    color: "grey",
  },
};

export const PartyCalendar = ({
  date,
  selectDate,
}: PartyCalendarProps) => {
  const { isMiddleScreen } = useViewport();
  const [thisMonth, setMonth] = useState<boolean>(true);
  const [monthCoefficient, setMonthCoefficient] = useState<number>(0);

  const monthName = dayjs()
    .date(1)
    .add(monthCoefficient, "M")
    .format(MONTH_NAME_FORMAT);

  const nextMonth = (monthCoefficient: number) => {
    setMonthCoefficient(++monthCoefficient);
    monthCoefficient == 0 ? setMonth(true) : setMonth(false);
  };

  const previousMonth = (monthCoefficient: number) => {
    setMonthCoefficient(--monthCoefficient);
    monthCoefficient == 0 ? setMonth(true) : setMonth(false);
  };
  return isMiddleScreen ? (
    <div className="mobile-calendar">
      <div className="monthButtons">
        <IconButton
          iconProps={{ iconName: "ChevronLeft" }}
          className="focusableRoundButton"
          name="back"
          styles={buttonStyle}
          onClick={() => previousMonth(monthCoefficient)}
          disabled={thisMonth == true}
        ></IconButton>
        <div className="monthName">{monthName}</div>
        <IconButton
          iconProps={{ iconName: "ChevronRight" }}
          className="focusableRoundButton"
          name="next"
          styles={buttonStyle}
          onClick={() => nextMonth(monthCoefficient)}
        ></IconButton>
      </div>
      <PartyMonth
        date={date}
        selectDate={selectDate}
        isMobile={true}
        monthCoefficient={monthCoefficient}
      />
    </div>
  ) : (
    <div className="calendar">
      <div className="monthButtons">
        <IconButton
          iconProps={{ iconName: "ChevronLeft" }}
          className="focusableRoundButton"
          name="back"
          styles={buttonStyle}
          onClick={() => previousMonth(monthCoefficient)}
          disabled={thisMonth == true}
        ></IconButton>
        <IconButton
          iconProps={{ iconName: "ChevronRight" }}
          className="focusableRoundButton"
          name="next"
          styles={buttonStyle}
          onClick={() => nextMonth(monthCoefficient)}
        ></IconButton>
      </div>
      <div className="month">
        <PartyMonth
          date={date}
          selectDate={selectDate}
          isMobile={false}
          monthCoefficient={monthCoefficient}
        />
      </div>
      <div className="month">
        <PartyMonth
          date={date}
          selectDate={selectDate}
          isMobile={false}
          monthCoefficient={monthCoefficient + 1}
        />
      </div>
    </div>
  );
};
