import { getHash, getSearch, goBack, replace } from "connected-react-router";
import dayjs from "dayjs";
import { chunk, maxBy } from "lodash";
import {
  CompoundButton,
  IDropdownOption,
  Spinner,
  SpinnerSize,
  mergeStyleSets,
} from "office-ui-fabric-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import guestsIcon from "../../../../common/assets/guests-icon.svg";
import locationIcon from "../../../../common/assets/location-icon.svg";
import { Accordion } from "../../../../common/components/Accordion/Accordion";
import { InfoAlert } from "../../../../common/components/Alert/Alert";
import { Calendar } from "../../../../common/components/Calendar/Calendar";
import { CalendarButton } from "../../../../common/components/CalendarButton/CalendarButton";
import CheckboxConfirm from "../../../../common/components/CheckboxConfirm/CheckboxConfirm";
import HTMLRenderer from "../../../../common/components/HTMLRenderer/HTMLRenderer";
import { MapImageLink } from "../../../../common/components/MapImageLink/MapImageLink";
import { Policy } from "../../../../common/components/Policy/Policy";
import { PrimaryButton } from "../../../../common/components/PrimaryButton/PrimaryButton";
import { SelectWithIcon } from "../../../../common/components/SelectWithIcon/SelectWithIcon";
import { VenueAddress } from "../../../../common/components/VenueAddress/VenueAddress";
import {
  CALENDAR_DATE_FORMAT,
  CALENDAR_DATE_MOBILE_FORMAT
} from "../../../../common/constants/timedate";
import {
  composeTimeSlots,
  getPackageDurations,
} from "../../../../common/utils/formats";
import {
  resetReservationAction,
  setReservationErrorAction,
  startBookingAction,
  updateReservationAction,
} from "../../actions/reservation-actions";
import {
  changeVenuePackageAction,
  changeVenuePackageWithReservationAction,
  parseHash,
  setCurrentPackageAction,
  toPartyWithVenueAction,
  toReservationWithVenueAction,
} from "../../actions/venue-actions";
import { useViewport } from "../../hooks/responsive";
import {
  selectIsUpdateReservation,
  selectRequestError,
  selectReservation,
} from "../../reducers/reservation";
import { selectUIConfig } from "../../reducers/ui-reducer";
import {
  selectAvailability,
  selectCurrentPackage,
  selectIsLoadingAvailability,
  selectIsLoadingVenue,
  selectIsLoadingVenues,
  selectVenue,
  selectVenues,
} from "../../reducers/venues";
import { CurrencyType, PackageName, State } from "../../store/types";
import { getVenueList } from "../../utils/formats";
import { toUrlHash } from "../../utils/urlSearchQuery";
import {
  TimeButtonType,
  timeButtonStyle,
} from "../MakeReservation/MakeReservation";
import MobileFixedButton from "../MobileFixedButton/MobileFixedButton";
import PackageCard from "../PackageCard/packageCard";
import Footer from "../common/Footer/Footer";
import Header from "../common/Header/Header";
import FilterButtons from "./FIlterButtons";
import "./makePackageReservation.scss";
import ThingsToKnow from "./ThingsToKnow";
import { convertFaqsToArray } from "./utils";
import AboutVenue from "./AboutVenue";

const mapDispatchToProps = {
  changeVenuePackage: changeVenuePackageAction,
  resetReservation: resetReservationAction,
  updateReservation: updateReservationAction,
  startBooking: startBookingAction,
  toPartyWithVenue: toPartyWithVenueAction,
  setReservationError: setReservationErrorAction,
  toReservationWithVenue: toReservationWithVenueAction,
  setCurrentPackage: setCurrentPackageAction,
  goBack,
  replace,
  changeVenuePackageWithReservation: changeVenuePackageWithReservationAction,
};

const mapStateToProps = (state: State) => ({
  isLoadingVenues: selectIsLoadingVenues(state),
  isLoadingVenue: selectIsLoadingVenue(state),
  isLoadingAvailability: selectIsLoadingAvailability(state),
  activeVenue: selectVenue(state),
  venues: selectVenues(state),
  availability: selectAvailability(state),
  reservation: selectReservation(state),
  searchParams: getSearch(state),
  requestError: selectRequestError(state),
  uiConfig: selectUIConfig(state),
  currentPackage: selectCurrentPackage(state),
  isUpdateReservation: selectIsUpdateReservation(state),
  hashParams: getHash(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const MakePackageReservation = ({
  venues,
  activeVenue,
  isLoadingVenues,
  isLoadingVenue,
  isLoadingAvailability,
  availability,
  uiConfig,
  goBack,
  changeVenuePackage,
  startBooking,
  resetReservation,
  updateReservation,
  toPartyWithVenue,
  reservation,
  searchParams,
  hashParams,
  setReservationError,
  toReservationWithVenue,
  requestError,
  currentPackage,
  setCurrentPackage,
  changeVenuePackageWithReservation,
  isUpdateReservation,
  replace,
}: Props) => {
  const top = useRef<HTMLDivElement>(null);
  const isSelectNewPackage = useRef(false);
  useEffect(() => {
    if (top?.current) {
      window.scrollTo(0, top.current.offsetTop);
    }
  }, [top]);
  useEffect(() => {
    if (isSelectNewPackage.current) {
      isSelectNewPackage.current = false;
      const timeSlotElements = document.getElementById("time-buttons-title");
      timeSlotElements?.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, [currentPackage]);
  const init = useCallback(() => {
    //to handle back action
    if (activeVenue) {
      //todo: refactor it, update url other way
      const { venueId, date, guests } = reservation;
      if (currentPackage && currentPackage.enableDurationChoice) {
        const parsedDuration = getPackageDurations(currentPackage)[0];
        updateReservation({ duration: parsedDuration });
        if (!isLoadingVenue && venues.length > 0) {
          changeVenuePackage({
            updatePackage: currentPackage,
            venueId,
            date,
            guests,
            selectDuration: parsedDuration,
          });
        }
      } else {
        initPackageTag(activeVenue.id);
        if (!isLoadingVenue && venues.length > 0) {
          changeVenuePackage({
            updatePackage: currentPackage,
            venueId,
            date,
            guests,
          });
        }
      }
    }
    console.log("loading...", venueId);
  }, [resetReservation, searchParams, hashParams]);
  useEffect(() => {
    setReservationError("");
    if (!(reservation.id && isUpdateReservation)) {
      init();
    } else {
      changeVenuePackageWithReservation({});
    }
  }, []);

  useEffect(() => {
    if (activeVenue) {
      initPackageTag(activeVenue?.id);
    }
  }, [activeVenue?.id, venues]);

  const initPackageTag = (venueId: string) => {
    const venue = venues?.find((venue) => venue.id == venueId);
    let { singlePackage } = parseHash(hashParams);
    if(!venue || singlePackage){
       return
    }
    replace({ search: searchParams });
    if (
      !singlePackage &&
      venue.packageTags?.length > 0 &&
      uiConfig?.hideAllPackageTab
    ) {
      const firstPackageTag = venue.packageTags[0];
      const newHash = toUrlHash(firstPackageTag);
      replace({ search: searchParams, hash: newHash });
      setSelectedPackageTag(firstPackageTag);
    }
  }

  const mainButtonColor = uiConfig?.mainButtonColor;
  const isHideDuration =
    uiConfig?.hideDuration || currentPackage?.hidePackageDuration;
  const isShowReservationTime = uiConfig?.showReservationTime;
  const scrollToTimeSlotAfterSelect = !!uiConfig?.scrollToTimeSlotAfterSelect;
  const currency = uiConfig?.currency || CurrencyType.USD;
  const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;

  const contentStyles = mergeStyleSets({
    venueName: {
      color: mainButtonColor,
    },
    venueActiveMenu: {
      color: `${mainButtonColor} !important`,
      borderBottom: `3px solid ${mainButtonColor} !important`,
    },
  });

  const MORE_THAN_11 = 100;

  const getMenuPackagePropsGuests: (
    count: number,
    selectedPackages?: PackageName[]
  ) => IDropdownOption[] = (
    count: number,
    selectedPackages?: PackageName[]
  ) => {
      const max = maxBy(
        selectedPackages,
        (selectedPackage) => selectedPackage.maxGuests
      )?.maxGuests;
      const maxGuests = max && max > count ? max : count;
      return Array.apply(null, Array(maxGuests + 1)).map((_, index) => ({
        key: index === maxGuests ? MORE_THAN_11 : index + 1,
        text:
          index === 0
            ? `${index + 1} Guest`
            : `${index + 1}${index === maxGuests ? "+" : ""} Guests`,
      }));
    };

  const { isMobile, isMiddleScreen } = useViewport();
  const [isCalendar, setIsCalendar] = useState(false);
  const [isCheck, setIsCheck] = useState<boolean>(false);
  const [errorGuestCount, setErrorGuestCount] = useState<string>("");
  const [isLessTreeTimeSlots, setIsLessTreeTimeSlots] =
    useState<boolean>(false);
  const [isLessFourDurationSlots, setIsLessFourDurationSlots] =
    useState<boolean>(false);

  const setScrolling = () => {
    if (scrollToTimeSlotAfterSelect) {
      isSelectNewPackage.current = true;
    }
  };

  const menuPropsLocations = getVenueList(venues, searchParams);
  const closed = activeVenue?.closed;
  const { venueId, lanes, guests, slots, duration, date } = reservation;

  const menuPackagePropsGuest = getMenuPackagePropsGuests(
    activeVenue?.maxGuests || 10,
    activeVenue?.packages
  );

  let show21CheckBox = false;
  if (activeVenue?.timeSlots) {
    const slot = activeVenue.timeSlots.find((s) => s.time === slots);
    show21CheckBox = slot && !closed ? slot.is21plus : false;
  }
  const timeButtons =
    closed || !currentPackage || activeVenue?.packages.length === 0
      ? []
      : composeTimeSlots({
        date,
        venue: activeVenue,
        duration,
        isShowReservationTime,
        isHideDuration,
        guests,
        currentPackage,
        currency,
        twelveHourClockFormat,
      });
  const getTimeButtonsGroup = (timeButtons: TimeButtonType[]) => {
    if (timeButtons.length <= 2) {
      if (isLessTreeTimeSlots === false) {
        setIsLessTreeTimeSlots(true);
      }
      return chunk(timeButtons, 1);
    } else {
      if (isLessTreeTimeSlots === true) {
        setIsLessTreeTimeSlots(false);
      }
      return chunk(
        timeButtons,
        isShowReservationTime
          ? isMobile
            ? 2
            : 3
          : isMiddleScreen
            ? isMobile
              ? 3
              : 4
            : 5
      );
    }
  };
  const timeButtonsGroups = getTimeButtonsGroup(timeButtons);
  const selectDate = (date: string) => {
    if (isCalendar) {
      setIsCalendar(false);
    }
    setDay(date);
  };
  const setDay = (date: string) => {
    if (isUpdateReservation) {
      updateReservation({ slots: undefined });
      changeVenuePackageWithReservation({
        updatePackage: currentPackage,
        date,
      });
    } else {
      changeVenuePackage({
        updatePackage: currentPackage,
        venueId,
        date,
        guests,
      });
    }
  };
  const selectVenueOption = (newVenueId?: number | string) => {
    const newVenue = venues.find((venue) => venue.id == newVenueId);
    if (newVenueId && newVenue) {
      const recountedGuests = newVenue.maxGuests >= guests ? guests : 1;
      initPackageTag(newVenue.id);
      if (isUpdateReservation) {
        updateReservation({ slots: undefined, packageId: undefined });
        changeVenuePackageWithReservation({
          venueId: newVenueId.toString(),
          date,
          guests: recountedGuests,
          updatePackage: undefined,
        });
      } else {
        changeVenuePackage({
          updatePackage: undefined,
          venueId: newVenueId.toString(),
          date,
          guests: recountedGuests,
        });
      }
      setIsCalendar(false);
    }
  };
  const selectGuestsOption = (value?: number | string) => {
    if (!value) {
      return;
    }
    setErrorGuestCount("");
    const guests = Number(value);
    if (guests === MORE_THAN_11) {
      toPartyWithVenue(date);
      return;
    }
    changeVenuePackage({
      updatePackage: currentPackage,
      venueId,
      date,
      guests,
    });
  };
  const setTimeSlot = (slot: number) => {
    updateReservation({ slots: slot });
  };
  const setDuration = (selectedPackage: PackageName, duration: number) => {
    if (isNaN(duration) || !venueId) {
      return;
    }
    updateReservation({ duration });
    if (isUpdateReservation) {
      if (
        reservation.packageId &&
        reservation.packageId === selectedPackage.id
      ) {
        setScrolling();
      }
      updateReservation({ slots: undefined });
      changeVenuePackageWithReservation({ selectDuration: duration });
    } else {
      setScrolling();
      changeVenuePackage({
        updatePackage: selectedPackage,
        venueId,
        date,
        guests,
        selectDuration: duration,
      });
    }
  };
  const onChangeCheck = (_: any, isChecked?: boolean) => {
    setIsCheck(!!isChecked);
  };
  const onContinue = () => {
    if (!activeVenue || !currentPackage) {
      return;
    }
    if (
      guests > currentPackage.maxGuests ||
      guests < currentPackage.minGuests
    ) {
      setErrorGuestCount(
        "Please select the number of guests from the range of guests in the selected package."
      );
      return;
    }
    startBooking({
      venue: activeVenue,
      date,
      currentPackage,
      isContinue: false,
    });
  };
  const returnToParty = (e: React.FormEvent) => {
    e.preventDefault();
    toPartyWithVenue(date);
  };
  const returnToReservation = (e: React.FormEvent) => {
    e.preventDefault();
    toReservationWithVenue(date, guests);
  };
  const disableContinueButton =
    closed ||
    !venueId ||
    guests < 1 ||
    (show21CheckBox && !isCheck) ||
    !activeVenue ||
    !activeVenue.timeSlots.find((item) => item.time === slots) ||
    !currentPackage ||
    isNaN(reservation.slots);

  const onChangeCurrentPackage = (updatePackage: PackageName) => {
    if (updatePackage === currentPackage) {
      setCurrentPackage(undefined);
      return;
    }

    setScrolling();

    if (updatePackage.enableDurationChoice) {
      const parsedDuration = getPackageDurations(updatePackage)[0];
      updateReservation({ duration: parsedDuration });
      if (isUpdateReservation) {
        updateReservation({ slots: undefined });
        changeVenuePackageWithReservation({
          updatePackage,
          selectDuration: parsedDuration,
        });
      } else {
        changeVenuePackage({
          updatePackage,
          venueId,
          date,
          guests,
          selectDuration: parsedDuration,
        });
      }
    } else {
      if (isUpdateReservation) {
        updateReservation({ slots: undefined });
        changeVenuePackageWithReservation({ updatePackage });
      } else {
        changeVenuePackage({ updatePackage, venueId, date, guests });
      }
    }
  };

  const { packagesTag, singlePackage } = parseHash(hashParams);

  const packages =
    (singlePackage
      ? activeVenue?.packages?.filter((item) => item.id === currentPackage?.id)
      : activeVenue?.packages) || [];


  const [selectedPackageTag, setSelectedPackageTag] = useState('All')

  const selectPackageTag = (tag: string) => {
    if (tag) {
      if (tag === 'All') tag = ''
      const newHash = toUrlHash(tag);
      replace({ search: searchParams, hash: newHash });
    } else {
      replace({ search: searchParams });
    }
    setSelectedPackageTag(tag)


    changeVenuePackage({
      updatePackage: currentPackage,
      venueId,
      date,
      guests,
    });
  };

  const onClearFilter = () => {
    replace({ search: searchParams });
    changeVenuePackage({
      updatePackage: currentPackage,
      venueId,
      date,
      guests,
    });
  };

  return (
    <>
      {(isLoadingVenues || isLoadingVenue || isLoadingAvailability) && (
        <div className="loading">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      <div
        className="make-package-reservation-container main-container"
        ref={top}
      >
        <Header handleBackButton={goBack} />
        <div className="makePackageReservation-inner-container main-content">
          <div
            className={`makePackageReservation-main-information main-content ${isMobile ? "mobile" : ""
              }`}
          >
            <div className="makePackageReservation-reservation">
              <div className="makePackageReservation-reservation-title">
                {activeVenue?.packagesTitle}
              </div>
              <div
                className={`makePartyReservation-reservation-subtitle ${contentStyles.venueName}`}
              >
                {activeVenue?.name}
              </div>
              <HTMLRenderer
                html={activeVenue?.packagesDescription}
                className="makePackageReservation-reservation-text"
              />
              <div
                className={`makePackageReservation-reservation-link-container ${isMobile ? "mobile" : ""
                  }`}
              >
                {!uiConfig?.isPackageReservationMode && (
                  <>
                    <div
                      className={`link-button ${isMobile ? "mobile" : ""}`}
                      onClick={returnToReservation}
                    >
                      Reservations
                    </div>
                    <div className="link-delimiter" />
                  </>
                )}
                <div
                  className={`link-button ${isMobile ? "mobile" : ""} active ${contentStyles.venueActiveMenu
                    }`}
                >
                  {activeVenue?.packagesTitle}
                </div>
                {!isUpdateReservation && (
                  <>
                    <div className="link-delimiter" />
                    <div
                      className={`link-button ${isMobile ? "mobile" : ""}`}
                      onClick={returnToParty}
                    >
                      {activeVenue?.eventInquiryTitle}
                    </div>
                  </>
                )}
              </div>

              {isMiddleScreen ? (
                <div className="makePackageReservation-reservation-selectors">
                  <div className="buttons-row">
                    <SelectWithIcon
                      placeholder=""
                      options={menuPropsLocations}
                      icon={locationIcon}
                      iconWidth={11}
                      iconHeight={16}
                      onChange={selectVenueOption}
                      value={venueId}
                    />
                    <div className="button-delimiter"></div>
                    <SelectWithIcon
                      placeholder="Guests"
                      options={menuPackagePropsGuest}
                      icon={guestsIcon}
                      iconWidth={20}
                      iconHeight={14}
                      value={guests}
                      onChange={selectGuestsOption}
                    />
                  </div>
                  <div className="buttons-row">
                    <CalendarButton
                      date={
                        date
                          ? dayjs(date).format(
                            isMobile
                              ? CALENDAR_DATE_MOBILE_FORMAT
                              : CALENDAR_DATE_FORMAT
                          )
                          : undefined
                      }
                      placeholder="Event Date"
                      onClick={() => setIsCalendar(!isCalendar)}
                      isCalendar={isCalendar}
                      disabled={closed}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div className="buttons-row">
                    <SelectWithIcon
                      placeholder=""
                      options={menuPropsLocations}
                      icon={locationIcon}
                      iconWidth={11}
                      iconHeight={16}
                      onChange={selectVenueOption}
                      value={venueId}
                    />
                    <div className="button-delimiter"></div>
                    <SelectWithIcon
                      placeholder="Guests"
                      options={menuPackagePropsGuest}
                      icon={guestsIcon}
                      iconWidth={20}
                      iconHeight={14}
                      value={guests}
                      onChange={selectGuestsOption}
                    />
                  </div>
                  <div className="buttons-row">
                    <CalendarButton
                      date={
                        date
                          ? dayjs(date).format(
                            isMobile
                              ? CALENDAR_DATE_MOBILE_FORMAT
                              : CALENDAR_DATE_FORMAT
                          )
                          : undefined
                      }
                      placeholder="Event Date"
                      onClick={() => setIsCalendar(!isCalendar)}
                      isCalendar={isCalendar}
                      disabled={closed}
                    />
                  </div>
                </>
              )}
              {isCalendar ? (
                <Calendar
                  date={date}
                  selectDate={selectDate}
                  availability={availability}
                  isMiddleScreen={isMiddleScreen}
                />
              ) : null}
              <div className="package-list-container">
                <div className="package-list-title">
                  <div>
                    {activeVenue?.choosePackageTitle || "Select a package"}
                  </div>
                  {singlePackage && (
                    <button className="clear-button" onClick={onClearFilter}>
                      clear filter ×
                    </button>
                  )}
                </div>
                {activeVenue?.packageTags &&
                  !singlePackage && activeVenue?.packageTags.length !== 0 ? (
                  <FilterButtons
                    values={
                      activeVenue
                        ? [
                          ...(uiConfig?.hideAllPackageTab ? [] : [{ value: 'All' }]),
                          ...activeVenue.packageTags.map((tag) => ({ value: tag })),
                        ]
                        : []
                    }
                    filterValue={selectedPackageTag}
                    onFilterChange={(tag) => selectPackageTag(tag)}
                  />
                ) : null}

                {packages.map((item) => (
                  <PackageCard
                    key={item.id}
                    item={item}
                    onContactUs={returnToParty}
                    onChangeCurrentPackage={onChangeCurrentPackage}
                    setDuration={setDuration}
                  />
                ))}
              </div>
              <div className="time-buttons-title" id="time-buttons-title" style={{ marginBottom: '20px' }}>
                Available Reservation Times
              </div>
              <div className="time-buttons">
                {timeButtonsGroups.map((timeButtonsRow, index) => (
                  <div
                    className={`timeButton-row ${index === timeButtonsGroups.length - 1 ? "no-padding" : ""
                      }`}
                    key={index}
                  >
                    {timeButtonsRow.map((t, index) => (
                      <React.Fragment key={t.slot}>
                        <div
                          className={`timeButton ${isLessTreeTimeSlots
                            ? "fullWidth"
                            : !isShowReservationTime
                              ? isMiddleScreen
                                ? isMobile
                                  ? "mobile"
                                  : "middle"
                                : ""
                              : isMobile
                                ? "small"
                                : "mobile"
                            }`}
                          key={t.slot}
                        >
                          <CompoundButton
                            className="focusableButton"
                            styles={timeButtonStyle(t.slot === slots)}
                            key={t.time}
                            onClick={() => setTimeSlot(t.slot)}
                            name="timeAndPrice"
                          >
                            {t.time}
                            <div
                              className={`${t.slot === slots ? "selectPrice" : "price"
                                }`}
                            >
                              {t.price}
                            </div>
                          </CompoundButton>
                        </div>
                        {index < timeButtonsRow.length - 1 && (
                          <div className="button-delimiter"></div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                ))}
              </div>
              {isMobile ? (
                <>
                  {closed ? (
                    <div className="mobile-summary-inner">
                      <div className="no-slots">
                        <HTMLRenderer html={activeVenue?.closedMessage} />
                      </div>
                    </div>
                  ) : (
                    timeButtons.length === 0 && (
                      <div className="mobile-summary-inner">
                        <div className="no-slots">
                          <HTMLRenderer
                            html={
                              activeVenue?.inactivePackageMessage ||
                              `There are currently no available reservations for this
                                date. We still welcome walk-in groups for our bays on
                                a first come, first serve basis.`
                            }
                          />
                        </div>
                      </div>
                    )
                  )}
                  <div className="mobile-summary">
                    <div className="mobile-summary-inner">
                      <Accordion title={activeVenue?.name || ""}>
                        <div className="location">
                          <VenueAddress venue={activeVenue} />
                          <div className="map-panel">
                            <MapImageLink venue={activeVenue} />
                          </div>
                        </div>
                      </Accordion>
                    </div>
                    <div className="mobile-summary-inner">
                      <Accordion
                        className="venue-policy"
                        title="VENUE POLICIES"
                      >
                        <Policy text={activeVenue?.venueInfo} />
                      </Accordion>
                    </div>

                    {show21CheckBox && (
                      <div className="mobile-summary-inner">
                        <CheckboxConfirm
                          onChangeCheck={onChangeCheck}
                          isCheck={isCheck}
                          age={activeVenue?.ageForPolicy}
                        />
                      </div>
                    )}
                    {isUpdateReservation && (
                      <InfoAlert
                        className="mobile-info-alert"
                        text="You are modifying an existing reservation. Based on your new selection there may be a price difference"
                      />
                    )}
                    {(errorGuestCount || requestError) && (
                      <div className="error mobile">
                        {errorGuestCount || requestError}
                      </div>
                    )}
                    <MobileFixedButton
                      onClick={onContinue}
                      disabled={disableContinueButton}
                      name="continue"
                      uiConfig={uiConfig}
                    >
                      CONTINUE
                    </MobileFixedButton>
                  </div>
                </>
              ) : (
                <>
                  {closed ? (
                    <div className="no-slots">
                      <HTMLRenderer html={activeVenue?.closedMessage} />
                    </div>
                  ) : (
                    timeButtons.length === 0 && (
                      <div className="no-slots">
                        <HTMLRenderer
                          html={
                            activeVenue?.inactivePackageMessage ||
                            `There are currently no available reservations for this date. We still welcome walk-in groups for our bays on a first come, first serve basis.`
                          }
                        />
                      </div>
                    )
                  )}
                  {show21CheckBox && (
                    <CheckboxConfirm
                      onChangeCheck={onChangeCheck}
                      isCheck={isCheck}
                      age={activeVenue?.ageForPolicy}
                    />
                  )}
                  {isUpdateReservation && (
                    <InfoAlert
                      className="info-alert"
                      text="You are modifying an existing reservation. Based on your new selection there may be a price difference"
                    />
                  )}
                  {(errorGuestCount || requestError) && (
                    <div className="error">
                      {errorGuestCount || requestError}
                    </div>
                  )}
                  <PrimaryButton
                    onClick={onContinue}
                    disabled={disableContinueButton}
                    name="continue"
                    uiConfig={uiConfig}
                  >
                    CONTINUE
                  </PrimaryButton>
                </>
              )}


              {uiConfig?.newDesign ?
                <>
                  <AboutVenue mt={'32px'} p={'32px'} description={activeVenue?.description as string} />
                  {activeVenue?.faqs ?
                    <ThingsToKnow mt={'32px'} accordionData={convertFaqsToArray(activeVenue.faqs)} title={activeVenue.faqTitle || 'Things to Know'} />
                    : null}
                </>
                : null}
            </div>

            {!isMobile && (
              <>
                <div className="panel-delimiter"></div>
                <div className="makePackageReservation-summary">
                  <img
                    className="info-logo"
                    src={activeVenue?.venueImage}
                    alt="venueImage"
                  />
                  <div className="summary-inner">
                    <Accordion title={activeVenue?.name || ""}>
                      <div className="location">
                        <VenueAddress venue={activeVenue} />
                        <div className="map-panel">
                          <MapImageLink venue={activeVenue} />
                        </div>
                      </div>
                    </Accordion>
                  </div>
                  <div className="summary-inner2">
                    <Accordion className="venue-policy" title="VENUE POLICIES">
                      <Policy text={activeVenue?.venueInfo} />
                    </Accordion>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <Footer className={isMobile ? "mobile-footer" : ""} />
      </div>
    </>
  );
};

export default connector(MakePackageReservation);
