import React, { useState, useEffect } from "react";
import {
  Spinner,
  SpinnerSize,
  ActionButton,
} from "office-ui-fabric-react";
import { isValidEmail } from "../../../../../common/utils/validate"
import { useViewport } from "../../../hooks/responsive";
import { PrimaryButton } from "../../../../../common/components/PrimaryButton/PrimaryButton";
import { InputField } from "../../../../../common/components/InputField/InputField";
import {Credentials} from '../../../actions/auth-actions'
import MobileFixedButton from "../../MobileFixedButton/MobileFixedButton";

import "./login.scss";

interface Props {
  login: (credentials: Credentials) => void;
  isInProgress: boolean;
  toRecover: () => void;
  cleanError: () => void,
  continueReservation: boolean;
  error?: string;
  uiConfig: any;
}


export const Login = ({
  login,
  toRecover,
  error,
  isInProgress,
  cleanError,
  continueReservation,
  uiConfig
}: Props) => {
  const { isMobile } = useViewport();
  const [username, setUserName] = useState<string | undefined>("");
  const [password, setPassword] = useState<string | undefined>("");
  const [errorUsername, setErrorUsername] = useState<string>("");
  const [errorPassword, setErrorPassword] = useState<string>("");
  useEffect(() => () => cleanError(), [cleanError])

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      username &&
      password &&
      isValidEmail(username) == true
    ) {
      login({ username, password ,continueReservation});
      return;
    }
    if (!username) {
      setErrorUsername("This field is required.");
    }
    if (username && isValidEmail(username) == false) {
      setErrorUsername("Please enter a valid email address.");
    }
    if (!password) {
      setErrorPassword("This field is required.");
    }
  };
  const onUserNameChange = (_:any, text?: string) => {
    setErrorUsername("");
    setUserName(text);
  };
  const onPasswordChange = (_:any, text?: string) => {
    setErrorPassword("");
    setPassword(text);
  }

  return (
    <form className="login main-content" onSubmit={onSubmit} >
      <div className={isMobile?"mobile-login-fields":"login-fields"}>
        {isInProgress && (
          <div className="loading">
            <Spinner size={SpinnerSize.large} />
          </div>
        )}
        <InputField
          label="Email"
          name="email"
          value={username}
          errorMessage={errorUsername}
          onChange={onUserNameChange}
          autoComplete="email"
          type="email"
        />
        <InputField
          type="password"
          name="password"
          label="Password"
          value={password}
          errorMessage={errorPassword}
          onChange={onPasswordChange}
          autoComplete="current-password"
        />
        {error && <div className="error">{error}</div>}
        {
          isMobile ? (
            <MobileFixedButton type="submit" name="continue" uiConfig={uiConfig}>
              CONTINUE
            </MobileFixedButton>
          ) : (
            <PrimaryButton type="submit" name="continue" uiConfig={uiConfig}>
              CONTINUE
            </PrimaryButton>
          )
        }
        <ActionButton className="forgot-password focusableButton" onClick={toRecover} name="forgotPassword">
          Forgot password?
        </ActionButton>
      </div>
    </form>
  );
};
