import React from "react";
import dayjs from "dayjs";
import { connect, ConnectedProps } from "react-redux";
import { mergeStyleSets } from "office-ui-fabric-react";
import { useViewport } from "../../hooks/responsive";
import Header from "../common/Header/Header";
import Footer from "../common/Footer/Footer";
import { PrimaryButton } from "../../../../common/components/PrimaryButton/PrimaryButton";
import { Accordion } from "../../../../common/components/Accordion/Accordion";
import { VenueAddress } from "../../../../common/components/VenueAddress/VenueAddress";
import { formatOccasionsAndGuestDetails, slotToTime, formatDuration } from "../../../../common/utils/formats";
import confirmationImage from "../../../../common/assets/confirmation.png";
import { CONFIRM_DATE_FORMAT } from "../../../../common/constants/timedate";
import { MapImageLink } from "../../../../common/components/MapImageLink/MapImageLink";
import { State } from "../../store/types";
import { selectVenue } from "../../reducers/venues";
import { selectParty } from "../../reducers/reservation";
import { selectUIConfig } from "../../reducers/ui-reducer";
import HTMLRenderer from "../../../../common/components/HTMLRenderer/HTMLRenderer";
import "./makePartyConfirmation.scss";

const mapStateToProps = (state: State) => ({
  party: selectParty(state),
  venue: selectVenue(state),
  uiConfig: selectUIConfig(state),
});

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const BookingConfirmation = ({ party, venue, uiConfig }: Props) => {
  const { isMobile } = useViewport();
  if (!party || !venue) {
    window.location.href = "/";
    return null;
  }
  const contentStyles = mergeStyleSets({
    link: {
      color: uiConfig?.mainButtonColor,
    },
  });
  const { date, timeSlot, email, occasion, guestCount, duration } = party;
  const oc = formatOccasionsAndGuestDetails(uiConfig?.occasions).find((item) => item.key === occasion)
  const occasionText = oc?.key || occasion;
  const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;

  const partyTimeSlotDuration = 30;
  const time = slotToTime(timeSlot, partyTimeSlotDuration, twelveHourClockFormat);
  const formattedDate = dayjs(date).format(CONFIRM_DATE_FORMAT);
  const partyDuration = formatDuration(duration);
  const eventConfirmationPageTitle = venue?.eventConfirmationPageTitle || 'Thank you for inquiring about a party!';
  const eventConfirmationPageCopy = venue?.eventConfirmationPageCopy || "";

  return (
    <div
      className={`party-confirm-container main-container ${isMobile ? "mobile" : ""
        }`}
    >
      <Header party />
      <div className={`confirmation-inner-container main-content`}>
        <div
          className={`confirmation-registrationMessage ${isMobile ? "mobile" : "no-mobile"
            }`}
        >
          <div className="registrationMessage">
            <img className="confirmationImage" src={confirmationImage} alt="confirmation image" />
            <div>Request submitted</div>
            <div className="reservationNumber">
              {eventConfirmationPageTitle}
            </div>
            {eventConfirmationPageCopy ? (
              <HTMLRenderer html={eventConfirmationPageCopy} />
            ) : (
              <div>A inquiry has been sent to your email address below. Please make sure to check your spam or junk folder if you do not receive the email within 5 minutes:</div>
            )}
            <div className="email">{email}</div>
            <a
              className={`venue-link ${contentStyles.link}`}
              href={venue.locationUrl}
            >{`${uiConfig?.companyName} ${venue.name}`}</a>
            <div className="booking-attr occasion">{`Occasion: ${occasionText}`}</div>
            <div className="booking-attr event-date">{`Event Date: ${formattedDate}`}</div>
            <div className="booking-attr event-start">{`Event Start Time: ${time}`}</div>
            <div className="booking-attr event-duration">{`Event duration: ${partyDuration}`}</div>
            <div className="booking-attr num-guests">{`Number of Guests: ${guestCount}`}</div>
            <PrimaryButton
              href={uiConfig?.homeLink}
              name="homePage"
              className="home-button"
              uiConfig={uiConfig}
            >
              RETURN HOME
            </PrimaryButton>
          </div>
        </div>
        {!isMobile && (
          <>
            <div className="column-delimiter"></div>
            <div className="confirmation-summary">
              <div className="summary-inner">
                <Accordion title={venue?.name || ""}>
                  <div className="location">
                    <VenueAddress venue={venue} />
                    <div className="map-panel">
                      <MapImageLink venue={venue} />
                    </div>
                  </div>
                </Accordion>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default connector(BookingConfirmation);
