import React, { useEffect, useState, useCallback, useRef } from "react";
import { getSearch } from "connected-react-router";
import dayjs from "dayjs";
import { chunk } from "lodash";
import {
  DefaultButton,
  IDropdownOption,
  IButtonStyles,
  CompoundButton,
  Spinner,
  SpinnerSize,
  mergeStyleSets,
} from "office-ui-fabric-react";
import { connect, ConnectedProps } from "react-redux";
import { goBack } from "connected-react-router";
import {
  changeVenueAction,
  toPartyWithVenueAction,
  toPackagesWithVenueAction,
  changeVenueWithReservationAction,
} from "../../actions/venue-actions";
import {
  resetReservationAction,
  updateReservationAction,
  startBookingAction,
  setReservationErrorAction,
} from "../../actions/reservation-actions";
import {
  selectReservation,
  selectRequestError,
  selectIsUpdateReservation,
} from "../../reducers/reservation";
import {
  selectIsLoadingVenues,
  selectIsLoadingVenue,
  selectIsLoadingAvailability,
  selectVenue,
  selectVenues,
  selectAvailability,
} from "../../reducers/venues";
import { selectUIConfig } from "../../reducers/ui-reducer";
import { CurrencyType, State, Venue } from "../../store/types";
import Header from "../common/Header/Header";
import Footer from "../common/Footer/Footer";
import CheckboxConfirm from "../../../../common/components/CheckboxConfirm/CheckboxConfirm";
import HTMLRenderer from "../../../../common/components/HTMLRenderer/HTMLRenderer";
import locationIcon from "../../../../common/assets/location-icon.svg";
import guestsIcon from "../../../../common/assets/guests-icon.svg";
import { SelectWithIcon } from "../../../../common/components/SelectWithIcon/SelectWithIcon";
import { Accordion } from "../../../../common/components/Accordion/Accordion";
import { Calendar } from "../../../../common/components/Calendar/Calendar";
import { InfoAlert } from "../../../../common/components/Alert/Alert";
import { parseUrlQuery } from "../../utils/urlSearchQuery";
import {
  rem,
  getVenueDurations,
  getVenueAssignedDuration,
  composeTimeSlots,
  formatDuration,
  formatLanesCount,
  formatLanes,
} from "../../../../common/utils/formats";
import {
  DATE_FORMAT,
  CALENDAR_DATE_FORMAT,
  CALENDAR_DATE_MOBILE_FORMAT,
} from "../../../../common/constants/timedate";
import { CalendarButton } from "../../../../common/components/CalendarButton/CalendarButton";
import { useViewport } from "../../hooks/responsive";
import { Policy } from "../../../../common/components/Policy/Policy";
import { VenueAddress } from "../../../../common/components/VenueAddress/VenueAddress";
import { color } from "../../../../common/constants/styles";
import { MapImageLink } from "../../../../common/components/MapImageLink/MapImageLink";
import AssistantIcon from "../../../../common/assets/actions/assistant-24px.svg";
import CasinoIcon from "../../../../common/assets/actions/casino-24px.svg";
import GolfCourseIcon from "../../../../common/assets/actions/golf_course-24px.svg";
import LocalPlayIcon from "../../../../common/assets/actions/local_play-24px.svg";
import ViewComfyIcon from "../../../../common/assets/actions/view_comfy-24px.svg";
import BowlingIcon from "../../../../common/assets/actions/bowling-24px.svg";
import { WeekDaysButton } from "../common/WeekDaysButton/WeekDaysButton";
import { PrimaryButton } from "../../../../common/components/PrimaryButton/PrimaryButton";
import { extractInnerText } from "../../../../common/utils/extractInnerText";
import ExternalBookingComponent from "../common/ExternalBookingComponent/ExternalBookingComponent";
import "./makeReservation.scss";
import MobileFixedButton from "../MobileFixedButton/MobileFixedButton";
import { getVenueList } from "../../utils/formats";
import { CheckInStatus } from "../../../../server/src/entities/enums";
import MakeReservationSummary2 from "./MakeReservationSummary2";
import Header2 from "../common/Header/Header2";
import Footer2 from "../common/Footer/Footer2";
import { AgeGroup } from "../../../../server/src/dto/ageGroup.dto";
import { AgeGroupPricing } from "../../../../server/src/utils/ageGroups";

const actionIcons: any = {
  assistant: AssistantIcon,
  casino: CasinoIcon,
  golf: GolfCourseIcon,
  local: LocalPlayIcon,
  comfy: ViewComfyIcon,
  bowling: BowlingIcon,
};

export interface TimeButtonType {
  text: string,
  key: number,
  price: string,
  time: string,
  slot: number,
  ageGroupPricing?: AgeGroupPricing,
}
export interface DurationButtonType {
  durationValue: number;
  textButton: string;
}

export const durationButtonStyle = (isBlack: boolean) => ({
  root: {
    height: rem(50),
    flex: 1,
    margin: 0,
    background: isBlack ? "black" : "white",
    color: isBlack ? "white" : "black",
    border: isBlack ? "1px solid black" : `1px solid ${color.grayBorder5}`,
    fontSize: rem(13),
  },
  rootHovered: {
    background: "black",
    color: "white",
    border: "1px solid black",
  },
  rootDisabled: {
    color: isBlack ? "white" : "rgb(161, 159, 157)",
  },
});

export const timeButtonStyle = (isBlack: boolean): IButtonStyles => ({
  root: {
    width: "100%",
    margin: 0,
    height: rem(50),
    maxWidth: "100%",
    minHeight: "50px",
    padding: "0px",
    background: isBlack ? "black" : "white",
    color: isBlack ? "white" : "black",
    fontWeight: "bold",
    border: isBlack ? "1px solid black" : `1px solid ${color.grayBorder5}`,
    fontSize: rem(13),
  },
  rootHovered: {
    background: "black",
    color: "white",
    border: "1px solid black",
  },
  flexContainer: {
    flexDirection: "column",
    alignItems: "center",
  },
  textContainer: {
    textAlign: "center",
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
  },
});

export const timeButtonStyleV2 = (isPressed: boolean, borderColor: string, isTablet?: boolean): IButtonStyles => ({
  root: {
    width: "100%",
    margin: 0,
    height: isTablet ? rem(48) : rem(64),
    maxWidth: "100%",
    minHeight: isTablet ? rem(48) : rem(64),
    padding: "12px 24px",
    background: '#F6F7FA',
    color: "black",
    fontWeight: "bold",
    border: isPressed ? `1px solid ${borderColor}` : '1px solid #F6F7FA',
    fontSize: rem(14),
    lineHeight: '24px',
    transition: 'all .05s ease',
    position: 'relative',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  rootHovered: {
    border: `1px solid ${borderColor}`,
  },
  flexContainer: {
    flexDirection: "column",
    alignItems: "center",
  },
  textContainer: {
    textAlign: "center",
  },
});

const mapDispatchToProps = {
  changeVenue: changeVenueAction,
  resetReservation: resetReservationAction,
  updateReservation: updateReservationAction,
  startBooking: startBookingAction,
  toPartyWithVenue: toPartyWithVenueAction,
  toPackagesWithVenue: toPackagesWithVenueAction,
  setReservationError: setReservationErrorAction,
  goBack,
  changeVenueWithReservation: changeVenueWithReservationAction,
};

const mapStateToProps = (state: State) => ({
  isLoadingVenues: selectIsLoadingVenues(state),
  isLoadingVenue: selectIsLoadingVenue(state),
  isLoadingAvailability: selectIsLoadingAvailability(state),
  activeVenue: selectVenue(state),
  venues: selectVenues(state),
  availability: selectAvailability(state),
  reservation: selectReservation(state),
  searchParams: getSearch(state),
  requestError: selectRequestError(state),
  uiConfig: selectUIConfig(state),
  isUpdateReservation: selectIsUpdateReservation(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;
export const getDurationButtons = (activeVenue: Venue) => {
  if (activeVenue) {
    const durations = getVenueDurations(activeVenue);
    const durationSlots = durations.map((d) => {
      const textButton = formatDuration(d, activeVenue.timeSlotDuration);
      return { durationValue: d, textButton };
    });
    return durationSlots;
  }
  return [];
};
const MakeReservation = ({
  venues,
  activeVenue,
  isLoadingVenues,
  isLoadingVenue,
  isLoadingAvailability,
  availability,
  uiConfig,
  goBack,
  changeVenue,
  startBooking,
  resetReservation,
  updateReservation,
  toPartyWithVenue,
  toPackagesWithVenue,
  reservation,
  searchParams,
  setReservationError,
  requestError,
  isUpdateReservation,
  changeVenueWithReservation,
}: Props) => {
  const top = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (top?.current) {
      window.scrollTo(0, top.current.offsetTop);
    }
  }, [top]);
  const init = useCallback(() => {
    const { venue, date, guests } = parseUrlQuery(searchParams);
    const parsedDate =
      date && date !== "undefined" ? date : dayjs().format(DATE_FORMAT);
    let parsedGuests = parseInt(guests, 10) || 1;
    const slots = reservation.slots;
    //to handle back action
    if (activeVenue) {
      const parsedDuration = (isHideDuration && defaultDuration)
        ? defaultDuration
        : activeVenue.enableAssignDuration
          ? getVenueAssignedDuration(activeVenue, parsedGuests) || getVenueDurations(activeVenue)[0]
          : getVenueDurations(activeVenue)[0]
      updateReservation({ duration: parsedDuration });
      const parsedLanes = formatLanesCount(parsedGuests, activeVenue.guestSplit, isOnlyOneLane);
      parsedGuests = activeVenue.name === venue ? parsedGuests : 1;
      console.log(
        "reset res, venue:",
        venue,
        "slots:",
        slots,
        parsedGuests,
        guests
      );
      const parsedSlots =
        activeVenue.name === venue
          ? slots || activeVenue.timeSlots[0]?.time
          : activeVenue.timeSlots[0]?.time;
      const timeSlotShifting = +activeVenue.timeSlotShifting[dayjs(parsedDate).day()] || 0
      resetReservation({
        id: undefined,
        venueId: activeVenue.id,
        date: parsedDate,
        slots: parsedSlots,
        duration: parsedDuration,
        guests: parsedGuests,
        lanes: activeVenue.name === venue ? parsedLanes : 1,
        price: 0,
        addonsPrice: 0,
        discount: 0,
        deposit: 0,
        tax: 0,
        serviceFee: 0,
        customFees: "",
        customTaxes: "",
        addonCustomTaxes: "",
        customFeesTaxes: "",
        depositParam: +activeVenue.deposit,
        depositType: activeVenue.depositType,
        textBox: activeVenue.textBox,
        occasion: "",
        pricing: activeVenue.pricing,
        timeSlotDuration: activeVenue.timeSlotDuration,
        allowCancellation: activeVenue.allowCancellation,
        allowModification: activeVenue.allowModification,
        timeZone: activeVenue.timeZone,
        venueName: activeVenue.name,
        venueAddress: `${activeVenue.address} ${activeVenue?.address2 || ''}`,
        venueImage: activeVenue.venueImage,
        timeSlotShifting: timeSlotShifting,
        startTime: 0,
        discountName: '',
        preBufferTime: activeVenue.preBufferTimeForReservation,
        bufferTime: activeVenue.bufferTimeForReservation,
        currency: uiConfig?.currency || CurrencyType.USD,
        giftCardPaid: 0,
        modificationFee: 0,
        modified: false,
        createdByAdmin: false,
        firstName: '',
        lastName: '',
        payed: 0,
        refunded: 0,
        checkedIn: false,
        checkInStatus: CheckInStatus.Upcoming,
        discountAmount: 0,
      });
      changeVenue({
        venueId: activeVenue.id,
        date: parsedDate,
        duration: parsedDuration,
        lanes: parsedLanes,
        slots,
        guests: parsedGuests,
      });
      return;
    }
    if (!isLoadingVenue && venues.length > 0) {
      console.log("loading...");
      const openedVenues = venues.filter(v => !v.isComingSoon);
      const parsedDuration = (isHideDuration && defaultDuration)
        ? defaultDuration
        : openedVenues[0].enableAssignDuration
          ? getVenueAssignedDuration(openedVenues[0], parsedGuests) || getVenueDurations(openedVenues[0])[0]
          : getVenueDurations(openedVenues[0])[0]
      updateReservation({ duration: parsedDuration });
      const parsedLanes = formatLanesCount(parsedGuests, openedVenues[0].guestSplit, isOnlyOneLane);
      changeVenue({
        venueId: openedVenues[0].id,
        date: parsedDate,
        duration: parsedDuration,
        lanes: parsedLanes,
        guests: parsedGuests,
      });
    }
  }, [resetReservation, searchParams, changeVenue]);
  useEffect(() => {
    setReservationError("")
    if (!(reservation.id && isUpdateReservation)) {
      init();
    } else {
      changeVenueWithReservation({});
    }
    if (uiConfig?.isPackageReservationMode) {
      toPackagesWithVenue();
    }
  }, []);

  const mainButtonColor = uiConfig?.mainButtonColor;
  const actionText = uiConfig?.actionText || "Lane";
  const actionIconId = uiConfig?.actionIcon;
  const actionIcon =
    actionIconId && actionIcons[actionIconId]
      ? actionIcons[actionIconId]
      : LocalPlayIcon;
  const isHideDuration = uiConfig?.hideDuration;
  const defaultDuration = uiConfig?.defaultDuration;
  const isHideNumberOfLines = uiConfig?.hideNumberOfLines;
  const isSubtractMultiLanes = uiConfig?.subtractMultiLanes;
  const isShowReservationTime = uiConfig?.showReservationTime;
  const currency = uiConfig?.currency || CurrencyType.USD;
  const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;
  const isOnlyOneLane = isHideNumberOfLines && !isSubtractMultiLanes;
  const contentStyles = mergeStyleSets({
    venueName: {
      color: mainButtonColor,
    },
    venueActiveMenu: {
      color: `${mainButtonColor} !important`,
      borderBottom: `3px solid ${mainButtonColor} !important`,
    }
  });

  const MORE_THAN_11 = 100;
  const getMenuPropsGuests: (count: number) => IDropdownOption[] = (
    count: number
  ) =>
    Array.apply(null, Array(count + 1)).map((_, index) => ({
      key: index === count ? MORE_THAN_11 : index + 1,
      text:
        index === 0
          ? `${index + 1} Guest`
          : `${index + 1}${index === count ? "+" : ""} Guests`,
    }));

  const { isMobile, isMiddleScreen, isMaxScreen } = useViewport();
  const [isCalendar, setIsCalendar] = useState(false);
  const [isCheck, setIsCheck] = useState<boolean>(false);
  const [errorGuestCount, setErrorGuestCount] = useState<string>("");
  const [isLessTreeTimeSlots, setIsLessTreeTimeSlots] = useState<boolean>(false);
  const [isLessFourDurationSlots, setIsLessFourDurationSlots] = useState<boolean>(false);

  const menuPropsLocations = getVenueList(venues, searchParams);
  const closed = activeVenue?.closed;
  const { venueId, lanes, guests, slots, duration, date } = reservation;
  let numberOfLanes = formatLanesCount(guests, activeVenue?.guestSplit, isOnlyOneLane);
  let lanesText = formatLanes(numberOfLanes, actionText)

  const lanesOptions = [
    {
      key: numberOfLanes,
      text: lanesText,
    }
  ];
  const menuPropsGuests = getMenuPropsGuests(activeVenue?.maxGuests || 10);

  let show21CheckBox = false;
  if (activeVenue?.timeSlots) {
    const slot = activeVenue.timeSlots.find((s) => s.time === slots);
    show21CheckBox = slot && !closed ? slot.is21plus : false;
  }
  const timeButtons = closed
    ? []
    : composeTimeSlots({ date, venue: activeVenue, duration, isShowReservationTime, isHideDuration, guests, currency, twelveHourClockFormat });
  const getTimeButtonsGroup = (timeButtons: TimeButtonType[]) => {
    if (timeButtons.length <= 2) {
      if (isLessTreeTimeSlots === false) {
        setIsLessTreeTimeSlots(true);
      }
      return chunk(timeButtons, 1);
    } else {
      if (isLessTreeTimeSlots === true) {
        setIsLessTreeTimeSlots(false);
      }
      return chunk(
        timeButtons,
        isShowReservationTime ? (isMobile ? 2 : 3) : isMiddleScreen ? (isMobile ? 3 : 4) : 5
      );
    }
  }
  const timeButtonsGroups = getTimeButtonsGroup(timeButtons);
  const selectDate = (date: string) => {
    if (isCalendar) {
      setIsCalendar(false);
    }
    setDay(date);
  };
  const setDay = (date: string) => {
    if (isUpdateReservation) {
      updateReservation({ slots: undefined });
      changeVenueWithReservation({ date });
    } else {
      changeVenue({ venueId, date, duration, lanes, slots, guests });
    }
  };
  const selectVenueOption = (newVenueId?: number | string) => {
    const newVenue = venues.find((venue) => venue.id == newVenueId);
    if (newVenueId && newVenue) {
      const duration = (isHideDuration && defaultDuration)
        ? defaultDuration
        : newVenue.enableAssignDuration
          ? getVenueAssignedDuration(newVenue, guests) || getVenueDurations(newVenue)[0]
          : getVenueDurations(newVenue)[0];
      const recountedGuests = newVenue.maxGuests >= guests ? guests : 1;
      const recountedLanes = formatLanesCount(recountedGuests, activeVenue?.guestSplit, isOnlyOneLane);
      if (isUpdateReservation) {
        updateReservation({ slots: undefined });
        changeVenueWithReservation({
          venueId: newVenueId.toString(),
          date,
          duration,
          lanes: recountedLanes,
          guests: recountedGuests,
        });
      } else {
        changeVenue({
          venueId: newVenueId.toString(),
          date,
          duration,
          lanes: recountedLanes,
          guests: recountedGuests,
        });
      }
      setIsCalendar(false);
    }
  };
  const selectGuestsOption = (value?: number | string) => {
    if (!value) {
      return;
    }
    setErrorGuestCount("");
    const guests = Number(value);
    if (guests === MORE_THAN_11) {
      toPartyWithVenue(date);
      return;
    }
    const newDuration = activeVenue?.enableAssignDuration
      ? getVenueAssignedDuration(activeVenue, guests) || duration
      : duration;
    updateReservation({ guests, duration: newDuration });
    const lanesCount = formatLanesCount(guests, activeVenue?.guestSplit, isOnlyOneLane);
    if (isUpdateReservation) {
      updateReservation({ slots: undefined });
      changeVenueWithReservation({ duration: newDuration, lanes: lanesCount, guests });
    } else {
      changeVenue({ venueId, date, duration: newDuration, lanes: lanesCount, slots, guests });
    }
  };
  const setDuration = (duration: number) => {
    if (isUpdateReservation) {
      updateReservation({ slots: undefined });
      changeVenueWithReservation({ duration });
    } else {
      changeVenue({ venueId, date, duration, lanes, slots, guests });
    }
  };
  const selectLanesOption = (value?: number | string) => {
    const lanesCount = Number(value);
    if (isUpdateReservation) {
      updateReservation({ slots: undefined });
      changeVenueWithReservation({ lanes: lanesCount });
    } else {
      changeVenue({ venueId, date, duration, lanes: lanesCount, slots, guests });
    }
  };
  const setTimeSlot = (slot: number) => {
    updateReservation({ slots: slot });
  };
  const onChangeCheck = (_: any, isChecked?: boolean) => {
    setIsCheck(!!isChecked);
  };
  const onContinue = () => {
    if (!activeVenue) {
      return;
    }
    if (guests > activeVenue.maxGuests && activeVenue.maxGuests > 0
    ) {
      setErrorGuestCount("Please select the number of guests in your party.");
      return;
    }
    startBooking({ venue: activeVenue, date, isContinue: false });
  };
  const returnToParty = (e: React.FormEvent) => {
    e.preventDefault();
    toPartyWithVenue(date);
  };
  const returnToPackages = (e: React.FormEvent) => {
    e.preventDefault();
    toPackagesWithVenue();
  };
  const showWeekView =
    !closed && date && dayjs().add(10, "d").isAfter(dayjs(date));


  const durationButtons = activeVenue ? getDurationButtons(activeVenue) : [];
  const getDurationButtonsGroup = (durationButtons: DurationButtonType[]) => {
    if (durationButtons.length < 4) {
      if (isLessFourDurationSlots === false) {
        setIsLessFourDurationSlots(true);
      }
      return chunk(durationButtons, durationButtons.length);
    } else {
      if (isLessFourDurationSlots === true) {
        setIsLessFourDurationSlots(false);
      }
      return chunk(durationButtons, 4);
    }
  }
  const durationButtonsGroups = getDurationButtonsGroup(durationButtons);
  const disableContinueButton = closed || !venueId || guests < 1 || (show21CheckBox && !isCheck) || !activeVenue || !activeVenue.timeSlots.find(item => item.time === slots) || isNaN(reservation.slots)
  const groupTitle = `${uiConfig?.groupTitle}`.replace('%s', uiConfig?.partyTitle || '').replace('%n', (1 + (activeVenue?.maxGuests || 10)).toString())
  let isReservationDetails = false;
  if (activeVenue?.reservationDetails) {
    const text = extractInnerText(activeVenue?.reservationDetails);
    if (!!text) {
      isReservationDetails = true;
    }
  }


  const isShowLinkContainer = (activeVenue?.hasPackages) ||
    uiConfig?.isPackageReservationMode ||
    uiConfig?.isExternalBooking;
  const isShowLink = !(activeVenue?.hasPackages) &&
    !uiConfig?.isPackageReservationMode &&
    !uiConfig?.isExternalBooking;

  return (
    <>
      {(isLoadingVenues || isLoadingVenue || isLoadingAvailability) && (
        <div className="loading">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      <div className="make-reservation-container main-container" ref={top}>
        <Header handleBackButton={goBack} />
        <div className="makeReservation-inner-container main-content">
          <div
            className={`makeReservation-main-information main-content ${isMobile ? "mobile" : ""
              }`}
          >
            <div className="makeReservation-reservation">
              <div className="makeReservation-reservation-title">
                Make a Reservation
              </div>
              <div
                className={`makePartyReservation-reservation-subtitle ${contentStyles.venueName}`}
              >
                {activeVenue?.name}
              </div>
              <HTMLRenderer className="makeReservation-reservation-text" html={activeVenue?.description} />
              {isShowLinkContainer && (
                <div className={`makeReservation-reservation-link-container ${isMobile ? "mobile" : ""}`}>
                  <div className={`link-button ${isMobile ? "mobile" : ""} active ${contentStyles.venueActiveMenu}`}>
                    Reservations
                  </div>
                  <div className="link-delimiter" />
                  <div className={`link-button ${isMobile ? "mobile" : ""}`} onClick={returnToPackages}>
                    {activeVenue?.packagesTitle}
                  </div>
                  {!isUpdateReservation && (
                    <>
                      <div className="link-delimiter" />
                      <div className={`link-button ${isMobile ? "mobile" : ""}`} onClick={returnToParty}>
                        {activeVenue?.eventInquiryTitle}
                      </div>
                    </>
                  )}
                </div>
              )}
              {isShowLink && (
                <div className="makeReservation-reservation-link">
                  <a href="#" className="focusable" onClick={returnToParty}>
                    {groupTitle}
                  </a>
                </div>
              )}
              {!uiConfig?.isExternalBooking ? (
                <>
                  {isMiddleScreen ? (
                    <div className="makeReservation-reservation-selectors">
                      <div className="buttons-row">
                        <SelectWithIcon
                          placeholder=""
                          options={menuPropsLocations}
                          icon={locationIcon}
                          iconWidth={11}
                          iconHeight={16}
                          onChange={selectVenueOption}
                          value={venueId}
                        />
                        <div className="button-delimiter"></div>
                        <SelectWithIcon
                          placeholder="Guests"
                          options={menuPropsGuests}
                          icon={guestsIcon}
                          iconWidth={20}
                          iconHeight={14}
                          value={guests}
                          onChange={selectGuestsOption}
                        />
                      </div>
                      <div className="buttons-row">
                        {!(isHideNumberOfLines) && (
                          <>
                            <SelectWithIcon
                              placeholder={`Select ${actionText}s`}
                              options={lanesOptions}
                              icon={actionIcon}
                              iconWidth={18}
                              iconHeight={20}
                              value={lanes}
                              onChange={selectLanesOption}
                              disabled={closed}
                            />
                            <div className="button-delimiter"></div>
                          </>
                        )}
                        <CalendarButton
                          date={
                            date
                              ? dayjs(date).format(
                                isMobile
                                  ? CALENDAR_DATE_MOBILE_FORMAT
                                  : CALENDAR_DATE_FORMAT
                              )
                              : undefined
                          }
                          placeholder="Event Date"
                          onClick={() => setIsCalendar(!isCalendar)}
                          isCalendar={isCalendar}
                          disabled={closed}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="buttons-row">
                        <SelectWithIcon
                          placeholder=""
                          options={menuPropsLocations}
                          icon={locationIcon}
                          iconWidth={11}
                          iconHeight={16}
                          onChange={selectVenueOption}
                          value={venueId}
                        />
                        <div className="button-delimiter"></div>
                        <SelectWithIcon
                          placeholder="Guests"
                          options={menuPropsGuests}
                          icon={guestsIcon}
                          iconWidth={20}
                          iconHeight={14}
                          value={guests}
                          onChange={selectGuestsOption}
                        />
                        {!(isHideNumberOfLines) && (
                          <>
                            <div className="button-delimiter"></div>
                            <SelectWithIcon
                              placeholder={`Select ${actionText}s`}
                              options={lanesOptions}
                              icon={actionIcon}
                              iconWidth={18}
                              iconHeight={20}
                              value={lanes}
                              onChange={selectLanesOption}
                              disabled={closed}
                            />
                          </>
                        )}
                      </div>
                      <div className="buttons-row">
                        <CalendarButton
                          date={
                            date
                              ? dayjs(date).format(
                                isMobile
                                  ? CALENDAR_DATE_MOBILE_FORMAT
                                  : CALENDAR_DATE_FORMAT
                              )
                              : undefined
                          }
                          placeholder="Event Date"
                          onClick={() => setIsCalendar(!isCalendar)}
                          isCalendar={isCalendar}
                          disabled={closed}
                        />
                      </div>
                    </>
                  )}
                  {isCalendar ? (
                    <Calendar
                      date={date}
                      selectDate={selectDate}
                      availability={availability}
                      isMiddleScreen={isMiddleScreen}
                    />
                  ) : showWeekView ? (
                    <WeekDaysButton
                      date={date}
                      selectDate={setDay}
                      showCalendar={() => setIsCalendar(!isCalendar)}
                      isMaxScreen={isMaxScreen}
                      availability={availability}
                      isMobile={isMobile}
                      isMiddleScreen={isMiddleScreen}
                    />
                  ) : null}
                  {!(isHideDuration) && (
                    <>
                      <div className="how-long">How long are you playing?</div>
                      <div className="duration-buttons">
                        {durationButtonsGroups.map((durationButtonsRow, index) => (
                          <div className="durationButton-row" key={index}>
                            {durationButtonsRow.map((durationSlots, index) => (
                              <React.Fragment key={index}>
                                <DefaultButton
                                  className={`${!isLessFourDurationSlots
                                    ? "fourButtonWidth"
                                    : ""
                                    }`}
                                  styles={durationButtonStyle(
                                    duration === durationSlots.durationValue
                                  )}
                                  onClick={() => setDuration(durationSlots.durationValue)}
                                  name="durationValueSlot"
                                  disabled={closed || activeVenue?.enableAssignDuration}
                                >
                                  {durationSlots.textButton}
                                </DefaultButton>
                                {index < durationButtonsRow.length - 1 && (
                                  <div
                                    className={`button-delimiter ${isMobile ? "mobile" : ""
                                      }`}
                                  ></div>
                                )}
                              </React.Fragment>
                            ))}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  <div className={`time-buttons-title ${!isReservationDetails ? "without-details" : ""}`}>
                    Available Reservation Times
                  </div>
                  {isReservationDetails && (
                    <HTMLRenderer html={activeVenue?.reservationDetails} className="makeReservation-details-text" />
                  )}
                  <div className="time-buttons">
                    {timeButtonsGroups.map((timeButtonsRow, index) => (
                      <div className={`timeButton-row ${index === timeButtonsGroups.length - 1 ? "no-padding" : ""}`} key={index}>
                        {timeButtonsRow.map((t, index) => (
                          <React.Fragment key={t.slot}>
                            <div
                              className={`timeButton ${isLessTreeTimeSlots
                                ? "fullWidth"
                                : !isShowReservationTime
                                  ? isMiddleScreen
                                    ? isMobile
                                      ? "mobile"
                                      : "middle"
                                    : ""
                                  : isMobile
                                    ? "small"
                                    : "mobile"
                                }`}
                              key={t.slot}
                            >
                              <CompoundButton
                                className="focusableButton"
                                styles={timeButtonStyle(t.slot === slots)}
                                key={t.time}
                                onClick={() => setTimeSlot(t.slot)}
                                name="timeAndPrice"
                              >
                                {t.time}
                                <div
                                  className={`${t.slot === slots ? "selectPrice" : "price"
                                    }`}
                                >
                                  {t.price}
                                </div>
                              </CompoundButton>
                            </div>
                            {index < timeButtonsRow.length - 1 && (
                              <div className="button-delimiter"></div>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    ))}
                  </div>
                  {isMobile ? (
                    <>
                      {closed ? (
                        <div className="mobile-summary-inner">
                          <div className="no-slots">
                            <HTMLRenderer html={activeVenue?.closedMessage} />
                          </div>
                        </div>
                      ) : (
                        timeButtons.length === 0 && (
                          <div className="mobile-summary-inner">
                            <div className="no-slots">
                              <HTMLRenderer html={activeVenue?.reservationIsNotAvailableText ||
                                `There are currently no available reservations for this
                                    date. We still welcome walk-in groups for our bays on
                                    a first come, first serve basis.`} />
                            </div>
                          </div>
                        )
                      )}
                      <div className="mobile-summary">
                        <div className="mobile-summary-inner">
                          <Accordion title={activeVenue?.name || ""}>
                            <div className="location">
                              <VenueAddress venue={activeVenue} />
                              <div className="map-panel">
                                <MapImageLink venue={activeVenue} />
                              </div>
                            </div>
                          </Accordion>
                        </div>
                        <div className="mobile-summary-inner">
                          <Accordion
                            className="venue-policy"
                            title="VENUE POLICIES"
                          >
                            <Policy text={activeVenue?.venueInfo} />
                          </Accordion>
                        </div>

                        {show21CheckBox && (
                          <div className="mobile-summary-inner">
                            <CheckboxConfirm onChangeCheck={onChangeCheck} isCheck={isCheck} age={activeVenue?.ageForPolicy} />
                          </div>
                        )}
                        {isUpdateReservation && (
                          <InfoAlert
                            className="mobile-info-alert"
                            text="You are modifying an existing reservation. Based on your new selection there may be a price difference"
                          />
                        )}
                        {(errorGuestCount || requestError) && (
                          <div className="error mobile">
                            {errorGuestCount || requestError}
                          </div>
                        )}
                        <MobileFixedButton
                          onClick={onContinue}
                          disabled={disableContinueButton}
                          name="continue"
                          uiConfig={uiConfig}
                        >
                          CONTINUE
                        </MobileFixedButton>
                      </div>
                    </>
                  ) : (
                    <>
                      {closed ? (
                        <div className="no-slots">
                          <HTMLRenderer html={activeVenue?.closedMessage} />
                        </div>
                      ) : (
                        timeButtons.length === 0 && (
                          <div className="no-slots">
                            <HTMLRenderer html={activeVenue?.reservationIsNotAvailableText ||
                              `There are currently no available reservations for this
                                  date. We still welcome walk-in groups for our bays on
                                  a first come, first serve basis.`} />
                          </div>
                        )
                      )}
                      {show21CheckBox && (
                        <CheckboxConfirm onChangeCheck={onChangeCheck} isCheck={isCheck} age={activeVenue?.ageForPolicy} />
                      )}
                      {isUpdateReservation && (
                        <InfoAlert
                          className='info-alert'
                          text="You are modifying an existing reservation. Based on your new selection there may be a price difference"
                        />
                      )}
                      {(errorGuestCount || requestError) && (
                        <div className="error">{(errorGuestCount || requestError)}</div>
                      )}
                      <PrimaryButton
                        onClick={onContinue}
                        disabled={disableContinueButton}
                        name="continue"
                        uiConfig={uiConfig}
                      >
                        CONTINUE
                      </PrimaryButton>
                    </>
                  )}
                </>
              ) : (
                <ExternalBookingComponent venues={venues} activeVenue={activeVenue} searchParams={searchParams} />
              )}
            </div>

            {!isMobile && (
              uiConfig?.newDesign ? <MakeReservationSummary2 venue={activeVenue} venueNames={venues} selectVenueOption={selectVenueOption} /> :
                <>
                  <div className="panel-delimiter"></div>
                  <div className="makeReservation-summary">
                    <img
                      className="info-logo"
                      src={activeVenue?.venueImage}
                      alt="venueImage"
                    />
                    <div className="summary-inner">
                      <Accordion title={activeVenue?.name || ""} withMaxWidth>
                        <div className="location">
                          <VenueAddress venue={activeVenue} />
                          <div className="map-panel">
                            <MapImageLink venue={activeVenue} />
                          </div>
                        </div>
                      </Accordion>
                    </div>
                    <div className="summary-inner2">
                      <Accordion className="venue-policy" title="VENUE POLICIES">
                        <Policy text={activeVenue?.venueInfo} />
                      </Accordion>
                    </div>
                  </div>
                </>
            )}
          </div>
        </div>
        {uiConfig?.newDesign ? <Footer2 className={isMobile ? 'mobile-footer' : ''} /> : <Footer className={isMobile ? 'mobile-footer' : ''} />}
      </div>
    </>
  );
};

export default connector(MakeReservation);