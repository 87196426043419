import React from "react";
import { Icon } from "office-ui-fabric-react";
import CalendarIcon from "../../assets/calendar-icon.svgr";
import "./calendarButton.scss";

interface CalendarButtonProps {
  date?: string;
  onClick: () => void;
  isCalendar: boolean;
  placeholder: string;
  disabled?: boolean;
}

export const CalendarButton = ({
  date,
  onClick,
  isCalendar,
  placeholder,
  disabled,
}: CalendarButtonProps) => {
  const handler = disabled?()=>{}:onClick
  return (
    <div
      className={`calendar-button ${isCalendar ? "selected" : ""} focusable`}
      onClick={handler}
      role="button"
      tabIndex={0}
      onKeyPress={handler}
    >
      <div className="first-icon">
        <CalendarIcon viewBox="0 0 14 15" />
      </div>
      {date ? (
        <div className="label">{date}</div>
      ) : (
        <div className="placeholder">{placeholder}</div>
      )}
      <div className="last-icon">
        <Icon iconName="ChevronDown" className="chevron" />
      </div>
    </div>
  );
};
