import { Pricing, CurrencyType, DateFormat } from '../entities/enums';

export interface SettingsDto {
  id: string;
  pricing: Pricing;
  hideDuration: boolean;
  hideNumberOfLines: boolean;
  hideCreateAccount: boolean;
  subtractMultiLanes: boolean;
  showReservationTime: boolean;
  disableGuestCheckout: boolean;
  defaultDuration: number;
  timeSlotDuration: number;
  actionIcon: string;
  actionText: string;
  occasions: string;
  occasionTitle: string;
  categories: string;
  showGuestDetails: boolean;
  guestDetails: string;
  guestDetailsTitle: string;
  partyTitle: string;
  groupTitle: string;
  homeTitle: string;
  homeBackgroundImage: string;
  homeBackgroundColor: string;
  homeLogo: string;
  homeEmailLogo: string;
  homeFavicon: string;
  headerBackgroundColor: string;
  footerBackgroundColor: string;
  headerForegroundColor: string;
  footerForegroundColor: string;
  homeLink: string;
  bodyBackgroundColor: string;
  mainButtonColor: string;
  mainButtonTextColor: string;
  htmlTitle: string;
  mchAudienceId: string;
  mchApiKey: string;
  mchAccessToken: string;
  mchServerPrefix: string;
  mchIsEnable: boolean;
  tripleSeatKey: string;
  tripleSeatSecret: string;
  tripleSeatPublicKey: string;
  tripleSeatFormId: string;
  tripleSeatOwnerId: number;
  tripleSeatSiteId: number;
  tripleSeatAccountId: number;
  tripleSeatContactId: number;
  tripleSeatEventTypeId: number;
  tripleSeatLeadSourceId: number;
  tripleSeatCFLanesCount: number;
  tripleSeatReservationUrl: string;
  sfEmail: string;
  sfPassword: string;
  sfSecureToken: string;
  posApiKey: string;
  posApiAuth: string;
  posApiUrl: string;
  mailgunKey: string;
  mailgunDomain: string;
  mailgunAdminEmail: string;
  companyName: string;
  hideCompanyNameFromEmails: boolean;
  instagramUrl: string;
  facebookUrl: string;
  dynamicScripts: string;
  qsrAccessKey: string;
  qsrSecretKey: string;
  paymentUrl: string;
  sevenRoomsId: string;
  sevenRoomsSecret: string;
  seatNinjaUrl: string;
  seatNinjaApiKey: string;
  sftpUrl: string;
  sftpName: string;
  sftpPassword: string;
  sftpUseAllDataReport: boolean;
  reservationMode: string;
  currency: CurrencyType;
  scrollToTimeSlotAfterSelect: boolean;
  dynamicBodyScripts: string;
  cancellationReasons: string;
  cancellationReasonsActive: boolean;
  privacyPolicy: string;
  termsOfUse: string;
  confirmationIcon: string;
  twelveHourClockFormat: boolean;
  dateFormat: DateFormat;
  hideAllPackageTab: boolean;
  enableTwilioIntegration: boolean;
  twilioAccountId: string;
  twilioAuthToken: string;
  twilioServiceId: string;
  twilioApiKey: string;
  twilioApiSecret: string;
  twilioChatServiceId: string;
  squareAccessToken: string;
  squareRefreshToken: string;
  squareMerchantId: string;
  squareIsEnable: boolean;
  newDesign: boolean;
  stripeAccountId: string;
  stripeConnectionStatus: string;
  stripePublicKey?: string;
  stripeSecretKey?: string;
  showReportV2Page: boolean;
  confirmationPageScript?: string;
  membershipTitle: string;
  membershipDescription: string;
  noPlansAvailableMessage: string;
  howDidYouHearAboutUs: string;
}

export class UIConfigDto {
  homeTitle: string;
  homeBackgroundImage: string;
  homeBackgroundColor: string;
  homeLogo: string;
  homeEmailLogo: string;
  homeFavicon: string;
  headerBackgroundColor: string;
  footerBackgroundColor: string;
  headerForegroundColor: string;
  footerForegroundColor: string;
  homeLink: string;
  bodyBackgroundColor: string;
  mainButtonColor: string;
  mainButtonTextColor: string;
  htmlTitle: string;
  companyName: string;
  instagramUrl: string;
  facebookUrl: string;
  dynamicScripts: string;
  actionIcon: string;
  actionText: string;
  occasions: string;
  occasionTitle: string;
  showGuestDetails: boolean;
  guestDetails: string;
  guestDetailsTitle: string;
  partyTitle: string;
  groupTitle: string;
  isDemo: boolean;
  isHideDemoMessage: boolean;
  isOnboarding: boolean;
  upgradePlanUrl: string;
  hideDuration: boolean;
  hideNumberOfLines: boolean;
  hideCreateAccount: boolean;
  subtractMultiLanes: boolean;
  showReservationTime: boolean;
  disableGuestCheckout: boolean;
  defaultDuration: number;
  isPackageReservationMode: boolean;
  isExternalBooking: boolean;
  currency: CurrencyType;
  scrollToTimeSlotAfterSelect: boolean;
  dynamicBodyScripts: string;
  privacyPolicy: string;
  termsOfUse: string;
  cancellationReasons: string;
  cancellationReasonsActive: boolean;
  confirmationIcon: string;
  twelveHourClockFormat: boolean;
  dateFormat: DateFormat;
  hideAllPackageTab: boolean;
  enableTwilioIntegration: boolean;
  newDesign: boolean;
  hasMemberships: boolean;
  confirmationPageScript?: string;
  stripeAccountId: string;
  stripeConnectionStatus: string;
  stripeGlobalPublishKey: string;
  sandboxStripePublicKey: string;
  membershipTitle: string;
  membershipDescription: string;
  noPlansAvailableMessage: string;
  howDidYouHearAboutUs: string;

}

export class SettingRevenueCategoryDto {
  name: string;
  marginScore: number;
}