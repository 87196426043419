import React from "react";
import dayjs from "dayjs";
import { DefaultButton } from "office-ui-fabric-react";
import { DATE_FORMAT, MONTH_NAME_FORMAT } from "../../../../../common/constants/timedate";
import "./partyMonth.scss";

interface PartyMonthProps {
  date: string;
  selectDate: (date: string) => void;
  isMobile: boolean;
  monthCoefficient: number;
}

interface DateItem {
  date: string;
  isSelected: boolean;
  isAvailable: boolean;
  day: string;
}

const week = Array.apply(null, Array(7)).map((_, index) => ({
  week: dayjs().date(1).startOf("week").add(index, "d").format("dd"),
}));

export const PartyMonth = ({
  date,
  selectDate,
  isMobile,
  monthCoefficient,
}: PartyMonthProps) => {
  const firstWeekOfMonth = dayjs().date(1).add(monthCoefficient, "M").startOf("week");
  const monthNumber = dayjs().date(1).add(monthCoefficient, "M").format("M");
  const monthName = dayjs().date(1).add(monthCoefficient, "M").format(MONTH_NAME_FORMAT);

  const calendarMonth = () => {
    const daysOfMonth = Array.apply(null, Array(6)).map((_, indexWeek) => {
      const weeks = firstWeekOfMonth.add(indexWeek, "w");
      const weekOfMonth = Array.apply(null, Array(7)).map((_, indexDay) => {
        const days = weeks.add(indexDay, "d");
        const dayDate = days.format(DATE_FORMAT);
        const isSelected = dayDate === date;

        return {
          date: dayDate,
          isSelected,
          isAvailable: true,
          day: `${
              days.format("M") === monthNumber
              ? days.format("D")
              : " "
          }`,
        };
      });
      return weekOfMonth;
    });
    return daysOfMonth;
  };
  const month = calendarMonth();

  const showDays = (data: DateItem) => {
    const { day, date, isAvailable, isSelected } = data;
    if (day == " ") {
      return (
        <div
          key={date}
          className={`days hide ${isMobile ? "mobile" : ""}`}
        ></div>
      );
    }
    if (date < dayjs().format(DATE_FORMAT)) {
      return (
        <div key={date} className={`inactiveDays ${isMobile ? "mobile" : ""}`}>
          {day}
        </div>
      );
    } else {
      return (
        <DefaultButton
          className={`days ${isSelected ? "daySelected" : ""} ${
            isMobile ? "mobile" : ""
          } ${isAvailable ? "" : "unavailable"} focusableRoundButton`}
          key={date}
          onClick={() => (isAvailable ? selectDate(date) : undefined)}
          disabled={!isAvailable}
        >
          {day}
        </DefaultButton>
      );
    }
  };

  return (
    <div className="month">
      {!isMobile && (
        <div className="monthName">
          {monthName}
        </div>
      )}
      <div className="week">
        {week.map((week) => (
          <div
            className={`daysOfWeek ${isMobile ? "mobile" : ""}`}
            key={week.week}
          >
            {week.week}
          </div>
        ))}
      </div>
      <div className="week-row">
        {month.map((week, index) => (
          <div className="daysOfMonth" key={index}>
            {week.map((day) => showDays(day))}
          </div>
        ))}
      </div>
    </div>
  );
};
