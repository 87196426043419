import { Box, BoxProps, Typography } from '@material-ui/core'
import React from 'react'
import { useViewport } from '../../../hooks/responsive'
import DefaultContainer2 from '../../common/DefaultContainer2/DefaultContainer2'

interface Props extends BoxProps {
    children?: React.ReactNode
}

const ExpressCheckout2 = ({ children, ...props }: Props) => {
    const { isMobile } = useViewport()
    return (
        <DefaultContainer2 isMobile={isMobile}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={'16px'}>
                <Typography className={isMobile ? 'heading-h1' : 'heading-h5'}>Express Checkout</Typography>
            </Box>
            <Box {...props}>
                {children}
            </Box>
        </DefaultContainer2>

    )
}

export default ExpressCheckout2