import { Box, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import HTMLRenderer from "../../../../../common/components/HTMLRenderer/HTMLRenderer";
import { DATE_FORMAT } from "../../../../../common/constants/timedate";
import { activeCustomFieldsToUIModel, formatOccasionsAndGuestDetails } from "../../../../../common/utils/formats";
import { isValidPhone } from "../../../../../common/utils/validate";
import { DiningOptions, GameType, GameTypeForStadium, OccasionOptions } from "../../../../../server/src/entities/enums";
import { cleanIsShowVerificationCodeModalAction, confirmVerificationCodeAction, getVerificationCodeMessageAction } from "../../../actions/auth-actions";
import {
    confirmReservationAction,
    updateConfirmationAction,
} from "../../../actions/reservation-actions";
import { backToReservationAction } from "../../../actions/venue-actions";
import { useViewport } from "../../../hooks/responsive";
import OrderSummaryWrapper from "../../../providers/OrderSummaryWrapper";
import { selectAuthError, selectClient, selectGuest, selectIsShowVerificationCodeModal, selectVerified } from "../../../reducers/auth-reducer";
import {
    selectGiftCardAmount,
    selectIsRequestInProgress,
    selectIsUpdateReservation,
    selectOldReservation,
    selectRequestError,
    selectReservation,
    selectReservationAddons,
    selectReservationConfirmation,
} from "../../../reducers/reservation";
import { selectUIConfig } from "../../../reducers/ui-reducer";
import { selectCurrentPackage, selectVenue } from "../../../reducers/venues";
import { CustomFieldsType, PizzaType, PlayerDetailsForSteltronicInfo, State, customFields } from "../../../store/types";
import AccountReservationInfoModal from '../../Account/Reservations/AccountReservationInfoModal';
import MobileFixedButton from "../../MobileFixedButton/MobileFixedButton";
import NoAvailiableGuests from '../../PackageCard/NoAvailiableGuests';
import ButtonMain from '../../common/ButtonMain/ButtonMain';
import DefaultContainer2 from '../../common/DefaultContainer2/DefaultContainer2';
import DatePicker2 from "../../common/Input2/DatePicker";
import Input2 from '../../common/Input2/Input2';
import PhoneInput2 from '../../common/Input2/PhoneInput2';
import TextArea2 from '../../common/Input2/TextArea2';
import MobileAttachedButton from '../../common/MobileAttachedButton/MobileAttachedButton';
import SelectWithIcon2 from '../../common/SelectWithIcon/SelectWithIcon2';
import VerificationCodeModal from "../VerificationCodeModal/VerificationCodeModal";
import "./reservation.scss";

const pizzaOptions = [
    {
        text: PizzaType.cheesePizza,
        key: PizzaType.cheesePizza,
    },
    {
        text: PizzaType.pepperoniPizza,
        key: PizzaType.pepperoniPizza,
    },
    {
        text: PizzaType.cheeseAndPepperoniPizza,
        key: PizzaType.cheeseAndPepperoniPizza,
    },
];

const gameOptions = (Object.keys(GameType) as Array<keyof typeof GameType>).map(key => ({
    text: GameType[key],
    key,
}));

const gameOptionsForStadium = (Object.keys(GameTypeForStadium) as Array<keyof typeof GameTypeForStadium>).map(key => ({
    text: GameTypeForStadium[key],
    key,
}));

const diningOptions = (Object.keys(DiningOptions) as Array<keyof typeof DiningOptions>).map(key => ({
    text: DiningOptions[key],
    key,
}));

const stringToObjectValue = (s: string, packageKeys: string[]): { [key: string]: string | null } => {
    if (!s) {
        return packageKeys.reduce((acc, key) => ({ ...acc, [key]: null }), {})
    }
    return s.split(';').map(pair => {
        let [key, v] = pair.split(/:(.*)/s)
        if (customFields[key].type === CustomFieldsType.Date && !v) {
            return { [key]: null }
        } else if (customFields[key].type === CustomFieldsType.String && !v) {
            return { [key]: '' }
        } else if (customFields[key].type === CustomFieldsType.Number && !v) {
            return { [key]: null }
        }
        return { [key]: v }
    }).reduce((acc: { [key: string]: string | null }, item) => ({ ...acc, ...item }), {})
}

const objectValueToString = (o: { [key: string]: string | null }): string => {
    return Object.keys(o).map(key => `${key}:${o[key] ? o[key] : ''}`).join(';')
}

const mapDispatchToProps = {
    backToReservation: backToReservationAction,
    confirmReservation: confirmReservationAction,
    updateConfirmation: updateConfirmationAction,
    cleanIsShowVerificationCodeModal: cleanIsShowVerificationCodeModalAction,
    getVerificationCodeMessage: getVerificationCodeMessageAction,
    confirmVerificationCode: confirmVerificationCodeAction,
};

const mapStateToProps = (state: State) => ({
    reservation: selectReservation(state),
    client: selectClient(state),
    guest: selectGuest(state),
    venue: selectVenue(state),
    confirmation: selectReservationConfirmation(state),
    uiConfig: selectUIConfig(state),
    giftCardAmount: selectGiftCardAmount(state),
    currentPackage: selectCurrentPackage(state),
    isUpdateReservation: selectIsUpdateReservation(state),
    oldReservation: selectOldReservation(state),
    reservationAddons: selectReservationAddons(state),
    isRequestInProgress: selectIsRequestInProgress(state),
    requestError: selectRequestError(state),
    validationError: selectAuthError(state),
    isShowVerificationCodeModal: selectIsShowVerificationCodeModal(state),
    verified: selectVerified(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const Reservation2 = ({
    client,
    guest,
    reservation,
    confirmReservation,
    venue,
    backToReservation,
    updateConfirmation,
    confirmation,
    uiConfig,
    giftCardAmount,
    currentPackage,
    isUpdateReservation,
    oldReservation,
    reservationAddons,
    isRequestInProgress,
    requestError,
    validationError,
    isShowVerificationCodeModal,
    verified,
    cleanIsShowVerificationCodeModal,
    getVerificationCodeMessage,
    confirmVerificationCode,
}: Props) => {
    useEffect(() => {
        cleanIsShowVerificationCodeModal();
    }, []);
    const [isOrderSummaryModalOpen, setIsOrderSummaryModalOpen] = useState(false);
    const isHideDuration = uiConfig?.hideDuration || currentPackage?.hidePackageDuration;
    const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;
    const { isMobile, isTabletScreen } = useViewport();
    const [errorFirstName, setErrorFirstName] = useState<string>("");
    const [errorLastName, setErrorLastName] = useState<string>("");
    const [errorPhone, setErrorPhone] = useState<string>("");
    const [errorCustomNotes, setErrorCustomNotes] = useState<string>("");
    const { firstName, lastName, phone, occasion, customNotes, guestDetail, customFieldsInformation } = confirmation;
    const isShowGuestDetails = uiConfig?.showGuestDetails;
    const guestDetailsTitle = uiConfig?.guestDetailsTitle;
    const guestDetails = formatOccasionsAndGuestDetails(uiConfig?.guestDetails);
    const occasionOptions = formatOccasionsAndGuestDetails(uiConfig?.occasions);
    const [isOpenVerificationModal, setIsOpenVerificationModal] = useState<boolean>(false);
    const [isExpandOrderSummary, setIsExpandOrderSummary] = useState<boolean>(false);
    const howDidYouHearAboutUsOptions = uiConfig?.howDidYouHearAboutUs ? uiConfig.howDidYouHearAboutUs.split(',') : [];
    const defaultTemplateCoumns = isMobile ? '1fr' : '1fr 1fr'

    useEffect(() => {
        if (!firstName || !lastName || !phone || !customNotes) {
            updateConfirmation({
                firstName: firstName || client?.firstName,
                lastName: lastName || client?.lastName,
                phone: phone || client?.phone,
                customNotes: customNotes || venue?.customNotesPrefilledText,
            });
        }
    }, [client]);
    useEffect(() => {
        if (isShowVerificationCodeModal) {
            setIsOpenVerificationModal(true);
        }
    }, [isShowVerificationCodeModal]);

    const changeFirstName = (firstName?: string) => {
        updateConfirmation({ firstName });
    };
    const changeLastName = (lastName?: string) => {
        updateConfirmation({ lastName });
    };
    const changePhone = (phone?: string) => {
        updateConfirmation({ phone });
    };
    const changeCustomNotes = (customNotes?: string) => {
        updateConfirmation({ customNotes });
    };
    const changeReservationGuestDetail = (detailsArray: string[], indexGuest: number, newGuestDetail?: string) => {
        detailsArray[indexGuest] = newGuestDetail ? newGuestDetail : '';
        const detailsString = detailsArray.join(';');
        updateConfirmation({ guestDetail: detailsString });
    };
    let activeCustomFields = !currentPackage && venue?.activeCustomFields
        ? activeCustomFieldsToUIModel(venue.activeCustomFields)
        : currentPackage && currentPackage?.activeCustomFields
            ? activeCustomFieldsToUIModel(currentPackage?.activeCustomFields)
            : [];
    let mandatoryCustomFields = !currentPackage && venue?.mandatoryCustomFields
        ? activeCustomFieldsToUIModel(venue.mandatoryCustomFields)
        : currentPackage && currentPackage?.mandatoryCustomFields
            ? activeCustomFieldsToUIModel(currentPackage?.mandatoryCustomFields)
            : [];
    const adminOnlyCustomFields = !currentPackage
        ? activeCustomFieldsToUIModel('')
        : currentPackage && currentPackage?.adminOnlyCustomFields
            ? activeCustomFieldsToUIModel(currentPackage?.adminOnlyCustomFields)
            : [];

    // Remove admin only custom fields
    activeCustomFields = activeCustomFields.filter(field => !adminOnlyCustomFields.includes(field));
    mandatoryCustomFields = mandatoryCustomFields.filter(field => !adminOnlyCustomFields.includes(field));


    const [customFieldsObject, setCustomFieldObject] = useState(stringToObjectValue(customFieldsInformation || '', activeCustomFields));
    const [errorCustomFields, setErrorCustomFields] = useState<string[]>(new Array(activeCustomFields.length).fill(''));
    useEffect(() => {
        if (customFieldsObject["playerDetailsForSteltronic"] && JSON.parse(customFieldsObject["playerDetailsForSteltronic"]).length !== reservation.guests) {
            console.log('-init ', customFieldsObject["playerDetailsForSteltronic"], reservation.guests)
            // const detailsArray = JSON.parse(customFieldsObject['playerDetailsForSteltronic']);
            // const newPlayerDetailsJSON = JSON.stringify(detailsArray);
            // changeReservationCustomFieldsInformation("playerDetailsForSteltronic", newPlayerDetailsJSON)
        }
        if (customFieldsObject["playerDetailsForTripleSeat"] && customFieldsObject["playerDetailsForTripleSeat"].split(',').length !== reservation.guests) {
            console.log('-playerDetailsForTripleSeat ', customFieldsObject["playerDetailsForTripleSeat"], reservation.guests)
            // const newPlayerDetailsJSON = playerDetailsArray.join(',');
            // changeReservationCustomFieldsInformation("playerDetailsForTripleSeat", newPlayerDetailsJSON)
        }

    }, [reservation.guests]);

    const getSteltronicDetails = useCallback(
        (type: CustomFieldsType) => {
            return Array.apply(null, Array(reservation.guests)).map(
                (_, indexGuest) => {
                    const playerDetails = customFieldsObject[type];
                    if (playerDetails) {
                        const playerDetailsForSteltronicInformation: PlayerDetailsForSteltronicInfo[] =
                            JSON.parse(playerDetails);
                        return (
                            playerDetailsForSteltronicInformation[indexGuest] || {
                                index: indexGuest + 1,
                                name: "",
                                shoeSize: "",
                                bumper: false,
                            }
                        );
                    } else {
                        return {
                            index: indexGuest + 1,
                            name: "",
                            shoeSize: "",
                            bumper: false,
                        };
                    }
                }
            );
        },
        [reservation.guests, customFieldsObject]
    );

    const changeReservationCustomFieldsInformation = (fieldKey: string, newInformation: string) => {
        const clearError = errorCustomFields.fill('');
        setErrorCustomFields(clearError)
        const newCustomFieldsObject = { ...customFieldsObject, [fieldKey]: newInformation }
        setCustomFieldObject(newCustomFieldsObject)
        updateConfirmation({ customFieldsInformation: objectValueToString(newCustomFieldsObject) });
    };

    const changeReservationPlayersInformation = ({ playerDetails, index, newName, newShoeSize, isNewBumper, customFieldType }: { playerDetails: PlayerDetailsForSteltronicInfo[], index: number, newName?: string, newShoeSize?: string, isNewBumper?: boolean, customFieldType: CustomFieldsType }) => {
        let newPlayerDetails = playerDetails;
        if (newName !== undefined) {
            newPlayerDetails[index].name = newName;
        } else if (newShoeSize !== undefined) {
            newPlayerDetails[index].shoeSize = newShoeSize;
        } else {
            newPlayerDetails[index].bumper = !!isNewBumper;
        }
        const newPlayerDetailsJSON = JSON.stringify(newPlayerDetails);
        changeReservationCustomFieldsInformation(customFieldType, newPlayerDetailsJSON);
    };

    const changeReservationPlayerDetailsInformationForTs = ({ playerDetailsForTs, index, newDetails }: { playerDetailsForTs: string[], index: number, newDetails?: string }) => {
        let newPlayerDetails = playerDetailsForTs;
        if (newDetails !== undefined) {
            newPlayerDetails[index] = newDetails;
        }
        const newPlayerDetailsJSON = newPlayerDetails.join(',');
        changeReservationCustomFieldsInformation("playerDetailsForTripleSeat", newPlayerDetailsJSON);
    };

    const changeReservationGuestNamesInformation = ({ guestNames, index, newName }: { guestNames: string[], index: number, newName?: string }) => {
        const newGuestNames = guestNames;
        if (newName !== undefined) {
            newGuestNames[index] = newName;
        }
        const newGuestNamesJSON = newGuestNames.join(',');
        changeReservationCustomFieldsInformation("guestNames", newGuestNamesJSON);
    };

    const isUserValidationRequired = !!venue?.enableTwilioPhoneVerification && (!!guest || (!!client && (!client.verified || client.phone !== phone)));
    const isShowVerification = isUserValidationRequired && !verified;

    const onVerificationCodeGetting = (phone: string) => {
        setErrorPhone("");
        if (!phone || !isValidPhone(phone)) {
            setErrorPhone("Please enter a valid phone number.");
            return;
        }
        getVerificationCodeMessage(phone, venue?.id);
    };

    const hideVerificationCodeModal = () => {
        cleanIsShowVerificationCodeModal();
        setIsOpenVerificationModal(false);
    }

    const onConfirmValidationCode = (code: string) => {
        if (phone) {
            cleanIsShowVerificationCodeModal();
            setIsOpenVerificationModal(false);
            const isUpdateClient = !!client && client.phone === phone;
            confirmVerificationCode(phone, code, isUpdateClient, venue?.id);
        }
    }
    const isErrorGeneral = errorFirstName || errorLastName || errorPhone || errorCustomNotes || errorCustomFields.some(error => error);
    const onContinue = () => {
        if (!firstName) {
            setErrorFirstName("This field is required.");
        }
        if (!lastName) {
            setErrorLastName("This field is required.");
        }
        if (!phone || !isValidPhone(phone)) {
            setErrorPhone("Please enter a valid phone number.");
        }
        if (venue?.enableCustomNotes && venue?.enableRequiredCustomNotes && !customNotes) {
            setErrorCustomNotes("This field is required.")
        }

        for (let mandatoryCustomField of mandatoryCustomFields) {
            if (adminOnlyCustomFields.includes(mandatoryCustomField)) continue;
            const isActiveCustomField = activeCustomFields.some(field => field === mandatoryCustomField);
            if (['playerDetailsForSteltronic', 'playerDetailsNoShoesForSteltronic', 'playerDetailsNoBumpersForSteltronic', 'playerDetailsNoShoesNoBumpersSteltronic'].includes(mandatoryCustomField)) {
                if (!customFieldsObject[mandatoryCustomField] && isActiveCustomField) {
                    const errorText = "All field is required."
                    const errorIndex = activeCustomFields.indexOf(mandatoryCustomField);
                    const newError = errorCustomFields.map((error, index) => errorIndex === index ? errorText : error)
                    setErrorCustomFields(newError)
                    return;
                }
                if (customFieldsObject[mandatoryCustomField]) {
                    let isError = false;
                    const detailsArray = JSON.parse(customFieldsObject[mandatoryCustomField] as string);
                    detailsArray.forEach((item: PlayerDetailsForSteltronicInfo) => {
                        if (!item.name) {
                            isError = true;

                        }
                    });
                    if ((isError || detailsArray.length !== reservation.guests) && isActiveCustomField) {
                        const errorText = "All field is required."
                        const errorIndex = activeCustomFields.indexOf(mandatoryCustomField);
                        const newError = errorCustomFields.map((error, index) => errorIndex === index ? errorText : error)
                        setErrorCustomFields(newError)
                        return;
                    }
                }
            } else if (mandatoryCustomField === 'playerDetailsForTripleSeat') {
                if (!customFieldsObject['playerDetailsForTripleSeat'] && isActiveCustomField) {
                    const errorText = "All field is required."
                    const errorIndex = activeCustomFields.indexOf(mandatoryCustomField);
                    const newError = errorCustomFields.map((error, index) => errorIndex === index ? errorText : error)
                    setErrorCustomFields(newError)
                    return;
                }
                if (customFieldsObject['playerDetailsForTripleSeat']) {
                    let isError = false;
                    const detailsArray = customFieldsObject['playerDetailsForTripleSeat'].split(',');
                    detailsArray.forEach((item: string) => {
                        if (!item) {
                            isError = true;
                        }
                    });
                    if ((isError || detailsArray.length !== reservation.guests) && isActiveCustomField) {
                        const errorText = "All field is required."
                        const errorIndex = activeCustomFields.indexOf(mandatoryCustomField);
                        const newError = errorCustomFields.map((error, index) => errorIndex === index ? errorText : error)
                        setErrorCustomFields(newError)
                        return;
                    }
                }
            } else if (mandatoryCustomField === 'guestNames') {
                if (!customFieldsObject['guestNames'] && isActiveCustomField) {
                    const errorText = "All field is required."
                    const errorIndex = activeCustomFields.indexOf(mandatoryCustomField);
                    const newError = errorCustomFields.map((error, index) => errorIndex === index ? errorText : error);
                    setErrorCustomFields(newError);
                    return;
                }
                if (customFieldsObject['guestNames']) {
                    let isError = false;
                    const namesArray = customFieldsObject['guestNames'].split(',');
                    namesArray.forEach((item: string) => {
                        if (!item) {
                            isError = true;
                        }
                    });
                    if ((isError || namesArray.length !== reservation.guests) && isActiveCustomField) {
                        const errorText = "All field is required.";
                        const errorIndex = activeCustomFields.indexOf(mandatoryCustomField);
                        const newError = errorCustomFields.map((error, index) => errorIndex === index ? errorText : error);
                        setErrorCustomFields(newError);
                        return;
                    }
                }
            } else {
                if (!customFieldsObject[mandatoryCustomField] && isActiveCustomField) {
                    const errorText = "This field is required."
                    const errorIndex = activeCustomFields.indexOf(mandatoryCustomField);
                    const newError = errorCustomFields.map((error, index) => errorIndex === index ? errorText : error)
                    setErrorCustomFields(newError)
                    return;
                }
            }
        }

        if (!!customFieldsObject["guestOfHonorBirthdate"] && !dayjs(customFieldsObject["guestOfHonorBirthdate"], DATE_FORMAT).isValid()) {
            const errorText = "Invalid date."
            const errorIndex = activeCustomFields.indexOf("guestOfHonorBirthdate");
            const newError = errorCustomFields.map((error, index) => errorIndex === index ? errorText : error)
            setErrorCustomFields(newError)
            return;
        }
        if (firstName &&
            lastName &&
            phone &&
            isValidPhone(phone) &&
            ((venue?.enableRequiredCustomNotes && customNotes) ||
                !venue?.enableRequiredCustomNotes ||
                !venue?.enableCustomNotes
            )
        ) {
            const email = client ? client.email : guest?.email;
            if (email !== confirmation.email || !confirmation.email) {
                updateConfirmation({ email });
            }
            if (isShowVerification) {
                onVerificationCodeGetting(phone);
            } else {
                confirmReservation(true);
            }
            return;
        }
    };
    const onFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setErrorFirstName("");
        changeFirstName(e.target.value);
    };
    const onLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setErrorLastName("");
        changeLastName(e.target.value);
    };
    const onPhoneChange = (text?: string) => {
        setErrorPhone("");
        changePhone(text);
    };
    const onCustomNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setErrorCustomNotes("");
        changeCustomNotes(e.target.value);
    };

    if (!reservation) {
        return null; //it should not happen
    }

    const guestDetailsList = Array.apply(null, Array(reservation.guests)).map((_, indexGuest) => {
        const detailsArray = guestDetail ? guestDetail.split(';') : new Array(reservation.guests);
        return isMobile ? (
            <>
                <SelectWithIcon2
                    truncateLabel
                    placeholder="Please select"
                    key={indexGuest}
                    label={`Guest ${indexGuest + 1}`}
                    options={guestDetails.map(option => option.text)}
                    onChange={(e) => changeReservationGuestDetail(detailsArray, indexGuest, guestDetails.find(detail => detail.text === e.target.value)?.key?.toString())}
                    value={detailsArray[indexGuest]}
                />
            </>
        ) : (
            <div className="guest-row" key={indexGuest}>
                <>
                    <SelectWithIcon2
                        truncateLabel
                        placeholder="Please select"
                        label={`Guest ${indexGuest + 1}`}
                        options={guestDetails.map(option => option.text)}
                        onChange={(e) => changeReservationGuestDetail(detailsArray, indexGuest, guestDetails.find(detail => detail.text === e.target.value)?.key?.toString())}
                        value={detailsArray[indexGuest]}
                    />
                </>
            </div>
        )
    });



    const isActiveCustomFields = activeCustomFields.length > 0;
    const showGuestDetails = activeCustomFields.some((customField) => {
        const customFieldType = customFields[customField]?.type;
        return (customFieldType === CustomFieldsType.String && customField !== 'address' && customField !== 'groupName' && customField !== "guestOfHonorName" && customField !== "howDidYouHearAboutUs")
            || customFieldType === CustomFieldsType.GuestNames
            || customFieldType === CustomFieldsType.PlayerDetailsForSteltronic
            || customFieldType === CustomFieldsType.PlayerDetailsNoShoesForSteltronic
            || customFieldType === CustomFieldsType.PlayerDetailsNoBumpersForSteltronic
            || customFieldType === CustomFieldsType.PlayerDetailsNoShoesNoBumpersSteltronic
            || customFieldType === CustomFieldsType.PlayerDetailsForTripleSeat
            || customFieldType === CustomFieldsType.Birthday
    })
    const showAdditionalInfo = activeCustomFields.some((customField) => {
        const customFieldType = customFields[customField]?.type;
        return (customFieldType === CustomFieldsType.Selector
            || customFieldType === CustomFieldsType.Date
            || customFieldType === CustomFieldsType.GameSelectionForStadium
            || customFieldType === CustomFieldsType.GameSelection
            || customFieldType === CustomFieldsType.DiningSelection
            || (customFieldType === CustomFieldsType.String && (customField === 'groupName' || customField === 'guestOfHonorName' || customField === 'howDidYouHearAboutUs'))
            || customFieldType === CustomFieldsType.Number
            || customFieldType === CustomFieldsType.Occasion)

    })
    const activeCustomFieldsListAdditionalInfo = activeCustomFields.map((customField, index) => {
        const isMandatory = mandatoryCustomFields.includes(customField);
        const customFieldValue = customFieldsObject[customField];
        if (!customFields[customField]) {
            return null;
        }
        const customFieldType = customFields[customField].type;
        const customFieldLabel = customFields[customField].text;
        const customFieldName = customFields[customField].fieldsName;


        const guestNamesArray = Array.apply(null, Array(reservation.guests)).map((_, indexGuest) => {
            if (customFieldsObject["guestNames"]) {
                const guestNamesInformation: string[] = customFieldsObject["guestNames"].split(',');
                if (guestNamesInformation.length !== reservation.guests) {
                    return guestNamesInformation[indexGuest] || '';
                }
                return guestNamesInformation[indexGuest];
            } else {
                return '';
            }
        });
        if (customFieldsObject["guestNames"] && customFieldsObject["guestNames"].split(',').length !== reservation.guests) {
            const newGuestNamesJSON = guestNamesArray.join(',');
            changeReservationCustomFieldsInformation("guestNames", newGuestNamesJSON);
        }


        return (
            <>
                {customFieldType === CustomFieldsType.Selector || customFieldType === CustomFieldsType.Date || (customFieldType === CustomFieldsType.String && (customField === 'groupName' || customField === 'guestOfHonorName' || customField === 'zipCode')) || customFieldType === CustomFieldsType.Number ?
                    <Box className={`custom-field ${isMobile ? "mobile" : ""}`} key={customField} data-testid={customField}>
                        {customFieldType === CustomFieldsType.String && (customField === 'groupName' || customField === 'guestOfHonorName' || customField === 'zipCode') && (
                            <Input2
                                label={customFieldLabel}
                                name={customFieldName}
                                value={customFieldValue || ""}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeReservationCustomFieldsInformation(customField, e.target.value || "")}
                                autoComplete={"off"}
                                errorInput={errorCustomFields[index]}
                                mandatory={isMandatory}

                            />
                        )}
                        {customFieldType === CustomFieldsType.Number && (
                            <Input2
                                label={customFieldLabel}
                                name={customFieldName}
                                value={customFieldValue?.toString() || ""}
                                type='number'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeReservationCustomFieldsInformation(customField, e.target.value?.toString() || "")}
                                autoComplete={"off"}
                                errorInput={errorCustomFields[index]}
                                mandatory={isMandatory}

                            />
                        )}
                        {customFieldType === CustomFieldsType.Date && (
                            <DatePicker2
                                autoOk
                                label={customFieldLabel}
                                name={customFieldName}
                                value={customFieldValue}
                                placeholder={uiConfig?.dateFormat || "MM/DD/YYYY"}
                                onChange={(data: any) => changeReservationCustomFieldsInformation(customField, data ? dayjs(data).format(DATE_FORMAT) : "")}
                                format={uiConfig?.dateFormat || "MM/DD/YYYY"}
                                errorInput={errorCustomFields[index]}
                                mandatory={isMandatory}
                            />
                        )}
                        {customFieldType === CustomFieldsType.Selector && (
                            <Box>
                                <SelectWithIcon2
                                    truncateLabel
                                    placeholder=''
                                    label={customFieldLabel}
                                    options={pizzaOptions.map(option => option.text)}
                                    onChange={(e) => changeReservationCustomFieldsInformation(customField, pizzaOptions.find(option => option.text === e.target.value)?.key as PizzaType)}
                                    value={customFieldValue || ''}
                                    mandatory={isMandatory}
                                    errorSelect={errorCustomFields[index]}
                                />
                            </Box>
                        )}

                    </Box> : null}
                {customFieldType === CustomFieldsType.GameSelection && (
                    <Box data-testid={customField}>
                        <SelectWithIcon2
                            truncateLabel
                            placeholder="Please select"
                            label={customFieldLabel}
                            options={gameOptions.map(option => option.text)}
                            onChange={(e) => changeReservationCustomFieldsInformation(customField, gameOptions.find(option => option.text === e.target.value)?.key as GameType)}
                            value={customFieldValue || ''}
                            mandatory={isMandatory}
                            errorSelect={errorCustomFields[index]}

                        />
                    </Box>
                )}
                {customFieldType === CustomFieldsType.GameSelectionForStadium && (
                    <Box data-testid={customField}>

                        <SelectWithIcon2
                            truncateLabel
                            placeholder="Please select"
                            label={customFieldLabel}
                            options={gameOptionsForStadium.map(option => option.text)}
                            onChange={(e) => changeReservationCustomFieldsInformation(customField, gameOptionsForStadium.find(option => option.text === e.target.value)?.key as GameTypeForStadium)}
                            value={customFieldValue || ''}
                            mandatory={isMandatory}
                            errorSelect={errorCustomFields[index]}
                        />
                    </Box>
                )}
                {customFieldType === CustomFieldsType.DiningSelection && (
                    <Box data-testid={customField}>

                        <SelectWithIcon2
                            truncateLabel
                            placeholder="Please select"
                            label={customFieldLabel}
                            options={diningOptions.map(option => option.text)}
                            onChange={(e) => changeReservationCustomFieldsInformation(customField, diningOptions.find(option => option.text === e.target.value)?.key as DiningOptions)}
                            value={customFieldValue || ''}
                            mandatory={isMandatory}
                            errorSelect={errorCustomFields[index]}
                        />
                    </Box>
                )}
                {customFieldType === CustomFieldsType.Occasion && (
                    <Box>

                        <SelectWithIcon2
                            truncateLabel
                            placeholder="Please select"
                            label={customFieldLabel}
                            options={occasionOptions.map(option => option.text)}
                            onChange={(e) => changeReservationCustomFieldsInformation(customField, occasionOptions.find(option => option.text === e.target.value)?.key as OccasionOptions)}
                            value={customFieldValue || ''}
                            mandatory={isMandatory}
                            errorSelect={errorCustomFields[index]}
                        />
                    </Box>
                )}
                {customField === 'howDidYouHearAboutUs' && customFieldType === CustomFieldsType.String && (
                    <Box>
                        <SelectWithIcon2
                            truncateLabel
                            placeholder='Please select'
                            label={customFieldLabel}
                            options={howDidYouHearAboutUsOptions}
                            onChange={(e) => changeReservationCustomFieldsInformation(customField, e.target.value as string || "")}
                            value={customFieldValue || ''}
                            mandatory={isMandatory}
                            errorSelect={errorCustomFields[index]}
                        />
                    </Box>
                )}
            </>
        )
    });
    const activeCustomFieldsListGuestDetails = activeCustomFields.map((customField, index) => {
        const customFieldValue = customFieldsObject[customField];
        if (!customFields[customField] || customField === 'howDidYouHearAboutUs') {
            return null;
        }
        const customFieldType = customFields[customField].type;
        const customFieldLabel = customFields[customField].text;
        const customFieldName = customFields[customField].fieldsName;

        const isMandatory = mandatoryCustomFields.includes(customField);

        const steltronicDetails: Partial<Record<CustomFieldsType, PlayerDetailsForSteltronicInfo[]>> = {
            playerDetailsForSteltronic: getSteltronicDetails(CustomFieldsType.PlayerDetailsForSteltronic),
            playerDetailsNoShoesForSteltronic: getSteltronicDetails(CustomFieldsType.PlayerDetailsNoShoesForSteltronic),
            playerDetailsNoBumpersForSteltronic: getSteltronicDetails(CustomFieldsType.PlayerDetailsNoBumpersForSteltronic),
            playerDetailsNoShoesNoBumpersSteltronic: getSteltronicDetails(CustomFieldsType.PlayerDetailsNoShoesNoBumpersSteltronic),
        };

        let playerDetailsList;
        let playerDetailsListOneRow;
        const isSteltronicType = [CustomFieldsType.PlayerDetailsForSteltronic, CustomFieldsType.PlayerDetailsNoShoesForSteltronic, CustomFieldsType.PlayerDetailsNoBumpersForSteltronic, CustomFieldsType.PlayerDetailsNoShoesNoBumpersSteltronic].includes(customFieldType);
        const withoutBumpers = [CustomFieldsType.PlayerDetailsNoBumpersForSteltronic, CustomFieldsType.PlayerDetailsNoShoesNoBumpersSteltronic].includes(customFieldType);

        if (isSteltronicType) {
            const playerDetails = steltronicDetails[customFieldType] || [];
            playerDetailsList = Array.apply(null, Array(reservation.guests)).map((_, indexGuest) => {
                return [CustomFieldsType.PlayerDetailsForSteltronic, CustomFieldsType.PlayerDetailsNoBumpersForSteltronic].includes(customFieldType) ? <Box key={`${customField} ${indexGuest}`} mb={withoutBumpers ? '16px' : 0} >
                    <Box display={'grid'} gridTemplateColumns={(isMobile || ![CustomFieldsType.PlayerDetailsForSteltronic, CustomFieldsType.PlayerDetailsNoBumpersForSteltronic].includes(customFieldType)) ? '1fr' : '1fr 1fr'} gridGap={'16px'} >
                        <Input2
                            className="m1"
                            label={`Player ${playerDetails[indexGuest].index} Name`}
                            name={`playerName ${indexGuest}`}
                            data-testid={`playerName ${indexGuest}`}
                            value={playerDetails[indexGuest].name || ""}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeReservationPlayersInformation({ playerDetails, index: indexGuest, newName: e.target.value || "", customFieldType })}
                            autoComplete={"off"}
                            fullWidth
                            errorInput={errorCustomFields[index]}
                            mandatory={isMandatory}

                        />
                        {[CustomFieldsType.PlayerDetailsForSteltronic, CustomFieldsType.PlayerDetailsNoBumpersForSteltronic].includes(customFieldType) && <Box className="custom-field-row" >
                            <Input2
                                label='Shoe Size'
                                name={`shoeSize ${indexGuest}`}
                                data-testid={`shoeSize ${indexGuest}`}
                                type='number'
                                fullWidth
                                value={playerDetails[indexGuest].shoeSize || ""}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeReservationPlayersInformation({ playerDetails, index: indexGuest, newShoeSize: e.target.value || "", customFieldType })}
                                autoComplete={"off"}
                                mandatory={isMandatory}

                            />
                        </Box>}
                    </Box>
                    {[CustomFieldsType.PlayerDetailsForSteltronic].includes(customFieldType) && (
                        <Box className="custom-field-row" mt={'9px'} mb={'20px'}>
                            <FormControlLabel
                                style={{
                                    alignItems: 'center'
                                }}
                                control={
                                    <Checkbox
                                        checked={playerDetails[indexGuest].bumper}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeReservationPlayersInformation({ playerDetails, index: indexGuest, isNewBumper: !!e.target.checked, customFieldType })}
                                        name="checked"
                                        color="default"
                                        style={{
                                            color: "#2E3A48",
                                        }}
                                    />
                                }
                                label={<Typography className='body-small'>Add Bumper</Typography>}
                            />
                        </Box>
                    )}
                </Box> : null;

            });
            playerDetailsListOneRow = Array.apply(null, Array(reservation.guests)).map((_, indexGuest) => {
                return ![CustomFieldsType.PlayerDetailsForSteltronic, CustomFieldsType.PlayerDetailsNoBumpersForSteltronic].includes(customFieldType) ? <Box key={`${customField} ${indexGuest}`} >
                    <Box display={'grid'} gridTemplateColumns={'1fr'} gridGap={'16px'} >
                        <Input2
                            label={`Player ${playerDetails[indexGuest].index} Name`}
                            name={`playerName ${indexGuest}`}
                            data-testid={`playerDetails ${indexGuest}`}
                            value={playerDetails[indexGuest].name || ""}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeReservationPlayersInformation({ playerDetails, index: indexGuest, newName: e.target.value || "", customFieldType })}
                            autoComplete={"off"}
                            fullWidth
                            errorInput={errorCustomFields[index]}
                            mandatory={isMandatory}

                        />

                    </Box>
                    {[CustomFieldsType.PlayerDetailsNoShoesForSteltronic].includes(customFieldType) && (
                        <Box className="custom-field-row" mt={'9px'} mb={'20px'}>
                            <FormControlLabel
                                style={{
                                    alignItems: 'center'
                                }}
                                control={
                                    <Checkbox
                                        checked={playerDetails[indexGuest].bumper}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeReservationPlayersInformation({ playerDetails, index: indexGuest, isNewBumper: !!e.target.checked, customFieldType })}
                                        name="checked"
                                        color="default"
                                        style={{
                                            color: "#2E3A48",
                                        }}
                                    />
                                }
                                label={<Typography className='body-small'>Add Bumper</Typography>}
                            />
                        </Box>
                    )}
                </Box> : null;

            });

        }


        const playerDetailsArray = Array.apply(null, Array(reservation.guests)).map((_, indexGuest) => {
            if (customFieldsObject["playerDetailsForTripleSeat"]) {
                const playerDetailsForTripleSeatInformation: string[] = customFieldsObject["playerDetailsForTripleSeat"].split(',');
                if (playerDetailsForTripleSeatInformation.length !== reservation.guests) {
                    return playerDetailsForTripleSeatInformation[indexGuest] || ''
                }
                return playerDetailsForTripleSeatInformation[indexGuest];
            } else {
                return '';
            }
        });

        let playerDetailsForTsList;
        if (customFieldType === CustomFieldsType.PlayerDetailsForTripleSeat) {
            playerDetailsForTsList = Array.apply(null, Array(reservation.guests)).map((_, indexGuest) => {
                return (
                    <Box className="custom-field-row-container" key={`${customField} ${indexGuest}`} >
                        <Input2
                            label={`Player ${indexGuest + 1} Details`}
                            name={`playerDetails ${indexGuest}`}
                            data-testid={`playerDetailsTripleSeat ${indexGuest}`}
                            value={playerDetailsArray[indexGuest] || ""}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeReservationPlayerDetailsInformationForTs({ playerDetailsForTs: playerDetailsArray, index: indexGuest, newDetails: e.target.value || "" })}
                            autoComplete={"off"}
                            errorInput={errorCustomFields[index]}
                            mandatory={isMandatory}

                        />
                    </Box>

                )
            });
        }

        const guestNamesArray = Array.apply(null, Array(reservation.guests)).map((_, indexGuest) => {
            if (customFieldsObject["guestNames"]) {
                const guestNamesInformation: string[] = customFieldsObject["guestNames"].split(',');
                if (guestNamesInformation.length !== reservation.guests) {
                    return guestNamesInformation[indexGuest] || '';
                }
                return guestNamesInformation[indexGuest];
            } else {
                return '';
            }
        });
        if (customFieldsObject["guestNames"] && customFieldsObject["guestNames"].split(',').length !== reservation.guests) {
            const newGuestNamesJSON = guestNamesArray.join(',');
            changeReservationCustomFieldsInformation("guestNames", newGuestNamesJSON);
        }

        let guestNamesList;
        if (customFieldType === CustomFieldsType.GuestNames) {
            guestNamesList = <Box mt={'16px'} display={'grid'} gridTemplateColumns={defaultTemplateCoumns} gridGap={'16px'}>{Array.apply(null, Array(reservation.guests)).map((_, indexGuest) => {
                return <Box className="custom-field-row-container" key={`${customField} ${indexGuest}`} >
                    <Input2
                        label={`Guest ${indexGuest + 1} Full Name`}
                        name={`guestNames ${indexGuest}`}
                        data-testid={`guestNames ${indexGuest}`}
                        value={guestNamesArray[indexGuest] || ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeReservationGuestNamesInformation({ guestNames: guestNamesArray, index: indexGuest, newName: e.target.value || "" })}
                        autoComplete={"off"}
                        errorInput={errorCustomFields[index]}
                        mandatory={isMandatory}

                    />
                </Box>

            })}</Box>;
        }

        return (
            <>
                {isSteltronicType && !!playerDetailsList && playerDetailsList}
                <Box display={'grid'} gridTemplateColumns={defaultTemplateCoumns} gridGap={'16px'}>
                    {((customFieldType === CustomFieldsType.String) && customField !== 'address' && customField !== 'groupName' && customField !== "guestOfHonorName" && customField !== 'zipCode') ? <Box className={`custom-field ${isMobile ? "mobile" : ""}`} key={customField}  >

                        {customFieldType === CustomFieldsType.String && customField !== 'address' && customField !== 'groupName' && customField !== "guestOfHonorName" && customField !== 'zipCode' && (
                            <Input2
                                label={customFieldLabel}
                                name={customFieldName}
                                value={customFieldValue || ""}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeReservationCustomFieldsInformation(customField, e.target.value || "")}
                                autoComplete={"off"}
                                errorInput={errorCustomFields[index]}
                                mandatory={isMandatory}
                            />
                        )}

                    </Box> : null}
                    {isSteltronicType && !!playerDetailsListOneRow && playerDetailsListOneRow}

                    {(customFieldType === CustomFieldsType.PlayerDetailsForTripleSeat && !!playerDetailsForTsList) && playerDetailsForTsList}

                    {(customFieldType === CustomFieldsType.Birthday && customField === 'birthday') &&
                        (
                            <Box mt={'16px'} className={`custom-field ${isMobile ? "mobile" : ""}`} key={customField} data-testid={customField}>
                                <DatePicker2
                                    autoOk
                                    label={customFieldLabel}
                                    name={customFieldName}
                                    value={customFieldValue}
                                    placeholder={"MM/DD"}
                                    onChange={(data: any) => changeReservationCustomFieldsInformation(customField, data ? dayjs(data).format("MM/DD") : "")}
                                    format={"MM/DD"}
                                    views={["month", "date"]}
                                    errorInput={errorCustomFields[index]}
                                    mandatory={isMandatory}
                                />
                            </Box>
                        )
                    }
                </Box>
                {(customFieldType === CustomFieldsType.GuestNames && !!guestNamesList) && guestNamesList}

            </>
        )
    });
    const containerRef = useRef<HTMLDivElement>(null);
    const handleClickOutside = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
            setIsOrderSummaryModalOpen(false);
        }
    };

    useEffect(() => {
        // @ts-ignore
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // @ts-ignore
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const mobileBg = isMobile ? { backgroundColor: uiConfig?.bodyBackgroundColor, borderRadius: '4px' } : {}

    return (
        <>
            {isOpenVerificationModal && (
                <VerificationCodeModal
                    onConfirmValidationCode={onConfirmValidationCode}
                    phone={phone}
                    onClose={hideVerificationCodeModal}
                    resendCode={onVerificationCodeGetting}
                />
            )
            }
            <div className={`reservation-container v2 ${isMobile ? 'mobile' : ''}`}>
                {isMobile && <Box display={'flex'} flexDirection={'column'} style={{ gap: '8px', }} p={'30px 24px'} bgcolor={uiConfig?.bodyBackgroundColor} >
                    <Typography className="heading-h1">Reservation Details</Typography>
                </Box>}
                <div className={`reservation-inner-container ${isTabletScreen ? 'is-tablet-v2' : ''} ${isMobile ? 'mobile' : ''} main-content-v2`}>
                    <div
                        className={`reservation-reservationInfo ${isMobile ? "mobile" : ""} ${isTabletScreen ? 'is-tablet-v2' : ''} v2`}
                    >
                        {!isMobile && <Box display={'flex'} flexDirection={'column'} style={{ gap: '8px' }} mb='32px' >
                            <Typography className="heading-h1">Reservation Details</Typography>
                        </Box>}

                        {(requestError || validationError) && (<Box mb='32px'>

                            <NoAvailiableGuests noSpace text={requestError || validationError} />
                        </Box>)}
                        {(isErrorGeneral) && (<Box mb='32px'>
                            <NoAvailiableGuests className='' noSpace isHtml text={'<strong>Submission failed</strong>  Multiple entries are invalid or do not match with the required format'} />
                        </Box>)}

                        <Box>
                            <DefaultContainer2 isMobile={isMobile} mb={isMobile ? 0 : '24px'} data-testid='contact-details'>
                                <Typography className='heading-h5'>Contact Details</Typography>
                                <Box mt='16px' display={'grid'} style={{ gridTemplateColumns: defaultTemplateCoumns }} gridRowGap={'16px'} gridGap={"16px"} >
                                    <Input2
                                        label="First Name"
                                        placeholder="First Name"
                                        value={firstName}
                                        onChange={onFirstNameChange}
                                        errorInput={errorFirstName}
                                        mandatory />
                                    <Input2
                                        label="Last Name"
                                        placeholder="Last Name"
                                        value={lastName}
                                        onChange={onLastNameChange}
                                        errorInput={errorLastName}
                                        mandatory />
                                    <PhoneInput2 placeholder='Phone Number' value={phone} onChange={onPhoneChange} errorInput={errorPhone} isReversed />
                                </Box>

                            </DefaultContainer2>
                        </Box>
                        {isActiveCustomFields && showGuestDetails && (
                            <>
                                {isMobile ? <Box height={'32px'} marginY={'24px'} marginX={'-24px'} bgcolor={uiConfig?.bodyBackgroundColor} /> : null}

                                <DefaultContainer2 isMobile={isMobile} mb={isMobile ? 0 : '24px'} data-testid='guest-details'>
                                    <Typography className='heading-h5'>Guest Details</Typography>
                                    <Box mt={'16px'} >
                                        {activeCustomFieldsListGuestDetails}
                                    </Box>
                                </DefaultContainer2>

                            </>
                        )}

                        {isActiveCustomFields && showAdditionalInfo && (
                            <>
                                {isMobile ? <Box height={'32px'} marginY={'24px'} marginX={'-24px'} bgcolor={uiConfig?.bodyBackgroundColor} /> : null}

                                <DefaultContainer2 mb={isMobile ? 0 : '24px'} isMobile={isMobile} data-testid='additional-info'>
                                    <Typography className='heading-h5'>Additional Info</Typography>
                                    <Box display={'grid'} gridTemplateColumns={defaultTemplateCoumns} gridGap={'16px'} mt={'16px'} >
                                        {activeCustomFieldsListAdditionalInfo}
                                    </Box>
                                </DefaultContainer2>
                            </>
                        )}
                        {activeCustomFields.includes('address') &&
                            <>
                                {isMobile ? <Box height={'32px'} marginY={'24px'} marginX={'-24px'} bgcolor={uiConfig?.bodyBackgroundColor} /> : null}

                                <DefaultContainer2 mb={isMobile ? 0 : '24px'} isMobile={isMobile} data-testid='address'>
                                    <Typography className='heading-h5'>Address</Typography>
                                    <Box mt={'16px'}>
                                        <Input2
                                            label="Address"
                                            value={customFieldsObject['address'] || ""}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeReservationCustomFieldsInformation('address', e.target.value || "")}
                                            autoComplete="off"
                                            errorInput={errorCustomFields[activeCustomFields.indexOf('address')]}
                                            mandatory={mandatoryCustomFields.includes('address')} />

                                    </Box>

                                </DefaultContainer2>
                            </>}

                        {venue?.enableCustomNotes && (
                            <>
                                {isMobile ? <Box height={'32px'} marginY={'24px'} marginX={'-24px'} bgcolor={uiConfig?.bodyBackgroundColor} /> : null}

                                <DefaultContainer2 mb={'24px'} display={'flex'} flexDirection={'column'} style={{ gap: '16px' }} isMobile={isMobile}>
                                    <Typography className='heading-h5'> {venue?.customNotesTitle || 'Notes'}</Typography>
                                    {venue?.customNotesExplanationText && (
                                        <HTMLRenderer html={venue?.customNotesExplanationText} className="root" />
                                    )}
                                    <TextArea2
                                        minRows={7}
                                        value={customNotes}
                                        errorInput={errorCustomNotes}
                                        onChange={onCustomNotesChange}
                                        autoComplete="off"
                                        placeholder='Type your notes here'
                                    />
                                    {isMobile && <Typography className='root' style={{ color: '#EE4C1A', paddingBottom: '32px' }}>* Required fields</Typography>}
                                </DefaultContainer2>
                            </>
                        )}
                        {isShowGuestDetails && (
                            <div className="guest-details-container">
                                <div className="guest-details-title">

                                    {guestDetailsTitle}
                                </div>
                                <div className="guest-details-fields">
                                    {guestDetailsList}
                                </div>
                            </div>
                        )}

                        {(requestError || validationError) && (
                            <div className="error">{requestError || validationError}</div>
                        )}
                        {(isMobile && uiConfig?.newDesign) ? <Box position={'fixed'} width={'100%'} p={'24px'} bottom={0} left={0} bgcolor={'white'} zIndex={100}>
                            <MobileAttachedButton
                                reservation={reservation}
                                onButtonClick={onContinue}
                                isButtonDisabled={isRequestInProgress}
                                giftCardAmount={giftCardAmount}
                                isUpdateReservation={isUpdateReservation}
                                venueId={venue?.id} />
                        </Box> :
                            (isMobile && !uiConfig?.newDesign) ? (
                                <OrderSummaryWrapper
                                    reservation={reservation}
                                    venue={venue}
                                    onEdit={backToReservation}
                                    actionText={uiConfig?.actionText}
                                    isMobile={isMobile}
                                    isHideDuration={isHideDuration}
                                    currentPackage={currentPackage}
                                    giftCardAmount={giftCardAmount}
                                    isUpdateReservation={isUpdateReservation}
                                    oldDeposit={reservation?.payed}
                                    reservationAddons={reservationAddons}
                                    twelveHourClockFormat={twelveHourClockFormat}
                                    onContinue2={onContinue}
                                >
                                    <MobileFixedButton onClick={onContinue} name="continue" uiConfig={uiConfig} disabled={isRequestInProgress}>
                                        CONTINUE
                                    </MobileFixedButton>
                                </OrderSummaryWrapper>
                            ) : (
                                <Box display={'flex'} flexDirection={'column'} style={{ gap: '32px' }} mb={'32px'}>
                                    <ButtonMain onClick={onContinue} name="continue" disabled={isRequestInProgress}>
                                        Continue
                                    </ButtonMain>
                                    <Typography className='root' style={{ color: '#EE4C1A' }}>* Required fields</Typography>
                                </Box>
                            )}
                    </div>
                    {!isMobile && (
                        <>
                            <div className="column-delimiter"></div>
                            <div className="reservation-summary">
                                <OrderSummaryWrapper
                                    reservation={reservation}
                                    venue={venue}
                                    onEdit={backToReservation}
                                    actionText={uiConfig?.actionText}
                                    isMobile={isMobile}
                                    isHideDuration={isHideDuration}
                                    currentPackage={currentPackage}
                                    giftCardAmount={giftCardAmount}
                                    isUpdateReservation={isUpdateReservation}
                                    oldDeposit={reservation?.payed}
                                    reservationAddons={reservationAddons}
                                    twelveHourClockFormat={twelveHourClockFormat}
                                    onContinue2={onContinue}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
            <AccountReservationInfoModal
                setOpen={() => setIsOrderSummaryModalOpen(true)}
                containerRef={containerRef}
                open={isOrderSummaryModalOpen}
                onClose={() => setIsOrderSummaryModalOpen(false)}
                resNumber={reservation.number!}
                reservation={reservation}
            ><></></AccountReservationInfoModal>
        </>
    );
};

export default connector(Reservation2);
