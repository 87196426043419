import React, { useEffect, useRef } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { goBack, getLocation, getSearch } from "connected-react-router";
import { mergeStyleSets } from "office-ui-fabric-react";
import Header from "../common/Header/Header";
import Footer from "../common/Footer/Footer";
import MultiStep from "../common/MultiStep/MultiStep";
import Account from "./Account/Account";
import Addons from "./Addons/Addons";
import Reservation from "./Reservation/Reservation";
import Payment from "./Payment/Payment";
import Confirmation from "./Confirmation/Confirmation";
import { State, STEP } from "../../store/types";
import { useViewport } from "../../hooks/responsive";
import { selectAuthenticated, selectGuest } from "../../reducers/auth-reducer";
import {
  selectReservation,
  selectPaymentCompleted,
} from "../../reducers/reservation";
import { selectUIConfig } from "../../reducers/ui-reducer";
import { backToReservationAction } from "../../actions/venue-actions";
import { parseUrlQuery } from "../../utils/urlSearchQuery";
import "./reservationInfo.scss";
import { selectCurrentPackage, selectVenue } from "../../reducers/venues";
import { pushUrlPathAction } from "../../actions/reservation-actions";
import Confirmation2 from "./Confirmation/Confirmation2";
import Addons2 from "./Addons/Addons2";
import Header2 from "../common/Header/Header2";
import Footer2 from "../common/Footer/Footer2";
import Account2 from "./Account/Account2";
import Reservation2 from "./Reservation/Reservation2";
import Payment2 from "./Payment/Payment2";

const mapDispatchToProps = {
  pushUrlPath: pushUrlPathAction,
  goBack,
  backToReservation: backToReservationAction,
};

const mapStateToProps = (state: State) => ({
  reservation: selectReservation(state),
  authenticated: selectAuthenticated(state),
  paymentCompleted: selectPaymentCompleted(state),
  loc: getLocation(state),
  uiConfig: selectUIConfig(state),
  guest: selectGuest(state),
  searchParams: getSearch(state),
  currentPackage: selectCurrentPackage(state),
  activeVenue: selectVenue(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const ReservationInfo = ({
  pushUrlPath,
  goBack,
  reservation,
  authenticated,
  paymentCompleted,
  backToReservation,
  loc,
  uiConfig,
  guest,
  searchParams,
  currentPackage,
  activeVenue,
}: Props) => {
  const { isMobile } = useViewport();
  const top = useRef<HTMLDivElement>(null);
  const diffStep = activeVenue && activeVenue?.addons.length > 0 ? 0 : 1;
  const getActiveStep = (pathname: string) => {
    console.log("-activeStep-", paymentCompleted, pathname, authenticated);
    if (pathname.includes("/addons") && !diffStep) {
      return STEP.ADDONS;
    } else if (!(authenticated || guest) || pathname.includes("/account")) {
      return STEP.ACCOUNT;
    } else if (pathname.includes("/reservation-confirm")) {
      return STEP.RESERVATION - diffStep;
    } else if (!paymentCompleted && pathname.includes("/payment")) {
      return STEP.PAYMENT - diffStep;
    } else if (
      paymentCompleted &&
      (pathname.includes("/confirmed") || pathname.includes("/payment"))
    ) {
      return STEP.CONFIRMATION - diffStep;
    } else {
      return STEP.INIT;
    }
  };
  const activeStep = getActiveStep(loc.pathname);
  useEffect(() => {
    console.log("-activeStep-", activeStep, reservation);
    if (activeStep === STEP.INIT || !reservation.venueId) {
      const isPackageReservationMode = !!currentPackage || uiConfig?.isPackageReservationMode;
      pushUrlPath(isPackageReservationMode ? "/package" : "/reservation");
      return;
    }
  }, [pushUrlPath, activeStep]);
  useEffect(() => {
    if (top?.current) {
      window.scrollTo(0, top.current.offsetTop);
    }
  }, [top, activeStep]);
  const handler = (step: STEP) => () => {
    if (step === STEP.ACCOUNT) {
      return pushUrlPath("/account");
    } else if (step === STEP.ADDONS && !diffStep) {
      return pushUrlPath("/reservation-info/addons");
    } else if (step === STEP.RESERVATION - diffStep) {
      return pushUrlPath("/reservation-info/reservation-confirm");
    } else {
      return backToReservation();
    }
  };
  let steps = [
    { label: STEP.INIT, name: "Day & Time", handler: handler(STEP.INIT) },
    {
      label: STEP.ACCOUNT,
      name: "Account",
      handler: handler(STEP.ACCOUNT),
    },
    {
      label: STEP.RESERVATION - diffStep,
      name: "Reservation",
      handler: handler(STEP.RESERVATION - diffStep),
    },
    {
      label: STEP.PAYMENT - diffStep,
      name: "Payment",
      handler: handler(STEP.INIT),
    },
    {
      label: STEP.CONFIRMATION - diffStep,
      name: "Confirmation",
      handler: handler(STEP.INIT),
    },
  ];
  if (!diffStep) {
    steps = [
      { label: STEP.INIT, name: "Day & Time", handler: handler(STEP.INIT) },
      {
        label: STEP.ADDONS,
        name: "Add Ons",
        handler: handler(STEP.ADDONS),
      },
      {
        label: STEP.ACCOUNT,
        name: "Account",
        handler: handler(STEP.ACCOUNT),
      },
      {
        label: STEP.RESERVATION,
        name: "Reservation",
        handler: handler(STEP.RESERVATION),
      },
      {
        label: STEP.PAYMENT,
        name: "Payment",
        handler: handler(STEP.INIT),
      },
      {
        label: STEP.CONFIRMATION,
        name: "Confirmation",
        handler: handler(STEP.INIT),
      },
    ];
  }
  const handleBackButton = () => {
    if (!!diffStep && activeStep === STEP.RESERVATION - diffStep) {
      // if (lastLocation?.pathname.includes("/account")) {
      //   return push("/account");
      // } else {
      return backToReservation();
      // }
    } else if (activeStep === STEP.CONFIRMATION - diffStep) {
      return backToReservation();
    } else {
      return goBack();
    }
  };
  const theme = mergeStyleSets({
    backgroundColor: {
      background: uiConfig?.bodyBackgroundColor,
    },
  });
  const params = parseUrlQuery(searchParams);
  const showReservation = activeStep !== STEP.ACCOUNT || (activeStep === STEP.ACCOUNT && !!params.continue);

  return (
    <div className={`reservationInfo-container ${theme.backgroundColor}`} ref={top}>
      {uiConfig?.newDesign ?
        <Header2 showReservation={showReservation} handleBackButton={handleBackButton} /> :
        <Header showReservation={showReservation} handleBackButton={handleBackButton} />
      }
      <div className={`reservationInfo-inner-container ${isMobile ? 'mobile' : ''}`}>
        {uiConfig?.newDesign ? (!isMobile && showReservation && (<MultiStep activeStep={activeStep} steps={steps} />)) : (!isMobile && (<MultiStep activeStep={activeStep} steps={steps} />))}
        <Switch>
          <Route exact path="/reservation-info/account" component={uiConfig?.newDesign ? Account2 : Account} />
          <Route
            exact
            path="/reservation-info/addons"
            component={uiConfig?.newDesign ? Addons2 : Addons}
          />
          <Route
            exact
            path="/reservation-info/reservation-confirm"
            component={uiConfig?.newDesign ? Reservation2 : Reservation}
          />
          <Route exact path="/reservation-info/payment" component={uiConfig?.newDesign ? Payment2 : Payment} />
          <Route
            exact
            path="/reservation-info/confirmed"
            component={uiConfig?.newDesign ? Confirmation2 : Confirmation}
          />
          {uiConfig?.isPackageReservationMode ? (
            <Redirect to="/package" />
          ) : (
            <Redirect to="/reservation" />
          )}
        </Switch>
      </div>
      {uiConfig?.newDesign ?
        <Footer2 className={isMobile ? 'mobile-footer' : ''} /> :
        <Footer className={isMobile ? 'mobile-footer' : ''} />
      }
    </div>
  );
};

export default connector(ReservationInfo);
