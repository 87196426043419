import { Box, Button, Tab, Tabs } from "@material-ui/core";
import { getHash, getSearch, goBack, replace } from "connected-react-router";
import {
    Spinner,
    SpinnerSize
} from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import {
    pushUrlParamsAction,
    resetReservationAction,
    setReservationErrorAction,
    startBookingAction,
    updateReservationAction,
} from "../../actions/reservation-actions";
import {
    changeVenuePackageAction,
    changeVenuePackageWithReservationAction,
    parseHash,
    setCurrentPackageAction,
    toPackagesWithVenueNewDesignAction,
    toPartyWithVenueAction,
    toReservationWithVenueAction
} from "../../actions/venue-actions";
import { useViewport } from "../../hooks/responsive";
import useSettingColors from "../../hooks/useSettingColors";
import {
    selectIsUpdateReservation,
    selectRequestError,
    selectReservation,
} from "../../reducers/reservation";
import { selectUIConfig } from "../../reducers/ui-reducer";
import {
    selectAvailability,
    selectCurrentPackage,
    selectIsLoadingAvailability,
    selectIsLoadingDateOrVenue,
    selectIsLoadingVenue,
    selectIsLoadingVenues,
    selectVenue,
    selectVenues,
} from "../../reducers/venues";

import { Skeleton } from "@material-ui/lab";
import HTMLRenderer from "../../../../common/components/HTMLRenderer/HTMLRenderer";
import HTMLRendererWithScript from "../../../../common/components/HTMLRendererWithScript/HTMLRendererWithScript";
import defaultImage from "../../../assets/default-image.jpg";
import { State } from "../../store/types";
import { getVenueImages } from "../../utils/venue";
import "../MakePackageReservation/makePackageReservation.scss";
import GalleryModal from "../MakeReservation/GalleryModal";
import MakeReservationSummary2 from "../MakeReservation/MakeReservationSummary2";
import PlanningEvent from "../MakeReservation/PlanningEvent";
import Footer2 from "../common/Footer/Footer2";
import Header2 from "../common/Header/Header2";
const mapDispatchToProps = {
    changeVenuePackage: changeVenuePackageAction,
    resetReservation: resetReservationAction,
    updateReservation: updateReservationAction,
    startBooking: startBookingAction,
    toPartyWithVenue: toPartyWithVenueAction,
    setReservationError: setReservationErrorAction,
    toReservationWithVenue: toReservationWithVenueAction,
    setCurrentPackage: setCurrentPackageAction,
    goBack,
    replace,
    changeVenuePackageWithReservation: changeVenuePackageWithReservationAction,
    toPackagesWithVenue: toPackagesWithVenueNewDesignAction,
    pushUrlParams: pushUrlParamsAction,
};

const mapStateToProps = (state: State) => ({
    isLoadingVenues: selectIsLoadingVenues(state),
    isLoadingVenue: selectIsLoadingVenue(state),
    isLoadingAvailability: selectIsLoadingAvailability(state),
    activeVenue: selectVenue(state),
    venues: selectVenues(state),
    availability: selectAvailability(state),
    reservation: selectReservation(state),
    searchParams: getSearch(state),
    requestError: selectRequestError(state),
    uiConfig: selectUIConfig(state),
    currentPackage: selectCurrentPackage(state),
    isUpdateReservation: selectIsUpdateReservation(state),
    hashParams: getHash(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const CustomTab2 = ({
    venues,
    activeVenue,
    isLoadingVenues,
    isLoadingVenue,
    isLoadingAvailability,
    availability,
    uiConfig,
    goBack,
    changeVenuePackage,
    startBooking,
    resetReservation,
    updateReservation,
    toPartyWithVenue,
    reservation,
    searchParams,
    hashParams,
    setReservationError,
    requestError,
    currentPackage,
    setCurrentPackage,
    changeVenuePackageWithReservation,
    isUpdateReservation,
    toPackagesWithVenue,
    pushUrlParams

}: Props) => {

    const [tabValue, setTabValue] = React.useState(2);
    const [isGalleryOpen, setIsGalleryOpen] = useState(false);

    const handleChangeTabValue = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
        if (newValue === 0) returnToPackages()
        if (newValue === 1) returnToParty()
    };
    const { bodyColor } = useSettingColors()

    const { isMobile, isTabletScreen } = useViewport()
    const returnToPackages = () => {
        toPackagesWithVenue();
    };
    const { date } = reservation;

    const returnToParty = () => {
        toPartyWithVenue(date);
    };


    const [isVenueSelecting, setIsVenueSelecting] = useState(false)
    const { venueId, guests, } = reservation;
    const { packagesTag } = parseHash(hashParams);

    const selectVenueOption = (newVenueId?: number | string) => {
        setIsVenueSelecting(true)
        const newVenue = venues.find((venue) => venue.id == newVenueId);
        if (newVenueId && newVenue) {
            const recountedGuests = newVenue.maxGuests >= guests ? guests : 1;
            if (!!packagesTag) {
                replace({ search: searchParams });
            }
            if (isUpdateReservation) {
                updateReservation({ slots: undefined, packageId: undefined });
                changeVenuePackageWithReservation({
                    venueId: newVenueId.toString(),
                    date,
                    guests: recountedGuests,
                    updatePackage: undefined,
                });
            } else {
                changeVenuePackage({
                    updatePackage: undefined,
                    venueId: newVenueId.toString(),
                    date,
                    guests: recountedGuests,
                    changeVenueWithoutPackage: true,
                });
            }
        }
    };
    useEffect(() => {
        if (isVenueSelecting) {
            pushUrlParams()
            setIsVenueSelecting(false)
        }
    }, [venueId])
    const images = getVenueImages(activeVenue);
    const isWithImages = images && images.length > 0;

    return (
        <>
            {(isLoadingVenues || isLoadingAvailability || isVenueSelecting) && (
                <div className="loading">
                    <Spinner size={SpinnerSize.large} />
                </div>
            )}
            <div
                className="make-package-reservation-container main-container main-container-v2"
                style={{ backgroundColor: bodyColor }}
            >
                <Header2 handleBackButton={goBack} />
                <div className="makePackageReservation-inner-container main-content main-content-v2">
                    <div
                        className={`makePackageReservation-main-information-v2 main-content main-content-v2 ${isMobile ? "mobile" : ""
                            }`}
                        style={isTabletScreen ? { paddingBottom: 0 } : {}}
                    >
                        <div className={`makePackageReservation-reservation makePackageReservation-reservation-v2 ${isTabletScreen ? 'tablet' : ''}`}>

                            {!isMobile ? <>
                                <div className="makePackageReservation-reservation-title-v2">
                                    {activeVenue?.customTabTabName || 'Custom Tab'}
                                </div>
                                {activeVenue?.customTabDescription && <HTMLRenderer
                                    html={activeVenue?.customTabDescription}
                                    className="makePackageReservation-reservation-text-v2"
                                />}</> : <Box position={'relative'} height={'290px'}>
                                {((isLoadingVenues || isLoadingAvailability)) ? (
                                    <Skeleton
                                        variant="rect"
                                        width={'100%'}
                                        height={'290px'}
                                        style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', transform: 'none' }}
                                    />
                                ) : (
                                    <img
                                        className="makePackageReservation-reservation-logo-v2"
                                        src={activeVenue?.venueImage || defaultImage}
                                        alt="venueImage"
                                    />
                                )}
                                {isWithImages && <Button className="makePackageReservation-reservation-btn-view-all-v2 body-small" onClick={() => setIsGalleryOpen(true)}>
                                    View All ({images.length})
                                </Button>}
                            </Box>}
                            <Box className={`makePackageReservation-reservation-header ${isMobile ? "mobile" : ""
                                }`}>

                                <Tabs
                                    value={tabValue}
                                    indicatorColor="primary"
                                    textColor="inherit"
                                    onChange={handleChangeTabValue}
                                    variant="fullWidth"
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: "#212B36",
                                            height: '3px',
                                            bottom: '-1px'
                                        }
                                    }}
                                    style={{
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.10)'
                                    }}
                                >
                                    <Tab label={activeVenue?.packagesTabName || "Reservations"} className="makePackageReservation-tab" />
                                    <Tab label={activeVenue?.eventInquiryTabName || activeVenue?.eventInquiryTitle || "Contact Us"} className="makePackageReservation-tab" />
                                    {activeVenue?.customTabEnable && <Tab label={activeVenue?.customTabTabName || 'Custom Tab'} className="makePackageReservation-tab" />}
                                </Tabs>

                                {activeVenue?.customTabScript ? (
                                    <Box p="32px">
                                        <HTMLRendererWithScript html={activeVenue?.customTabScript} />
                                    </Box>
                                ) : null}
                            </Box>

                            {isTabletScreen && <PlanningEvent
                                mt={'32px'}
                                maxWidth={'100% !important'}
                                descriptionProps={{
                                    style: {
                                        maxWidth: '100%'
                                    }
                                }}
                                activeVenue={activeVenue} />}

                        </div>
                        {(uiConfig?.newDesign && !isMobile) && <MakeReservationSummary2 venue={activeVenue} venueNames={venues} selectVenueOption={selectVenueOption} />}

                    </div>
                </div>

                <Footer2 className={isMobile ? "mobile-footer" : ""} />

            </div>
            <GalleryModal onOpen={() => setIsGalleryOpen(true)} venue={activeVenue} onClose={() => setIsGalleryOpen(false)} open={isGalleryOpen} />

        </>

    );
};

export default connector(CustomTab2);
