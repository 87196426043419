import React from "react";
import { Accordion } from "../Accordion/Accordion";
import { Policy } from "../Policy/Policy";
import { ReservationDto } from "../../../server/src/dto/reservation.dto";
import {
  GiftCardBalanceDto,
  GiftCardBalanceRequestDto,
  GiftCardPaymentRequestDto,
} from "../../../server/src/dto/giftCard.dto";
import { VenueDto } from "../../../server/src/dto/venue.dto";
import { PackageNameDto } from "../../../server/src/dto/packageName.dto";
import { AddonType, FeeType, Pricing } from "../../../server/src/entities/enums";
import {
  formatDuration,
  formatPrice,
  getReservationTotalPrice,
  formatCustomFees,
  calculateDeposit,
} from "../../utils/formats";
import { Details } from "../ReservationDetails/ReservationDetails";
import { VenueAddress } from "../VenueAddress/VenueAddress";
import { MapImageLink } from "../MapImageLink/MapImageLink";
import GiftCard from "../GiftCard";
import { CouponCodes } from "../CouponCodes";
import "./orderSummary.scss";
import HTMLRenderer from "../HTMLRenderer/HTMLRenderer";
import { ReservationAddonDto } from "../../../server/src/dto/reservationAddon.dto";

interface Props {
  onEdit: () => void;
  reservation?: ReservationDto;
  venue?: VenueDto;
  completed?: boolean;
  actionText?: string;
  isMobile?: boolean;
  isHideDuration?: boolean;
  currentPackage?: PackageNameDto;
  showGiftCard?: boolean;
  giftBalance?: GiftCardBalanceDto;
  getGiftCardBalance?: (cardParams: GiftCardBalanceRequestDto) => void;
  giftBalanceError?: string;
  addGiftCard?: (cardParams: GiftCardPaymentRequestDto) => void;
  giftCardAmount?: number;
  showDiscounts?: boolean;
  applyCouponCode?: (couponCodes: string[]) => void;
  isSkipPayment?: boolean;
  isUpdateReservation?: boolean;
  oldDeposit?: number;
  reservationAddons?: ReservationAddonDto[];
  twelveHourClockFormat: boolean;
  notShowEdit?: boolean
}

export const OrderSummary = ({
  reservation,
  venue,
  completed = false,
  onEdit,
  children,
  actionText,
  isMobile,
  isHideDuration,
  currentPackage,
  showGiftCard,
  giftBalance,
  getGiftCardBalance,
  giftBalanceError,
  addGiftCard,
  giftCardAmount,
  showDiscounts,
  applyCouponCode,
  isSkipPayment,
  isUpdateReservation,
  oldDeposit,
  reservationAddons,
  twelveHourClockFormat,
  notShowEdit
}: React.PropsWithChildren<Props>) => {
  if (!reservation || !venue) {
    return null; // show error
  }
  const getPriceTitle = (
    reservation: ReservationDto,
    isHideDuration: boolean,
    actionText?: string
  ): string => {
    if (reservation.pricing === Pricing.flatRate) {
      return currentPackage ? currentPackage.name : "Flat Rate";
    } else if (reservation.pricing === Pricing.perPersonFlat) {
      return `${reservation.guests} ${reservation.guests === 1 ? "Guest" : "Guests"
        }`;
    } else if (reservation.pricing === Pricing.flatRatePerLane) {
      return `${reservation.lanes} ${actionText}${reservation.lanes < 2 ? "" : "s"
        }`;
    } else if (reservation.pricing === Pricing.perPerson) {
      return `${reservation.guests} ${reservation.guests === 1 ? "Guest" : "Guests"
        }`;
    } else {
      return `${reservation.lanes} ${actionText}${reservation.lanes < 2 ? "" : "s"
        } ${isHideDuration
          ? ""
          : formatDuration(reservation.duration, reservation.timeSlotDuration)
        }`;
    }
  };
  const priceTitle = getPriceTitle(reservation, !!isHideDuration, actionText);
  const discountAmount = reservation.discountAmount || 0;
  let guests = reservation.guests
  if(reservationAddons && reservationAddons.length > 0){
    reservationAddons.forEach(addon=>{
      if(addon.addonType === AddonType.guestCount){
        guests = guests + addon.quantity
      }
    })
  }
  let deposit = calculateDeposit({
    reservation,
    giftCardAmount: giftCardAmount ? +giftCardAmount : 0,
    isUpdateReservation: !!isUpdateReservation,
    isUpdateWithVenueChange: venue?.id !== reservation?.venueId
  });
  let totalPaid = !!oldDeposit ? +oldDeposit : 0;
  let modificationFeeInDollars = reservation.modificationFee || 0;
  const totalPrice = getReservationTotalPrice(reservation);
  if (isUpdateReservation) {
    if (deposit <= 0) {
      isSkipPayment = true;
    } else {
      isSkipPayment = false;
    }
  }

  const depositParamValue =
    +reservation.deposit <= +reservation.depositParam
      ? +reservation.deposit
      : +reservation.depositParam;
  let depositParamText =
    reservation.depositType === FeeType.PERCENT
      ? `${reservation.depositParam}%`
      : `${formatPrice(depositParamValue, reservation.currency, true)}`;
  const customFees = formatCustomFees(reservation.customFees, reservation.currency)
  let customFeeElements = null;
  if (customFees.length > 0) {
    customFeeElements = customFees.map(({ name, value }) => (
      <div className="row" key={name}>
        <div>{name}</div>
        <div>{value}</div>
      </div>
    ));
  }

  const reservationAddonsPriceElements =
    reservationAddons && reservationAddons.length > 0
      ? reservationAddons.map((reservationAddon, index) => {
        const addonPrice =
          Math.round(
            reservationAddon.price * reservationAddon.quantity * 100
          ) / 100;
        return (
          <div className="row" key={index}>
            <div>{`${reservationAddon.name} (${formatPrice(
              reservationAddon.price,
              reservation.currency,
              true
            )} x ${reservationAddon.quantity})`}</div>
            <div>{formatPrice(addonPrice, reservation.currency, true)}</div>
          </div>
        );
      })
      : null;
  return (
    <div className={`order-summary ${isMobile ? "mobile" : ""}`}>
      {!completed && !isMobile && (
        <div className="order-summary-header ">
          <div>ORDER SUMMARY</div>
          <div>{formatPrice(totalPrice, reservation.currency, true)}</div>
        </div>
      )}
      <div
        className={
          completed
            ? "order-summary-inner no-header"
            : `order-summary-inner ${isMobile ? "mobile" : ""}`
        }
      >
        <Accordion title={venue?.name || ""}>
          <>{!notShowEdit ? <div
            className="edit focusable"
            onClick={onEdit}
            role="button"
            tabIndex={0}
            onKeyPress={onEdit}
          >
            Edit
          </div> : null}
            <div className="location">
              <VenueAddress venue={venue} />
              <div className="panel">
                <MapImageLink venue={venue} />
              </div>
            </div></>
        </Accordion>
      </div>
      <div className={`order-summary-inner ${isMobile ? "mobile" : ""}`}>
        <Accordion title="VENUE POLICIES" defaultCollapsed>
          <Policy text={venue?.venueInfo} />
        </Accordion>
      </div>
      <div className={`order-summary-inner ${isMobile ? "mobile" : ""}`}>
        <Accordion title="RESERVATION DETAILS">
          <div
            className="edit focusable"
            onClick={onEdit}
            role="button"
            tabIndex={0}
            onKeyPress={onEdit}
          >
            Edit
          </div>
          <Details
            reservation={reservation}
            actionText={actionText}
            twelveHourClockFormat={twelveHourClockFormat}
            isHideDuration={isHideDuration}
            currentPackage={currentPackage}
            guests={guests}
          />
        </Accordion>
      </div>
      <div className={`order-summary-inner ${isMobile ? "mobile" : ""}`}>
        <div className="order-summary-info">
          <div className="summary">
            {!!showGiftCard &&
              !isMobile &&
              !!getGiftCardBalance &&
              !!addGiftCard && (
                <GiftCard
                  isMobile={!!isMobile}
                  deposit={deposit}
                  giftBalance={giftBalance}
                  getGiftCardBalance={getGiftCardBalance}
                  giftBalanceError={giftBalanceError}
                  addGiftCard={addGiftCard}
                  giftCardAmount={giftCardAmount}
                  isSkipPayment={isSkipPayment}
                />
              )}
            {!!showDiscounts &&
              !isMobile &&
              !!applyCouponCode && (
                <CouponCodes
                  isMobile={!!isMobile}
                  deposit={deposit}
                  coupons={reservation.coupons}
                  applyCouponCode={applyCouponCode}
                  isSkipPayment={isSkipPayment}
                />
              )}
            <div className="subtitle">ORDER SUMMARY</div>
            <div className="row">
              <div>{priceTitle}</div>
              <div>
                {formatPrice(
                  reservation.price + reservation.discount,
                  reservation.currency,
                  true
                )}
              </div>
            </div>
            {reservationAddonsPriceElements}
            <div className="row bold-text">
              <div>Subtotal</div>
              <div>
                {formatPrice(
                  reservation.price + reservation.addonsPrice,
                  reservation.currency,
                  true
                )}
              </div>
            </div>
            {reservation.tax ? (
              <div className="row">
                <div>Tax</div>
                <div>
                  {formatPrice(reservation.tax, reservation.currency, true)}
                </div>
              </div>
            ) : null}
            {customFeeElements}
            {modificationFeeInDollars ? (
              <div className="row">
                <div>Modification Fee</div>
                <div>
                  {formatPrice(
                    modificationFeeInDollars,
                    reservation.currency,
                    true
                  )}
                </div>
              </div>
            ) : null}
            {giftCardAmount && giftCardAmount > 0 ? (
              <div className="row">
                <div>Gift Card</div>
                <div>
                  -{formatPrice(giftCardAmount, reservation.currency, true)}
                </div>
              </div>
            ) : null}
            {discountAmount && discountAmount > 0 ? (
              <div className="row">
                <div>Discount</div>
                <div>
                  -{formatPrice(discountAmount, reservation.currency, true)}
                </div>
              </div>
            ) : null}
            {reservation.discount > 0 && (
              <div className="row">
                <div>{reservation?.discountName || "Discount"}</div>
                <div>
                  -{" "}
                  {formatPrice(
                    reservation.discount,
                    reservation.currency,
                    true
                  )}
                </div>
              </div>
            )}
            {isUpdateReservation ? (
              <div className="row">
                <div>Total Paid</div>
                <div>-{formatPrice(totalPaid, reservation.currency, true)}</div>
              </div>
            ) : null}
            <div className="row bold-text">
              <div>Grand total</div>
              <div>{formatPrice(totalPrice, reservation.currency, true)}</div>
            </div>
            <div className="row">
              <div>Deposit</div>
              <div>{depositParamText}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={`order-summary-inner bottom ${isMobile ? "mobile" : ""}`}>
        <div className="order-total">
          <div className="subtitle">DUE NOW</div>
          <div className="subtitle">
            {formatPrice(deposit, reservation.currency, true)}
          </div>
        </div>
        {!completed && (
          <HTMLRenderer className="information" html={reservation.textBox} />
        )}
      </div>
      {children && (
        <div
          className={`order-summary-inner bottom-last ${isMobile ? "mobile" : ""
            }`}
        >
          {children}
        </div>
      )}
    </div>
  );
};
