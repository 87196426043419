import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import confirmationImage from "../../../../../common/assets/confirmation.png";
import { PrimaryButton } from "../../../../../common/components/PrimaryButton/PrimaryButton";
import { confirmationPageIcons } from "../../../../../common/constants/confirmationPageIcons";
import { pushUrlPathAction } from "../../../actions/reservation-actions";
import { initialLoadVenuesAction } from "../../../actions/venue-actions";
import { useViewport } from "../../../hooks/responsive";
import OrderSummaryWrapper from "../../../providers/OrderSummaryWrapper";
import { selectClient, selectGuest } from "../../../reducers/auth-reducer";
import {
  selectGiftCardAmount,
  selectIsUpdateReservation,
  selectOldReservation,
  selectReservation,
  selectReservationAddons,
} from "../../../reducers/reservation";
import { selectUIConfig } from "../../../reducers/ui-reducer";
import { selectCurrentPackage, selectVenue } from "../../../reducers/venues";
import { State } from "../../../store/types";
import "./confirmation.scss";

const mapDispatchToProps = {
  pushUrlPath: pushUrlPathAction,
  initialLoadVenues: initialLoadVenuesAction,
};

const mapStateToProps = (state: State) => ({
  reservation: selectReservation(state),
  client: selectClient(state),
  guest: selectGuest(state),
  venue: selectVenue(state),
  uiConfig: selectUIConfig(state),
  giftCardAmount: selectGiftCardAmount(state),
  currentPackage: selectCurrentPackage(state),
  isUpdateReservation: selectIsUpdateReservation(state),
  oldReservation: selectOldReservation(state),
  reservationAddons: selectReservationAddons(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const Confirmation = ({
  reservation,
  client,
  guest,
  venue,
  uiConfig,
  pushUrlPath,
  initialLoadVenues,
  giftCardAmount,
  currentPackage,
  isUpdateReservation,
  oldReservation,
  reservationAddons,
}: Props) => {
  useEffect(() => {
    const isPackages = !!currentPackage;
    return () =>
      initialLoadVenues({
        venue: venue?.id,
        guests: 1,
        isPackages,
      });
  }, [initialLoadVenues]);
  useEffect(() => {
    if (uiConfig?.confirmationPageScript) {
      try {
        const node = document
          .createRange()
          .createContextualFragment(uiConfig?.confirmationPageScript);
        document.body.appendChild(node);
      } catch (e) {
        //ignore
        console.log("confirmationPageScript error:", e);
      }
    }
  }, [uiConfig?.confirmationPageScript]);

  const backToStartBooking = () => {
    if (uiConfig?.isPackageReservationMode) {
      pushUrlPath("/package");
    } else {
      pushUrlPath("/reservation");
    }
  };

  const { isMobile } = useViewport();
  if (!reservation || !venue) {
    console.log("invalid state for confirmation view");
    backToStartBooking();
    return null; // show error
  }
  const email = reservation.email
    ? reservation.email
    : client
    ? client.email
    : guest?.email;

  const uiConfigIcon =
    uiConfig?.confirmationIcon as keyof typeof confirmationPageIcons;
  const isHideDuration =
    uiConfig?.hideDuration || currentPackage?.hidePackageDuration;
  const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;
  let confirmationIcon;

  if (uiConfigIcon === "Default") {
    confirmationIcon = confirmationImage;
  } else {
    confirmationIcon = confirmationPageIcons[uiConfigIcon] || confirmationImage;
  }

  return (
    <div className="confirmation-container">
      <div className="confirmation-inner-container main-content">
        <div
          className={`confirmation-registrationMessage ${
            isMobile ? "mobile" : "no-mobile"
          }`}
        >
          <div className="registrationMessage">
            <img
              className="confirmationImage"
              src={confirmationIcon}
              alt="confirmation image"
            />
            <div>Reservation Confirmed</div>
            <div className="reservationNumber">
              Reservation #{reservation.number}
            </div>
            <div>
              A confirmation has been sent to your email address below. Please
              make sure to check your spam or junk folder if you do not receive
              the email within 5 minutes:
            </div>
            <div className="email">{email}</div>
            <PrimaryButton
              href={uiConfig?.homeLink}
              name="homePage"
              className="home-button"
              uiConfig={uiConfig}
            >
              GO BACK TO HOMEPAGE
            </PrimaryButton>
          </div>
        </div>
        {!isMobile && (
          <>
            <div className="column-delimiter"></div>
            <div className="confirmation-summary">
              <OrderSummaryWrapper
                reservation={reservation}
                venue={venue}
                completed
                onEdit={backToStartBooking}
                actionText={uiConfig?.actionText}
                isMobile={isMobile}
                isHideDuration={isHideDuration}
                currentPackage={currentPackage}
                giftCardAmount={giftCardAmount}
                isUpdateReservation={isUpdateReservation}
                oldDeposit={reservation?.payed}
                reservationAddons={reservationAddons}
                twelveHourClockFormat={twelveHourClockFormat}
                notShowEdit
                notShowContinue2
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default connector(Confirmation);
