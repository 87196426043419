import React from 'react';
import { useSelector } from 'react-redux';
import { OrderSummary } from '../../../common/components/OrderSummary/OrderSummary';
import { GiftCardBalanceDto, GiftCardBalanceRequestDto, GiftCardPaymentRequestDto } from '../../../server/src/dto/giftCard.dto';
import { PackageNameDto } from '../../../server/src/dto/packageName.dto';
import { ReservationDto } from '../../../server/src/dto/reservation.dto';
import { ReservationAddonDto } from '../../../server/src/dto/reservationAddon.dto';
import { VenueDto } from '../../../server/src/dto/venue.dto';
import { OrderSummary2 } from '../components/OrderSummary2/OrderSummary2';
import { State } from '../store/types';

interface Props {
    onEdit: () => void;
    onContinue2?: () => void;
    reservation?: ReservationDto;
    venue?: VenueDto;
    completed?: boolean;
    actionText?: string;
    isMobile?: boolean;
    isHideDuration?: boolean;
    currentPackage?: PackageNameDto;
    showGiftCard?: boolean;
    giftBalance?: GiftCardBalanceDto;
    getGiftCardBalance?: (cardParams: GiftCardBalanceRequestDto) => void;
    giftBalanceError?: string;
    addGiftCard?: (cardParams: GiftCardPaymentRequestDto) => void;
    giftCardAmount?: number;
    showDiscounts?: boolean;
    applyCouponCode?: (couponCodes: string[]) => void;
    isSkipPayment?: boolean;
    isUpdateReservation?: boolean;
    oldDeposit?: number;
    reservationAddons?: ReservationAddonDto[];
    twelveHourClockFormat: boolean;
    notShowEdit?: boolean;
    notShowContinue2?: boolean;
    isLoading?: boolean;
    onCloseModal?: () => void;
    isSummaryExpanded?: boolean;
    isModal?: boolean;
    isHandleProgressAddons?: boolean;
}

const OrderSummaryWrapper = (props: React.PropsWithChildren<Props>) => {
    const newDesign = useSelector((state: State) => state.uiState.uiConfig?.newDesign);
    const { notShowContinue2, onContinue2, isLoading, onCloseModal, isSummaryExpanded, isModal, isHandleProgressAddons, ...oldProps } = props;

    if (newDesign) return <OrderSummary2 {...props} notShowContinue={props.notShowContinue2} onContinue={props.onContinue2}>{props.children}</OrderSummary2>;
    return (
        <OrderSummary {...oldProps}>{oldProps.children}</OrderSummary>
    );
};

export default OrderSummaryWrapper;
