import { Button, makeStyles } from '@material-ui/core'
import React from 'react'
import LockSmIcon from '../../../assets/lock_sm.svgr'

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: '#EBECF0',
        color: '#000000',
        borderRadius: '4px',
        textTransform: 'none',
        width: '180px',
        height: '48px',
        transition: 'all 0.15s',
        padding: 0,
        '&:hover': {
            opacity: 0.8,
        },

    },

    label: {
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '24px'
    }
}))

interface Props {
    onClick?: () => void;

}

const MemberSignInButton = ({ onClick }: Props) => {
    const classes = useStyles()
    return (
        <Button className={classes.button} classes={{ label: classes.label }} onClick={onClick}
        >
            Members Sign In <LockSmIcon />
        </Button>
    )
}

export default MemberSignInButton