import React, { useState } from "react";
import dayjs from "dayjs";
import { IconButton } from "office-ui-fabric-react";
import { Month } from "../../../../../common/components/Month/Month";
import { MONTH_NAME_FORMAT } from "../../../../../common/constants/timedate";
import { Availability } from "../../../../../server/src/dto/venue.dto";
import { Button } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Month2 } from "./Month2";
import { useViewport } from "../../../hooks/responsive";

interface CalendarProps {
    date: string;
    selectDate: (date: string) => void;
    availability: Availability;
    isMiddleScreen?: boolean;
    isInsideContainer?: boolean;
}

const buttonStyle = {
    root: {
        width: "24px",
        margin: 0,
        height: "24px",
        minHeight: "24px",
        padding: "0px",
        background: "white",
        color: "black",
        borderRadius: "50%",
        border: "0.6px solid black",
    },
    rootHovered: {
        background: "white",
        color: "grey",
    },
};

export const Calendar2 = ({ date, selectDate, availability, isInsideContainer }: CalendarProps) => {

    const [thisMonth, setMonth] = useState<boolean>(true);
    const [monthCoefficient, setMonthCoefficient] = useState<number>(0);
    const { isTabletScreen, isMobile } = useViewport()

    const firstAvailabilityDate = Object.keys(availability)[0];
    const lastAvailabilityDate = Object.keys(availability)[Object.keys(availability).length - 1];

    const firstAvailabilityYear = dayjs(firstAvailabilityDate).year();
    const lastAvailabilityYear = dayjs(lastAvailabilityDate).year();
    const firstAvailabilityMonth = dayjs(firstAvailabilityDate).month();
    const lastAvailabilityMonth = dayjs(lastAvailabilityDate).month();

    const dateDifference = (lastAvailabilityYear - firstAvailabilityYear) * 12 + (lastAvailabilityMonth - firstAvailabilityMonth);

    const monthName = dayjs()
        .date(1)
        .add(monthCoefficient, "M")
        .format(MONTH_NAME_FORMAT);

    const nextMonth = (monthCoefficient: number) => {
        setMonthCoefficient(++monthCoefficient);
        monthCoefficient == 0 ? setMonth(true) : setMonth(false);
    };
    const previousMonth = (monthCoefficient: number) => {
        setMonthCoefficient(--monthCoefficient);
        monthCoefficient == 0 ? setMonth(true) : setMonth(false);
    };


    return (isMobile) ? (
        <div className="mobile-calendar v2">
            <div className="monthButtons monthButtons2">
                <Button
                    className={`focusableRoundButton monthButton2 ${isInsideContainer ? 'left-alt' : 'left'}`}
                    onClick={() => previousMonth(monthCoefficient)}
                    disabled={thisMonth == true}
                >
                    <ArrowBackIcon />
                </Button>
                <div className="monthName v2">{monthName}</div>
                <Button
                    className={`focusableRoundButton monthButton2 ${isInsideContainer ? 'right-alt' : 'right'}`}
                    onClick={() => nextMonth(monthCoefficient)}
                    disabled={monthCoefficient >= dateDifference}
                >
                    <ArrowForwardIcon />
                </Button>
            </div>
            <Month2
                date={date}
                selectDate={selectDate}
                availability={availability}
                isMobile={true}
                isTabletScreen={isTabletScreen}
                monthCoefficient={monthCoefficient}
            />
        </div>
    ) : (
        <div className="calendar">
            <div className="monthButtons monthButtons2">
                <Button
                    className={`focusableRoundButton monthButton2 ${isInsideContainer ? 'left-alt' : 'left'}`}
                    onClick={() => previousMonth(monthCoefficient)}
                    disabled={thisMonth == true}
                >
                    <ArrowBackIcon />
                </Button>
                <Button
                    className={`focusableRoundButton monthButton2 ${isInsideContainer ? 'right-alt' : 'right'}`}
                    onClick={() => nextMonth(monthCoefficient)}
                    disabled={monthCoefficient >= dateDifference - 1}
                >
                    <ArrowForwardIcon />
                </Button>
            </div>
            <div className="months-root">

                <Month2
                    date={date}
                    selectDate={selectDate}
                    availability={availability}
                    isMobile={false}
                    isTabletScreen={isTabletScreen}
                    monthCoefficient={monthCoefficient}
                />
                <Month2
                    date={date}
                    selectDate={selectDate}
                    availability={availability}
                    isMobile={false}
                    isTabletScreen={isTabletScreen}
                    monthCoefficient={monthCoefficient + 1}
                />
            </div>
        </div>
    );
};
