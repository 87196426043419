import { Box, Button, FormControl, FormHelperText, Input, InputLabel, InputProps, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import InVisibleIcon from '../../../../assets/password/invisible.svgr';
import VisibleIcon from '../../../../assets/password/visible.svgr';
import { useViewport } from '../../../hooks/responsive';
const useStyles = makeStyles((theme) => ({
    input: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '28px',
        color: '#212B36',

        '& .MuiInputBase-input::placeholder': {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '28px',
            color: '#3F4852',
            opacity: 0.7
        },
    },

    form: {
        backgroundColor: '#F6F7FA',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '16px 23.5px',
        transition: 'all 0.2s ease',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: "#EBEDF4 !important",
        },
    },
    inputLabel: {
        marginLeft: '72px',
        // marginTop: '16px',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '16px',
        color: '#3F4852',

        '& .MuiFormLabel-root.Mui-focused': {
            color: '#3F4852'
        },
    },
    focused: {
        marginTop: '16px',
        transform: 'translate(0, 12px) scale(0.75)',
        color: '#3F4852',

    },
    focusedTablet: {
        marginTop: '8px !important'

    },
    error: {
        color: '#EE4C1A',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '20px',
        paddingLeft: '24px',
    },
    truncateLabel: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '90%',
    }
}));

interface Props extends InputProps {
    label?: string;
    fullWidth?: boolean;
    errorInput?: string;
    isPassword?: boolean;
    mandatory?: boolean;
    notTotruncateLabel?: boolean;
}

const Input2 = ({ label, fullWidth, errorInput, isPassword, mandatory, notTotruncateLabel, ...props }: Props) => {
    const classes = useStyles();
    const { isTabletScreen } = useViewport()
    const [isFocused, setIsFocused] = React.useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: any) => {
        // event.preventDefault();
    };

    return (
        <Box display='flex' flexDirection='column' width={'100%'} className='input-v2'>
            <FormControl className={classes.form} style={{ padding: '11px 24px', border: errorInput ? '1px solid #EE4C1A' : isFocused ? '1px solid #0000001A' : '1px solid #F6F7FA', backgroundColor: errorInput ? '#F6F7FA' : isFocused ? '#EBEDF4' : '#F6F7FA', ...(isTabletScreen ? { maxHeight: '48px' } : {}) }} fullWidth={fullWidth}>
                {label && <InputLabel className={`${classes.inputLabel} ${errorInput ? 'error-label' : ''} ${(notTotruncateLabel || isFocused) ? '' : classes.truncateLabel}`} classes={{ focused: isTabletScreen ? classes.focusedTablet : classes.focused, shrink: isTabletScreen ? classes.focusedTablet : classes.focused, }}
                    style={{ marginLeft: '24px', marginTop: isTabletScreen ? '-7px' : '6px', }} variant="standard" htmlFor="uncontrolled-native">
                    {label} {mandatory && <span style={{ color: '#EE4C1A' }}>{' '}*</span>}
                </InputLabel>}
                <Input onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} type={isPassword ? (showPassword ? 'text' : 'password') : 'text'}
                    {...props} disableUnderline className={classes.input} fullWidth />
                {isPassword && <Button onClick={handleClickShowPassword}
                    className='btn-icon-v2'
                    onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <VisibleIcon /> : <InVisibleIcon />}
                </Button>}
                {/* {errorInput && <ErrorIcon />} */}
            </FormControl >
            {errorInput && <FormHelperText className={classes.error}>{errorInput} </FormHelperText>}
        </Box>)
}

export default Input2