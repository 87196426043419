import { getHash, getSearch, replace } from 'connected-react-router';
import { ActionTypeEnum, AppThunk } from '.';
import { MembershipPlan } from '../components/Memberships/entity';
import { selectReservation } from '../reducers/reservation';
import { selectVenue, selectCurrentPackage } from '../reducers/venues';
import { parseUrlQuery } from '../utils/urlSearchQuery';
import { getWithAuth, postWithAuth } from './api';
import { replaceAppUrlAction } from './reservation-actions';
import { selectBilling, selectPlan, selectPlans } from '../reducers/membership';
import { MembershipBilling } from '../store/types/memberships';
import { clearAuthErrorAction } from './auth-actions';
const STRIPE_PLANS_URL = "/api/membership";
const STRIPE_CUSTOMER_SETTINGS_URL = "https://dashboard.stripe.com/test/settings/billing/portal";

export const selectPlansAction = (id?: string) => ({ type: ActionTypeEnum.SelectPlan, payload: id })

export const getPlansAction = (): AppThunk => async (dispatch) => {
    try {
        dispatch({ type: ActionTypeEnum.GetPlans });
        const response = await getWithAuth(STRIPE_PLANS_URL);
        dispatch({
            type: ActionTypeEnum.GetPlansSuccess,
            payload: response.data,
        });
    } catch (e) {
        dispatch({
            type: ActionTypeEnum.GetPlansFailure,
            payload: "error getting plans",
        });
    }
};
export const setPlans = (plan: MembershipPlan): AppThunk => async (dispatch) => {
    dispatch({
        type: ActionTypeEnum.GetPlansSuccess,
        payload: [plan],
    });
}
export const subscribeToPlanAction = (
    plan: MembershipPlan,
    isAnnual: boolean
): AppThunk => async (dispatch) => {
    try {
        dispatch({ type: ActionTypeEnum.SubscribeToPlan });
        const response = await postWithAuth(STRIPE_PLANS_URL + '/subscribe', { id: plan.id, isAnnual });
        console.log(response.data, response);
        if (response.data.url) {
            location.href = response.data.url;
        }
        dispatch({
            type: ActionTypeEnum.SubscribeToPlanSuccess,
            payload: response.data,
        });
    } catch (e) {
        dispatch({
            type: ActionTypeEnum.SubscribeToPlanFailure,
            payload: "Error subscribing to plan",
        });
    }
};

export const unSubscribeAction = (
    subscriptionId: string
): AppThunk => async (dispatch) => {
    try {
        dispatch({ type: ActionTypeEnum.UnSubscribeToPlan });
        const response = await postWithAuth(STRIPE_PLANS_URL + '/unsubscribe', { subscriptionId });
        dispatch({
            type: ActionTypeEnum.UnSubscribeToPlanSuccess,
            payload: response.data,
        });
    } catch (e) {
        dispatch({
            type: ActionTypeEnum.UnSubscribeToPlanFailure,
            payload: "error unsubscribe to plan",
        });
    }
};

export const getBillingSessionAction = (
    customerId: string
): AppThunk => async (dispatch) => {
    try {
        dispatch({ type: ActionTypeEnum.GetBillingSession });
        const response = await postWithAuth(STRIPE_PLANS_URL + '/billing-session/' + customerId);
        window.location.href = response.data.url;

        dispatch({
            type: ActionTypeEnum.GetBillingSessionSuccess,
            payload: response.data,
        });
    } catch (e) {
        dispatch({
            type: ActionTypeEnum.GetBillingSessionFailure,
            payload: "Error getting billing session. Enable customer billing portal in stripe settings",
        });
    }
};

export const setSelectedPlanAction = (plan: MembershipPlan): AppThunk => async (dispatch, getState) => {
    const currentPlan = selectPlan(getState());
    if (plan.id === currentPlan?.id) {
        dispatch({
            type: ActionTypeEnum.SetSelectedPlan,
            payload: undefined,
        });
    } else {
        dispatch({
            type: ActionTypeEnum.SetSelectedPlan,
            payload: plan,
        });
    }
    dispatch(clearAuthErrorAction())
    dispatch(updateMembershipUrlAction());
}
export const setBillingAction = (billing: MembershipBilling): AppThunk => async (dispatch) => {
    dispatch({
        type: ActionTypeEnum.SetBilling,
        payload: billing,
    });
    dispatch(updateMembershipUrlAction());
}
export const setSinglePlan = (singlePlan?: MembershipPlan): AppThunk => async (dispatch) => {
    dispatch({
        type: ActionTypeEnum.SetSinglePlan,
        payload: singlePlan,
    });
}

export const updateMembershipUrlAction = (): AppThunk => async (
    dispatch,
    getState
) => {
    const plan = selectPlan(getState());
    const billing = selectBilling(getState());
    const params: { [key: string]: any } = {
    };
    if (plan?.name) {
        params.plan = plan.name;
    }
    if (billing) {
        params.billing = billing;
    }

    dispatch(replaceAppUrlAction(params));

};

export const initialMembershipLoadAction = (): AppThunk => async (
    dispatch,
    getState
) => {
    const searchParams = getSearch(getState());
    const query = parseUrlQuery(searchParams);
    const plans = selectPlans(getState());
    const isSinglePlan = getHash(getState()) === '#featured';
    if (query.billing) {
        dispatch(setBillingAction(query.billing as MembershipBilling));
    }
    if (query.plan) {
        const queryPlan = parseUrlQuery(searchParams).plan;
        const selectedPlan = plans.find((p) => p.name === queryPlan);
        if (selectedPlan) {
            dispatch(setSelectedPlanAction(selectedPlan));
            if (isSinglePlan) {
                dispatch(setSinglePlan(selectedPlan));
            }
        }
    }
    if (!query.billing && !query.plan) {
        dispatch(replaceAppUrlAction({
            billing: 'monthly',
        }))
    }

};