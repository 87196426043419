import {
  Checkbox,
  Spinner,
  SpinnerSize,
  mergeStyleSets,
} from "office-ui-fabric-react";
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { InfoAlert } from "../../../../../common/components/Alert/Alert";
import GiftCard from "../../../../../common/components/GiftCard";
import {
  calculateDeposit, getReservationTotalPrice,
  rem,
} from "../../../../../common/utils/formats";
import { openPopup } from "../../../../../common/utils/popup";
import {
  ProfileDto,
  CreateSquarePartialPaymentDto,
  GiftCardBalance,
  GiftCardBalanceRequest,
  GiftCardPaymentRequest,
  Guest,
  PMethod,
  Payment,
  Reservation,
  SquarePaymentDetails,
  Venue,
} from "../../../store/types";
import * as squareApi from "./square";

import SquareLogo from "../../../../assets/payment/square.png";
import CouponCodes2 from "../../OrderSummary2/CouponCodes2";
import PaymentButton from "../../ReservationInfo/Payment/PaymentButton";
import ButtonMain from "../ButtonMain/ButtonMain";
import "./squareForm.scss";

interface Props {
  squarePayments: squareApi.Payments | null;
  client?: ProfileDto;
  guest?: Guest;
  venue: Venue | undefined;
  reservation: Reservation;
  isMobile: boolean;
  showGiftCard: boolean;
  orderSummary: (error?: string, block?: boolean) => JSX.Element;
  uiConfig: any;
  requestError?: string;
  completePayment: (payment: Payment) => void;
  giftBalance?: GiftCardBalance;
  getGiftCardBalance: (cardParams: GiftCardBalanceRequest) => void;
  giftBalanceError?: string;
  addGiftCard: (cardParams: GiftCardPaymentRequest) => void;
  giftCardAmount: number;
  showDiscounts?: boolean;
  applyCouponCode?: (couponCodes: string[]) => void;
  createEmptyPayment: (paymentType: PMethod) => void;
  isUpdateReservation: boolean;
  isUpdateWithVenueChange: boolean;
  oldReservation?: Reservation;
  isSandbox: boolean;
  createSquarePartial: () => Promise<CreateSquarePartialPaymentDto>;
  check: () => Promise<boolean>;
  customHandler?: (payment: Payment) => void;
}

const checkboxStyle = {
  root: {
    margin: "0 0 30px",
  },
  checkbox: {
    width: rem(20),
    height: rem(20),
  },
  text: {
    fontSize: rem(13),
    lineHeight: "1.5",
  },
  checkmark: {
    fontSize: rem(13),
  },
};

const SquareForm2 = ({
  venue,
  reservation,
  client,
  guest,
  isMobile,
  orderSummary,
  uiConfig,
  requestError,
  showGiftCard,
  completePayment,
  giftBalance,
  getGiftCardBalance,
  giftBalanceError,
  addGiftCard,
  giftCardAmount,
  showDiscounts,
  applyCouponCode,
  createEmptyPayment,
  isUpdateReservation,
  isUpdateWithVenueChange,
  oldReservation,
  squarePayments,
  isSandbox,
  createSquarePartial,
  check,
  customHandler,
}: Props) => {
  const cardRef = useRef<HTMLDivElement | null>(null);
  const cashAppPayRef = useRef<HTMLDivElement | null>(null);
  const afterpayButtonRef = useRef<HTMLDivElement | null>(null);
  const paymentStatusRef = useRef<HTMLDivElement | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const [squareCard, setSquareCard] = useState<squareApi.Card | null>(null);
  const [cashAppPay, setCashAppPay] = useState<squareApi.CashAppPay | null>(
    null
  );
  const [afterClearPay, setAfterClearpay] =
    useState<squareApi.AfterpayClearpay | null>(null);

  const [error, setError] = useState<string>("");
  const [isAgreementSelected, setIsAgreementSelected] = useState(false);

  const deposit = calculateDeposit({
    reservation,
    giftCardAmount,
    isUpdateReservation,
    isUpdateWithVenueChange,
    oldReservation,
  });

  const isPartialPayment = deposit !== getReservationTotalPrice(reservation);

  const subtitle = mergeStyleSets({
    backgroundColor: {
      background: uiConfig?.bodyBackgroundColor,
    },
  });

  const isSkipPayment = deposit <= 0;
  const isRefundPayment = deposit < 0;
  const isShowRefundPaymentInfo =
    isUpdateReservation && isSkipPayment && isRefundPayment;
  const isShowSkipPaymentInfo =
    isUpdateReservation && isSkipPayment && !isRefundPayment;
  const isShowPaymentInfo = isUpdateReservation && !isSkipPayment;

  const destroyAllPayments = async () => {
    try {
      await Promise.all([
        squareCard?.destroy(),
        cashAppPay?.destroy(),
        afterClearPay?.destroy(),
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const run = async () => {
      if (!squarePayments) return;
      if (squareCard || cashAppPay || afterClearPay) {
        await destroyAllPayments();
      }

      try {
        setIsLoading(true);
        const card = await squareApi.initializeCard(cardRef, squarePayments);
        setSquareCard(card);
      } catch (error) {
        console.error("Initializing Square Card failed", error);
        setError("Initializing Square Card failed. Please use another method");
      } finally {
        setIsLoading(false);
      }

      try {
        if (venue?.isSquareCashAppEnable) {
          setIsLoading(true);

          const cash = await squareApi.initializeCashApp({
            ref: cashAppPayRef,
            payments: squarePayments,
            redirectURL: window.location.href,
            amount: deposit.toString(),
          });
          cash.addEventListener("ontokenization", async ({ detail }: any) => {
            const tokenResult = detail.tokenResult;
            if (tokenResult.status === "OK") {
              const paymentResults = await createPayment(tokenResult.token);

              console.debug("Payment Success", paymentResults);
              destroyAllPayments();
            } else {
              let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;

              if (tokenResult.errors) {
                errorMessage += ` and errors: ${JSON.stringify(
                  tokenResult.errors
                )}`;
              }
              throw new Error(errorMessage);
            }
          });
          setCashAppPay(cash);
        }
      } catch (error) {
        console.error("Initializing Cash App Pay failed", error);
        setError("Initializing Cash App Pay failed. Please use another method");
      } finally {
        setIsLoading(false);
      }

      try {
        if (venue?.isSquareAfterPayEnable) {
          setIsLoading(true);
          const afterpay = await squareApi.initializeAfterpay({
            selector: "#afterpay-button",
            payments: squarePayments,
            amount: deposit.toString(),
            useCustomButton: true,
          });
          setAfterClearpay(afterpay);
        }
      } catch (error) {
        console.error("Initializing Afterpay/Clearpay failed", error);
        setError(
          "Initializing Afterpay/Clearpay failed. Please use another method"
        );
      } finally {
        setIsLoading(false);
      }
    };

    if (isPartialPayment) {
      setIsLoading(false);
      return;
    }

    run()
      .catch((e) => {
        console.error(e);
        setError(e.toString());
      })
      .finally(() => setIsLoading(false));
  }, [squarePayments, isPartialPayment]);

  useEffect(() => {
    return () => {
      destroyAllPayments();
    };
  }, [cashAppPay]);

  const createPayment = async (token: string, verificationToken = "test") => {
    const data: SquarePaymentDetails = {
      locationId: venue?.squareLocationId || "",
      sourceId: token,
      verificationToken: verificationToken || "",
      idempotencyKey: uuidv4(),
      note: `${venue?.name} - ${reservation.id}`,
    };
    const payment: Payment = {
      paymentReference: token,
      details: data,
      postalCode: "",
      paymentType: PMethod.square,
    };
    customHandler ? customHandler(payment) : completePayment(payment);
  };

  if (!squarePayments) return <></>;

  const confirmButtonText = isUpdateReservation
    ? "MODIFY RESERVATION"
    : "COMPLETE YOUR RESERVATION";

  const handlePaymentMethodSubmission = async (
    event: any,
    paymentMethod: squareApi.Card | squareApi.AfterpayClearpay | null
  ) => {
    event.preventDefault();
    if (!paymentMethod) return;

    try {
      // disable the submit button as we await tokenization and make a payment request.
      setIsLoading(true);
      const verificationDetails:
        | squareApi.ChargeVerifyBuyerDetails
        | squareApi.StoreVerifyBuyerDetails = {
        amount: Math.round(deposit * 100).toString(),
        billingContact: squareApi.makeBillingContact(client, guest),
        currencyCode: reservation.currency,
        intent: "CHARGE",
      };
      const token = await squareApi.tokenize(paymentMethod);
      const verificationToken = await squareApi.verifyBuyer(
        verificationDetails,
        squarePayments,
        token
      );
      const paymentResults = await createPayment(token, verificationToken);
      squareApi.displayPaymentResults(paymentStatusRef, "SUCCESS");

      console.debug("Payment Success", paymentResults);
    } catch (e) {
      squareApi.displayPaymentResults(paymentStatusRef, "FAILURE");
      console.error(e);
      setError(`Sorry, we cannot complete payment. Please try again later.`);
    } finally {
      setIsLoading(false);
    }
  };

  const isDisableSubmit = isLoading;
  const showSpinner =
    ((!isPartialPayment || isLoading) &&
      !(squareCard || cashAppPay || afterClearPay)) ||
    !squarePayments;

  const startChecking = () => {
    setIsLoading(true);
    setTimeout(async () => {
      const isOk = await check();
      if (!isOk) startChecking();
    }, 1000);
  };

  const handlePartialFlow = async () => {
    setIsLoading(true);
    const newWindow = openPopup(
      "",
      undefined,
      window.innerWidth <= 2560 ? window.screen.width / 2 : 2560,
      window.innerHeight
    );
    if (newWindow) {
      const styleElement = document.createElement("style");
      styleElement.innerHTML = `
                .container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100vh;
                }
                .loader {
                    width: 128px;
                    height: 128px;
                    border: 5px solid #000;
                    border-bottom-color: transparent;
                    border-radius: 50%;
                    display: inline-block;
                    box-sizing: border-box;
                    animation: rotation 1s linear infinite;
                    }
                
                    @keyframes rotation {
                        0% {
                            transform: rotate(0deg);
                        }
                        100% {
                            transform: rotate(360deg);
                        }
                    } 
            `;
      const spinnerHTML = `
                <div class="container">
                    <span class="loader"><span>
                </div>
            `;
      newWindow.document.write(spinnerHTML);
      newWindow.document.head.appendChild(styleElement);
    }

    let squareInvoiceId = reservation.squareInvoiceId;
    if (!reservation.squareInvoiceId) {
      const { invoiceId } = await createSquarePartial().finally(() =>
        setIsLoading(false)
      );
      squareInvoiceId = invoiceId;
    }
    const url = `https://squareup${
      isSandbox ? "sandbox" : ""
    }.com/pay-invoice/${squareInvoiceId}`;

    if (newWindow) {
      const popupWidth =
        window.innerWidth < 1280 ? window.innerWidth / 2 : 1280;
      const popupHeight = window.innerHeight;
      const left = (window.screen.width - popupWidth) / 2;
      const top = (window.screen.height - popupHeight) / 2;
      newWindow.location.replace(url);
      newWindow.resizeTo(popupWidth, popupHeight);
      newWindow.moveTo(left, top);
    }
    startChecking();
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    isPartialPayment
      ? handlePartialFlow()
      : handlePaymentMethodSubmission(event, squareCard);
  };

  return (
    <>
      {showSpinner && (
        <div className="loading">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}

      <form id="square-payment-form" onSubmit={onSubmit}>
        {!isPartialPayment && (
          <>
            {(venue?.isSquareCashAppEnable ||
              venue?.isSquareAfterPayEnable) && (
              <>
                <div className="square-delimiter">
                  <div className={subtitle.backgroundColor}>
                    Checkout options
                  </div>
                </div>
                <div className="square-option-container">
                  {venue?.isSquareCashAppEnable && (
                    <div className="item">
                      <div id="cash-app-pay" ref={cashAppPayRef}></div>
                    </div>
                  )}
                  {venue?.isSquareAfterPayEnable && (
                    <div className="item">
                      <div
                        id="afterpay-button"
                        ref={afterpayButtonRef}
                        onClick={(e) =>
                          handlePaymentMethodSubmission(e, afterClearPay)
                        }
                      ></div>
                    </div>
                  )}
                </div>
              </>
            )}

            <div className="square-delimiter">
              <div className={subtitle.backgroundColor}>Pay with card</div>
            </div>
            <div id="card-container" ref={cardRef}></div>
            <div id="payment-status-container" ref={paymentStatusRef}></div>
            {(requestError || error) && (
              <div className="card-error" role="alert">
                {error ? error : requestError}
              </div>
            )}
          </>
        )}
        {showGiftCard && isMobile && (
          <GiftCard
            isMobile={!!isMobile}
            deposit={deposit}
            giftBalance={giftBalance}
            getGiftCardBalance={getGiftCardBalance}
            giftBalanceError={giftBalanceError}
            addGiftCard={addGiftCard}
            giftCardAmount={giftCardAmount}
            isSkipPayment={isSkipPayment}
          />
        )}
        {showDiscounts && isMobile && (
          <CouponCodes2
            reservation={reservation}
            applyCouponCode={applyCouponCode}
            deposit={deposit}
            isSkipPayment={isRefundPayment}
          />
        )}
        {isMobile ? (
          orderSummary(error, showSpinner || !!error)
        ) : (
          <>
            {venue?.showPolicy && (
              <>
                <div className="venue-policy">
                  <div className="heading">VENUE POLICIES</div>
                  <div
                    className="venue-policy-value"
                    dangerouslySetInnerHTML={{
                      __html: venue?.venueInfo || "",
                    }}
                  ></div>
                </div>
                <Checkbox
                  styles={checkboxStyle}
                  label="I have read and agree to the venue policies."
                  checked={isAgreementSelected}
                  onChange={(_: any, isChecked?: boolean) =>
                    setIsAgreementSelected(!!isChecked)
                  }
                />
              </>
            )}
            {isShowRefundPaymentInfo && (
              <InfoAlert text="You will receive a refund for the price difference" />
            )}
            {isShowSkipPaymentInfo && (
              <InfoAlert text="There is no price difference for your new reservation. No additional payment is needed" />
            )}
            {isShowPaymentInfo && (
              <InfoAlert text="Your new reservation requires an additional payment" />
            )}
            {venue?.showPolicy && (requestError || error) && (
              <div className="card-error" role="alert">
                {error ? error : requestError}
              </div>
            )}

            {isSkipPayment &&
              (isPartialPayment ? (
                <PaymentButton
                  role="button"
                  onClick={() => createEmptyPayment(PMethod.stripe)}
                  disabled={!isAgreementSelected}
                >
                  <img src={SquareLogo} />
                </PaymentButton>
              ) : (
                <ButtonMain
                  role="button"
                  onClick={() => createEmptyPayment(PMethod.stripe)}
                  disabled={!isAgreementSelected}
                >
                  {confirmButtonText}
                </ButtonMain>
              ))}
            {!isSkipPayment &&
              (isPartialPayment ? (
                <PaymentButton
                  id="card-button"
                  disabled={
                    isDisableSubmit ||
                    (venue?.showPolicy && !isAgreementSelected)
                  }
                  role="button"
                  aria-label="submit form"
                  type="submit"
                >
                  <img src={SquareLogo} />
                </PaymentButton>
              ) : (
                <ButtonMain
                  id="card-button"
                  disabled={
                    isDisableSubmit ||
                    (venue?.showPolicy && !isAgreementSelected)
                  }
                  role="button"
                  aria-label="submit form"
                  type="submit"
                >
                  {confirmButtonText}
                </ButtonMain>
              ))}
          </>
        )}
      </form>
    </>
  );
};

export default SquareForm2;
