import React, { useState, useEffect } from "react";
import { PrimaryButton } from "../../../../../common/components/PrimaryButton/PrimaryButton";
import { useViewport } from "../../../hooks/responsive";
import { InputField } from "../../../../../common/components/InputField/InputField";
import "./changePassword.scss";

interface Prop {
  changePassword: ({
    newPassword,
    currentPassword,
  }: {
    newPassword: string;
    currentPassword: string;
  }) => void;
  isInProgress: boolean;
  isPasswordChanged: boolean;
  error?: string;
  cleanError: () => void;
  uiConfig: any;
}

export const ChangePassword = ({
  error,
  isInProgress,
  isPasswordChanged,
  changePassword,
  cleanError,
  uiConfig,
}: Prop) => {
  const { isMobile } = useViewport();

  useEffect(() => {
    if (!isInProgress && isPasswordChanged && lastProgress) {
      setConfirmText("Profile is updated.");
      setErrorText("");
    }
  }, [isInProgress, isPasswordChanged]);

  useEffect(() => {
    setErrorText(error);
  }, [error]);
  useEffect(() => {
    return () => cleanError();
  }, [cleanError]);

  const [lastProgress, setLastProgress] = useState(false);
  const [currentPassword, setCurrentPassword] = useState<string | undefined>(
    ""
  );
  const [newPassword, setNewPassword] = useState<string | undefined>("");
  const [repeatPassword, setRepeatPassword] = useState<string | undefined>("");

  const [confirmText, setConfirmText] = useState<string | undefined>("");
  const [errorText, setErrorText] = useState<string | undefined>("");
  const [errorPassword, setErrorPassword] = useState<string>("");
  const [errorNewPassword, setErrorNewPassword] = useState<string>("");
  const [errorRepeatPassword, setErrorRepeatPassword] = useState<string>("");

  const onChangePassword = () => {
    setConfirmText("");
    setErrorText("");
    setLastProgress(true);

    if (
      currentPassword &&
      newPassword &&
      repeatPassword &&
      newPassword === repeatPassword
    ) {
      changePassword({ newPassword, currentPassword });
      return;
    }
    if (!currentPassword) {
      setErrorPassword("This field is required.");
    }
    if (newPassword && repeatPassword && newPassword !== repeatPassword) {
      setErrorNewPassword("These fields must match.");
      setErrorRepeatPassword("These fields must match.");
    }
    if (!newPassword) {
      setErrorNewPassword("This field is required.");
    }
    if (!repeatPassword) {
      setErrorRepeatPassword("This field is required.");
    }
  };
  const onPasswordChange = (_: any, text?: string) => {
    setErrorPassword("");
    setCurrentPassword(text);
  };
  const onNewPassword = (_: any, text?: string) => {
    setErrorNewPassword("");
    setErrorRepeatPassword("");
    setNewPassword(text);
  };
  const onRepeatPassword = (_: any, text?: string) => {
    setErrorNewPassword("");
    setErrorRepeatPassword("");
    setRepeatPassword(text);
  };

  return (
    <div className="change-password">
      <div className={`enteringPassword ${isMobile ? "mobile" : ""}`}>
        <InputField
          type="password"
          name="currentPassword"
          autoComplete="off"
          label="Current Password"
          value={currentPassword}
          errorMessage={errorPassword}
          onChange={onPasswordChange}
        />
        <InputField
          type="password"
          name="newPassword"
          label="New Password"
          autoComplete="off"
          value={newPassword}
          errorMessage={errorNewPassword}
          onChange={onNewPassword}
        />
        <InputField
          type="password"
          name="confirmNewPassword"
          label="Confirm New Password"
          autoComplete="off"
          value={repeatPassword}
          errorMessage={errorRepeatPassword}
          onChange={onRepeatPassword}
        />
        {errorText && <div className="error">{errorText}</div>}
        {confirmText && !errorText && (
          <div className="confirm">{confirmText}</div>
        )}
        <PrimaryButton name="save" onClick={onChangePassword} uiConfig={uiConfig}>
          SAVE
        </PrimaryButton>
      </div>
    </div>
  );
};
