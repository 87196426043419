import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { useViewport } from '../../../hooks/responsive'
import { CurrencyType, State } from '../../../store/types';
import { connect, ConnectedProps } from 'react-redux';
import { MembershipSubscription } from './entity';
import AccountMembership from './AccountMembership';
import { selectUIConfig } from '../../../reducers/ui-reducer';
import { getBillingSessionAction } from '../../../actions/membership-actions';
import { selectError } from '../../../reducers/membership';
import NoAvailiableGuests from '../../PackageCard/NoAvailiableGuests';
import AccountReservationEmptyPlaceholder from '../Reservations/AccountReservationEmptyPlaceholder';
import DefaultContainer2 from '../../common/DefaultContainer2/DefaultContainer2';
import { ArrowForward } from '@material-ui/icons'
import { pushUrlPathAction } from '../../../actions/reservation-actions';

const mapStateToProps = (state: State) => ({
    uiConfig: selectUIConfig(state),
    error: selectError(state)
});

const mapDispatchToProps = {
    getBiliingSession: getBillingSessionAction,
    pushUrlPath: pushUrlPathAction

};


const connector = connect(mapStateToProps, mapDispatchToProps);

type ConnectedProp = ConnectedProps<typeof connector>;

interface Props extends ConnectedProp {
    subscriptions: MembershipSubscription[]
}
const AccountMemberships = ({ subscriptions, uiConfig, getBiliingSession, error, pushUrlPath }: Props) => {
    const { isMobile } = useViewport()

    const handleManageCard = (customerId: string) => {
        getBiliingSession(customerId)
    }

    const handleRedirectToMembeships = () => {
        pushUrlPath('/memberships')
    }

    return (
        <Box mb={isMobile ? '24px' : '48px'}>
            <Typography className="heading-h2 account-section-title">Memberships</Typography>
            {subscriptions && subscriptions.length > 0 ? <Box display='flex' flexDirection={'column'} style={{ gap: '24px' }}>
                {subscriptions.map((subscription) => {
                    return <AccountMembership key={subscription.id} subscription={subscription} currency={uiConfig?.currency || CurrencyType.USD} onManage={() => handleManageCard(subscription.stripeCustomerId || '')} />
                })}
            </Box> :
                <DefaultContainer2 style={{ padding: '24px' }}>
                    <Typography className='root'>You have no subscribed memberships</Typography>
                    <button onClick={handleRedirectToMembeships} className='btn-link-v2'> <Typography className='root bold account-link-text-v2'>Subscribe for a membeship</Typography> <ArrowForward className='arrow-icon' /></button>
                </DefaultContainer2>
            }
            {
                error ? <NoAvailiableGuests mt={'24px'} text={error} noSpace /> : null
            }
        </Box>
    )
}

export default connector(AccountMemberships)