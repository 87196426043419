import React from "react";
import "./policy.scss";
import HTMLRenderer from "../HTMLRenderer/HTMLRenderer";

interface Props {
  text?: string;
}

export const Policy = ({ text }: Props) => (
  <div className="venue-policy">
    <HTMLRenderer html={text} className="venue-policy-value" />
  </div>
);
