import { Box } from '@material-ui/core';
import { getSearch, goBack, replace } from "connected-react-router";
import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { getPlansAction, initialMembershipLoadAction, setBillingAction, setSinglePlan } from '../../actions/membership-actions';
import useSettingColors from '../../hooks/useSettingColors';
import { selectBilling, selectIsMembershipRequestInProgress, selectPlans, selectPlan, selectSinglePlan } from '../../reducers/membership';
import { State } from '../../store/types';
import Footer2 from '../common/Footer/Footer2';
import Header2 from '../common/Header/Header2';
import MembershipsHeader from './membershipHeader';
import MembershipsList from './membershipsList';
import { selectUIConfig } from '../../reducers/ui-reducer';
import { MembershipBilling } from '../../store/types/memberships';

const mapStateToProps = (state: State) => ({
    plans: selectPlans(state),
    isRequestInProgress: selectIsMembershipRequestInProgress(state),
    uiConfig: selectUIConfig(state),
    billing: selectBilling(state),
    selectedPlan: selectPlan(state),
    singlePlan: selectSinglePlan(state),
    searchParams: getSearch(state)
});

const mapDispatchToProps = {
    goBack,
    getPlans: getPlansAction,
    setBilling: setBillingAction,
    initialMembershipLoad: initialMembershipLoadAction,
    setSinglePlan: setSinglePlan,
    replace
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Prop = ConnectedProps<typeof connector>;

const Memberships = ({ goBack, plans, isRequestInProgress, getPlans, uiConfig, setBilling, billing, initialMembershipLoad, selectedPlan, singlePlan, setSinglePlan, searchParams, replace }: Prop) => {
    const { bodyColor } = useSettingColors()
    const [isPlansLoaded, setIsPlansLoaded] = useState(false);

    useEffect(() => {
        getPlans();
    }, [getPlans]);

    useEffect(() => {
        if (plans.length > 0 && !isPlansLoaded) {
            initialMembershipLoad();
            setIsPlansLoaded(true);
        }
    }, [plans, isPlansLoaded, initialMembershipLoad]);

    const filteredPlans = !!singlePlan ? [selectedPlan || singlePlan] : plans?.filter(plan => billing === MembershipBilling.ANNUAL ? !!(+plan.annualRate) : !!(+plan.monthlyRate)) || [];

    const handleClearFeatured = () => {
        setSinglePlan(undefined);
        replace({ search: searchParams });
    }

    return (
        <>
            <Box bgcolor={bodyColor} pb={'186px'} flex={1}>
                <Header2 handleBackButton={goBack} />
                <Box maxWidth={'816px'} marginX={'auto'}>
                    <MembershipsHeader
                        title={uiConfig?.membershipTitle || 'Become a member'}
                        description={uiConfig?.membershipDescription || 'Choose the membership that fits you best!'}
                        onAnnualPlanSelect={() => setBilling(MembershipBilling.ANNUAL)}
                        onMonthlyPlanSelect={() => setBilling(MembershipBilling.MONTHLY)}
                        selectedBilling={billing}
                        hideTabs={!!singlePlan}
                    />
                    {singlePlan && (
                        <Box textAlign={'center'} fontSize={'24px'}>
                            <button className="clear-button" onClick={handleClearFeatured}>
                                clear filter ×
                            </button>
                        </Box>
                    )}
                    <MembershipsList
                        noPlansAvailableMessage={uiConfig?.noPlansAvailableMessage || 'There are currently no memberships.'}
                        isLoading={isRequestInProgress}
                        plans={filteredPlans}
                        isAnnual={billing === MembershipBilling.ANNUAL}
                        selectedPlan={selectedPlan}
                    />
                </Box>
            </Box>
            <Footer2 />
        </>
    )
}

export default connector(Memberships);
