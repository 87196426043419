import React, { useState } from 'react';
import './Addons.scss'
import AddonsCard from "../../AddonsCard/AddonsCard";
import { Addon, State } from "../../../store/types";
import { selectUIConfig } from "../../../reducers/ui-reducer";
import { connect, ConnectedProps } from "react-redux";
import { startBookingAction } from "../../../actions/reservation-actions";
import { PrimaryButton } from "../../../../../common/components/PrimaryButton/PrimaryButton";
import { selectCurrentPackage, selectVenue } from "../../../reducers/venues";
import { selectGiftCardAmount, selectIsUpdateReservation, selectOldReservation, selectReservation, selectReservationAddons } from "../../../reducers/reservation";
import HtmlRenderer from '../../../../../common/components/HTMLRenderer/HTMLRenderer';
import { useViewport } from '../../../hooks/responsive';
import { OrderSummary } from '../../../../../common/components/OrderSummary/OrderSummary';
import { ErrorAlert } from '../../../../../common/components/Alert/Alert';
import MobileFixedButton from '../../MobileFixedButton/MobileFixedButton';
import { backToReservationAction } from '../../../actions/venue-actions';

const mapStateToProps = (state: State) => ({
  uiConfig: selectUIConfig(state),
  currentPackage: selectCurrentPackage(state),
  reservation: selectReservation(state),
  activeVenue: selectVenue(state),
  isUpdateReservation: selectIsUpdateReservation(state),
  oldReservation: selectOldReservation(state),
  giftCardAmount: selectGiftCardAmount(state),
  reservationAddons: selectReservationAddons(state),
});

const mapDispatchToProps = {
  startBooking: startBookingAction,
  backToReservation: backToReservationAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const Addons = (
  {
    uiConfig,
    startBooking,
    currentPackage,
    reservation,
    activeVenue,
    isUpdateReservation,
    oldReservation,
    giftCardAmount,
    backToReservation,
    reservationAddons,
  }: ConnectedProps<typeof connector>
) => {
  const isHideDuration = uiConfig?.hideDuration || currentPackage?.hidePackageDuration;
  const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;
  const { isMobile } = useViewport();
  const { date } = reservation;
  const [fixedReservationAddons] = useState(reservationAddons);
  const [showRequiredError, setShowRequiredError] = useState(false);

  const onContinue = () => {
    const requiredAddons = activeVenue?.addons.filter(addon => addon.required) || [];

    if (requiredAddons.some(addon => !reservationAddons.some(reservationAddon => reservationAddon.addonId === addon.id))) {
      setShowRequiredError(true);
      return
    }

    setShowRequiredError(false)

    if (currentPackage) {
      if (!activeVenue || !currentPackage) return;
      startBooking({ venue: activeVenue, date, currentPackage, isContinue: true });
    } else {
      if (!activeVenue) return;
      startBooking({ venue: activeVenue, date, isContinue: true });
    }
  }

  return (
    <div className="addons-container">
      <div className="addons-inner-container main-content">
        <div
          className={`addons ${isMobile ? "mobile" : ""}`}
        >
          <div className="title">Add Ons</div>
          <HtmlRenderer html={activeVenue?.addonsDescription} className="content-description" />
          <h3 className='addons-title'>
            Choose your Add Ons
          </h3>
          {
            activeVenue?.addons.map(
              (item: Addon) => (
                <AddonsCard
                  key={item.id}
                  item={item}
                />
              ))
          }
          {showRequiredError && <ErrorAlert className='addons-error' text='Select all required add ons' />}
          {isMobile ? (
            <OrderSummary
              reservation={reservation}
              venue={activeVenue}
              onEdit={backToReservation}
              actionText={uiConfig?.actionText}
              isMobile={isMobile}
              isHideDuration={isHideDuration}
              currentPackage={currentPackage}
              giftCardAmount={giftCardAmount}
              isUpdateReservation={isUpdateReservation}
              oldDeposit={reservation?.payed}
              reservationAddons={fixedReservationAddons}
              twelveHourClockFormat={twelveHourClockFormat}
            >
              <MobileFixedButton onClick={onContinue} name="continue" uiConfig={uiConfig}>
                CONTINUE
              </MobileFixedButton>
            </OrderSummary>
          ) : (
            <PrimaryButton
              onClick={onContinue}
              name="continue"
              uiConfig={uiConfig}
            >
              CONTINUE
            </PrimaryButton>
          )}
        </div>
        {!isMobile && (
          <>
            <div className="column-delimiter"></div>
            <div className="reservation-summary">
              <OrderSummary
                reservation={reservation}
                venue={activeVenue}
                onEdit={backToReservation}
                actionText={uiConfig?.actionText}
                isMobile={isMobile}
                isHideDuration={isHideDuration}
                currentPackage={currentPackage}
                giftCardAmount={giftCardAmount}
                isUpdateReservation={isUpdateReservation}
                oldDeposit={reservation?.payed}
                reservationAddons={fixedReservationAddons}
                twelveHourClockFormat={twelveHourClockFormat}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default connector(Addons); 