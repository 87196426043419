import { Box } from '@material-ui/core';
import { Checkbox } from 'office-ui-fabric-react';
import React from 'react';
import HTMLRenderer from '../../../../../common/components/HTMLRenderer/HTMLRenderer';
import { rem } from '../../../../../common/utils/formats';

const checkboxStyle = {
    root: {
        margin: "0 0 30px",
    },
    checkbox: {
        width: rem(20),
        height: rem(20),
    },
    text: {
        fontSize: rem(13),
        lineHeight: "1.5",
    },
    checkmark: {
        fontSize: rem(13),
    },
};

interface Props {
    venueInfo?: string;
    selected: boolean;
    onChange: (_: any, isChecked?: boolean) => void;
}

const VenuePolicies = ({ venueInfo, selected, onChange }: Props) => {
    return (
        <Box pb={"100px"}>
            <Box className="venue-policy">
                <Box className="heading">VENUE POLICIES</Box>
                <HTMLRenderer
                    html={venueInfo}
                    className="venue-policy-value"
                />
            </Box>
            <Checkbox
                styles={checkboxStyle}
                label="I have read and agree to the venue policies."
                checked={selected}
                onChange={onChange}
            />
        </Box>
    )
}

export default VenuePolicies