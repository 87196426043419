import { Box } from "@material-ui/core";
import { getSearch } from "connected-react-router";
import React, { useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import {
    cleanAuthErrorAction,
    continueAsGuestAction,
    loginAction,
    recoverAction,
    registerAction,
    resetRecoverStatusAction,
} from "../../../actions/auth-actions";
import { useViewport } from "../../../hooks/responsive";
import useSettingColors from "../../../hooks/useSettingColors";
import {
    selectAuthError,
    selectIsAuthRequestInProgress,
    selectIsRecoverCompleted,
} from "../../../reducers/auth-reducer";
import { selectUIConfig } from "../../../reducers/ui-reducer";
import { State } from "../../../store/types";
import { parseUrlQuery } from "../../../utils/urlSearchQuery";
import DefaultContainer2 from "../../common/DefaultContainer2/DefaultContainer2";
import { ContinueAsGuest2 } from "../ContinueAsGuest/ContinueAsGuest2";
import { Login2 } from "../Login/Login2";
import { PhoneLoginModal2 } from "../PhoneLogin/PhoneLoginModal2";
import { Recover2 } from "../Recover/Recover2";
import { Registration2 } from "../Registration/Registration2";
import "./account.scss";


const mapStateToProps = (state: State) => ({
    error: selectAuthError(state),
    isInProgress: selectIsAuthRequestInProgress(state),
    isRecoverCompleted: selectIsRecoverCompleted(state),
    searchParams: getSearch(state),
    uiConfig: selectUIConfig(state)
});

const mapDispatchToProps = {
    login: loginAction,
    recover: recoverAction,
    register: registerAction,
    continueAsGuest: continueAsGuestAction,
    cleanAuthError: cleanAuthErrorAction,
    resetRecoverStatus: resetRecoverStatusAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const Account2 = ({
    login,
    recover,
    register,
    error,
    isInProgress,
    isRecoverCompleted,
    resetRecoverStatus,
    cleanAuthError,
    searchParams,
    uiConfig,
    continueAsGuest,
}: Props) => {
    const params = parseUrlQuery(searchParams);
    const isDisableGuestCheckout = uiConfig?.disableGuestCheckout;
    const showLoginAsGuest = !!params.continue && !isDisableGuestCheckout;
    const showCreateAccount = !uiConfig?.hideCreateAccount || !showLoginAsGuest;
    const { bodyColor } = useSettingColors()

    const { isMobile } = useViewport()
    const [isLogin, setIsLogin] = useState(false);
    const [isGuest, setIsGuest] = useState(!showCreateAccount);
    const [isRecover, setIsRecover] = useState(false);

    const [isPhoneLoginOpen, setIsPhoneLoginOpen] = useState(false);

    const handleOpenPhoneLogin = () => {
        setIsPhoneLoginOpen(true);
    }
    const handleClosePhoneLogin = () => {
        setIsPhoneLoginOpen(false);
    }

    const onCreateAccount = () => {
        setIsGuest(false);
        setIsLogin(false);
    }
    const onSignIn = () => {
        setIsGuest(false);
        setIsLogin(true);
    }
    const onContinueAsGuest = () => {
        setIsGuest(true);
        setIsLogin(false);
    }

    let content = null
    if (isLogin && isRecover) {
        content = <Recover2
            recover={recover}
            toLogin={() => setIsRecover(false)}
            error={error}
            isInProgress={isInProgress}
            isRecoverCompleted={isRecoverCompleted}
            resetRecoverStatus={resetRecoverStatus}
            cleanError={cleanAuthError}
            uiConfig={uiConfig}
        />
    } else if (isGuest && !uiConfig?.disableGuestCheckout) {
        content = <ContinueAsGuest2
            continueAsGuest={continueAsGuest}
            error={error}
            cleanError={cleanAuthError}
            continueReservation={!!params.continue}
            uiConfig={uiConfig}
            onCreateAccount={onCreateAccount}
            onSignIn={onSignIn}
        />
    } else if (isLogin) {
        content = <Login2
            login={login}
            toRecover={() => setIsRecover(true)}
            error={error}
            isInProgress={isInProgress}
            cleanError={cleanAuthError}
            continueReservation={!!params.continue}
            onCreateAccount={onCreateAccount}
            onCheckoutAsGuest={onContinueAsGuest}
            onRecover={() => setIsRecover(true)}
            onSignInWithPhone={handleOpenPhoneLogin}
            showCheckoutAsGuest={!uiConfig?.disableGuestCheckout}
            uiConfig={uiConfig}

        />
    } else {
        content = <Registration2
            register={register}
            error={error}
            cleanError={cleanAuthError}
            continueReservation={!!params.continue}
            uiConfig={uiConfig}
            onContinueAsGuest={onContinueAsGuest}
            onSignIn={onSignIn}
            showCheckoutAsGuest={!uiConfig?.disableGuestCheckout}

        />
    }


    return (
        <Box className="auth-container" py={'32px'} bgcolor={bodyColor} >
            <Box className="auth-inner-container" height={'100%'} >
                <DefaultContainer2 className={`${isMobile ? 'mobile-container-v2' : ''}`}>
                    {content}
                </DefaultContainer2>
            </Box>
            <PhoneLoginModal2
                open={isPhoneLoginOpen}
                onClose={handleClosePhoneLogin}
                login={login}
                toRecover={() => setIsRecover(true)}
                error={error}
                isInProgress={isInProgress}
                cleanError={cleanAuthError}
                continueReservation={!!params.continue}
                onCreateAccount={onCreateAccount}
                onCheckoutAsGuest={onContinueAsGuest}
                onRecover={() => setIsRecover(true)}
                onOutsideClick={handleClosePhoneLogin}
                showCheckoutAsGuest={!uiConfig?.disableGuestCheckout}>
                <>
                </>
            </PhoneLoginModal2>
        </Box>
    );
};

export default connector(Account2);
