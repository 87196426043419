import dayjs from "dayjs";
import { times } from "lodash";
import { formatPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { PackageDto } from "../../server/src/dto/package.dto";
import { PackageNameDto } from "../../server/src/dto/packageName.dto";
import { ReservationDto } from "../../server/src/dto/reservation.dto";
import { VenueDto } from "../../server/src/dto/venue.dto";
import { VenueNameDto } from "../../server/src/dto/venueName.dto";
import { VenueSettingDto } from "../../server/src/dto/venueSetting.dto";
import { CurrencyType, Pricing } from "../../server/src/entities/enums";
import { createTimeText, currencyFormatter, currencyFormatterForBigPrice, formatDuration, slotToTime } from "../../server/src/utils/formats";

export * from "../../server/src/utils/formats";

export const slotToTimeWithDuration = (slot: number, timeSlotDuration: number, duration: number, twelveHourClockFormat: boolean, timeShifting?: number) => {
  const timeSlotShifting = timeShifting && !isNaN(+timeShifting) ? +timeShifting : 0;
  const minutesShifting = Math.floor(timeSlotShifting % 60);
  const slotsInHour = 60 / timeSlotDuration;
  const slotsWithDuration = slot + duration;

  const hourBefore = Math.floor((slot * timeSlotDuration + timeSlotShifting) / 60);
  const minutesBefore = Math.floor((timeSlotDuration * (slot % slotsInHour) + minutesShifting) % 60);

  const hourAfter = Math.floor((slotsWithDuration * timeSlotDuration + timeSlotShifting) / 60);
  const minutesAfter = Math.floor((timeSlotDuration * (slotsWithDuration % slotsInHour) + minutesShifting) % 60);

  const timeBefore = createTimeText(hourBefore, minutesBefore, twelveHourClockFormat);
  const timeAfter = createTimeText(hourAfter, minutesAfter, twelveHourClockFormat);
  return `${timeBefore} - ${timeAfter}`;
};

export const formatReservationDate = (reservation: ReservationDto, format: string) => {
  let date = dayjs(reservation.date)
  if (reservation.slots * reservation.timeSlotDuration >= 24 * 60) {
    date = date.add(1, 'day')
  }
  return date.format(format);
};

export const formatMinPackagePrice = (price: number) => {
  if (price >= 1000) {
    return (Math.floor(price)).toLocaleString();
  }
  return price.toFixed(2);
};

export const formatPhone = (phone?: string) => {
  if (phone == '(___) ___-____') {
    return '';
  }
  if (!phone || phone.length !== 10) {
    return phone || '';
  }
  return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`;
};
export const formatUSPhone = (phone: string) => {
  if (!phone) {
    return phone;
  }
  const phoneNumber = parsePhoneNumber(phone);
  if (phoneNumber && phoneNumber.country === "US") {
    return formatPhoneNumber(phone);
  }
  return phone;
};

export const rem = (px: number) => {
  return `${px / 16}rem`;
};

export const getCurrencySymbol = (currency: CurrencyType) => {
  let currencyInMessage = '';
  let currencySymbol = '$';
  switch (currency) {
    case CurrencyType.CAD:
      currencyInMessage = ' CAD';
      break;
    case CurrencyType.NZD:
      currencyInMessage = ' NZD';
      break;
    case CurrencyType.AUD:
      currencyInMessage = ' AUD';
      break;
    case CurrencyType.GBP:
      currencySymbol = '£';
      break;
    case CurrencyType.EUR:
      currencySymbol = '€';
      break;
    default:
      currencyInMessage = '';
  }
  return { currencySymbol, currencyInMessage };
};

export const formatPriceInUSD = new Intl.NumberFormat("en-US", { maximumFractionDigits: 0, currency: "USD", style: "currency" }).format;

export const formatPriceBasedOnValue = (price: number, currency: CurrencyType, IsTruncateLargePrice?: boolean) => {
  let result = currencyFormatter(price);

  if (IsTruncateLargePrice && Math.floor(price).toString().length > 3) {
    result = result.split('.')[0]; // Remove the decimal part
  }

  if (currency === CurrencyType.GBP) {
    return result.replace('$', '£');
  }
  if (currency === CurrencyType.EUR) {
    return result.replace('$', '€');
  }
  return result;
}

export const formatPriceForTimeSlot = (price: number, currency: CurrencyType) => {
  let result = '';
  if (price >= 1000) {
    result = `${currencyFormatterForBigPrice(price)}`
  } else {
    result = `${currencyFormatter(price)}`
  }
  if (currency === CurrencyType.GBP) {
    result = result.replace('$', '£');
  }
  if (currency === CurrencyType.EUR) {
    result = result.replace('$', '€');
  }
  return result
}

export const composeTimeSlots = ({
  date,
  venue,
  duration,
  isShowReservationTime,
  isHideDuration,
  guests,
  currentPackage,
  isAdmin,
  currency,
  twelveHourClockFormat,
}: {
  date: string,
  venue?: VenueDto,
  duration?: number,
  isShowReservationTime?: boolean,
  isHideDuration?: boolean,
  guests?: number,
  currentPackage?: PackageNameDto,
  isAdmin?: boolean,
  currency: CurrencyType,
  twelveHourClockFormat: boolean,
}) => {
  if (!venue) {
    return [];
  }
  if (!duration || (!getVenueDurations(venue, !!isAdmin).includes(duration) &&
    !isHideDuration) &&
    ((!(currentPackage?.enableDurationChoice || (isAdmin && currentPackage?.durationInSlotsForAdmin)) && !currentPackage?.duration) || (!!(currentPackage?.enableDurationChoice || (isAdmin && currentPackage?.durationInSlotsForAdmin)) && !getPackageDurations(currentPackage, isAdmin).includes(duration))) &&
    (!currentPackage?.isTimeSlotsInPackage || venue?.timeSlots.length === 0) &&
    !venue.enableAssignDuration && !currentPackage?.enableAssignDuration) {
    return [];
  }
  const timeSlotShifting = +venue.timeSlotShifting[dayjs(date).day()] || 0
  const priceLabel = !currentPackage
    ? venue?.pricing === Pricing.perPerson
      ? "/PP/HR"
      : venue?.pricing === Pricing.perPersonFlat
        ? "/PP"
        : (venue?.pricing === Pricing.flatRate || venue?.pricing === Pricing.flatRatePerLane)
          ? ""
          : "/HOUR"
    : currentPackage?.method === Pricing.perPerson
      ? "/PP/HR"
      : currentPackage?.method === Pricing.perPersonFlat
        ? "/PP"
        : (currentPackage?.method === Pricing.flatRate || currentPackage?.method === Pricing.flatRatePerLane)
          ? ""
          : "/HOUR"
  const guestSplit = venue?.guestSplit;
  let lanes = !currentPackage
    ? guests && guests <= guestSplit ? 1 : 2
    : currentPackage.numberOfLanes;
  if (currentPackage && !!currentPackage.countLanesByGuest && guests && currentPackage.maxGuestsPerLane) {
    lanes = Math.ceil(guests / currentPackage.maxGuestsPerLane);
    if (isAdmin && currentPackage.maxGuestsPerLaneForAdmin) {
      lanes = Math.ceil(guests / currentPackage.maxGuestsPerLaneForAdmin);
    }
  }
  const priceMultiplier = !currentPackage
    ? venue?.pricing === Pricing.flatRatePerLane ? lanes : 1
    : currentPackage?.method === Pricing.flatRatePerLane ? lanes : 1
  let selectedDuration = duration;
  if (!!currentPackage && !currentPackage?.enableDurationChoice && !(isAdmin && currentPackage?.durationInSlotsForAdmin)) {
    selectedDuration = currentPackage?.duration;
  }
  console.log(venue.timeSlots.map((item) => item.ageGroupPricing))
  console.log(currentPackage)
  let slots = !currentPackage
    ? venue.timeSlots.map((item) => {
      const price = `${formatPriceForTimeSlot(+item.rate * priceMultiplier, currency)}${priceLabel}`;
      return ({
        text: isShowReservationTime ?
          slotToTimeWithDuration(item.time, venue.timeSlotDuration, selectedDuration, twelveHourClockFormat, timeSlotShifting) :
          slotToTime(item.time, venue.timeSlotDuration, twelveHourClockFormat, timeSlotShifting),
        key: item.time,
        price,
        time: isShowReservationTime ?
          slotToTimeWithDuration(item.time, venue.timeSlotDuration, selectedDuration, twelveHourClockFormat, timeSlotShifting) :
          slotToTime(item.time, venue.timeSlotDuration, twelveHourClockFormat, timeSlotShifting),
        slot: item.time,
        ageGroupPricing: item.ageGroupPricing
      })
    })
    : venue.timeSlots.map((item) => {
      const price = `${formatPriceForTimeSlot(+item.rate * priceMultiplier, currency)}${priceLabel}`;
      return ({
        text: isShowReservationTime ?
          slotToTimeWithDuration(item.time, venue.timeSlotDuration, selectedDuration, twelveHourClockFormat, timeSlotShifting) :
          slotToTime(item.time, venue.timeSlotDuration, twelveHourClockFormat, timeSlotShifting),
        key: item.time,
        price,
        time: isShowReservationTime ?
          slotToTimeWithDuration(item.time, venue.timeSlotDuration, selectedDuration, twelveHourClockFormat, timeSlotShifting) :
          slotToTime(item.time, venue.timeSlotDuration, twelveHourClockFormat, timeSlotShifting),
        slot: item.time,
        ageGroupPricing: item.ageGroupPricing


      })
    });
  if (venue.isStartTimeOnly && !isAdmin) {
    slots = slots.filter(slot => venue.filterTimeSlots.includes(slot.slot));
  }

  return slots;
};

export const composePartyTimeSlots = (date: string, twelveHourClockFormat: boolean, venue?: VenueDto) => {
  if (!venue) {
    return [];
  }
  // 30 min //12 am  48items
  // how log is your event  30 1, 1.5,2,2.5 -- 8min
  const timeSlotDuration = 30;
  const timeSlots = times(48, (item) => item);
  let slots = timeSlots.map((item) => ({
    text: slotToTime(item, timeSlotDuration, twelveHourClockFormat),
    key: item,
    time: slotToTime(item, timeSlotDuration, twelveHourClockFormat),
    slot: item,
  }));
  const startSlot = getAllowedStartSlot(venue, date);
  slots = slots.filter((slot) => slot.slot >= startSlot);
  return slots;
};

export const getPartyDurationList = () => {
  return Array(16).fill(null).map((_, index) => {
    const duration = index + 1;
    return {
      key: duration,
      text: formatDuration(duration, 30),
    }
  });
};

export const getVenueDurations = (venue: VenueDto | VenueNameDto | VenueSettingDto, isAdmin?: boolean): number[] => {
  const durationInSlots = isAdmin && venue?.durationInSlotsForAdmin ? venue.durationInSlotsForAdmin : venue.durationInSlots;
  const durationSlotsString = durationInSlots.split(",");
  const durationSlots = durationSlotsString.map((slot) =>
    parseInt(slot.trim())
  );
  return durationSlots;
};

export const getPackageDurations = (selectedPackage?: PackageDto | PackageNameDto, isAdmin?: boolean): number[] => {
  if (!selectedPackage) {
    return [];
  }
  const durationInSlots = isAdmin && selectedPackage?.durationInSlotsForAdmin ? selectedPackage.durationInSlotsForAdmin : selectedPackage.durationInSlots;
  const durationSlotsString = durationInSlots.split(",");
  const durationSlots = durationSlotsString.map((slot) =>
    parseInt(slot.trim())
  );
  return durationSlots;
};

export const getVenueAssignedDuration = (venue: VenueDto | VenueNameDto | VenueSettingDto, guests: number, isAdmin?: boolean): number => {
  if (guests && venue) {
    const assignDuration = isAdmin && venue?.assignDurationForAdmin ? venue.assignDurationForAdmin : venue.assignDuration;
    const durationSlotsString = assignDuration.split(';').map((item, index) => ({ id: index, guests: item.split(':')[0] || '', duration: item.split(':')[1] || '' }))
    const sortGuestArray = durationSlotsString.sort((a, b) => a.guests < b.guests ? 1 : -1);
    const guestsArray = durationSlotsString.map(item => item.guests);
    if (guestsArray.includes(guests.toString())) {
      const durationSlot = sortGuestArray.find(item => +item.guests === guests)
      return durationSlot ? +durationSlot?.duration : +durationSlotsString[0].duration
    }
    const durationSlot = sortGuestArray.find(item => +item.guests <= guests)
    return durationSlot ? +durationSlot?.duration : +durationSlotsString[0].duration
  }
  return 1
};

export const formatOccasionsAndGuestDetails = (listOfOptions?: string) => {
  if (!listOfOptions) {
    return []
  }
  return listOfOptions.split(';').map(item => item.split(':')[0]).map(key => ({ key, text: key }));
};

export const getAllowedStartSlot = (venue: VenueDto, date: string) => {
  //get time for particular timezone,
  const venueDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: venue.timeZone })
  );
  const allowedReservationDate = dayjs(venueDate).add(venue.allowedReservationInterval, 'minute')
  let startSlot = 0;
  if (isNaN(dayjs(date).toDate().getTime())) {
    startSlot = 0; //invalid state
    console.log("cannot calculate venue date", venue.timeZone, "for", date);
  } else if (allowedReservationDate.startOf("d").isBefore(dayjs(date))) {
    startSlot = 0; //this day is not started at the venue
  } else if (allowedReservationDate.startOf("d").isAfter(dayjs(date))) {
    startSlot = 100; //this day is over at the venue
  } else {
    const timeDiff = allowedReservationDate.diff(dayjs(date), 'minute')
    startSlot = Math.ceil(timeDiff / venue.timeSlotDuration);
  }
  return startSlot
}

export const formatLanesCount = (guests: number, guestSplit?: number, isOnlyOneLane?: boolean) => {
  if (isOnlyOneLane || !guestSplit) {
    return 1
  }
  return Math.ceil(guests / guestSplit);
};

export const formatLanes = (lanes: number, actionText: string) => {
  return `${lanes} ${actionText}${lanes === 1 ? "" : "s"}`;
};

export const activeCustomFieldsToUIModel = (activeCustomFields: string): string[] => {
  const customFields = activeCustomFields ? activeCustomFields.split(",") : [];
  return customFields || [];
};

export const uiModelToActiveCustomFields = (activeCustomFields: string[]): string => {
  const result = activeCustomFields.map((customField) => `${customField}`).join(",");
  return result;
};

export const getReservationTotalPrice = (reservation: ReservationDto, isInfo?: boolean): number => {
  if (reservation?.total && !isNaN(Number(reservation.total)) && !isInfo) {
    return Number(reservation.total)
  }
  return Number(reservation.price) + Number(reservation.tax) + Number(reservation.serviceFee) + Number(reservation.addonsPrice);
};

export const totalSumFees = (fees: { name: string, value: string }[]): number => {
  let sum = 0;

  for (const fee of fees) {
    const numericValue = parseFloat(fee.value.replace('$', ''));
    sum += numericValue;
  }

  return sum;
};

export const calculateDeposit = ({
  reservation,
  giftCardAmount,
  isUpdateReservation,
  isUpdateWithVenueChange,
  oldReservation,
}: {
  reservation: ReservationDto;
  giftCardAmount: number;
  isUpdateReservation: boolean;
  isUpdateWithVenueChange: boolean;
  oldReservation?: ReservationDto;
}): number => {
  //for new reservation
  let deposit = reservation.deposit - giftCardAmount;

  //for payed reservations (not modified res), we need pay all amount, it happen if we send payment link for partially payed reservation
  if (!isUpdateReservation && reservation?.payed > 0) {
    deposit = getReservationTotalPrice(reservation) - reservation.payed;
  }

  //for updated reservation
  if (isUpdateReservation && !isUpdateWithVenueChange && !!reservation?.payed) {
    const diffDeposit =
      +reservation.deposit - +reservation.payed + +reservation.modificationFee;
    deposit = diffDeposit - giftCardAmount - +reservation.giftCardPaid;
  }
  if (deposit < 0) {
    deposit = 0;
  }
  return deposit;
};