import React, { useState, useEffect } from "react";
import { InputField } from "../InputField/InputField";
import './couponCode.scss';

interface Props {
  isMobile: boolean;
  applyCouponCode?: (couponCodes: string[]) => void;
  coupons?:{ name: string; code: string }[],
  deposit: number;
  isSkipPayment?: boolean;
}

export const CouponCodes = ({
  isMobile,
  deposit,
  applyCouponCode,
  coupons = [],
  isSkipPayment,
}: Props) => {

  const [couponCode, setCouponCode] = useState<string>("");
  const [errorCouponCode, setErrorCouponCode] = useState<string>("");
  useEffect(() => {
    console.log('--', coupons)
    setCouponCode('')
    setErrorCouponCode('')
  }, [coupons.length])

  const onClearErrors = () => {
    setErrorCouponCode("");
  }

  const onChangeCouponCode = (value: string) => {
    if (!!errorCouponCode) {
      onClearErrors();
    }
    setCouponCode(value);
  }


  const onClickApply = () => {
    if (!couponCode) {
      setErrorCouponCode("Please enter a coupon code");
      return
    }

    applyCouponCode && applyCouponCode([...coupons.map(c=>c.code), couponCode]);
  }

  const onRemoveCoupon = (coupon: string) => {
    applyCouponCode && applyCouponCode(coupons.filter(item => item.code !== coupon).map(c=>c.code));
  }


  return (
      <div className={`coupon-code-form ${isMobile ? "mobile" : ""}`}>

        <div className={`coupon-code-info-container ${isMobile ? "mobile" : ""}`}>
          <div className="coupon-code-info-fields">
            <InputField
              placeholder='Coupon Code'
              name='couponCode'
              className="coupon-code-field card-number"
              value={couponCode || ""}
              onChange={(_: any, text?: string) => onChangeCouponCode(text || "")}
              autoComplete={"off"}
              disabled={deposit === 0 || !!isSkipPayment}
              errorMessage={errorCouponCode}
            />
          </div>
          <button
            className="coupon-code-button"
            onClick={onClickApply}
            type="button"
            disabled={!couponCode}
          >
            APPLY
          </button>
        </div>
        <div className="applied-coupon-container">
          {coupons.map(coupon => <div key={coupon.code} className="applied-coupon">
            <div>{coupon.name}</div>
            <button
              className="remove-coupon-button"
              onClick={() => onRemoveCoupon(coupon.code)}
              type="button"
            >
              ×
            </button>
          </div>)}
        </div>
      </div>
  );
};
