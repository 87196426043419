import React from "react";
import { mergeStyleSets } from "office-ui-fabric-react";
import dayjs from "dayjs";
import { connect, ConnectedProps } from "react-redux";
import { State } from "../../../store/types";
import { selectUIConfig } from "../../../reducers/ui-reducer";
import { useViewport } from "../../../hooks/responsive";
import InstagramIcon from "../../../../assets/icon-instagram.svgr";
import FacebookIcon from "../../../../assets/icon-facebook.svgr";
import "./footer.scss";
import { Box, BoxProps, Link, Typography } from "@material-ui/core";
import RexIcon from "../../../../assets/rex-icon.svgr";
const mapStateToProps = (state: State) => ({
    uiConfig: selectUIConfig(state),
});
const connector = connect(mapStateToProps);
type Props = {
    className?: string;
    mobileProps?: BoxProps
} & ConnectedProps<typeof connector>;

const year = dayjs().format("YYYY");
const Footer2 = ({ uiConfig, className = '', mobileProps }: Props) => {
    const { isMobile, isTabletScreen } = useViewport();

    const footerBackgroundColor = uiConfig?.footerBackgroundColor || "#FFF";
    const footerForegroundColor = uiConfig?.footerForegroundColor || "#2E3A48";
    const homeLink = uiConfig?.homeLink;
    const companyName = uiConfig?.companyName;
    const instagramUrl = uiConfig?.instagramUrl;
    const facebookUrl = uiConfig?.facebookUrl;
    const contentStyles = mergeStyleSets({
        footer: {
            background: footerBackgroundColor,
            color: footerForegroundColor,
        },
        element: {
            color: footerForegroundColor,
        },
        delimer: {
            borderRight: `1px solid ${footerForegroundColor}`,
        },
        icon: {
            fill: footerForegroundColor,
        },
    });

    if (isTabletScreen) return null

    if (isMobile) {
        return (
            <Box mt={'32px'} width={'100%'} p={'8px 12px'} bgcolor={'white'} display={'flex'} justifyContent={'center'} alignItems={'center'} boxShadow={'0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.04), 0px 0px 4px 4px rgba(0, 0, 0, 0.04)'} {...mobileProps}>
                <Link href="https://reservewithrex.com/?utm_source=powered-by" target="_blank">
                    <RexIcon fill={footerForegroundColor} />
                </Link>
            </Box>
        )
    }

    return (
        <Box
            className={`footer v2 ${contentStyles.footer} ${className}`} bgcolor={footerBackgroundColor}
        >
            <Box className={`body-small-no-color`} style={{
                color: footerForegroundColor,

            }}>
                {`© ${year} `}

                {companyName}

            </Box>
        </Box>
    );
};

export default connector(Footer2);
