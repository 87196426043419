import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import { times } from "lodash";
import React, { Dispatch, MouseEvent, useEffect, useRef, useState } from 'react';
import { ConnectedProps, connect } from "react-redux";
import { formatPriceBasedOnValue } from '../../../../../common/utils/formats';
import defaultImage from "../../../../assets/default-image.jpg";
import PlusIcon from '../../../../assets/plus-icon.svg';
import { startBookingAction } from '../../../actions/reservation-actions';
import { setAddonQuantityAction, toggleAddonSelectedStateAction } from '../../../actions/venue-actions';
import { useViewport } from '../../../hooks/responsive';
import { selectReservation, selectReservationAddons } from '../../../reducers/reservation';
import { selectUIConfig } from '../../../reducers/ui-reducer';
import { selectAddonsQuantities, selectCurrentPackage, selectVenue } from '../../../reducers/venues';
import { Addon, CurrencyType, State } from '../../../store/types';
import AddonModal from './AddonModal';
import AddonSwipeableModal from './AddonSwipeableModal';
import { getSelectedModifiersAndOptions } from './utils';
interface Props extends ConnectedProps<typeof connector> {
    addon: Addon;
    setAddingAddons: Dispatch<React.SetStateAction<boolean>>;

}

const mapStateToProps = (state: State) => ({
    reservation: selectReservation(state),
    uiConfig: selectUIConfig(state),
    activeVenue: selectVenue(state),
    addonQuantities: selectAddonsQuantities(state),
    reservationAddons: selectReservationAddons(state),
    currentPackage: selectCurrentPackage(state),
});

const mapDispatchToProps = {
    setAddonQuantity: setAddonQuantityAction,
    toggleSelected: toggleAddonSelectedStateAction,
    startBooking: startBookingAction,

};

const connector = connect(mapStateToProps, mapDispatchToProps);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: 'white',
        color: '#212B36',
        height: '120px',
        boxShadow: '0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.04), 0px 0px 4px 4px rgba(0, 0, 0, 0.04)',
        borderRadius: '8px',

    },
    img: { width: '120px', height: '120px', objectFit: 'cover', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' },
    name: {
        fontSize: '16px', fontWeight: 600, maxWidth: '140px', overflow: 'hidden', textOverflow: 'ellipsis',
        display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', lineHeight: 1.1
    },
    tabletName: {
        maxWidth: '114px',
    },
    price: { fontSize: '16px', fontWeight: 600 },
    button: {
        minWidth: '48px', width: '48px', height: '48px',
        textAlign: 'center', marginTop: '-5px',
        fontSize: '18px', fontWeight: 600, lineHeight: '32px',
        transition: 'all 0.2s ease-in-out',
        '&:hover': { opacity: 0.8 },
    },
    tabletButton: {
        minWidth: '40px', width: '40px', height: '40px',

    },
    description: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '150px',
        fontWeight: 400,
        fontSize: '14px',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        marginTop: 'auto',
        paddingBottom: '4px',
        lineHeight: 1.1,
        maxHeight: '28px'

    },
}));

type OrderItem = {
    quantity: number;
};
export type AddonWithQuantity = Addon & OrderItem;

const AddonItem: React.FC<Props> = ({ addon, uiConfig, toggleSelected, startBooking, activeVenue, currentPackage, reservation, reservationAddons }) => {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    // const preSelectedAddons = reservation.addons
    const [addonsSelected, setAddonsSelected] = useState<AddonWithQuantity[]>([])
    const { date } = reservation;
    const { isMobile, isTabletScreen } = useViewport()
    const addonMinQuantity = addon.matchGuestCount ? reservation.guests : addon.minimumQuantity;
    const addonMaxQuantity = addon.matchGuestCount ? reservation.guests : addon.maximumQuantity;
    console.log(getSelectedModifiersAndOptions(addon, reservation.addons), 'selectedModifiers')

    console.log(addon, 'addon')
    console.log(reservation.addons, 'reservation.addons')

    useEffect(() => {
        reservation?.addons?.forEach(
            add => {
                if (add.addonId === addon.id) {
                    toggleSelected(addon, true, add.quantity, getSelectedModifiersAndOptions(addon, reservation.addons))
                    handleRequestForSummary()
                }
            }
        )
    }, [reservation.addons])


    const quantityOptions = times((addonMaxQuantity + 1 - addonMinQuantity), (i) => ({
        key: String(i + addonMinQuantity),
        text: String(i + addonMinQuantity),
    }));

    const containerRef = useRef<HTMLDivElement>(null);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
            setOpen(false);
        }
    };

    const handleRequestForSummary = () => {
        if (currentPackage) {
            if (!activeVenue || !currentPackage) return;
            startBooking({ venue: activeVenue, date, currentPackage, isContinue: false });
        } else {
            if (!activeVenue) return;
            startBooking({ venue: activeVenue, date, isContinue: false });
        }
    }

    useEffect(() => {
        // @ts-ignore
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // @ts-ignore
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);




    const currency = uiConfig?.currency || CurrencyType.USD;
    const price = formatPriceBasedOnValue(addon.price, currency, true)

    const isQunaititySelected = (reservation?.addons?.some((add => add.addonId === addon.id)) || reservationAddons.some(add => addon.id === add.addonId))
    return (
        <>
            <Box
                className={classes.root}
                width={!isMobile ? '100%' : 'calc(100vw - 32px)'}
                overflow={'hidden'}
            >
                <img src={addon.image || defaultImage} alt={addon.name} className={classes.img} />
                <Box p={isTabletScreen ? '16px' : '16px 24px'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'} minWidth={(isTabletScreen || isMobile) ? 'none' : '276px'} {...((isTabletScreen || isMobile) ? { width: '100%' } : {})}>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-start'} style={{ gap: '8px' }}>
                        <Typography className={`${classes.name} ${isTabletScreen ? classes.tabletName : ''}`}>{addon.required && <span className='required-star'>*</span>}{addon.name}</Typography>
                        <Typography className={classes.price}>{price}</Typography>
                    </Box>
                    <Box display={'flex'} justifyContent={'space-between'} style={{ gap: '8px' }}>
                        <Typography className={classes.description}>{addon.shortDescription}</Typography>
                        <Button
                            onClick={handleOpen}
                            className={`${classes.button} ${isTabletScreen ? classes.tabletButton : ''}`}
                            style={{ backgroundColor: isQunaititySelected ? '#2E3A48' : '#EBECF0', color: isQunaititySelected ? 'white' : 'black', }}
                        >
                            {
                                isQunaititySelected ? reservationAddons.find(add => addon.id === add.addonId)?.quantity || reservation?.addons?.find((add => add.addonId === addon.id))?.quantity || 0 :
                                    <img src={PlusIcon} alt="PlusIcon" />
                            }
                        </Button>
                    </Box>
                </Box>
            </Box>
            {open && (isMobile ? <AddonSwipeableModal addonsSelected={reservationAddons} reservationAddons={reservation.addons || []} activeVenue={activeVenue} setAddonsSelected={setAddonsSelected} toggleSelected={toggleSelected} addon={addon} price={price} quantityOptions={quantityOptions} containerRef={containerRef} open={open} onClose={handleClose} setOpen={setOpen} onRequestForSummary={handleRequestForSummary} /> :
                <AddonModal addonsSelected={reservationAddons} reservationAddons={reservation.addons || []} activeVenue={activeVenue} setAddonsSelected={setAddonsSelected} toggleSelected={toggleSelected} addon={addon} price={price} quantityOptions={quantityOptions} containerRef={containerRef} onClose={handleClose} setOpen={setOpen} onRequestForSummary={handleRequestForSummary} />)
            }
        </>
    );
};

export default connector(AddonItem);
