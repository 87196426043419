import React from 'react';
import {Modal} from "office-ui-fabric-react";
import {useViewport} from "../../../hooks/responsive";
import {FeeType, Reservation, UIConfigDto} from "../../../store/types";
import {PrimaryButton} from "../../../../../common/components/PrimaryButton/PrimaryButton";
import {SelectWithIcon} from "../../../../../common/components/SelectWithIcon/SelectWithIcon";
import {WarningAlert} from "../../../../../common/components/Alert/Alert";
import './cancel-reservation-confirmation-modal.scss'

type Props = {
  currentReservation?: Reservation;
  uiConfig?: UIConfigDto;
  cancellationFee?: string;
  cancellationFeeType?: FeeType;
  onOk: (reason: string) => void;
  onClose: () => void;
};

const CancelReservationConfirmationModal = (
  {
    cancellationFee,
    cancellationFeeType,
    currentReservation,
    uiConfig,
    onOk,
    onClose
  }: Props
) => {
  const {isMobile} = useViewport()
  const feeInDollars = cancellationFeeType === FeeType.PERCENT
    ? ((parseInt(cancellationFee || "") || 0) * (currentReservation?.deposit || 0)  / 100).toFixed(2)
    : (parseInt(cancellationFee || "") || 0).toFixed(2);
  const cancellationAlertText = cancellationFeeType === FeeType.PERCENT
    ? `There is a ${cancellationFee}% charge ($${feeInDollars}) for cancelling your reservation`
    : `There is a $${feeInDollars} charge for cancelling your reservation`;


  const cancellationReasonsOptions = uiConfig?.cancellationReasons?.split(';').map(reason => ({
    key: reason,
    text: reason
  })) || [];

  const [reason, setReason] = React.useState<string>(cancellationReasonsOptions[0]?.key);

  const onConfirm = () => {
    onOk(reason);
  }

  return (
    <Modal
      isOpen
      onDismiss={() => onClose()}
      isBlocking={true}
      containerClassName={`cancel-reservation ${isMobile ? "mobile" : ""
      }`}
    >
      <div className="container">
        <h3 className='cancel-reservation-header'>
          Cancel Reservation
        </h3>
        <h5 className='cancel-reservation-id'>
          #{currentReservation?.number}
        </h5>
        <p className={`cancel-reservation-confirm-text ${uiConfig?.cancellationReasonsActive || !cancellationFee ? 'has-margin' : ''}`}>
          Are you sure you want to cancel your reservation?
        </p>
        {
          uiConfig?.cancellationReasonsActive && (
            <SelectWithIcon
              options={cancellationReasonsOptions}
              placeholder='Reason for canceling'
              value={reason}
              onChange={(option) => setReason(String(option))}
              contentClass='cancel-reservation-select'
              wrapperClassName={`${!cancellationFee ? 'selector-with-margin' : ''}`}
            />
          )
        }
        {!!cancellationFee && (
          <WarningAlert
            text={cancellationAlertText}
            className='cancel-reservation-alert'
          />
        )}
        <div className='cancel-reservation-buttons'>
          <button className='close' onClick={onClose}>
            Nevermind
          </button>
          <PrimaryButton uiConfig={uiConfig} onClick={onConfirm} className='confirm'>
            Confirm Cancellation
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default CancelReservationConfirmationModal;