import React, { useState } from "react";
import dayjs from "dayjs";
import { IconButton } from "office-ui-fabric-react";
import "./calendar.scss";
import { Month } from "../Month/Month";
import { MONTH_NAME_FORMAT } from "../../constants/timedate";
import { Availability } from "../../../server/src/dto/venue.dto";

interface CalendarProps {
  date: string;
  selectDate: (date: string) => void;
  availability: Availability;
  isMiddleScreen?: boolean;
}

const buttonStyle = {
  root: {
    width: "46px",
    margin: 0,
    height: "46px",
    minHeight: "46px",
    padding: "0px",
    background: "white",
    color: "black",
    borderRadius: "50%",
    border: "0.6px solid black",
  },
  rootHovered: {
    background: "white",
    color: "grey",
  },
};

export const Calendar = ({ date, selectDate, availability, isMiddleScreen }: CalendarProps) => {

  const [thisMonth, setMonth] = useState<boolean>(true);
  const [monthCoefficient, setMonthCoefficient] = useState<number>(0);

  const firstAvailabilityDate = Object.keys(availability)[0];
  const lastAvailabilityDate = Object.keys(availability)[Object.keys(availability).length - 1];

  const firstAvailabilityYear = dayjs(firstAvailabilityDate).year();
  const lastAvailabilityYear = dayjs(lastAvailabilityDate).year();
  const firstAvailabilityMonth = dayjs(firstAvailabilityDate).month();
  const lastAvailabilityMonth = dayjs(lastAvailabilityDate).month();

  const dateDifference = (lastAvailabilityYear - firstAvailabilityYear) * 12 + (lastAvailabilityMonth - firstAvailabilityMonth);

  const monthName = dayjs()
    .date(1)
    .add(monthCoefficient, "M")
    .format(MONTH_NAME_FORMAT);

  const nextMonth = (monthCoefficient: number) => {
    setMonthCoefficient(++monthCoefficient);
    monthCoefficient == 0 ? setMonth(true) : setMonth(false);
  };
  const previousMonth = (monthCoefficient: number) => {
    setMonthCoefficient(--monthCoefficient);
    monthCoefficient == 0 ? setMonth(true) : setMonth(false);
  };
  
  return isMiddleScreen ? (
    <div className="mobile-calendar">
      <div className="monthButtons">
        <IconButton
          iconProps={{ iconName: "ChevronLeft" }}
          className="focusableRoundButton"
          name="back"
          styles={buttonStyle}
          onClick={() => previousMonth(monthCoefficient)}
          disabled={thisMonth == true}
        ></IconButton>
        <div className="monthName">{monthName}</div>
        <IconButton
          iconProps={{ iconName: "ChevronRight" }}
          className="focusableRoundButton"
          name="next"
          styles={buttonStyle}
          onClick={() => nextMonth(monthCoefficient)}
          disabled={monthCoefficient >= dateDifference}
        ></IconButton>
      </div>
      <Month
        date={date}
        selectDate={selectDate}
        availability={availability}
        isMobile={true}
        monthCoefficient={monthCoefficient}
      />
    </div>
  ) : (
    <div className="calendar">
      <div className="monthButtons">
        <IconButton
          iconProps={{ iconName: "ChevronLeft" }}
          className="focusableRoundButton"
          name="back"
          styles={buttonStyle}
          onClick={() => previousMonth(monthCoefficient)}
          disabled={thisMonth == true}
        ></IconButton>
        <IconButton
          iconProps={{ iconName: "ChevronRight" }}
          className="focusableRoundButton"
          name="next"
          styles={buttonStyle}
          onClick={() => nextMonth(monthCoefficient)}
          disabled={monthCoefficient >= dateDifference - 1}
        ></IconButton>
      </div>
      <div className="month">
        <Month
          date={date}
          selectDate={selectDate}
          availability={availability}
          isMobile={false}
          monthCoefficient={monthCoefficient}
        />
      </div>
      <div className="month">
        <Month
          date={date}
          selectDate={selectDate}
          availability={availability}
          isMobile={false}
          monthCoefficient={monthCoefficient + 1}
        />
      </div>
    </div>
  ); 
};
