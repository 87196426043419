import React from "react";
import { Icon, ImageIcon, IImageStyles } from "office-ui-fabric-react";
import "./selectorWithIcon.scss";

interface Props {
  options: { key: string | number; text: string }[];
  value?: string | number;
  onChange: (value?: string | number) => void;
  placeholder: string;
  icon?: string;
  iconHeight?: number;
  iconWidth?: number;
  contentClass?: string;
  disabled?: boolean;
  wrapperClassName?: string;
  id?: string;
}

const iconStyles = (iconWidth?: number, iconHeight?: number): IImageStyles => {
  if (!iconWidth || !iconHeight) return { image: {}, root: {} };
  return {
    image: {
      width: `${iconWidth / 16}rem`,
      height: `${iconHeight / 16}rem`,
    },
    root: {},
  };
};

export const SelectWithIcon = ({
  options,
  value,
  onChange,
  placeholder,
  icon,
  iconHeight,
  iconWidth,
  contentClass,
  disabled,
  wrapperClassName
}: Props) => {
  const selected = options.find((item) => item.key === value);
  const text = selected ? selected.text : placeholder;
  const selectProps = value ? { value } : { defaultValue: "" };
  return (
    <div className={`select-with-icon ${wrapperClassName ? wrapperClassName : ""}`}>
      <select
        className="selector"
        {...selectProps}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
      >
        <option disabled value="">
          {placeholder}
        </option>
        {options.map((item) => (
          <option
            value={item.key}
            label={item.text}
            className="opt"
            key={item.key}
          >
            {item.text}
          </option>
        ))}
      </select>
      <div className={`content ${contentClass ? contentClass : ""}`}>
        {icon && (
          <div className="icon">
            <ImageIcon
              imageProps={{
                src: icon,
                styles: iconStyles(iconWidth, iconHeight),
              }}
              aria-hidden="true"
            />
          </div>
        )}
        <div className={`value ${selected ? "" : "placeholder"}`}>{text}</div>
        <div className="last-icon">
          <Icon iconName="ChevronDown" className="chevron" />
        </div>
      </div>
    </div>
  );
};
