import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { backToReservationAction } from "../../../actions/venue-actions";
import { Text, Icon, DefaultButton, IButtonStyles } from "office-ui-fabric-react";
import { useViewport } from "../../../hooks/responsive";
import { STEP } from "../../../store/types";
import "./multiStep.scss";

interface Step {
  label: number;
  name: string;
  handler: () => void;
}

interface MultiStepProps {
  activeStep: STEP;
  steps: Step[];
}
const mapDispatchToProps = {
  backToReservation: backToReservationAction,
};
const connector = connect(null, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & MultiStepProps;

interface StepProps {
  label: string;
  passed: boolean;
  active: boolean;
  onClick?: () => void;
}

const checkMarkButton: IButtonStyles = {
  root: {
    minHeight: "34px !important",
    minWidth: "34px !important",
    margin: "6px",
    padding: 0,
    border: "none", 
    background: "transparent"
  },
  rootHovered: {
    borderRadius: "50%",
  }
};

const Step: React.FunctionComponent<StepProps> = ({
  label,
  active,
  passed,
  onClick,
}) => (
  <div>
    {passed ? (
      <DefaultButton 
        styles={checkMarkButton} 
        className="focusMenuButton"  
        onClick={onClick}
      >
        <Icon
          iconName="CheckMark"
          className="menuButton"
        ></Icon>
      </DefaultButton>
    ) : (
      <Text
        variant={"large"}
        className={`${active ? "menuActive" : "menuInactive"}`}
      >
        {label}
      </Text>
    )}
  </div>
);

const MultiStep = ({ activeStep, steps, backToReservation }: Props) => {
  const { isMobile } = useViewport();
  const handler = (step: Step, activeStep: number) => {
    if (step.label === STEP.INIT || activeStep === STEP.CONFIRMATION) {
      return backToReservation();
    }
    step.handler();
  };
  return (
    <div className="reservationInfo-menu">
      {steps.map((step) => (
        <React.Fragment key={step.label}>
          <Step
            label={`${step.label}`}
            active={activeStep === step.label}
            passed={activeStep > step.label}
            onClick={
              activeStep > step.label
                ? () => handler(step, activeStep)
                : () => null
            }
          />
          {!isMobile && <div>{step.name}</div>}
          {step.label !== STEP.CONFIRMATION && <hr className="line" />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default connector(MultiStep);
