import React, { useState } from 'react'
import { Reservation } from '../../../store/types';
import { calculateDeposit, formatPrice } from '../../../../../common/utils/formats';
import { Box, ButtonProps, Typography } from '@material-ui/core';
import { ExpandLess } from '@material-ui/icons';
import { useViewport } from '../../../hooks/responsive';
import AccountReservationInfoModal from '../../Account/Reservations/AccountReservationInfoModal';
import ButtonMain from '../ButtonMain/ButtonMain';
import { mobileDropshodow } from './utils';

interface Props extends ButtonProps {
    reservation: Reservation,
    onButtonClick?: () => void,
    isSummaryExpanded?: boolean,
    isButtonDisabled?: boolean,
    venueId?: string,
    isUpdateReservation?: boolean,
    giftCardAmount: number,
    children?: React.ReactNode,
    noButton?: boolean
}

const MobileAttachedButton = ({ reservation, onButtonClick, isSummaryExpanded, isButtonDisabled, venueId, isUpdateReservation, giftCardAmount, children, noButton, ...props }: Props) => {

    const { isMobile } = useViewport()
    const [isExpandOrderSummary, setIsExpandOrderSummary] = useState(false);
    const handleOrderSummaryOpen = () => {
        setIsExpandOrderSummary(true);
    }
    const handleOrderSummaryClose = () => {
        setIsExpandOrderSummary(false);
    }
    let deposit = calculateDeposit({
        reservation,
        giftCardAmount: giftCardAmount ? +giftCardAmount : 0,
        isUpdateReservation: !!isUpdateReservation,
        isUpdateWithVenueChange: venueId !== reservation?.venueId
    });
    if (!isMobile) return null

    return (
        <>
            <Box position={'fixed'} bottom={0} left={0} pt={0} width={'100%'} bgcolor={'white'} zIndex={100} style={{ filter: mobileDropshodow }}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'16px 24px'} borderBottom={'1px solid rgba(0, 0, 0, 0.05)'} onClick={handleOrderSummaryOpen}>
                    <Typography className="body-big-bold flex items-center gap-XS">
                        Due Now {'  '} <ExpandLess style={{ color: '#212B36' }} />
                    </Typography>
                    <Typography className="body-big-bold">
                        {formatPrice(deposit, reservation.currency, true)}
                    </Typography>
                </Box>
                {noButton ? <Box height={'12px'} /> : <Box p={'24px'}>
                    <ButtonMain onClick={onButtonClick} disabled={isButtonDisabled} {...props}>{children || 'Continue'}</ButtonMain>
                </Box>}
            </Box>
            <AccountReservationInfoModal
                setOpen={handleOrderSummaryOpen}
                open={isExpandOrderSummary}
                onClose={handleOrderSummaryClose}
                resNumber={reservation.number!}
                reservation={reservation}
                isSummaryExpanded={isSummaryExpanded}
            ><></></AccountReservationInfoModal>
        </>
    )
}

export default MobileAttachedButton