import React from 'react';
import { Modal } from "office-ui-fabric-react";
import { useViewport } from "../../../hooks/responsive";
import { InfoAlert } from "../../../../../common/components/Alert/Alert";
import './modify-reservation-confirmation-modal.scss'
import { PrimaryButton } from "../../../../../common/components/PrimaryButton/PrimaryButton";
import { FeeType, Reservation, UIConfigDto } from "../../../store/types";

type Props = {
  onOk: () => void;
  onClose: () => void;
  currentReservation?: Reservation;
  uiConfig?: UIConfigDto;
  feeForModification?: number;
  modificationFeeType?: FeeType;
}

const ModifyReservationConfirmationModal = (
  {
    onClose,
    uiConfig,
    currentReservation,
    onOk,
    feeForModification,
    modificationFeeType,
  }: Props
) => {
  const { isMobile } = useViewport();
  const feeInDollars = modificationFeeType === FeeType.PERCENT
    ? ((parseInt(feeForModification?.toFixed(2) || "") || 0) * (currentReservation?.deposit || 0) / 100).toFixed(2)
    : (parseInt(feeForModification?.toFixed(2) || "") || 0).toFixed(2);
  let modificationFeeText = '';
  if (!!feeForModification) {
    modificationFeeText = modificationFeeType === FeeType.PERCENT
    ? `There is a ${feeForModification}% charge ($${feeInDollars}) for modifying your reservation. `
    : `There is a $${feeInDollars} charge for modifying your reservation. `;
  }
  const modificationAlertText = `${modificationFeeText}Based on your new selection there may be an additional price difference.`
  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={true}
      containerClassName={`modify-reservation ${isMobile ? "mobile" : ""}`}
    >
      <div className="container">
        <h3 className='modify-reservation-header'>
          Modify Reservation
        </h3>
        <h5 className='modify-reservation-id'>
          #{currentReservation?.number}
        </h5>
        <p className='modify-reservation-confirm-text'>
          You will be redirected to the reservation page. In order to modify your reservation you will need to go
          through checkout again. If your changes result in a price difference you will either make a payment or receive
          a refund for the difference.
        </p>
        <InfoAlert
            text={modificationAlertText}
            className='modify-reservation-alert'
        />
        <div className='modify-reservation-buttons'>
          <button className='close' onClick={onClose}>
            Nevermind
          </button>
          <PrimaryButton uiConfig={uiConfig} onClick={onOk} className='confirm'>
            Modify
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default ModifyReservationConfirmationModal;