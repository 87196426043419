import { Box, BoxProps, makeStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';


const useStyles = makeStyles(() => ({
    root: {
        background: 'white',
        borderRadius: '8px',
        boxShadow: '0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.04), 0px 0px 4px 4px rgba(0, 0, 0, 0.04)',
        padding: '32px'
    },
    rootMobile: {
        padding: 0
    }
}));

interface Props extends BoxProps {
    children: ReactNode | ReactNode[],
    isMobile?: boolean
}

const DefaultContainer2 = ({ children, isMobile, ...props }: Props) => {
    const classes = useStyles()
    return (
        <Box  {...props} className={`${isMobile ? classes.rootMobile : classes.root} ${props.className}`}>
            {children}
        </Box>
    )
}

export default DefaultContainer2