import React from "react";
import { Label } from "office-ui-fabric-react";
import { rem } from "../../utils/formats";
import { color } from "../../constants/styles";
import "./formField.scss";

const labelStyle = {
  root: {
    padding: "8px 0",
    fontSize: rem(16),
    color: color.grayDarkText,
  },
};

interface Props {
  label: string;
  children: React.ReactNode;
  error?: string;
}

export const FormField = ({ label, error, children }: Props) => {
  return (
    <div className={`form-field ${error ? "with-error" : ""}`}>
      <Label styles={labelStyle}>{label}</Label>
      {children}
      {error && <div className="error">{error}</div>}
    </div>
  );
};
