import { Typography } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import React from 'react'
import { ConnectedProps, connect } from "react-redux"
import { backFromAccountAction, pushUrlPathAction } from '../../../actions/reservation-actions'
import { selectReservation } from '../../../reducers/reservation'
import { State } from '../../../store/types'
import DefaultContainer2 from '../../common/DefaultContainer2/DefaultContainer2'


const mapStateToProps = (state: State) => ({
    reservation: selectReservation(state),
});

const mapDispatchToProps = {
    backFromAccount: backFromAccountAction,
    pushUrlPath: pushUrlPathAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ConnectedProp = ConnectedProps<typeof connector>;

interface Props extends ConnectedProp {
    upcoming?: boolean
}

const AccountReservationEmptyPlaceholder = ({ upcoming, backFromAccount, pushUrlPath, reservation }: Props) => {
    const handleRedirectToReservations = () => {
        if (reservation.price) {
            pushUrlPath("/reservation-info/reservation-confirm");
        } else {
            backFromAccount();
        }
    }
    return (
        <DefaultContainer2 style={{ padding: '24px' }}>
            <Typography className='root'>{`You have no ${upcoming ? 'upcoming' : 'past'} reservations`}</Typography>
            {upcoming ? <button onClick={handleRedirectToReservations} className='btn-link-v2'> <Typography className='root bold account-link-text-v2'>Make a reservation</Typography> <ArrowForward className='arrow-icon' /></button> : null}
        </DefaultContainer2>
    )
}

export default connector(AccountReservationEmptyPlaceholder)