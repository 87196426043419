import { Box, Button, Typography } from "@material-ui/core";
import React, { ReactNode, useEffect, useState } from "react";
import { isValidPhone } from "../../../../../common/utils/validate";
import { useViewport } from "../../../hooks/responsive";
import { ProfileDto, UpdateClient } from "../../../store/types";
import ButtonMain from "../../common/ButtonMain/ButtonMain";
import DefaultContainer2 from "../../common/DefaultContainer2/DefaultContainer2";
import Input2 from "../../common/Input2/Input2";
import PhoneInput2 from "../../common/Input2/PhoneInput2";
import ErrorAlert from "../common/ErrorAlert";
import CloseIcon from "../../../../assets/close.svgr";
import "./editProfile.scss";

interface Prop {
  updateProfile: (updatedClient: UpdateClient) => void;
  unSubscribe: (id: string) => void;
  isInProgress: boolean;
  client?: ProfileDto;
  error?: string;
  cleanError: () => void;
  uiConfig: any;
  children?: ReactNode;
}

export const EditProfile2 = ({
  error,
  isInProgress,
  updateProfile,
  unSubscribe,
  client,
  cleanError,
  children,
}: Prop) => {
  useEffect(() => {
    if (!client) {
      return;
    }
    const { firstName, lastName, email, phone } = client;
    setFirstName(firstName);
    setLastName(lastName);
    setPhone(phone);
    setEmail(email);
  }, [client]);
  useEffect(() => {
    setErrorText(error);
  }, [error]);
  useEffect(() => {
    if (!isInProgress && lastProgress) {
      setConfirmText("Profile is updated.");
      setLastProgress(false);
    }
  }, [isInProgress]);
  const [lastProgress, setLastProgress] = useState(false);
  const [firstName, setFirstName] = useState<string | undefined>(
    client?.firstName
  );
  const [lastName, setLastName] = useState<string | undefined>(
    client?.lastName
  );
  const [phone, setPhone] = useState<string | undefined>("");
  const [email, setEmail] = useState<string | undefined>("");
  const [errorText, setErrorText] = useState<string | undefined>("");
  const [confirmText, setConfirmText] = useState<string | undefined>("");
  const { isMobile } = useViewport();

  const [errorFirstName, setErrorFirstName] = useState<string>("");
  const [errorLastName, setErrorLastName] = useState<string>("");
  const [errorPhone, setErrorPhone] = useState<string>("");
  useEffect(() => () => cleanError(), [cleanError]);

  const onUpdateProfile = () => {
    if (firstName && lastName && email && phone && isValidPhone(phone)) {
      setErrorText("");
      setConfirmText("");
      setLastProgress(true);
      updateProfile({ firstName, lastName, email, phone });
      return;
    }
    if (!firstName) {
      setErrorFirstName("This field is required.");
      setErrorText("All fields are mandatory. Please fill in all fields.");
    }
    if (!lastName) {
      setErrorLastName("This field is required.");
      setErrorText("All fields are mandatory. Please fill in all fields.");
    }
    if (!phone || !isValidPhone(phone)) {
      setErrorPhone("Please enter a valid phone number.");
      setErrorText("All fields are mandatory. Please fill in all fields.");
    }
  };

  const onFirstNameChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: string }>
  ) => {
    setErrorFirstName("");
    setFirstName(e.target.value);
  };
  const onLastNameChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: string }>
  ) => {
    setErrorLastName("");
    setLastName(e.target.value);
  };
  const onPhoneChange = (text?: string) => {
    setErrorPhone("");
    setPhone(text);
  };


  return (
    <Box className="edit-profile-v2">
      {!isMobile && (
        <Typography className="heading-h1 title">Profile</Typography>
      )}
      <DefaultContainer2 mb={"42px"} borderRadius={isMobile ? 0 : "8px"}>
        <Typography className="heading-h3 section-title">
          Account Details
        </Typography>

        <Box>
          <Box
            display={"flex"}
            flexDirection={isMobile ? "column" : "row"}
            style={{ gap: "16px" }}
            mb={"16px"}
          >
            <Input2
              errorInput={errorFirstName}
              label="First Name"
              placeholder="Enter your first name"
              value={firstName}
              onChange={onFirstNameChange}
              fullWidth
            />
            <Input2
              errorInput={errorLastName}
              label="Last Name"
              placeholder="Enter your last name"
              value={lastName}
              onChange={onLastNameChange}
              fullWidth
            />
          </Box>
          <Box
            display={"flex"}
            flexDirection={isMobile ? "column" : "row"}
            style={{ gap: "16px" }}
            mb={"28px"}
          >
            <Input2
              disabled
              type="email"
              label="Email"
              placeholder="Enter your email"
              value={email}
              fullWidth
            />
            <PhoneInput2
              errorInput={errorPhone}
              label="Phone Number"
              placeholder="Enter your phone"
              value={phone}
              onChange={onPhoneChange}
              fullWidth
            />
          </Box>
          {errorText && !errorText.includes("pass") && (
            <ErrorAlert error={"Account details change issue!"} mb={"10px"} />
          )}

          {confirmText && !errorText && (
            <Box className="confirm">{confirmText}</Box>
          )}
          <ButtonMain
            name="save"
            onClick={onUpdateProfile}
            disabled={!firstName || !lastName || !phone}
          >
            SAVE
          </ButtonMain>
        </Box>
      </DefaultContainer2>
      {client && client.subscriptions?.length > 0 && (
        <DefaultContainer2 mb={"42px"} borderRadius={isMobile ? 0 : "8px"}>
          <Typography className="heading-h3 section-title">
            Subsciptions
          </Typography>

          <Box>
            {client.subscriptions.map((sub, index) => (
              <Box
                display={"flex"}
                flexDirection="row"
                alignItems='center'
                justifyContent='space-between'
                style={{ gap: "16px" }}
                mb={"16px"}
                key={sub.id}
              >
                <Typography className="section-title">
                  Plan: {sub.plan.name}
                </Typography>
                <Typography className="section-title">
                  from: {sub.start}
                </Typography>
                <Typography className="section-title">
                  till: {sub.end}
                </Typography>
                <Typography className="section-title">
                  {sub.active ? "Active" : "Inactive"}
                </Typography>
                <Button className="gallery-btn" onClick={() => unSubscribe(sub.id)}>
                  <CloseIcon />
                </Button>
              </Box>
            ))}
          </Box>
        </DefaultContainer2>
      )}
      {children}
    </Box>
  );
};
