import React, { useState, useEffect } from "react";
import {
  CardElement,
  PaymentRequestButtonElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Stripe, StripeCardElement } from "@stripe/stripe-js";
import { PrimaryButton } from "../../../../../common/components/PrimaryButton/PrimaryButton";
import {
  GiftCardBalance,
  GiftCardBalanceRequest,
  GiftCardPaymentRequest,
  PMethod,
  Reservation,
  Venue,
} from "../../../store/types";
import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";
import { calculateDeposit, rem } from "../../../../../common/utils/formats";
import GiftCard from "../../../../../common/components/GiftCard";
import { CouponCodes } from "../../../../../common/components/CouponCodes";
import { InfoAlert } from "../../../../../common/components/Alert/Alert";
import './stripeForm.scss';

interface Props {
  venue: Venue | undefined;
  reservation: Reservation;
  selected: boolean;
  isMobile: boolean;
  showGiftCard: boolean;
  orderSummary?: ((error?: string, block?: boolean) => JSX.Element) | null;
  uiConfig: any;
  requestError?: string;
  makeStripeReservation: (stripe: Stripe, elements: StripeCardElement) => void;
  paymentMethodStripe: (stripe: Stripe, ev: any) => void;
  giftBalance?: GiftCardBalance;
  getGiftCardBalance: (cardParams: GiftCardBalanceRequest) => void;
  giftBalanceError?: string;
  addGiftCard: (cardParams: GiftCardPaymentRequest) => void;
  giftCardAmount: number;
  showDiscounts?: boolean;
  applyCouponCode?: (couponCodes: string[]) => void;
  createEmptyPayment: (paymentType: PMethod) => void;
  isUpdateReservation: boolean;
  isUpdateWithVenueChange: boolean;
  oldReservation?: Reservation;
  customStripeHandler?: (stripe: Stripe, elements: StripeCardElement) => void;
}

const checkboxStyle = {
  root: {
    margin: "0 0 30px",
  },
  checkbox: {
    width: rem(20),
    height: rem(20),
  },
  text: {
    fontSize: rem(13),
    lineHeight: "1.5",
  },
  checkmark: {
    fontSize: rem(13),
  },
};

const StripeForm = ({
  venue,
  reservation,
  selected,
  isMobile,
  orderSummary,
  uiConfig,
  requestError,
  showGiftCard,
  makeStripeReservation,
  paymentMethodStripe,
  customStripeHandler,
  giftBalance,
  getGiftCardBalance,
  giftBalanceError,
  addGiftCard,
  giftCardAmount,
  showDiscounts,
  applyCouponCode,
  createEmptyPayment,
  isUpdateReservation,
  isUpdateWithVenueChange,
  oldReservation,
}: Props) => {
  const [succeeded, setSucceeded] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [processing, setProcessing] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [isSelected, setIsSelected] = useState<boolean>(selected);
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const stripe = useStripe();
  const elements = useElements();
  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'reservation',
          amount: Math.round(deposit * 100),
        },
        requestPayerEmail: true,
      });
      pr.on('paymentmethod', (ev) => paymentMethodStripe(stripe, ev));

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  const confirmButtonText = isUpdateReservation ? "MODIFY RESERVATION" : "COMPLETE YOUR RESERVATION";

  if (!stripe || !elements) {
    return <></>;
  }

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleChange = async (event: any) => {
    setDisabled(event.empty);
    setSucceeded(event.empty);
    setError("");
  };

  const handleSubmit = async (ev: any) => {
    ev.preventDefault();
    setProcessing(true);
    try {
      const cardMethod = elements.getElement(CardElement);
      if (cardMethod !== null) {
        if (customStripeHandler) {
          customStripeHandler(stripe, cardMethod)
        } else {
          makeStripeReservation(stripe, cardMethod);
        }
        setSucceeded(true);
        setProcessing(false);
      }
    } catch (e) {
      console.log(e)
      setError(`Sorry, we cannot complete payment. Please try again later.`);
      setDisabled(true);
      setProcessing(false);
    }
  };

  let deposit = calculateDeposit({
    reservation,
    giftCardAmount,
    isUpdateReservation,
    isUpdateWithVenueChange,
    oldReservation,
  })
  let isSkipPayment = deposit <= 0;
  let isRefundPayment = deposit < 0;
  let isShowRefundPaymentInfo = isUpdateReservation && isSkipPayment && isRefundPayment;
  let isShowSkipPaymentInfo = isUpdateReservation && isSkipPayment && !isRefundPayment;
  let isShowPaymentInfo = isUpdateReservation && !isSkipPayment;
  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {!isSkipPayment && (
        <>
          <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
        </>
      )}
      {showGiftCard && isMobile && (
        <GiftCard
          isMobile={!!isMobile}
          deposit={deposit}
          giftBalance={giftBalance}
          getGiftCardBalance={getGiftCardBalance}
          giftBalanceError={giftBalanceError}
          addGiftCard={addGiftCard}
          giftCardAmount={giftCardAmount}
          isSkipPayment={isSkipPayment}
        />
      )}
      {showDiscounts && isMobile && (
        <CouponCodes
          isMobile={!!isMobile}
          deposit={deposit}
          coupons={reservation.coupons}
          applyCouponCode={applyCouponCode}
          isSkipPayment={isRefundPayment}
        />
      )}
      {(isMobile && orderSummary) ? orderSummary(error, (processing || disabled || succeeded || !!error)) : (
        <>
          {venue?.showPolicy && (
            <>
              <div className="venue-policy">
                <div className="heading">VENUE POLICIES</div>
                <div
                  className="venue-policy-value"
                  dangerouslySetInnerHTML={{
                    __html: venue?.venueInfo || "",
                  }}
                ></div>
              </div>
              <Checkbox
                styles={checkboxStyle}
                label="I have read and agree to the venue policies."
                checked={isSelected}
                onChange={(_: any, isChecked?: boolean) =>
                  setIsSelected(!!isChecked)
                }
              />
            </>
          )}
          {(isShowRefundPaymentInfo) && (
            <InfoAlert
              text="You will receive a refund for the price difference"
            />
          )}
          {(isShowSkipPaymentInfo) && (
            <InfoAlert
              text="There is no price difference for your new reservation. No additional payment is needed"
            />
          )}
          {isShowPaymentInfo && (
            <InfoAlert
              text="Your new reservation requires an additional payment"
            />
          )}
          {(requestError || error) && (
            <div className="card-error" role="alert">
              {error ? error : requestError}
            </div>
          )}
          {isSkipPayment && (
            <PrimaryButton
              role="button"
              onClick={() => createEmptyPayment(PMethod.stripe)}
              uiConfig={uiConfig}
              disabled={!isSelected}
            >
              {confirmButtonText}
            </PrimaryButton>
          )}
          {!isSkipPayment && (
            <PrimaryButton
              id="submit"
              disabled={processing || disabled || succeeded || !!error || !isSelected}
              role="button"
              aria-label="submit form"
              type="submit"
              uiConfig={uiConfig}
            >
              {confirmButtonText}
            </PrimaryButton>
          )}
        </>
      )}
    </form>
  );
};

export default StripeForm;
