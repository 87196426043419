import { Box } from "@material-ui/core";
import React from "react";
import { VenueDto } from "../../../../../server/src/dto/venue.dto";
interface Props {
    venue?: VenueDto;
}

export const VenueAddress2 = ({ venue }: Props) => {
    if (!venue) {
        return null;
    }
    return (
        <Box className="venue-address2">
            <Box>{venue.address}</Box>
            {venue.address2 && <Box>{venue.address2}</Box>}
            <Box>{`${venue.city}, ${venue.state} ${venue.zipCode}`}</Box>
        </Box>
    );
};
