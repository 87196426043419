import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { getSearch } from "connected-react-router";
import { mergeStyleSets } from "office-ui-fabric-react";
import Header from "../common/Header/Header";
import Footer from "../common/Footer/Footer";
import Payment from "../ReservationInfo/Payment/Payment";
import Confirmation from "../ReservationInfo/Confirmation/Confirmation";
import { State } from "../../store/types";
import { useViewport } from "../../hooks/responsive";
import {
    selectReservation,
    selectConfirmed,
    selectPaymentCompleted,
    selectRequestError,
    selectIsRequestInProgress,
} from "../../reducers/reservation";
import { selectUIConfig } from "../../reducers/ui-reducer";
import { selectVenue } from "../../reducers/venues";
import {
    loadReservationAction,
    rePayAction,
    rePayStripeAction,
} from "../../actions/reservation-actions";
import { parseUrlQuery } from "../../utils/urlSearchQuery";
import "./repayReservation.scss";
import Confirmation2 from "../ReservationInfo/Confirmation/Confirmation2";
import Payment2 from "../ReservationInfo/Payment/Payment2";

const mapDispatchToProps = {
    loadReservation: loadReservationAction,
    rePay: rePayAction,
    rePayStripe: rePayStripeAction,
};

const mapStateToProps = (state: State) => ({
    venue: selectVenue(state),
    searchParams: getSearch(state),
    reservation: selectReservation(state),
    error: selectRequestError(state),
    confirmed: selectConfirmed(state),
    paymentCompleted: selectPaymentCompleted(state),
    uiConfig: selectUIConfig(state),
    isRequestInProgress: selectIsRequestInProgress(state),
});

enum Step {
    PAYMENT,
    CONFIRM,
}
const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const RepayReservation2 = ({
    loadReservation,
    rePay,
    rePayStripe,
    reservation,
    searchParams,
    uiConfig,
    error,
    paymentCompleted,
    venue,
    isRequestInProgress,
}: Props) => {
    const { isMobile } = useViewport();
    const [step, setStep] = useState(Step.PAYMENT);
    const [localError, setLocalError] = useState("");


    useEffect(() => {
        const { id, token } = parseUrlQuery(searchParams);
        if (!id || !token) {
            setLocalError("Content load error, URL is not valid.");
            return;
        }
        loadReservation(id, token);
        console.log("-loadReservation-", id);
    }, []);
    useEffect(() => {
        if (paymentCompleted) {
            setStep(Step.CONFIRM);
        }
    }, [paymentCompleted]);

    const theme = mergeStyleSets({
        backgroundColor: {
            background: uiConfig?.bodyBackgroundColor,
        },
    });

    if (!reservation?.id || localError || error || isRequestInProgress) {
        const content = localError || error || "loading...";
        return (
            <div className={`payReservation-container ${theme.backgroundColor}`}>
                <Header />
                <div className="payReservation-inner-container">{content}</div>
                <Footer />
            </div>
        );
    }

    return (
        <div className={`payReservation-container ${theme.backgroundColor}`}>
            <Header repay />
            <div
                className={`payReservation-inner-container ${isMobile ? "mobile" : ""}`}
            >
                {step === Step.PAYMENT ? (
                    (uiConfig?.newDesign ? <Payment2 customHandler={rePay} customStripeHandler={rePayStripe} /> : <Payment customHandler={rePay} customStripeHandler={rePayStripe} />)
                ) : (
                    (uiConfig?.newDesign ? <Confirmation2 /> : <Confirmation />)
                )}
            </div>
            <Footer />
        </div>
    );
};

export default connector(RepayReservation2);
