import React from "react";
import { mergeStyleSets } from "office-ui-fabric-react";
import dayjs from "dayjs";
import { connect, ConnectedProps } from "react-redux";
import { State } from "../../../store/types";
import { selectUIConfig } from "../../../reducers/ui-reducer";
import { useViewport } from "../../../hooks/responsive";
import InstagramIcon from "../../../../assets/icon-instagram.svgr";
import FacebookIcon from "../../../../assets/icon-facebook.svgr";
import "./footer.scss";

const mapStateToProps = (state: State) => ({
  uiConfig: selectUIConfig(state),
});
const connector = connect(mapStateToProps);
type Props = {
  className?: string;
} & ConnectedProps<typeof connector>;

const year = dayjs().format("YYYY");
const Footer = ({ uiConfig, className = '' }: Props) => {
  const { isMobile } = useViewport();

  const footerBackgroundColor = uiConfig?.footerBackgroundColor || "#000";
  const footerForegroundColor = uiConfig?.footerForegroundColor || "#fff";
  const homeLink = uiConfig?.homeLink;
  const companyName = uiConfig?.companyName;
  const instagramUrl = uiConfig?.instagramUrl;
  const facebookUrl = uiConfig?.facebookUrl;
  const contentStyles = mergeStyleSets({
    footer: {
      background: footerBackgroundColor,
      color: footerForegroundColor,
    },
    element: {
      color: footerForegroundColor,
    },
    delimer: {
      borderRight: `1px solid ${footerForegroundColor}`,
    },
    icon: {
      fill: footerForegroundColor,
    },
  });

  return (
    <div
      className={`footer ${isMobile ? "mobile" : ""} ${contentStyles.footer} ${className}`}
    >
      <div className={`footer-line ${isMobile ? "mobile" : ""}`}>
        {`© ${year}`}
        <a
          href={homeLink}
          target="_blank"
          className={`footer-link ${contentStyles.element}`}
        >
          {companyName}
        </a>
      </div>
      <div className={`footer-icons ${isMobile ? "mobile" : ""}`}>
        <a
          href={facebookUrl}
          target="_blank"
          className={`footer-icon ${contentStyles.element}`}
        >
          <FacebookIcon className={contentStyles.icon} />
        </a>
        <a
          href={instagramUrl}
          target="_blank"
          className={`footer-icon ${contentStyles.element}`}
        >
          <InstagramIcon className={contentStyles.icon} />
        </a>
      </div>
    </div>
  );
};

export default connector(Footer);
