import React from "react";
import { VenueDto } from "../../../server/src/dto/venue.dto";
import { formatPhone } from "../../utils/formats";
import "./venueAddress.scss";

interface Props {
  venue?: VenueDto;
}

export const VenueAddress = ({ venue }: Props) => {
  if (!venue) {
    return null;
  }
  const venuePhone = formatPhone(venue.phone);
  return (
    <div className="venue-address">
      <div>{venue.address}</div>
      {venue.address2 && <div>{venue.address2}</div>}
      <div>{`${venue.city}, ${venue.state} ${venue.zipCode}`}</div>
      {venuePhone && <a className="phone focusable" href={`tel:${venuePhone}`}>{formatPhone(venuePhone)}</a>}
    </div>
  );
};
