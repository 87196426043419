import * as React from "react";
import {
  DefaultButton,
  IIconProps,
  IButtonStyles
} from "office-ui-fabric-react";
import "./accordion.scss";

interface Props {
  defaultCollapsed?: boolean;
  title: string;
  className?: string;
  withMaxWidth?: boolean

}

interface State {
  expanded: boolean;
}

const collapsedIcon: IIconProps = {
  iconName: "ChevronRight",
  className: "accordionChevron"
};
const expandedIcon: IIconProps = {
  iconName: "ChevronDown",
  className: "accordionChevron"
};


export class Accordion extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      expanded: props.defaultCollapsed ? !props.defaultCollapsed : true
    };
  }



  public render(): React.ReactElement<Props> {

    const accordionButton: IButtonStyles = {
      root: {
        fontFamily: "Roboto",
        fontSize: "medium",
        fontWeight: 700,
        ...(this.props.withMaxWidth ? { maxWidth: '250px' } : {})
      },
      label: {
        marginLeft: 0,
        fontWeight: 700
      },
    };

    return (
      <div className="accordion">
        <DefaultButton
          toggle
          checked={this.state.expanded}
          text={this.props.title}
          iconProps={this.state.expanded ? expandedIcon : collapsedIcon}
          onClick={() => {
            this.setState({
              expanded: !this.state.expanded
            });
          }}
          aria-expanded={this.state.expanded}
          styles={accordionButton}
          className="focusableButton"
          name="accordion"
        />
        {this.state.expanded ? this.props.children : null}
      </div>
    );
  }
}
