import React from "react";
import { VenueDto } from "../../../server/src/dto/venue.dto";
import "./mapImageLink.scss";

interface Props {
  venue?: VenueDto;
}

export const MapImageLink = ({ venue }: Props) => {
  const mapImage = venue
    ? `/map/${venue.zipCode}-${venue.city.replace(" ", "-")}.png`
    : null;
  const address = `Drive+Shack+${venue?.address}, ${venue?.city}, ${venue?.zipCode}, USA`
  const mapRef = `${venue?.mapUrl}`;

  return (
    <a className="map-image-link" href={mapRef} target="__blank">
      {mapImage && <img className="map" src={venue?.mapImage} alt="map" />}
    </a>
  );
};
