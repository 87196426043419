import React from "react";
import dayjs from "dayjs";
import { connect, ConnectedProps } from "react-redux";
import { changeVenueAction } from "../../../actions/venue-actions";
import { Venue, VenueName } from "../../../store/types";
import locationIcon from "../../../../../common/assets/location-icon.svg";
import { SelectWithIcon } from "../../../../../common/components/SelectWithIcon/SelectWithIcon";
import { Accordion } from "../../../../../common/components/Accordion/Accordion";
import { DATE_FORMAT } from "../../../../../common/constants/timedate";
import { useViewport } from "../../../hooks/responsive";
import { Policy } from "../../../../../common/components/Policy/Policy";
import { VenueAddress } from "../../../../../common/components/VenueAddress/VenueAddress";
import { MapImageLink } from "../../../../../common/components/MapImageLink/MapImageLink";
import HTMLRenderer from "../../../../../common/components/HTMLRenderer/HTMLRenderer";
import { getVenueList } from "../../../utils/formats";
import "./externalBookingComponent.scss";

interface ExternalBookingProps {
  activeVenue?: Venue;
  venues: VenueName[];
  searchParams: string;
}

const mapDispatchToProps = {
  changeVenue: changeVenueAction,
};

const connector = connect(null, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & ExternalBookingProps;

const ExternalBookingComponent = ({
  venues,
  activeVenue,
  searchParams,
  changeVenue,
}: Props) => {

  const { isMobile, isMiddleScreen } = useViewport();
  const menuPropsLocations = getVenueList(venues, searchParams);
  const venueId = activeVenue ? activeVenue.id : '';
  const closed = activeVenue?.closed;

  const selectVenueOption = (newVenueId?: number | string) => {
    const newVenue = venues.find((venue) => venue.id == newVenueId);
    if (newVenueId && venueId !== newVenueId && newVenue) {
      const nowDate = dayjs().format(DATE_FORMAT);
      changeVenue({
        venueId: newVenueId.toString(),
        date: nowDate,
        duration: 0,
        lanes: 1,
        guests: 1,
      });
    }
  };
  const liteWidget = activeVenue?.lightScript || "";

  return (
    <div className="externalBookingComponent-container">
      {isMiddleScreen ? (
        <div className="externalBookingComponent-selectors">
          <div className="buttons-row">
            <SelectWithIcon
              placeholder=""
              options={menuPropsLocations}
              icon={locationIcon}
              iconWidth={11}
              iconHeight={16}
              onChange={selectVenueOption}
              value={venueId}
            />
          </div>
        </div>
      ) : (
        <div className="buttons-row">
          <SelectWithIcon
            placeholder=""
            options={menuPropsLocations}
            icon={locationIcon}
            iconWidth={11}
            iconHeight={16}
            onChange={selectVenueOption}
            value={venueId}
          />
        </div>
      )}
      {closed ? (
        isMobile ? (
          <div className="mobile-summary-inner">
            <div className="no-slots">
              <HTMLRenderer html={activeVenue?.closedMessage} />
            </div>
          </div>
        ) : (
          <div className="no-slots">
            <HTMLRenderer html={activeVenue?.closedMessage} />
          </div>
        )
      ) : (
        <div
          className={`externalBookingComponent-widget ${isMobile ? "mobile" : ""}`}
          dangerouslySetInnerHTML={{
            __html: liteWidget,
          }}
        ></div>
      )}
      {isMobile && (
        <div className="mobile-summary">
          <div className="mobile-summary-inner">
            <Accordion title={activeVenue?.name || ""}>
              <div className="location">
                <VenueAddress venue={activeVenue} />
                <div className="map-panel">
                  <MapImageLink venue={activeVenue} />
                </div>
              </div>
            </Accordion>
          </div>
          <div className="mobile-summary-inner">
            <Accordion
              className="venue-policy"
              title="VENUE POLICIES"
            >
              <Policy text={activeVenue?.venueInfo} />
            </Accordion>
          </div>
        </div>
      )}
    </div>
  );
};

export default connector(ExternalBookingComponent);
