import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { goBack } from "connected-react-router";
import { IDropdownOption, mergeStyleSets } from "office-ui-fabric-react";
import { selectVenues } from "../../reducers/venues";
import { pushUrlParamsAction, updateReservationAction } from "../../actions/reservation-actions";
import { changeVenueAction, toPartyWithVenueAction } from "../../actions/venue-actions";
import { selectUIConfig } from "../../reducers/ui-reducer";
import { State, VenueName } from "../../store/types";
import { formatLanesCount, getVenueDurations } from "../../../../common/utils/formats";
import Header from "../common/Header/Header";
import { SelectWithIcon } from "../../../../common/components/SelectWithIcon/SelectWithIcon";
import locationIcon from "../../../assets/location-icon.svg";
import guestsIcon from "../../../assets/guests-icon.svg";
import { PrimaryButton } from "../../../../common/components/PrimaryButton/PrimaryButton";
import { useViewport } from "../../hooks/responsive";
import { DATE_FORMAT } from "../../../../common/constants/timedate";
import dayjs from "dayjs";
import { getVenueList } from "../../utils/formats";
import "./home.scss";
import Header2 from "../common/Header/Header2";

const MORE_THAN_11 = 100;

const mapDispatchToProps = {
  changeVenue: changeVenueAction,
  toPartyWithVenue: toPartyWithVenueAction,
  updateReservation: updateReservationAction,
  goBack,
  pushUrlParams: pushUrlParamsAction,
};
const mapStateToProps = (state: State) => ({
  venues: selectVenues(state),
  uiConfig: selectUIConfig(state),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const getMenuPropsGuests: (count: number) => IDropdownOption[] = (
  count: number
) =>
  Array(count + 1)
    .fill(null)
    .map((_, index) => ({
      key: index === count ? MORE_THAN_11 : index + 1,
      text:
        index === 0
          ? `${index + 1} Guest`
          : `${index + 1}${index === count ? "+" : ""} Guests`,
    }));

const Home = ({
  venues,
  uiConfig,
  changeVenue,
  updateReservation,
  toPartyWithVenue,
  goBack,
  pushUrlParams,
}: Props) => {
  const { isMobile } = useViewport();
  const isOnlyOneLane = uiConfig?.hideNumberOfLines && !uiConfig?.subtractMultiLanes;

  const contentStyles = mergeStyleSets({
    body: {
      flex: 1,
      background: `no-repeat center url(${uiConfig?.homeBackgroundImage})`,
      backgroundSize: "cover",
    },
  });

  const menuPropsLocations = getVenueList(venues, '');
  const [venue, setVenue] = useState<VenueName>();
  const [guests, setGuests] = useState(2);
  const [errorVenue, setErrorVenue] = useState<string>("");

  const menuPropsGuests = getMenuPropsGuests(venue ? venue.maxGuests : 10);
  const date = dayjs().format(DATE_FORMAT);

  const selectVenueOption = (newVenueId?: number | string) => {
    const openedVenues = venues.filter(v => !v.isComingSoon);
    const currentVenue = venues.find((venue) => venue.id === newVenueId) || openedVenues[0];
    if (newVenueId && venue?.id !== newVenueId && currentVenue) {
      setErrorVenue("");
      setVenue(currentVenue);
      if (guests > currentVenue.maxGuests) {
        setGuests(MORE_THAN_11);
      }
      const duration = getVenueDurations(currentVenue)[0];
      changeVenue({
        venueId: newVenueId.toString(),
        date,
        duration,
        lanes: formatLanesCount(guests, venue?.guestSplit, isOnlyOneLane),
        guests,
      });
    }
  };
  const selectGuestsOption = (value?: number | string) => {
    if (!value) {
      return;
    }
    const guests = Number(value);
    setGuests(guests);
  };

  const onContinue = () => {
    if (!venue) {
      setErrorVenue("Please choose a location to continue");
      return;
    }
    if (guests === MORE_THAN_11) {
      toPartyWithVenue(dayjs().format(DATE_FORMAT));
      return;
    }
    updateReservation({ guests, date });
    pushUrlParams();
  };

  return (
    <div className="home-container">
      {uiConfig?.newDesign ? <Header2 handleBackButton={goBack} /> : <Header handleBackButton={goBack} />}

      <div className={contentStyles.body}>
        <div className="container">
          <div className="home-content main-content">
            <div className={`selectors ${isMobile ? "mobile" : ""}`}>
              <div className="title">{uiConfig?.homeTitle}</div>
              <div className="home-selector">
                {errorVenue && <div className="error">{errorVenue}</div>}
                <SelectWithIcon
                  placeholder="Select location"
                  options={menuPropsLocations}
                  icon={locationIcon}
                  iconWidth={11}
                  iconHeight={16}
                  onChange={selectVenueOption}
                  value={venue?.id}
                />
              </div>
              <div className="home-selector">
                <SelectWithIcon
                  placeholder="Number of Guests"
                  options={menuPropsGuests}
                  icon={guestsIcon}
                  iconWidth={20}
                  iconHeight={14}
                  value={guests}
                  onChange={selectGuestsOption}
                />
              </div>
              <PrimaryButton onClick={() => onContinue()} name="continue" uiConfig={uiConfig}>
                NEXT
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connector(Home);
