import React, { useEffect, useRef } from 'react';
import { Addon, CurrencyType, State } from "../../store/types";
import { useViewport } from "../../hooks/responsive";
import { selectAddonsQuantities, selectVenue } from "../../reducers/venues";
import { selectReservation, selectReservationAddons } from "../../reducers/reservation";
import { selectUIConfig } from "../../reducers/ui-reducer";
import { connect, ConnectedProps } from "react-redux";
import classNames from "classnames";
import './addonsCard.scss'
import downIcon from "../../../assets/down-icon.svg"
import { times } from "lodash";
import { setAddonQuantityAction, toggleAddonSelectedStateAction } from "../../actions/venue-actions";
import { formatPriceBasedOnValue } from "../../../../common/utils/formats";
import { SelectWithIcon } from "../../../../common/components/SelectWithIcon/SelectWithIcon";
import HTMLRenderer from "../../../../common/components/HTMLRenderer/HTMLRenderer";
import defaultImage from "../../../assets/default-image.jpg";

const mapStateToProps = (state: State) => ({
  reservation: selectReservation(state),
  uiConfig: selectUIConfig(state),
  activeVenue: selectVenue(state),
  addonQuantities: selectAddonsQuantities(state),
  reservationAddons: selectReservationAddons(state),
});

const mapDispatchToProps = {
  setAddonQuantity: setAddonQuantityAction,
  toggleSelected: toggleAddonSelectedStateAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = {
  item: Addon;
} & ConnectedProps<typeof connector>


const AddonsCard = (
  {
    item,
    activeVenue,
    addonQuantities,
    reservationAddons,
    uiConfig,
    setAddonQuantity,
    toggleSelected,
  }: Props
) => {
  const accordionContentHeight = useRef({ height: 0 });
  const accordionDivRef = useRef<HTMLDivElement>(null);

  const addonMinQuantity = item.matchGuestCount ? activeVenue?.maxGuests || 1 : item.minimumQuantity;
  const addonMaxQuantity = item.matchGuestCount ? activeVenue?.maxGuests || 1 : item.maximumQuantity;

  const quantityOptions = times((addonMaxQuantity + 1 - addonMinQuantity), (i) => ({
    key: String(i + addonMinQuantity),
    text: String(i + addonMinQuantity),
  }));

  useEffect(() => {
    if (accordionDivRef.current) {
      accordionContentHeight.current.height = accordionDivRef.current.scrollHeight;
    }
  }, [])

  const [showMoreInfo, setShowMoreInfo] = React.useState(false);
  const [addonsIsActive, setAddonsIsActive] = React.useState(!!reservationAddons.find(addon => addon.addonId === item.id));
  const [addonQuantityParam, setAddonQuantityParam] = React.useState(addonQuantities.find(addonQuantity => addonQuantity.addonId === item.id) || { addonId: item.id, quantity: +quantityOptions[0].key });
  const toggleShowMoreInfo = () => setShowMoreInfo(!showMoreInfo);

  const closed = activeVenue?.closed;
  const currency = uiConfig?.currency || CurrencyType.USD;

  const { isMobile } = useViewport();

  const renderPrice = () => {
    return (
      <p className={`price ${isMobile ? 'is-mobile' : ''}`}>
        <span className={`value ${isMobile ? 'is-mobile' : ''}`}>{formatPriceBasedOnValue(item.price, currency)}</span>
      </p>
    )
  }

  const renderMoreInfo = () => {
    return (
      <div
        ref={accordionDivRef}
        style={{ height: showMoreInfo ? `${accordionContentHeight.current.height}px` : 0 }}
        className={`addons-card-info-content-wrapper ${isMobile ? 'is-mobile' : ''}`}
      >
        <br />
        <HTMLRenderer html={item.description} className='info-content' />
      </div>
    )
  }

  const onUpdateAddonQuantity = (quantity: number) => {
    setAddonQuantity({
      addonId: item.id,
      quantity: quantity,
    })
    setAddonQuantityParam({
      addonId: item.id,
      quantity: quantity,
    })
  }

  const onSelectAddon = () => {
    toggleSelected(item, !addonsIsActive, addonQuantityParam?.quantity || +quantityOptions[0].key);
    setAddonsIsActive(!addonsIsActive);
  }

  return (
    <article
      className={
        classNames(
          'addons-card',
          {
            'is-active': addonsIsActive,
            'is-mobile': isMobile,
          }
        )
      }
    >
      <div className='addons-image-container'>
        <img src={item.image || defaultImage} alt={item.name} className={`addons-image ${isMobile ? 'is-mobile' : ''}`} />
        {/*<span className='addons-tag'>NEW</span>*/}
      </div>
      <div className={`addons-card-content  ${isMobile ? 'is-mobile' : ''}`}>
        <h1 className={`addons-card-title ${isMobile ? 'is-mobile' : ''}`}>
          {item.name}
        </h1>
        <div className={`details  ${isMobile ? 'is-mobile' : ''}`}>
          {renderPrice()}
        </div>
        <p className={`addons-card-short-description  ${isMobile ? 'is-mobile' : ''}`}>{item.shortDescription}</p>
        {renderMoreInfo()}
        <div className='addons-card-actions'>
          <button
            className={
              classNames('addons-card-info-button', {
                'is-active': showMoreInfo,
              })
            }
            onClick={toggleShowMoreInfo}
          >
            <span>
              {showMoreInfo ? 'Show less' : 'More Info'}
            </span>
            <img src={downIcon} alt="Down" />
          </button>
          <div className='addons-card-select-wrapper'>
            <label htmlFor="select-quantity" className='addons-card-select-label'>
              Qty
            </label>
            <SelectWithIcon
              options={quantityOptions}
              id='select-quantity'
              wrapperClassName={classNames(
                'addons-card-select',
                {
                  'is-mobile': isMobile,
                }
              )}
              contentClass='content'
              placeholder=''
              disabled={closed}
              value={String(addonQuantityParam?.quantity || +quantityOptions[0].key)}
              onChange={(quantity) => onUpdateAddonQuantity(quantity ? +quantity : 0)}
            />
          </div>
          <button
            className={
              classNames(
                'addons-card-select-button',
                {
                  'is-selected': addonsIsActive,
                  'is-mobile': isMobile,
                }
              )
            }
            onClick={() => onSelectAddon()}
          >
            {addonsIsActive ? 'Added' : 'Add'}
          </button>
        </div>
      </div>
    </article >
  )
}

export default connector(AddonsCard);
