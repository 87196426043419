import React, { useState, useEffect } from "react";
import { Checkbox, Text, Label, Link } from "office-ui-fabric-react";
import { isValidEmail, isValidPhone } from "../../../../../common/utils/validate";
import { useViewport } from "../../../hooks/responsive";
import { PrimaryButton } from "../../../../../common/components/PrimaryButton/PrimaryButton";
import { InputField } from "../../../../../common/components/InputField/InputField";
import { PhoneInput } from "../../../../../common/components/PhoneInput/PhoneInput";
import { FormField } from "../../../../../common/components/FormField/FormField";
import { NewClient } from "../../../store/types";

import "./registration.scss";
import { color } from "../../../../../common/constants/styles";
import MobileFixedButton from "../../MobileFixedButton/MobileFixedButton";

interface Props {
  register: (newClient: NewClient) => void;
  cleanError: () => void;
  continueReservation: boolean;
  error?: string;
  uiConfig: any;
}

const textStyle = {
  root: {
    color: color.grayText3,
    paddingTop: "5px",
  },
};

const textLinkStyle = {
  root: {
    color: color.mainDark,
  },
};

export const Registration = ({
  register,
  cleanError,
  error,
  continueReservation,
  uiConfig,
}: Props) => {
  const { isMobile } = useViewport();
  const [firstName, setFirstName] = useState<string | undefined>("");
  const [lastName, setLastName] = useState<string | undefined>("");
  const [phone, setPhone] = useState<string | undefined>("");
  const [email, setEmail] = useState<string | undefined>("");
  const [confirmEmail, setConfirmEmail] = useState<string | undefined>("");
  const [password, setPassword] = useState<string | undefined>("");
  const [subscribeToOffers, setSubscribeToOffers] = useState<boolean>(true);

  const [errorFirstName, setErrorFirstName] = useState<string>("");
  const [errorLastName, setErrorLastName] = useState<string>("");
  const [errorPhone, setErrorPhone] = useState<string>("");
  const [errorEmail, setErrorEmail] = useState<string>("");
  const [errorConfirmEmail, setErrorConfirmEmail] = useState<string>("");
  const [errorPassword, setErrorPassword] = useState<string>("");
  useEffect(() => () => cleanError(), [cleanError]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      firstName &&
      lastName &&
      phone &&
      email &&
      confirmEmail &&
      password &&
      email === confirmEmail &&
      isValidPhone(phone) &&
      isValidEmail(email)
    ) {
      register({
        firstName,
        lastName,
        phone,
        email,
        confirmEmail,
        password,
        subscribeToOffers,
        continueReservation,
      });
      return;
    }
    if (
      email &&
      confirmEmail &&
      email === confirmEmail &&
      isValidEmail(email) == true
    ) {
      setErrorEmail("");
      setErrorConfirmEmail("");
    }

    if (!firstName) {
      setErrorFirstName("This field is required.");
    }
    if (!lastName) {
      setErrorLastName("This field is required.");
    }
    if (!phone || !isValidPhone(phone)) {
      setErrorPhone("Please enter a valid phone number.");
    }

    if (!email || !isValidEmail(email)) {
      setErrorEmail("Please enter a valid email address.");
    }
    if (!confirmEmail || !isValidEmail(confirmEmail)) {
      setErrorConfirmEmail("Please enter a valid email address.");
    }
    if (confirmEmail && email && email !== confirmEmail) {
      setErrorEmail("These fields must match.");
      setErrorConfirmEmail("These fields must match.");
    }
    if (!password) {
      setErrorPassword("This field is required.");
    }
  };

  const onFirstNameChange = (_: any, text?: string) => {
    setErrorFirstName("");
    setFirstName(text);
  };
  const onLastNameChange = (_: any, text?: string) => {
    setErrorLastName("");
    setLastName(text);
  };
  const onPhoneChange = (text?: string) => {
    setErrorPhone("");
    setPhone(text);
  };
  const onEmailChange = (_: any, text?: string) => {
    setErrorEmail("");
    setErrorConfirmEmail("");
    setEmail(text);
  };
  const onConfirmEmailChange = (_: any, text?: string) => {
    setErrorEmail("");
    setErrorConfirmEmail("");
    setConfirmEmail(text);
  };
  const onPasswordChange = (_: any, text?: string) => {
    setErrorPassword("");
    setPassword(text);
  };
  return (
    <form
      className={
        isMobile
          ? "account-registrationInfo mobile"
          : "account-registrationInfo main-content"
      }
      onSubmit={onSubmit}
    >
      <div className="row">
        <InputField
          label="First Name"
          name="firstName"
          value={firstName}
          errorMessage={errorFirstName}
          onChange={onFirstNameChange}
          autoComplete="given-name"
        />
        <div className="row-delimiter"></div>
        <InputField
          label="Last Name"
          name="lastName"
          value={lastName}
          errorMessage={errorLastName}
          onChange={onLastNameChange}
          autoComplete="family-name"
        />
      </div>
      <div className={isMobile ? "column" : "row"}>
        <FormField label="Phone" error={errorPhone}>
          <PhoneInput
            placeholder="Phone number"
            defaultCountry="US"
            value={phone || ""}
            name="phone"
            onChange={onPhoneChange}
            limitMaxLength={true}
          />
        </FormField>
        {!isMobile && <div className="row-delimiter"></div>}
        <InputField
          label="Email"
          name="email"
          value={email}
          errorMessage={errorEmail}
          onChange={onEmailChange}
          autoComplete="email"
          type="email"
        />
      </div>
      <div className={isMobile ? "column" : "row"}>
        <InputField
          label="Confirm email"
          name="confirmEmail"
          value={confirmEmail}
          errorMessage={errorConfirmEmail}
          onChange={onConfirmEmailChange}
          autoComplete="off"
          type="email"
        />
        {!isMobile && <div className="row-delimiter"></div>}
        <InputField
          type="password"
          label="Password"
          name="password"
          value={password}
          errorMessage={errorPassword}
          onChange={onPasswordChange}
          autoComplete="new-password"
        />
      </div>
      {error && <div className="error">{error}</div>}
      <div className={isMobile ? "column" : "row"}>
        <div className="privacyPolicy">
          <Checkbox
            className="focusableCheckbox"
            label="Keep me updated on exclusive offers and events"
            checked={subscribeToOffers}
            onChange={(_, value) => setSubscribeToOffers(!!value)}
            name="offersAndEvents"
          />
          <Text variant={"medium"} styles={textStyle}>
            By proceeding, you will create an account and agree to our{' '}
            <Link href={uiConfig.privacyPolicy} target="_blank" styles={textLinkStyle}>Privacy Policy</Link>
            {' '}and{' '}
            <Link href={uiConfig.termsOfUse} target="_blank" styles={textLinkStyle}> Terms of Use </Link>
          </Text>
        </div>
        <div className="row-delimiter"></div>
        {
          isMobile ? (
            <MobileFixedButton type="submit" name="continue" uiConfig={uiConfig}>
              CONTINUE
            </MobileFixedButton>
          ) : (
            <PrimaryButton type="submit" name="continue" uiConfig={uiConfig}>
              CONTINUE
            </PrimaryButton>
          )
        }
      </div>
    </form>
  );
};
