import React from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  IconButton,
  IIconProps,
  DefaultButton,
  IButtonStyles,
  mergeStyleSets,
  registerIcons,
} from "office-ui-fabric-react";
import { getLocation } from "connected-react-router";
import { State } from "../../../store/types";
import { selectUIConfig } from "../../../reducers/ui-reducer";
import { selectCurrentPackage, selectVenue } from "../../../reducers/venues";
import { selectReservation } from "../../../reducers/reservation";
import { selectAuthenticated } from "../../../reducers/auth-reducer";
import { backToReservationAction } from "../../../actions/venue-actions";
import { pushUrlPathAction } from "../../../actions/reservation-actions";
import {
  slotToTime,
  formatDuration,
  formatReservationDate,
  formatPrice,
  getReservationTotalPrice,
} from "../../../../../common/utils/formats";
import { useViewport } from "../../../hooks/responsive";
import LoginIcon from "../../../../assets/login-icon.svgr";
import "./header.scss";
import { DateFormat } from "../../../../../server/src/entities/enums";

interface HeaderProps {
  showReservation?: boolean;
  party?: boolean;
  cancellation?: boolean;
  repay?: boolean;
  handleBackButton?: () => void;
}

registerIcons({
  icons: {
    "loginIcon-svg": <LoginIcon />,
  },
});

const RIGHT_ICON_SIZE = 40;

const backButton: IIconProps = { iconName: "Back" };

const MobileRightIcon = ({
  name,
  handler,
  headerForegroundColor,
  headerBackgroundColor,
}: {
  name: string;
  handler: () => void;
  headerForegroundColor?: string;
  headerBackgroundColor?: string;
}) => (
  <IconButton
    iconProps={{
      iconName: "loginIcon-svg",
      styles: {
        root: {
          height: RIGHT_ICON_SIZE,
          width: RIGHT_ICON_SIZE,
          fill: headerForegroundColor,
        },
      },
    }}
    styles={{
      root: {
        height: RIGHT_ICON_SIZE,
        width: RIGHT_ICON_SIZE,
        marginRight: "20px",
        paddingRight: 0,
      },
      rootHovered: { background: headerBackgroundColor },
      rootPressed: { background: headerBackgroundColor },
    }}
    name={name}
    onClick={handler}
    className="focusableHeaderButton"
  ></IconButton>
);

const mapDispatchToProps = {
  pushUrlPath: pushUrlPathAction,
  backToReservation: backToReservationAction,
};

const mapStateToProps = (state: State) => ({
  reservation: selectReservation(state),
  venue: selectVenue(state),
  authenticated: selectAuthenticated(state),
  pageLocation: getLocation(state),
  uiConfig: selectUIConfig(state),
  currentPackage: selectCurrentPackage(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & HeaderProps;

const Header = ({
  showReservation,
  party,
  cancellation,
  repay,
  authenticated,
  pushUrlPath,
  backToReservation,
  reservation,
  venue,
  pageLocation,
  uiConfig,
  handleBackButton,
  currentPackage,
}: Props) => {
  const { isMobile } = useViewport();

  const homeLogo = uiConfig?.homeLogo;
  const headerBackgroundColor = uiConfig?.headerBackgroundColor || "#000";
  const headerForegroundColor = uiConfig?.headerForegroundColor || "#fff";
  const homeLink = uiConfig?.homeLink;
  const companyName = uiConfig?.companyName;
  const actionText = uiConfig?.actionText;
  const hideDuration = uiConfig?.hideDuration;
  const hideCreateAccount = uiConfig?.hideCreateAccount;
  const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;

  const contentStyles = mergeStyleSets({
    header: {
      background: headerBackgroundColor,
      color: headerForegroundColor,
    },
    element: {
      color: headerForegroundColor,
    },
  });

  const backButtonStiles: IButtonStyles = {
    root: {
      color: headerForegroundColor,
      border: `1px solid ${headerForegroundColor}`,
      borderRadius: "50%",
      marginLeft: "20px",
    },
  };

  const buttonStyle = (
    isMobile: boolean,
    isSmallWidthScreen: boolean
  ): IButtonStyles => ({
    root: {
      background: headerBackgroundColor,
      color: headerForegroundColor,
      padding: 0,
      border: 0,
      height: "100%",
      minWidth: "0",
      width: "auto",
      marginRight: "10px",
      fontSize: "inherit",
      textTransform: "uppercase",
    },
    rootHovered: {
      background: "transparent",
      color: headerForegroundColor,
    },
    rootPressed: {
      background: "transparent",
      color: headerForegroundColor,
    },
    flexContainer: {
      justifyContent: "flex-end",
    },
    textContainer: {
      flexGrow: 0,
      maxWidth: isMobile && isSmallWidthScreen ? "min-content" : "max-content",
    },
    label: {
      border: "1px solid transparent",
    },
    labelHovered: {
      borderBottom: `1px solid ${headerForegroundColor}`,
    },
  });

  const dateFormat =
    uiConfig?.dateFormat === DateFormat.MMDDYYYY ? "ddd, MM/D" : "ddd, D/MM";

  const { date, duration, slots, lanes, timeSlotShifting } = reservation;
  const totalPrice = getReservationTotalPrice(reservation);
  const parsedTotalPrice = formatPrice(totalPrice, reservation.currency, true);
  const title1 =
    showReservation && venue && date
      ? `${venue.name}: ${formatReservationDate(
          reservation,
          dateFormat
        )} @ ${slotToTime(
          slots,
          venue.timeSlotDuration,
          twelveHourClockFormat,
          timeSlotShifting
        )}`
      : null;
  const title2 =
    showReservation && venue && date
      ? currentPackage
        ? `${currentPackage.name} = ${parsedTotalPrice}`
        : `${lanes} ${actionText}${reservation.lanes < 2 ? "" : "s"} ${
            hideDuration
              ? ""
              : `, ${formatDuration(duration, venue.timeSlotDuration)}`
          } = ${parsedTotalPrice}`
      : null;
  const onRightButtonClick = () => {
    if (reservation.price) {
      pushUrlPath("/reservation-info/reservation-confirm");
    } else {
      backToReservation();
    }
  };
  const isSmallWidthScreen = document.documentElement.clientWidth < 500;
  if (party) {
    return (
      <div className={`header ${contentStyles.header}`}>
        <div className="party-header">
          <a className="logo-link" href={homeLink}>
            <img
              className="makeReservation-top-image"
              src={homeLogo}
              alt={companyName}
            ></img>
          </a>
        </div>
        {handleBackButton && (
          <div className="makeReservation-top-backButton">
            <IconButton
              className="focusableRoundButton"
              iconProps={backButton}
              styles={backButtonStiles}
              onClick={handleBackButton}
              name="back"
            />
          </div>
        )}
      </div>
    );
  }
  if (cancellation) {
    return (
      <div className={`header ${contentStyles.header}`}>
        <div className="cancellation-header">
          <a className="logo-link" href={homeLink}>
            <img
              className="makeReservation-top-image"
              src={homeLogo}
              alt={companyName}
            ></img>
          </a>
        </div>
      </div>
    );
  }
  if (repay) {
    return (
      <div className={`header ${contentStyles.header}`}>
        <a className="logo-link" href={homeLink}>
          <img
            className="makeReservation-top-image"
            src={homeLogo}
            alt={companyName}
          ></img>
        </a>
      </div>
    );
  }
  return (
    <div className={`header ${contentStyles.header}`}>
      <div className="makeReservation-top-backButton">
        <IconButton
          className="focusableRoundButton"
          iconProps={backButton}
          styles={backButtonStiles}
          onClick={handleBackButton}
          name="back"
        />
      </div>
      {showReservation ? (
        <div className={`reservation-info ${contentStyles.element}`}>
          <div className="title">{title1}</div>
          <div className="title">{title2}</div>
        </div>
      ) : (
        <a className="logo-link" href={homeLink}>
          <img
            className="makeReservation-top-image"
            src={homeLogo}
            alt={companyName}
          ></img>
        </a>
      )}
      {!hideCreateAccount && (
        <div className="makeReservation-top-loginButton">
          {authenticated ? (
            pageLocation.pathname === "/account" ? (
              <DefaultButton
                className="focusableHeaderButton"
                styles={buttonStyle(isMobile, isSmallWidthScreen)}
                onClick={onRightButtonClick}
                name="reservation"
              >
                {reservation.price ? "RESERVATION" : "MAKE A RESERVATION"}
              </DefaultButton>
            ) : isMobile ? (
              <MobileRightIcon
                name="account"
                handler={() => pushUrlPath("/account")}
                headerForegroundColor={headerForegroundColor}
                headerBackgroundColor={headerBackgroundColor}
              />
            ) : (
              <DefaultButton
                className="focusableHeaderButton"
                styles={buttonStyle(isMobile, isSmallWidthScreen)}
                onClick={() => pushUrlPath("/account")}
                name="account"
              >
                ACCOUNT
              </DefaultButton>
            )
          ) : isMobile ? (
            <MobileRightIcon
              name="login"
              handler={() => pushUrlPath("/reservation-info/account")}
              headerForegroundColor={headerForegroundColor}
              headerBackgroundColor={headerBackgroundColor}
            />
          ) : (
            <DefaultButton
              className="focusableHeaderButton"
              styles={buttonStyle(isMobile, isSmallWidthScreen)}
              onClick={() => pushUrlPath("/reservation-info/account")}
              name="login"
            >
              LOGIN
            </DefaultButton>
          )}
        </div>
      )}
    </div>
  );
};

export default connector(Header);
