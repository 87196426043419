import React from "react";
import { ReservationDto } from "../../../server/src/dto/reservation.dto";
import { slotToTime, formatDuration, formatReservationDate } from "../../utils/formats";
import { FULL_DATE_FORMAT } from "../../constants/timedate";
import "./reservationDetails.scss";
import { PackageNameDto } from "../../../server/src/dto/packageName.dto";

interface Props {
  reservation: ReservationDto;
  actionText?: string;
  isHideDuration?: boolean;
  currentPackage?: PackageNameDto;
  twelveHourClockFormat: boolean;
  guests: number;
}

export const Details = ({ reservation, actionText, isHideDuration, currentPackage, twelveHourClockFormat, guests }: Props) => (
  <div className="reservation-details">
    <div className="reservation-details-row">
      {!currentPackage
        ? `${guests} ${guests === 1 ? "Guest" : "Guests"
          }, ${reservation?.lanes} ${actionText}${reservation.lanes < 2 ? "" : "s"
          }`
        : currentPackage.name
      }
    </div>
    {currentPackage && (
      <div className="reservation-details-row">
        {`${guests} ${guests === 1 ? "Guest" : "Guests"}`}
      </div>
    )}
    <div className="reservation-details-row">
      {formatReservationDate(reservation, FULL_DATE_FORMAT)}
    </div>
    <div className="reservation-detail-row">
      {`${slotToTime(
        reservation?.slots,
        reservation.timeSlotDuration,
        twelveHourClockFormat,
        reservation.timeSlotShifting
      )} ${isHideDuration
        ? ''
        : ` for ${formatDuration(
          reservation?.duration,
          reservation.timeSlotDuration
        )}`}`}
    </div>
  </div>
);
