import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { DefaultButton, IButtonStyles, mergeStyleSets } from "office-ui-fabric-react";
import { getSearch } from "connected-react-router";
import { Login } from "../Login/Login";
import { Recover } from "../Recover/Recover";
import { Registration } from "../Registration/Registration";
import { ContinueAsGuest } from "../ContinueAsGuest/ContinueAsGuest";
import { State } from "../../../store/types";
import {
  selectAuthError,
  selectIsAuthRequestInProgress,
  selectIsRecoverCompleted,
} from "../../../reducers/auth-reducer";
import {
  recoverAction,
  loginAction,
  registerAction,
  cleanAuthErrorAction,
  resetRecoverStatusAction,
  continueAsGuestAction,
} from "../../../actions/auth-actions";
import { selectUIConfig } from "../../../reducers/ui-reducer";
import { color } from "../../../../../common/constants/styles";
import { rem } from "../../../../../common/utils/formats";
import { parseUrlQuery } from "../../../utils/urlSearchQuery";
import { useViewport } from "../../../hooks/responsive";
import "./account.scss";

const loginButtonStyle: IButtonStyles = {
  root: {
    border: `1px solid ${color.grayBorder3}`,
    height: rem(50),
    width: 170,
    background: color.grayBackground4,
    color: color.mainDark,
    zIndex: 1,
    padding: 0,
  },
  label: {
    fontSize: rem(16),
    fontWeight: "300",
  },
};

const mapStateToProps = (state: State) => ({
  error: selectAuthError(state),
  isInProgress: selectIsAuthRequestInProgress(state),
  isRecoverCompleted: selectIsRecoverCompleted(state),
  searchParams: getSearch(state),
  uiConfig: selectUIConfig(state)
});

const mapDispatchToProps = {
  login: loginAction,
  recover: recoverAction,
  register: registerAction,
  continueAsGuest: continueAsGuestAction,
  cleanAuthError: cleanAuthErrorAction,
  resetRecoverStatus: resetRecoverStatusAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const Account = ({
  login,
  recover,
  register,
  error,
  isInProgress,
  isRecoverCompleted,
  resetRecoverStatus,
  cleanAuthError,
  searchParams,
  uiConfig,
  continueAsGuest,
}: Props) => {
  const params = parseUrlQuery(searchParams);
  const isDisableGuestCheckout = uiConfig?.disableGuestCheckout;

  const showLoginAsGuest = !!params.continue && !isDisableGuestCheckout;
  const showCreateAccount = !uiConfig?.hideCreateAccount || !showLoginAsGuest;

  const { isMobile } = useViewport();
  const [isLogin, setIsLogin] = useState(false);
  const [isGuest, setIsGuest] = useState(!showCreateAccount);
  const [isRecover, setIsRecover] = useState(false);

  const loginButtonFocusStyle: IButtonStyles = {
    root: {
      border: `1px solid ${color.grayBorder3}`,
      borderBottom: "none",
      height: rem(50),
      width: 170,
      color: color.mainDark,
      padding: 0,
      zIndex: 1,
      fontSize: rem(16),
      fontWeight: "700",
      cursor: "default",
    },
    flexContainer: {
      background: uiConfig?.bodyBackgroundColor,
      padding: "25px 0 50px",
      position: "relative",
    },
    label: {
      fontSize: rem(16),
      fontWeight: "500",
    },
  };

  const contentStyles = mergeStyleSets({
    venueName: {
      color: uiConfig?.mainButtonColor,
    },
    venueActiveMenu: {
      color: `${uiConfig?.mainButtonColor} !important`,
      borderBottom: `3px solid ${uiConfig?.mainButtonColor} !important`,
    }
  });

  let content = null
  if (isLogin && isRecover) {
    content = <Recover
      recover={recover}
      toLogin={() => setIsRecover(false)}
      error={error}
      isInProgress={isInProgress}
      isRecoverCompleted={isRecoverCompleted}
      resetRecoverStatus={resetRecoverStatus}
      cleanError={cleanAuthError}
      uiConfig={uiConfig}
    />
  } else if (isGuest && showLoginAsGuest) {
    content = <ContinueAsGuest
      continueAsGuest={continueAsGuest}
      error={error}
      cleanError={cleanAuthError}
      continueReservation={!!params.continue}
      uiConfig={uiConfig}
    />
  } else if (isLogin) {
    content = <Login
      login={login}
      toRecover={() => setIsRecover(true)}
      error={error}
      isInProgress={isInProgress}
      cleanError={cleanAuthError}
      continueReservation={!!params.continue}
      uiConfig={uiConfig}
    />
  } else {
    content = <Registration
      register={register}
      error={error}
      cleanError={cleanAuthError}
      continueReservation={!!params.continue}
      uiConfig={uiConfig}
    />
  }

  const onCreateAccount = () => {
    setIsGuest(false);
    setIsLogin(false);
  }
  const onSignIn = () => {
    setIsGuest(false);
    setIsLogin(true);
  }
  const onContinueAsGuest = () => {
    setIsGuest(true);
    setIsLogin(false);
  }

  return (
    <div className="auth-container">
      <div className="auth-inner-container">
        <div className={`loginButton ${isMobile ? "mobile" : "main-content"}`}>
          {
            showCreateAccount && (
              <>
                <div
                  className={`focusableButton ${isMobile ? "mobile" : ""} ${!isLogin && !isGuest ? `active ${contentStyles.venueActiveMenu}` : ""}`}
                  onClick={onCreateAccount}
                >
                  Create Account
                </div>
                {!isMobile && <div className="link-delimiter" />}
                <div
                  className={`focusableButton ${isMobile ? "mobile" : ""} ${isLogin && !isGuest ? `active ${contentStyles.venueActiveMenu}` : ""}`}
                  onClick={onSignIn}
                >
                  Sign In
                </div>
                {!isMobile && <div className="link-delimiter" />}
              </>
            )
          }
          {showLoginAsGuest && (
            <>
              <div
                className={`focusableButton ${isMobile ? "mobile" : ""} ${!isLogin && isGuest ? `active ${contentStyles.venueActiveMenu}` : ""}`}
                onClick={onContinueAsGuest}
              >
                Guest Checkout
              </div>
            </>
          )}
        </div>
        {content}
      </div>
    </div>
  );
};

export default connector(Account);