import React from "react";
import { ICheckboxStyles, Checkbox } from "office-ui-fabric-react";
import { rem } from "../../utils/formats";

interface CheckboxConfirmProps {
    isCheck: boolean;
    onChangeCheck: (_: any, isChecked?: boolean) => void;
    age?: string;
}

const confirmStyle: ICheckboxStyles = {
    checkbox: {
        top: rem(3.2),
        width: rem(20),
        height: rem(20),
    },
    text: {
        fontSize: rem(13),
        lineHeight: "1.5",
    },
    checkmark: {
        fontSize: rem(13),
    },
};

const CheckboxConfirm = ({ isCheck, onChangeCheck, age }: CheckboxConfirmProps) => {

    return (
        <Checkbox
            className="focusableCheckbox"
            styles={confirmStyle}
            label={`Our venue is restricted to guests ${age || '21'}+ during this reservation time. Please check the box to confirm your party will not have any guests under ${age || '21'}.`}
            name="ageLimit"
            onChange={onChangeCheck}
            checked={isCheck}
        />
    );
};

export default CheckboxConfirm;