import React, { useState, useEffect } from "react";
import {
  Text,
  Spinner,
  SpinnerSize,
  ActionButton,
  ITextStyles,
  Icon,
} from "office-ui-fabric-react";
import { isValidEmail } from "../../../../../common/utils/validate";
import { useViewport } from "../../../hooks/responsive";
import { PrimaryButton } from "../../../../../common/components/PrimaryButton/PrimaryButton";
import { InputField } from "../../../../../common/components/InputField/InputField";
import "./recover.scss";
import { rem } from "../../../../../common/utils/formats";
import MobileFixedButton from "../../MobileFixedButton/MobileFixedButton";

interface Props {
  recover: (email: string) => void;
  isInProgress: boolean;
  isRecoverCompleted: boolean;
  resetRecoverStatus: () => void;
  toLogin: () => void;
  cleanError: () => void;
  error?: string;
  uiConfig: any;
}

const textStyles: ITextStyles = {
  root: {
    margin: "0px 0px 26px",
    fontSize: rem(38),
    fontWeight: 900,
  },
};

export const Recover = ({
  recover,
  error,
  isInProgress,
  toLogin,
  cleanError,
  isRecoverCompleted,
  resetRecoverStatus,
  uiConfig,
}: Props) => {
  const { isMobile } = useViewport();
  const [username, setUserName] = useState<string | undefined>("");
  const [errorUsername, setErrorUsername] = useState<string>("");
  useEffect(() => {
    resetRecoverStatus();
    cleanError();
    return () => {
      resetRecoverStatus();
      cleanError();
    };
  }, [cleanError, resetRecoverStatus]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (username && isValidEmail(username) == true) {
      recover(username);
      return;
    }
    if (!username) {
      setErrorUsername("This field is required.");
    }
    if (username && isValidEmail(username) == false) {
      setErrorUsername("Please enter a valid email address.");
    }
  };
  const onUserNameChange = (_: any, text?: string) => {
    setErrorUsername("");
    setUserName(text);
  };
  return (
    <form className="recover main-content" onSubmit={onSubmit} noValidate>
      <div className={isMobile ? "mobile-recover-fields" : "recover-fields"}>
        {isInProgress && (
          <div className="loading">
            <Spinner size={SpinnerSize.large} />
          </div>
        )}
        <Text styles={textStyles}>Forgot your password?</Text>
        <div className="sub-title">
          Enter your email address and we’ll send you a link to reset your
          password.
        </div>
        {isRecoverCompleted ? (
          <div className="completed">
            <div className="completed-icon">
              <Icon
                iconName="CheckMark"
                className="completed-icon-1"
                aria-hidden="true"
              />
            </div>
            <div className="completed-label">
              You’re almost done - we’ve just sent you an email with a link to
              reset your password.
            </div>
          </div>
        ) : (
          <>
            <InputField
              type="email"
              label="Email"
              name="email"
              value={username}
              errorMessage={errorUsername}
              onChange={onUserNameChange}
              autoComplete="email"
            />
            {error && <div className="error">{error}</div>}
            {
              isMobile ? (
                <MobileFixedButton type="submit" name="continue" uiConfig={uiConfig}>
                  CONTINUE
                </MobileFixedButton>
              ) : (
                <PrimaryButton type="submit" name="continue" uiConfig={uiConfig}>
                  CONTINUE
                </PrimaryButton>
              )
            }
            <ActionButton
              className="returnLogin focusableButton"
              onClick={toLogin}
              name="loginButton"
            >
              Return to Login
            </ActionButton>
          </>
        )}
      </div>
    </form>
  );
};
