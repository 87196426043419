import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { getSearch } from "connected-react-router";
import {
  Text,
  Spinner,
  SpinnerSize,
} from "office-ui-fabric-react";
import Header from "../common/Header/Header";
import Footer from "../common/Footer/Footer";
import { goBack } from "connected-react-router";
import { State } from "../../store/types";
import {
  selectAuthError,
  selectIsAuthRequestInProgress,
  selectIsPasswordChanged,
} from "../../reducers/auth-reducer";
import {
  resetPasswordAction,
  cleanAuthErrorAction,
} from "../../actions/auth-actions";
import { selectUIConfig } from "../../reducers/ui-reducer";
import { parseUrlQuery } from "../../utils/urlSearchQuery";
import { PrimaryButton } from "../../../../common/components/PrimaryButton/PrimaryButton";
import { InputField } from "../../../../common/components/InputField/InputField";
import { useViewport } from "../../hooks/responsive";
import "./resetPassword.scss";

const mapDispatchToProps = {
  goBack,
  resetPassword: resetPasswordAction,
  cleanAuthError: cleanAuthErrorAction,
};
const mapStateToProps = (state: State) => ({
  error: selectAuthError(state),
  isInProgress: selectIsAuthRequestInProgress(state),
  isPasswordChanged: selectIsPasswordChanged(state),
  searchParams: getSearch(state),
  uiConfig: selectUIConfig(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const textStyles = {
  root: {
    marginTop: "36px",
  },
};

const ResetPassword = ({
  resetPassword,
  goBack,
  isInProgress,
  isPasswordChanged,
  error,
  searchParams,
  cleanAuthError,
  uiConfig,
}: Props) => {
  useEffect(() => {
    cleanAuthError();
    return () => {
      cleanAuthError();
    };
  }, [cleanAuthError]);
  const [password, setPassword] = useState<string | undefined>("");
  const [repeatPassword, setRepeatPassword] = useState<string | undefined>("");

  const [errorPassword, setErrorPassword] = useState<string>("");
  const [errorRepeatPassword, setErrorRepeatPassword] = useState<string>("");
  const { isMobile } = useViewport();

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if(isPasswordChanged){
      goBack();
      return
    }
    const { token } = parseUrlQuery(searchParams);
    if (password && repeatPassword === password && token) {
      resetPassword({
        password,
        token,
      });
      return;
    }
    if (!password) {
      setErrorPassword("This field is required.");
    }
    if (!repeatPassword) {
      setErrorRepeatPassword("This field is required.");
    }
    if (password && repeatPassword && repeatPassword !== password) {
      setErrorPassword("These fields must match.");
      setErrorRepeatPassword("These fields must match.");
    }
  };
  const onPasswordChange = (_: any, text?: string) => {
    setErrorPassword("");
    setErrorRepeatPassword("");
    setPassword(text);
  };
  const onRepeatPasswordChange = (_: any, text?: string) => {
    setErrorPassword("");
    setErrorRepeatPassword("");
    setRepeatPassword(text);
  };
  return (
    <div className="reset-password">
      {isInProgress && (
        <div className="loading">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      <Header handleBackButton={goBack} />
      <form
        className={isMobile ? "mobile-reset-form" : "reset-form"}
        onSubmit={onSubmit}
      >
        <Text variant={"xxLargePlus"} styles={textStyles}>
          Reset password
        </Text>
        <InputField
          type="password"
          name="password"
          label="Password"
          value={password}
          errorMessage={errorPassword}
          onChange={onPasswordChange}
        />
        <InputField
          type="password"
          name="repeatPassword"
          label="Repeat password"
          value={repeatPassword}
          errorMessage={errorRepeatPassword}
          onChange={onRepeatPasswordChange}
        />
        {error && <div className="error">{error}</div>}
        {isPasswordChanged && (
          <div className="info">
            Password is successfully changed, you can continue to make booking.
          </div>
        )}
        <div className="bottom">
          <PrimaryButton type="submit" name="continue" uiConfig={uiConfig}>
            CONTINUE
          </PrimaryButton>
        </div>
      </form>
      <Footer />
    </div>
  );
};

export default connector(ResetPassword);
