import React, { useEffect, useState, useRef, useCallback } from "react";
import { getSearch } from "connected-react-router";
import dayjs from "dayjs";
import {
  Checkbox,
  Spinner,
  SpinnerSize,
  mergeStyleSets,
} from "office-ui-fabric-react";
import { connect, ConnectedProps } from "react-redux";
import { goBack } from "connected-react-router";
import {
  submitPartyAction,
  setIsPartySubmittedAction,
  setReservationErrorAction,
  replaceAppUrlAction,
} from "../../actions/reservation-actions";
import {
  selectIsLoadingVenues,
  selectIsLoadingVenue,
  selectVenue,
  selectVenues,
} from "../../reducers/venues";
import {
  selectIsRequestInProgress,
  selectIsPartySubmitted,
  selectRequestError,
} from "../../reducers/reservation";
import { selectUIConfig } from "../../reducers/ui-reducer";
import {
  getVenueAction,
  toReservationWithVenueAction,
  toPackagesWithVenueAction,
} from "../../actions/venue-actions";
import { State } from "../../store/types";
import Header from "../common/Header/Header";
import Footer from "../common/Footer/Footer";
import locationIcon from "../../../assets/location-icon.svg";
import cakeIcon from "../../../assets/cake-icon.svg";
import timeIcon from "../../../assets/time-icon.svg";
import { SelectWithIcon } from "../../../../common/components/SelectWithIcon/SelectWithIcon";
import { Accordion } from "../../../../common/components/Accordion/Accordion";
import { PartyCalendar } from "../common/PartyCalendar/PartyCalendar";
import {
  composePartyTimeSlots,
  formatOccasionsAndGuestDetails,
  formatUSPhone,
  getPartyDurationList,
} from "../../../../common/utils/formats";
import {
  CALENDAR_DATE_FORMAT,
  CALENDAR_DATE_MOBILE_FORMAT,
  DATE_FORMAT,
} from "../../../../common/constants/timedate";
import { CalendarButton } from "../../../../common/components/CalendarButton/CalendarButton";
import HTMLRenderer from "../../../../common/components/HTMLRenderer/HTMLRenderer";
import { useViewport } from "../../hooks/responsive";
import { PrimaryButton } from "../../../../common/components/PrimaryButton/PrimaryButton";
import { VenueAddress } from "../../../../common/components/VenueAddress/VenueAddress";
import { InputField } from "../../../../common/components/InputField/InputField";
import { PhoneInput } from "../../../../common/components/PhoneInput/PhoneInput";
import { FormField } from "../../../../common/components/FormField/FormField";
import { MapImageLink } from "../../../../common/components/MapImageLink/MapImageLink";
import { isValidEmail, isValidPhone } from "../../../../common/utils/validate";
import { parseUrlQuery } from "../../utils/urlSearchQuery";
import { isEqual } from "lodash";
import "./makePartyReservation.scss";
import "../../../../common/styles/responsive.scss";
import MobileFixedButton from "../MobileFixedButton/MobileFixedButton";
import { getVenueList } from "../../utils/formats";

export const isValidNumber = (value: string) => value && !isNaN(+value);

const durationList = getPartyDurationList();

const mapDispatchToProps = {
  submitParty: submitPartyAction,
  setIsPartySubmitted: setIsPartySubmittedAction,
  getVenue: getVenueAction,
  toReservationWithVenue: toReservationWithVenueAction,
  toPackagesWithVenue: toPackagesWithVenueAction,
  setReservationError: setReservationErrorAction,
  replaceAppUrl: replaceAppUrlAction,
  goBack,
};

const mapStateToProps = (state: State) => ({
  isLoadingVenues: selectIsLoadingVenues(state),
  isLoadingVenue: selectIsLoadingVenue(state),
  isRequestInProgress: selectIsRequestInProgress(state),
  isPartySubmitted: selectIsPartySubmitted(state),
  error: selectRequestError(state),
  activeVenue: selectVenue(state),
  venues: selectVenues(state),
  searchParams: getSearch(state),
  uiConfig: selectUIConfig(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const MakePartyReservation = ({
  venues,
  activeVenue,
  isLoadingVenues,
  isLoadingVenue,
  isRequestInProgress,
  isPartySubmitted,
  error,
  searchParams,
  uiConfig,
  getVenue,
  submitParty,
  setIsPartySubmitted,
  goBack,
  toReservationWithVenue,
  toPackagesWithVenue,
  setReservationError,
  replaceAppUrl,
}: Props) => {
  const top = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (top?.current) {
      window.scrollTo(0, top.current.offsetTop);
    }
  }, [top]);

  useEffect(() => {
    setIsPartySubmitted(false);
    if (activeVenue) {
      setVenueId(activeVenue.id);
      return;
    }
  }, [activeVenue]);
  const [phase, setPhase] = useState(0);
  useEffect(() => {
    setReservationError();
  }, [phase]);

  const { isMobile } = useViewport();
  const [isCalendar, setIsCalendar] = useState(false);
  const [isCheck, setIsCheck] = useState<boolean>(true);
  const [occasion, setOccasion] = useState<string>("");
  const [venueId, setVenueId] = useState<string>("");
  const [targetDate, setTargetDate] = useState<string>("");
  const [timeSlot, setTimeSlot] = useState<number | undefined>(undefined);
  const [duration, setDuration] = useState<number | undefined>(undefined);
  const [guestCount, setGuestCount] = useState<string>("");
  const [errorGuestCount, setErrorGuestCount] = useState<string>("");
  const [errorFirstName, setErrorFirstName] = useState<string>("");
  const [errorLastName, setErrorLastName] = useState<string>("");
  const [errorPhone, setErrorPhone] = useState<string>("");
  const [errorEmail, setErrorEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const partyTitle = uiConfig?.partyTitle;
  const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;
  const occasions = formatOccasionsAndGuestDetails(uiConfig?.occasions);
  const contentStyles = mergeStyleSets({
    venueName: {
      color: uiConfig?.mainButtonColor,
    },
    venueActiveMenu: {
      color: `${uiConfig?.mainButtonColor} !important`,
      borderBottom: `3px solid ${uiConfig?.mainButtonColor} !important`,
    },
  });
  const setDate = (newDate: string) => {
    setTargetDate(newDate);
    const { venue, date } = parseUrlQuery(searchParams);
    const params = {
      venue: activeVenue?.name,
      date: newDate,
    };
    if (!activeVenue?.name || isEqual(params, { venue, date })) {
      return;
    }
    replaceAppUrl(params);
  };
  const init = useCallback(() => {
    const { venue, date } = parseUrlQuery(searchParams);
    const parsedDate =
      date && date !== "undefined" ? date : dayjs().format(DATE_FORMAT);
    if (parsedDate !== targetDate) {
      setDate(parsedDate);
    }

    if (venues && venues.length > 0) {
      const openedVenues = venues.filter((v) => !v.isComingSoon);
      if (!venue) {
        setVenueId(openedVenues[0].id);
        getVenue(openedVenues[0].id);
      } else if (activeVenue?.name !== venue) {
        console.log("reset party, venue:", venue);
        const v = venues.find((item) => item.name === venue);
        if (v) {
          setVenueId(v.id);
          getVenue(v.id);
        } else {
          setVenueId(openedVenues[0].id);
          getVenue(openedVenues[0].id);
        }
      }
      return;
    }
    if (!isLoadingVenue && venues.length > 0) {
      console.log("loading...");
    }
  }, [venues]);
  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    const { venue, date } = parseUrlQuery(searchParams);
    const params = {
      venue: activeVenue?.name,
      date: targetDate,
    };
    if (!activeVenue?.name || isEqual(params, { venue, date })) {
      return;
    }
    replaceAppUrl(params);
    if (activeVenue?.partyUrl) {
      if (uiConfig?.isPackageReservationMode) {
        toPackagesWithVenue();
      } else {
        toReservationWithVenue(params.date);
      }
    }
  }, [replaceAppUrl, activeVenue, targetDate]);

  const timeSlots = composePartyTimeSlots(
    targetDate,
    twelveHourClockFormat,
    activeVenue
  );
  const menuPropsLocations = getVenueList(venues, searchParams);
  const closed = activeVenue?.closed;
  const selectDate = (newDate: string) => {
    setIsCalendar(!isCalendar);
    setDate(newDate);
    setTimeSlot(undefined);
  };
  const toggleCalendar = () => {
    setIsCalendar(!isCalendar);
  };
  const onGuestCountChange = (_: any, text?: string) => {
    setErrorGuestCount("");
    setGuestCount(text || "");
  };
  const onFirstNameChange = (_: any, text?: string) => {
    setErrorFirstName("");
    setFirstName(text || "");
  };
  const onLastNameChange = (_: any, text?: string) => {
    setErrorLastName("");
    setLastName(text || "");
  };
  const onPhoneChange = (text?: string) => {
    setErrorPhone("");
    setPhone(text || "");
  };
  const onEmailChange = (_: any, text?: string) => {
    setErrorEmail("");
    setEmail(text || "");
  };

  const selectVenueOption = (value?: number | string) => {
    if (value && venueId !== value) {
      const id = value.toString();
      setVenueId(id);
      getVenue(id);
      setErrorGuestCount("");
      setTimeSlot(undefined);
    }
  };

  const onChangeCheck = (_: any, isChecked?: boolean) => {
    setIsCheck(!!isChecked);
  };
  const onContinue = (e: any) => {
    e.preventDefault();
    if (
      !activeVenue ||
      !targetDate ||
      timeSlot === undefined ||
      duration === undefined ||
      !occasion ||
      !isValidNumber(guestCount)
    ) {
      if (!isValidNumber(guestCount)) {
        setErrorGuestCount("Please enter a number");
      }
      return;
    }
    if (
      activeVenue.maxGuestsForInquiry > 0 &&
      +guestCount > activeVenue.maxGuestsForInquiry
    ) {
      setErrorGuestCount(
        `Guest number should be less than ${activeVenue.maxGuestsForInquiry}`
      );
      return;
    }
    if (
      activeVenue.minGuestsForInquiry > 0 &&
      +guestCount < activeVenue.minGuestsForInquiry
    ) {
      setErrorGuestCount(
        `Guest number should be more than ${activeVenue.minGuestsForInquiry}`
      );
      return;
    }

    if (phase === 0) {
      return setPhase(1);
    }
  };
  const onSubmit = () => {
    if (
      timeSlot !== undefined &&
      duration !== undefined &&
      firstName &&
      lastName &&
      phone &&
      occasion &&
      isValidPhone(phone) &&
      email &&
      isValidEmail(email)
    ) {
      const GA_Medium__c = (
        document.getElementById("GA_Medium__c") as HTMLInputElement
      )?.value;
      const GA_Source__c = (
        document.getElementById("GA_Source__c") as HTMLInputElement
      )?.value;
      const GA_Campaign__c = (
        document.getElementById("GA_Campaign__c") as HTMLInputElement
      )?.value;
      const GA_Term__c = (
        document.getElementById("GA_Term__c") as HTMLInputElement
      )?.value;
      const GA_AdGroup__c = (
        document.getElementById("GA_AdGroup__c") as HTMLInputElement
      )?.value;
      const GCLIDGCLID__c = (
        document.getElementById("GCLIDGCLID__c") as HTMLInputElement
      )?.value;
      const zuevsoftware__gaconnector_Google_Analytics_Client_ID__c = (
        document.getElementById(
          "zuevsoftware__gaconnector_Google_Analytics_Client_ID__c"
        ) as HTMLInputElement
      )?.value;
      const gaconnector_Google_Analytics_Client_ID__c = (
        document.getElementById(
          "gaconnector_Google_Analytics_Client_ID__c"
        ) as HTMLInputElement
      )?.value;
      const lead_source = (
        document.getElementById("lead_source") as HTMLInputElement
      )?.value;
      const device = (document.getElementById("device") as HTMLInputElement)
        ?.value;
      const timeSlotText = timeSlots.find((item) => item.key === timeSlot)?.text || '';
      const startTime = timeSlotText;
      const endTime = dayjs(timeSlotText).add(duration, "hours").format("HH:mm");

      submitParty({
        venueId,
        date: targetDate,
        startTime,
        endTime,
        timeSlot,
        duration,
        firstName,
        lastName,
        phone: formatUSPhone(phone),
        email,
        occasion,
        guestCount,
        subscribe: isCheck,
        device,
        GA_Medium__c,
        GA_Source__c,
        GA_Campaign__c,
        GA_Term__c,
        GA_AdGroup__c,
        GCLIDGCLID__c,
        zuevsoftware__gaconnector_Google_Analytics_Client_ID__c,
        gaconnector_Google_Analytics_Client_ID__c,
        lead_source,
      });
      return;
    }

    if (!firstName) {
      setErrorFirstName("This field is required.");
    }
    if (!lastName) {
      setErrorLastName("This field is required.");
    }
    if (!phone || !isValidPhone(phone)) {
      setErrorPhone("Please enter a valid phone number.");
    }

    if (!email || !isValidEmail(email)) {
      setErrorEmail("Please enter a valid email.");
    }
  };
  const returnToReservation = (e: React.FormEvent) => {
    e.preventDefault();
    const date = targetDate ? targetDate : dayjs().format(DATE_FORMAT);
    toReservationWithVenue(date);
  };
  const returnToPackageReservation = (e: React.FormEvent) => {
    e.preventDefault();
    toPackagesWithVenue();
  };

  const isDisabled = () => {
    if (phase === 0) {
      return (
        !activeVenue ||
        !targetDate ||
        timeSlot === undefined ||
        !occasion ||
        !guestCount ||
        duration === undefined
      );
    }
    return false;
  };

  const isShowLinkContainer =
    activeVenue?.hasPackages ||
    uiConfig?.isPackageReservationMode ||
    uiConfig?.isExternalBooking;
  const isShowLink =
    !activeVenue?.hasPackages &&
    !uiConfig?.isPackageReservationMode &&
    !uiConfig?.isExternalBooking;

  return (
    <>
      {(isLoadingVenues || isLoadingVenue || isRequestInProgress) && (
        <div className="loading">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      <div
        className="make-party-reservation-container main-container"
        ref={top}
      >
        <Header
          party
          handleBackButton={phase === 0 ? () => goBack() : () => setPhase(0)}
        />
        <div className="makePartyReservation-inner-container main-content">
          <div
            className={`makePartyReservation-main-information main-content ${isMobile ? "mobile" : ""
              }`}
          >
            <div className="makePartyReservation-reservation">
              <div className="makePartyReservation-reservation-title">
                {partyTitle || activeVenue?.eventInquiryTitle}
              </div>
              <div
                className={`makePartyReservation-reservation-subtitle ${contentStyles.venueName}`}
              >
                {activeVenue?.name}
              </div>
              <HTMLRenderer
                html={activeVenue?.descriptionParty}
                className="makePartyReservation-reservation-text"
              />
              {isShowLinkContainer && (
                <div
                  className={`makeReservation-reservation-link-container ${isMobile ? "mobile" : ""
                    }`}
                >
                  {!uiConfig?.isPackageReservationMode && (
                    <>
                      <div
                        className={`link-button ${isMobile ? "mobile" : ""}`}
                        onClick={returnToReservation}
                      >
                        Reservations
                      </div>
                      <div className="link-delimiter" />
                    </>
                  )}
                  <div
                    className={`link-button ${isMobile ? "mobile" : ""}`}
                    onClick={returnToPackageReservation}
                  >
                    {activeVenue?.packagesTitle}
                  </div>
                  <div className="link-delimiter" />
                  <div
                    className={`link-button active ${contentStyles.venueActiveMenu
                      } ${isMobile ? "mobile" : ""}`}
                  >
                    {activeVenue?.eventInquiryTitle}
                  </div>
                </div>
              )}
              {isShowLink && (
                <div className="makePartyReservation-reservation-link">
                  <a
                    href="#"
                    className="focusable"
                    onClick={returnToReservation}
                  >
                    {`Groups of ${activeVenue?.maxGuests} or fewer make a reservation here`}
                  </a>
                </div>
              )}
              {phase === 0 ? (
                <div className="makePartyReservation-reservation-selectors">
                  <div className={isMobile ? "column" : "row"}>
                    <SelectWithIcon
                      placeholder="Select Venue"
                      options={menuPropsLocations}
                      icon={locationIcon}
                      iconWidth={18}
                      iconHeight={16}
                      onChange={selectVenueOption}
                      value={venueId}
                    />
                    <div className="row-delimiter"></div>
                    <SelectWithIcon
                      placeholder="What's the Occasion?"
                      options={occasions}
                      icon={cakeIcon}
                      iconWidth={18}
                      iconHeight={18}
                      value={occasion}
                      onChange={(value) =>
                        setOccasion(value ? value.toString() : "")
                      }
                    />
                  </div>

                  <div className="column">
                    <CalendarButton
                      date={
                        targetDate
                          ? dayjs(targetDate).format(
                            isMobile
                              ? CALENDAR_DATE_MOBILE_FORMAT
                              : CALENDAR_DATE_FORMAT
                          )
                          : undefined
                      }
                      placeholder="Event Date"
                      onClick={toggleCalendar}
                      isCalendar={isCalendar}
                    />
                  </div>
                  {phase === 0 && isCalendar && (
                    <PartyCalendar date={targetDate} selectDate={selectDate} />
                  )}
                  <div className={isMobile ? "column" : "row"}>
                    <SelectWithIcon
                      placeholder="Event Start Time"
                      options={timeSlots}
                      icon={timeIcon}
                      iconWidth={18}
                      iconHeight={18}
                      value={timeSlot}
                      onChange={(option) =>
                        option ? setTimeSlot(+option) : undefined
                      }
                    />
                    <div className="row-delimiter"></div>
                    <SelectWithIcon
                      placeholder="Event Duration"
                      options={durationList}
                      icon={timeIcon}
                      iconWidth={18}
                      iconHeight={18}
                      value={duration}
                      onChange={(option) =>
                        option ? setDuration(+option) : undefined
                      }
                    />
                  </div>
                </div>
              ) : (
                <form
                  id="sales-force"
                  className="makePartyReservation-reservation-selectors"
                >
                  <div className={isMobile ? "column" : "row"}>
                    <InputField
                      label="First name"
                      name="first_name"
                      value={firstName}
                      errorMessage={errorFirstName}
                      onChange={onFirstNameChange}
                      autoComplete={"off"}
                    />
                    {!isMobile && <div className="row-delimiter"></div>}
                    <InputField
                      label="Last name"
                      name="last_name"
                      value={lastName}
                      errorMessage={errorLastName}
                      onChange={onLastNameChange}
                      autoComplete={"off"}
                    />
                  </div>
                  <div className={isMobile ? "column" : "row"}>
                    <InputField
                      label="Email"
                      name="email"
                      value={email}
                      errorMessage={errorEmail}
                      onChange={onEmailChange}
                      autoComplete={"off"}
                      type="email"
                    />
                    {!isMobile && <div className="row-delimiter"></div>}
                    <FormField label="Phone" error={errorPhone}>
                      <PhoneInput
                        placeholder="Phone"
                        defaultCountry="US"
                        value={phone || ""}
                        onChange={onPhoneChange}
                        limitMaxLength={true}
                      />
                    </FormField>
                  </div>
                </form>
              )}
              {phase === 0 && (
                <>
                  <InputField
                    label="Number of Guests"
                    name="numberOfGuest"
                    placeholder="Estimated guests"
                    value={guestCount}
                    errorMessage={errorGuestCount}
                    onChange={onGuestCountChange}
                    autoComplete={"off"}
                    type="tel"
                  />
                </>
              )}
              <input type="hidden" name="GA_Medium__c" id="GA_Medium__c" />
              <input type="hidden" name="GA_Source__c" id="GA_Source__c" />
              <input type="hidden" name="GA_Campaign__c" id="GA_Campaign__c" />
              <input type="hidden" name="GA_Term__c" id="GA_Term__c" />
              <input type="hidden" name="GA_AdGroup__c" id="GA_AdGroup__c" />
              <input type="hidden" name="GCLIDGCLID__c" id="GCLIDGCLID__c" />
              <input
                type="hidden"
                name="zuevsoftware__gaconnector_Google_Analytics_Client_ID__c"
                id="zuevsoftware__gaconnector_Google_Analytics_Client_ID__c"
              />
              <input
                type="hidden"
                name="gaconnector_Google_Analytics_Client_ID__c"
                id="gaconnector_Google_Analytics_Client_ID__c"
              />
              <input type="hidden" name="lead_source" id="lead_source" />
              <input type="hidden" name="device" id="device" />
              {isMobile ? (
                <div className="mobile-summary">
                  <div className="mobile-summary-inner">
                    <Accordion title={activeVenue?.name || ""}>
                      <div className="location">
                        <VenueAddress venue={activeVenue} />
                        <div className="map-panel">
                          <MapImageLink venue={activeVenue} />
                        </div>
                      </div>
                    </Accordion>
                  </div>
                  {/* {closed && (
                  <div className="mobile-summary-inner">
                    <div className="no-slots">
                      Effective March 16th: We will be closed until further
                      notice to comply with state regulations and to support the
                      health and safety of our employees and guests.
                    </div>
                  </div>
                )} */}

                  <div className="mobile-button-container">
                    {phase !== 0 && (
                      <div className="subscription">
                        <Checkbox
                          className="offersAndEvents"
                          label="Keep me updated on exclusive offers and events"
                          name="offersAndEvents"
                          onChange={onChangeCheck}
                          checked={isCheck}
                        />
                        <HTMLRenderer
                          className="info"
                          html={activeVenue?.makePartyNoteText}
                        />
                      </div>
                    )}
                    {error && <div className="error">{error}</div>}
                    {isPartySubmitted && (
                      <div className="confirmation">
                        Party request is submitted. Confirmation is sent on your
                        email.
                      </div>
                    )}
                    {phase === 0 ? (
                      <MobileFixedButton
                        onClick={onContinue}
                        disabled={isDisabled()}
                        uiConfig={uiConfig}
                      >
                        NEXT
                      </MobileFixedButton>
                    ) : (
                      <MobileFixedButton
                        onClick={onSubmit}
                        disabled={isDisabled()}
                        uiConfig={uiConfig}
                      >
                        SUBMIT
                      </MobileFixedButton>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  {phase !== 0 && (
                    <div className="subscription">
                      <Checkbox
                        label="Keep me updated on exclusive offers and events"
                        name="offersAndEvents"
                        onChange={onChangeCheck}
                        checked={isCheck}
                        className="focusableCheckbox"
                      />
                      <HTMLRenderer
                        className="info"
                        html={activeVenue?.makePartyNoteText}
                      />
                    </div>
                  )}
                  {error && <div className="error">{error}</div>}
                  {isPartySubmitted && (
                    <div className="confirmation">
                      Party request is submitted. Confirmation is sent on your
                      email.
                    </div>
                  )}
                  {phase === 0 ? (
                    <PrimaryButton
                      onClick={onContinue}
                      disabled={isDisabled()}
                      uiConfig={uiConfig}
                    >
                      NEXT
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton
                      onClick={onSubmit}
                      disabled={isDisabled()}
                      uiConfig={uiConfig}
                    >
                      SUBMIT
                    </PrimaryButton>
                  )}
                </>
              )}
            </div>

            {!isMobile && (
              <>
                <div className="panel-delimiter"></div>
                <div className="makePartyReservation-summary">
                  <img
                    className="info-logo"
                    src={activeVenue?.venueImage}
                    alt="venueImage"
                  />
                  <div className="summary-inner">
                    <Accordion title={activeVenue?.name || ""}>
                      <div className="location">
                        <VenueAddress venue={activeVenue} />
                        <div className="map-panel">
                          <MapImageLink venue={activeVenue} />
                        </div>
                      </div>
                    </Accordion>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <Footer className={isMobile ? "mobile-footer" : ""} />
      </div>
    </>
  );
};

export default connector(MakePartyReservation);
