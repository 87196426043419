import React, { useState, useEffect } from "react";
import {Checkbox, Link, Text} from "office-ui-fabric-react";
import { isValidEmail } from "../../../../../common/utils/validate"
import { useViewport } from "../../../hooks/responsive";
import { color } from "../../../../../common/constants/styles";
import { PrimaryButton } from "../../../../../common/components/PrimaryButton/PrimaryButton";
import { InputField } from "../../../../../common/components/InputField/InputField";
import { NewGuest } from "../../../store/types";
import "./continueAsGuest.scss";
import MobileFixedButton from "../../MobileFixedButton/MobileFixedButton";

interface Props {
  continueAsGuest: (newGuest: NewGuest) => void;
  cleanError: () => void;
  continueReservation: boolean;
  error?: string;
  uiConfig: any;
}

const textStyle = {
  root: {
    color: color.grayText3,
    paddingTop: "5px",
  },
};

const textLinkStyle = {
  root: {
    color: color.mainDark,
  },
};

export const ContinueAsGuest = ({
  continueAsGuest,
  cleanError,
  error,
  continueReservation,
  uiConfig,
}: Props) => {
  const { isMobile } = useViewport();
  const [email, setEmail] = useState<string | undefined>("");
  const [confirmEmail, setConfirmEmail] = useState<string | undefined>("");
  const [subscribeToOffers, setSubscribeToOffers] = useState<boolean>(true);

  const [errorEmail, setErrorEmail] = useState<string>("");
  const [errorConfirmEmail, setErrorConfirmEmail] = useState<string>("");
  useEffect(() => () => cleanError(), [cleanError]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      email &&
      confirmEmail &&
      email === confirmEmail &&
      isValidEmail(email)
    ) {
      continueAsGuest({
        email,
        subscribeToOffers,
      });
      return;
    }
    if (
      email &&
      confirmEmail &&
      email === confirmEmail &&
      isValidEmail(email) == true
    ) {
      setErrorEmail("");
      setErrorConfirmEmail("");
    }
    if (!email || !isValidEmail(email)) {
      setErrorEmail("Please enter a valid email address.");
    }
    if (!confirmEmail || !isValidEmail(confirmEmail)) {
      setErrorConfirmEmail("Please enter a valid email address.");
    }
    if (confirmEmail && email && email !== confirmEmail) {
      setErrorEmail("These fields must match.");
      setErrorConfirmEmail("These fields must match.");
    }
  };

  const onEmailChange = (_: any, text?: string) => {
    setErrorEmail("");
    setErrorConfirmEmail("");
    setEmail(text);
  };
  const onConfirmEmailChange = (_: any, text?: string) => {
    setErrorEmail("");
    setErrorConfirmEmail("");
    setConfirmEmail(text);
  };
  return (
    <form
      className={
        isMobile
          ? "account-continueAsGuest mobile"
          : "account-continueAsGuest main-content"
      }
      onSubmit={onSubmit}
    >
      <div className={isMobile ? "mobile-column" : "column"}>
        <InputField
          label="Email"
          name="email"
          value={email}
          errorMessage={errorEmail}
          onChange={onEmailChange}
          autoComplete="email"
          type="email"
        />
        <InputField
          label="Confirm email"
          name="confirmEmail"
          value={confirmEmail}
          errorMessage={errorConfirmEmail}
          onChange={onConfirmEmailChange}
          autoComplete="off"
          type="email"
        />
      </div>
      {error && <div className="error">{error}</div>}
      <div className={isMobile ? "mobile-column" : "column"}>
        <div className="privacyPolicy">
          <Checkbox
            className="focusableCheckbox"
            label="Keep me updated on exclusive offers and events"
            checked={subscribeToOffers}
            onChange={(_, value) => setSubscribeToOffers(!!value)}
            name="offersAndEvents"
          />
          <Text variant={"medium"} styles={textStyle}>
            By proceeding, you will create an account and agree to our{' '}
            <Link href={uiConfig.privacyPolicy} target="_blank" styles={textLinkStyle}>Privacy Policy</Link>
            {' '}and{' '}
            <Link href={uiConfig.termsOfUse} target="_blank" styles={textLinkStyle}> Terms of Use </Link>
          </Text>
        </div>
        <div className="row-delimiter"></div>
        {
          isMobile ? (
            <MobileFixedButton type="submit" name="continue" uiConfig={uiConfig}>
              CONTINUE
            </MobileFixedButton>
          ) : (
            <PrimaryButton type="submit" name="continue" uiConfig={uiConfig}>
              CONTINUE
            </PrimaryButton>
          )
        }
      </div>
    </form>
  );
};
